type SymbolVariant = 'Symbol' | 'Code';

const changeCurrencySymbol = (currency: 'SOLES' | 'DOLARES', variant: SymbolVariant = 'Code') => {
  if (!currency) {
    return '';
  }

  switch (variant) {
    case 'Symbol':
      const variantSymbol = { SOLES: 'S/.', DOLARES: '$' };
      const typeSymbol = variantSymbol[currency] !== undefined && variantSymbol[currency];
      return typeSymbol;
    case 'Code':
      const variantyCode = { SOLES: 'PEN', DOLARES: 'USD' };
      const typeCode = variantyCode[currency] !== undefined && variantyCode[currency];
      return typeCode;
    default:
      const variantyDefault = { SOLES: 'PEN', DOLARES: 'USD' };
      const typeDefault = variantyDefault[currency] !== undefined && variantyDefault[currency];
      return typeDefault;
  }
};

export default changeCurrencySymbol;
