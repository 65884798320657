import { Link as RouterLink } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { roundDecimal } from '#root/utils';
import { GuveryDialog } from '#root/ui-components';
import { CanguroGuvery } from '#root/assets';
import * as ROUTES from '#root/constants/navigation';
import styles from './priceDialog.module.scss';

interface PriceDialogProps {
  open: boolean;
  onClose: () => void;
  order: any;
}

const PriceDialog = (props: PriceDialogProps) => {
  const { open = false, onClose, order } = props;
  const imagePath = order?.tienda_product?.img_path;
  const precioProduct = Number(order?.precio);
  const priceDelivery = Number(order?.delivery_precio);
  const priceEnvio = order?.envio_precio ? Number(order?.envio_precio) : 0;
  const priceTotal = precioProduct + priceDelivery + priceEnvio;

  return (
    <GuveryDialog
      open={open}
      onClose={onClose}
      content={false}
      title="Detalle del total"
      closeText="Cerrar">
      <TableContainer>
        <Table className={styles.table}>
          <TableHead className={styles.header}>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <div className={styles.box}>
                  <div className={styles.image}>
                    <img
                      src={imagePath ? imagePath : CanguroGuvery}
                      alt={order?.tienda_product?.nombre}
                    />
                  </div>
                  <div className={styles.body}>
                    <div className={styles.name}>
                      <span>{order?.tienda_product?.nombre}</span>
                    </div>
                    <div className={styles.desc}>
                      <span>{order?.tienda_product?.marca}</span>
                    </div>
                  </div>
                </div>
              </TableCell>
              <TableCell>{order.cantidad}</TableCell>
              <TableCell>S/. {roundDecimal(precioProduct)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Motorizado</TableCell>
              <TableCell></TableCell>
              <TableCell>S/. {roundDecimal(priceDelivery)}</TableCell>
            </TableRow>
            {priceEnvio !== 0 && (
              <TableRow>
                <TableCell>
                  <RouterLink className={styles.link} to={ROUTES.USER_SHIPMENTS}>
                    Envio Provincia <strong>#{order.envio_id}</strong>
                  </RouterLink>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>S/. {roundDecimal(priceEnvio)}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                <span className={styles.total}>Total</span>
              </TableCell>
              <TableCell></TableCell>
              <TableCell>
                <span className={styles.total}>S/. {roundDecimal(priceTotal)}</span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </GuveryDialog>
  );
};

export default PriceDialog;
