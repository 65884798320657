import { MouseEvent } from 'react';
import { IconButton, Toolbar } from '@material-ui/core';

import { useResponsive } from '#root/hooks';
import { ArrowBackIcon, CloseIcon } from '#root/icons';
import styles from './appbar.module.scss';

interface AppbarProps {
  title?: string;
  onClose: (e?: MouseEvent<HTMLElement>) => void;
}

const Appbar = (props: AppbarProps) => {
  const { title, onClose } = props;
  const { isMobile } = useResponsive();

  return (
    <div className={styles.root}>
      <Toolbar>
        {isMobile && (
          <IconButton onClick={onClose}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <h6 className={styles.title}>{title}</h6>
        {!isMobile && (
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Toolbar>
    </div>
  );
};

export default Appbar;
