import { ReactNode, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@material-ui/core';
import {
  IconClock,
  IconPencil,
  IconShoppingCart,
  IconChecks,
  IconTruck,
  IconCameraPlus,
} from '@tabler/icons';
import styles from './actions.module.scss';

import { useDialog, useGlobalNotification } from '#root/hooks';
import { dealService } from '#root/services';
import { replacePathParams } from '#root/utils';
import { ExpirationDays, DealInstructionsDrawer } from '#root/components';
import { AlertDialog, GuverySpace } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import { TRAVELER_DEAL_EDIT } from '#root/constants';
import DeliveredOfficeDialog from '#root/views/TravelerDeals/DealCard/DeliveredOfficeDialog';
import ProvinceDialog from '#root/views/TravelerDeals/DealCard/ProvinceDialog';

interface AlertProps {
  message?: string | ReactNode;
  title?: string;
  variant?: 'error' | 'info' | 'success' | 'warning';
  onConfirm?: () => void;
}

const Actions = (props) => {
  const {
    deal,
    dealIda,
    handleLoading,
    carriers,
    isCreated,
    isPaid,
    isPurchased,
    isReceived,
    isReady,
    isOffice,
    unclosedChangelogs = null,
  } = props;
  const globalNotification = useGlobalNotification();
  const alertDialog = useDialog();
  const deliveredOfficeDialog = useDialog();
  const provinceDialog = useDialog();
  const [drawerInstructionsOpen, setDrawerInstructionsOpen] = useState(false);
  const [alertProps, setAlertProps] = useState<AlertProps>({
    variant: 'info',
    title: 'Marcar el acuerdo como comprado',
    message: (
      <span>
        <span style={{ display: 'inline-block' }}>
          ¿Está seguro de que desea marcar este acuerdo como comprado?.
        </span>
        <span style={{ display: 'inline-block' }}>Esta acción no se puede deshacer.</span>
      </span>
    ),
    onConfirm: () => fetchPurchased(),
  });

  const handleInstructionsDrawer = () => {
    setDrawerInstructionsOpen(!drawerInstructionsOpen);
  };

  const handleOpenAlert = (type) => {
    if (type === 'purchased') {
      setAlertProps({
        variant: 'success',
        title: 'Marcar el acuerdo como comprado',
        message: (
          <span>
            <span style={{ display: 'inline-block' }}>
              ¿Está seguro de que desea marcar este acuerdo como comprado?.
            </span>
            <span style={{ display: 'inline-block' }}>Esta acción no se puede deshacer.</span>
          </span>
        ),
        onConfirm: () => fetchPurchased(),
      });
    }
    if (type === 'received') {
      setAlertProps({
        variant: 'success',
        title: 'Recibido en EE.UU.',
        message: (
          <span>
            <span style={{ display: 'inline-block' }}>
              ¿Estás seguro de que deseas confirmar que recibiste el acuerdo?
            </span>
            <span style={{ display: 'inline-block' }}>Esta acción no se puede deshacer.</span>
          </span>
        ),
        onConfirm: () => fetchRecivedTraveler(),
      });
    }
    if (type === 'ready') {
      setAlertProps({
        variant: 'success',
        title: 'Listo para entregar.',
        message: (
          <span>
            <span style={{ display: 'inline-block' }}>
              ¿Estás seguro de que deseas marcar este acuerdo como 'Listo para entregar'?
            </span>
            <span style={{ display: 'inline-block' }}>Esta acción no se puede deshacer.</span>
          </span>
        ),
        onConfirm: () => fetchReady(),
      });
    }
    if (type === 'delivered') {
      setAlertProps({
        variant: 'success',
        title: 'Acuerdo Entregado',
        message: (
          <span>
            <span style={{ display: 'inline-block' }}>
              ¿Estás seguro de que deseas marcar este acuerdo como <strong>Entregado</strong>?
            </span>
            <span style={{ display: 'inline-block' }}>Esta acción no se puede deshacer.</span>
          </span>
        ),
        onConfirm: () => fetchDelivered(),
      });
    }
    alertDialog.openDialog();
  };

  const fetchPurchased = async () => {
    const res = await dealService.purchasedByTraveler({ deal_id: deal?.ida });
    if (!res.error) {
      globalNotification.alert('Acuerdo actualizado con éxito.', 'success');
      handleLoading();
    } else {
      globalNotification.alert('No se pudo actualizar el acuerdo.', 'error');
    }
    alertDialog.closeDialog();
  };

  const fetchRecivedTraveler = async () => {
    const res = await dealService.receivedByTraveler({ deal_id: deal?.ida });
    if (!res.error) {
      globalNotification.alert('Acuerdo actualizado con éxito.', 'success');
      handleLoading();
    } else {
      globalNotification.alert('No se pudo actualizar el acuerdo.', 'error');
    }
    alertDialog.closeDialog();
  };

  const fetchReady = async () => {
    const res = await dealService.readyByTraveler({ deal_id: deal?.ida });
    if (!res.error) {
      globalNotification.alert('Acuerdo actualizado con éxito.', 'success');
      handleLoading();
    } else {
      globalNotification.alert('No se pudo actualizar el acuerdo.', 'error');
    }
    alertDialog.closeDialog();
  };

  const fetchDelivered = async () => {
    const res = await dealService.deliveredByTraveler({ deal_id: deal?.ida });
    if (!res.error) {
      globalNotification.alert('Acuerdo actualizado con éxito.', 'success');
      handleLoading();
    } else {
      globalNotification.alert('No se pudo actualizar el acuerdo.', 'error');
    }
    alertDialog.closeDialog();
  };

  const fetchDeliveredCarrier = async (
    photo_1,
    photo_2 = null,
    carrier_id,
    message = null,
    deal_id,
  ) => {
    const body = {
      photo_1,
      photo_2,
      carrier_id,
      message,
      deal_id,
    };
    const res = await dealService.deliveredCarrierByTraveler(body);
    if (!res.error) {
      globalNotification.alert('Acuerdo actualizado con éxito.', 'success');
      handleLoading();
    } else {
      globalNotification.alert('No se pudo actualizar el acuerdo.', 'error');
    }
    alertDialog.closeDialog();
  };

  if (!isCreated && !isPaid && !isPurchased && !isReceived && !isReady) {
    return null;
  }

  return (
    <GuveryPaper disableElevation disableHover className={styles.root}>
      {unclosedChangelogs && unclosedChangelogs.length > 0 ? (
        <>
          <GuverySpace direction="vertical" size="middle">
            <div>
              <Button
                fullWidth
                disableElevation
                variant="contained"
                disabled
                color="secondary"
                startIcon={<IconClock />}>
                Hay cambios en Proceso
              </Button>
              <p
                style={{
                  fontSize: 'var(--font-size-7)',
                  color: 'var(--color-text-alt-2)',
                  marginTop: 6,
                }}>
                Mientras tanto, no puedes modificar el acuerdo
              </p>
            </div>
          </GuverySpace>
        </>
      ) : (
        <>
          <GuverySpace direction="vertical" size="middle">
            {isCreated && (
              <div>
                <Button
                  fullWidth
                  disableElevation
                  variant="contained"
                  color="secondary"
                  startIcon={<IconPencil />}
                  component={RouterLink}
                  to={replacePathParams(TRAVELER_DEAL_EDIT, { ida: dealIda })}>
                  Modificar
                </Button>
                <ExpirationDays
                  expirationDays={deal?.fixed_expiration_days}
                  style={{ marginTop: 6 }}
                />
              </div>
            )}
            {isPaid && (
              <>
                <p className={styles.anchorActionSuggestionText}>
                  <a onClick={handleInstructionsDrawer}>Instrucciones para comprar</a>
                </p>
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  startIcon={<IconShoppingCart />}
                  onClick={handleInstructionsDrawer}>
                  Ya lo compré
                </Button>
                <DealInstructionsDrawer
                  open={drawerInstructionsOpen}
                  onClose={() => setDrawerInstructionsOpen(!drawerInstructionsOpen)}
                  handleLoading={handleOpenAlert}
                  deal={deal}
                  nextAction="purchased"
                />
              </>
            )}
            {isPurchased && (
              <>
                <p className={styles.anchorActionSuggestionText}>
                  <a onClick={handleInstructionsDrawer}>¿Qué hacer al recibir este pedido?</a>
                </p>
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  startIcon={<IconTruck />}
                  onClick={handleInstructionsDrawer}>
                  Ya lo recibí
                </Button>
                <DealInstructionsDrawer
                  open={drawerInstructionsOpen}
                  onClose={() => setDrawerInstructionsOpen(!drawerInstructionsOpen)}
                  handleLoading={handleOpenAlert}
                  deal={deal}
                  nextAction="received"
                />
              </>
            )}
            {isReceived && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <div style={{ textAlign: 'end' }}>
                  <Button
                    fullWidth
                    disableElevation
                    variant="contained"
                    color="primary"
                    startIcon={<IconChecks />}
                    onClick={() => handleOpenAlert('ready')}>
                    Listo para entrega
                  </Button>
                </div>
                <span style={{ marginTop: 8 }}>
                  * Indica al comprador que estás listo para entregar.
                </span>
              </div>
            )}
            {isReady && !isOffice && (
              <Button
                disableElevation
                variant="contained"
                color="primary"
                startIcon={<IconChecks />}
                onClick={() => handleOpenAlert('delivered')}>
                Entregado
              </Button>
            )}
            {isReady && isOffice && (
              <>
                <p className={styles.anchorActionSuggestionText}>
                  <a onClick={provinceDialog.openDialog}>¿Cómo entregar en oficina?</a>
                </p>
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  startIcon={<IconCameraPlus />}
                  onClick={deliveredOfficeDialog.openDialog}>
                  Entregado en oficina
                </Button>
              </>
            )}
          </GuverySpace>
          <AlertDialog
            open={alertDialog.open}
            onClose={alertDialog.closeDialog}
            {...alertProps}
          />
          <DeliveredOfficeDialog
            id={deal?.ida}
            carriers={carriers}
            open={deliveredOfficeDialog.open}
            onClose={deliveredOfficeDialog.closeDialog}
            onConfirm={fetchDeliveredCarrier}
          />
          <ProvinceDialog open={provinceDialog.open} onClose={provinceDialog.closeDialog} />
        </>
      )}
    </GuveryPaper>
  );
};

export default Actions;
