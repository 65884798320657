import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { IconTrash, IconUserCheck } from '@tabler/icons';

import { useDialog } from '#root/hooks';
import { offerService } from '#root/services';
import { replacePathParams } from '#root/utils';
import { AlertDialog } from '#root/ui-components';
import { USER_ORDERS_BY_ID_BY_OFFER } from '#root/constants/navigation';
import styles from './offerActions.module.scss';

interface OfferActionsProps {
  orderId: string | number;
  offerId: string | number;
  isCreated?: boolean;
  isOfferRejected?: boolean;
  isOfferExpired?: boolean;
  isNegotiating?: boolean;
  handleLoading?: () => void;
  createMessage?: any;
}

const OfferActions = (props: OfferActionsProps) => {
  const {
    orderId,
    offerId,
    isCreated,
    isOfferRejected,
    isOfferExpired,
    isNegotiating,
    handleLoading,
    createMessage,
  } = props;
  const history = useHistory();

  const dialogRejectOffer = useDialog();

  const handleRejectOffer = () => {
      fetchReject();
      dialogRejectOffer.closeDialog();
  }

  const fetchAccept = async () => {
    const res = await offerService.acceptByShopper(offerId);
    if (!res.error) {
      createMessage.success('Oferta aceptada.');
      const offerDrawerPath = replacePathParams(USER_ORDERS_BY_ID_BY_OFFER, {
        idp: orderId,
        ido: offerId,
      });
      history.push(offerDrawerPath);
      handleLoading();
    } else {
      createMessage.error('No se pudo actualizar la oferta.');
    }
  };

  const fetchReject = async () => {
    const res = await offerService.rejectByShopper(offerId);
    if (!res.error) {
      createMessage.success('Oferta rechazada.');
      const offerDrawerPath = replacePathParams(USER_ORDERS_BY_ID_BY_OFFER, {
        idp: orderId,
        ido: offerId,
      });
      history.push(offerDrawerPath);
      handleLoading();
    } else {
      createMessage.error('No se pudo actualizar la oferta.');
    }
  };

  if (isOfferRejected || isOfferExpired) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.accept}>
        {(isCreated || isNegotiating) && (
          <Button
            fullWidth
            disableElevation
            variant="contained"
            color="primary"
            startIcon={!isOfferExpired && !isNegotiating && <IconUserCheck />}
            onClick={fetchAccept}>
            Aceptar oferta
          </Button>
        )}
      </div>
      {(!isOfferRejected || isOfferExpired) && (
        <>
        <Button
          className={styles.remove}
          disableElevation
          variant="text"
          color="secondary"
          startIcon={<IconTrash color={'var(--color-status-error-expired)'} />}
          onClick={dialogRejectOffer.openDialog}>
          <span style={{color: 'var(--color-status-error-expired)'}}>Rechazar Oferta</span>
        </Button>
        <AlertDialog
          variant="error"
          open={dialogRejectOffer.open}
          onClose={dialogRejectOffer.closeDialog}
          title="Rechazar Oferta"
          message={
              <span>
                <span style={{ display: 'inline-block' }}>
                  ¿Estás seguro de que deseas Rechazar esta oferta?
                </span>
                <span style={{ display: 'inline-block' }}>Esta acción no se puede deshacer.</span>
              </span>
          }
          onConfirm={handleRejectOffer}
          confirmText="Rechazar Oferta"
        />
        </>
      )}
    </div>
  );
};

export default OfferActions;
