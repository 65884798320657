import { useState, useEffect } from 'react';
import {
  Avatar,
  IconButton,
  Button,
  Drawer,
  Collapse,
  ClickAwayListener,
  ListItemSecondaryAction,
  List,
  Switch,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { IconX, IconFilter } from '@tabler/icons';
import { formatDistanceStrict } from 'date-fns';
import { es } from 'date-fns/locale';
import clsx from 'clsx';

import ConversationDTO from '#root/interfaces/ConversationDTO';
import { ConversationMessageStatus } from '#root/interfaces/ConversationMessageDTO';
import { GuverySpace } from '#root/ui-components';
import { useResponsive, useAuth } from '#root/hooks';
import { PersonOutlineIcon } from '#root/icons';
import Logo from '#root/assets/images/guvery/canguro_guvery.png';
import * as VARIABLES from '#root/constants/variables';
import styles from './conversationList.module.scss';

interface ConversationItemProps {
  conversation?: ConversationDTO;
  isSelected?: boolean;
  onSelectConversation?: (conversationId: string | number) => void;
}

const ConversationItem = (props: ConversationItemProps) => {
  const { conversation, isSelected = false, onSelectConversation } = props;
  const { usuario } = useAuth();
  const { isMobile } = useResponsive();
  const lastMessage = conversation?.last_channel_message;
  const messageDate = lastMessage?.created_at
    ? lastMessage?.created_at.toString().replace(/ /g, 'T')
    : conversation?.created_at.toString().replace(/ /g, 'T');

  const messageState =
    lastMessage?.user_id !== usuario.idu &&
    (lastMessage?.current_status === ConversationMessageStatus.created ||
      lastMessage?.current_status === ConversationMessageStatus.notified)
      ? true
      : false;

  const titleMessage = conversation?.deal
    ? `Acuerdo #${conversation?.deal.ida}`
    : `Pedido #${conversation?.offer.order.id_public}`;

  const senderUser = conversation?.channel_users.find(
    (e) => e.user_id !== usuario.idu,
  );

  if (!senderUser) {
    return (
      <div
        className={clsx(
          styles.item,
          isMobile && styles.mobile,
          isSelected && styles.active,
          messageState && styles.itemActive,
        )}>
        <div className={styles.avatar}>
          <Avatar variant="rounded" src={Logo} alt="Logo">
            <PersonOutlineIcon />
          </Avatar>
        </div>
        <div>No se encontró al usuario</div>
      </div>
    );
  }

  const senderId = senderUser?.user_id ? senderUser.user_id : '';
  const senderFirstName = senderUser?.user?.first_name ? senderUser.user.first_name : '---';
  const senderLastName = senderUser?.user?.last_name ? senderUser.user.last_name : '---';
  const senderPic = senderUser?.user?.profile_picture ? senderUser.user.profile_picture : '';

  return (
    <div
      className={clsx(
        styles.item,
        isMobile && styles.mobile,
        isSelected && styles.active,
        messageState && styles.itemActive,
      )}
      onClick={() => onSelectConversation(conversation.id)}>
      <div className={styles.avatar}>
        <Avatar alt={senderFirstName} src={VARIABLES.IMAGE_PROFILE_PATH + senderPic + '.png'}>
          {senderFirstName.charAt(0)}
        </Avatar>
      </div>
      <div className={styles.content}>
        <strong>{titleMessage}</strong>
        <span className={clsx(styles.name, messageState && styles.active)}>
          {senderFirstName} {senderLastName.charAt(0)}.
        </span>
        <p className={clsx(styles.msg, messageState && styles.active)}>
          {usuario.idu === senderId && <span className={styles.sufix}>Tú: </span>}
          {lastMessage?.content}
        </p>
      </div>
      <div className={styles.extra}>
        <div className={styles.date}>
          {formatDistanceStrict(new Date(messageDate), new Date(), { locale: es })}
        </div>
        {messageState && <div className={styles.state}></div>}
      </div>
    </div>
  );
};

interface ConversationListProps {
  openList?: boolean;
  onListClose?: () => void;
  loading?: boolean;
  conversations?: ConversationDTO[];
  activeConversationId?: string | number;
  onSelectConversation?: (conversationId: string | number) => void;
  applyFilter?: (type: 'open' | 'closed' | 'all') => void;
}

const ConversationList = (props: ConversationListProps) => {
  const {
    openList,
    onListClose,
    loading,
    conversations = [],
    activeConversationId,
    onSelectConversation,
    applyFilter,
  } = props;
  const toolbarMatches = useMediaQuery('(min-width:600px)');
  const { isMobile } = useResponsive();
  const [showFilter, setShowFilter] = useState(false);
  const [toggleFilter, setToggleFilter] = useState({
    open: true,
    closed: false,
  });

  const handleFilterBtnChange = () => {
    setShowFilter((prev) => !prev);
  };

  const handleApplyFilter = () => {
    if (toggleFilter.open && toggleFilter.closed) {
      applyFilter('all');
      setShowFilter(false);
      return;
    }
    if (!toggleFilter.open && !toggleFilter.closed) {
      applyFilter('all');
      setShowFilter(false);
      return;
    }
    if (toggleFilter.open) {
      applyFilter('open');
      setShowFilter(false);
      return;
    }
    if (toggleFilter.closed) {
      applyFilter('closed');
      setShowFilter(false);
      return;
    }
  };

  const handleToggle = (value: string) => () => {
    setToggleFilter({
      ...toggleFilter,
      [value]: !toggleFilter[value],
    });
  };

  useEffect(() => {
    if (toggleFilter.open === false) {
      setToggleFilter({
        ...toggleFilter,
        closed: true,
      });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleFilter.open]);

  useEffect(() => {
    if (toggleFilter.closed === false) {
      setToggleFilter({
        ...toggleFilter,
        open: true,
      });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleFilter.closed]);

  const content = (
    <>
      {openList && (
        <>
          <ClickAwayListener onClickAway={() => setShowFilter(false)}>
            <div className={styles.toolbar}>
              <div className={styles.wrap}>
                <h1 className={styles.title}>Mis Chats</h1>
                <GuverySpace>
                  <IconButton
                    color="secondary"
                    aria-label="filter"
                    onClick={handleFilterBtnChange}>
                    {showFilter ? <IconX size={22} /> : <IconFilter size={22} />}
                  </IconButton>
                </GuverySpace>
              </div>
              <Collapse in={showFilter}>
                <div className={styles.toolsPop}>
                  <List aria-label="tools">
                    <ListItem>
                      <ListItemText id="switch-list-label-open" primary="Mostrar Activos" />
                      <ListItemSecondaryAction>
                        <Switch
                          color="primary"
                          edge="end"
                          onChange={handleToggle('open')}
                          checked={toggleFilter.open}
                          inputProps={{ 'aria-labelledby': 'switch-list-label-open' }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemText id="switch-list-label-closed" primary="Mostrar Cerrados" />
                      <ListItemSecondaryAction>
                        <Switch
                          color="primary"
                          edge="end"
                          onChange={handleToggle('closed')}
                          checked={toggleFilter.closed}
                          inputProps={{ 'aria-labelledby': 'switch-list-label-closed' }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <Button
                        disableElevation
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleApplyFilter}
                        startIcon={<IconFilter size={22} />}>
                        Aplicar Filtros
                      </Button>
                    </ListItem>
                  </List>
                </div>
              </Collapse>
            </div>
          </ClickAwayListener>
        </>
      )}
      {showFilter && (
        <div className={styles.backdrop} style={{ opacity: !showFilter && 0 }}></div>
      )}
      {!loading ? (
        <div className={styles.list}>
          <GuverySpace direction="vertical" size={0}>
            {conversations.length > 0 ? (
              <>
                {conversations?.map((conversation) => (
                  <ConversationItem
                    key={conversation.id}
                    conversation={conversation}
                    isSelected={activeConversationId === conversation.id}
                    onSelectConversation={onSelectConversation}
                  />
                ))}
              </>
            ) : (
              <div className={styles.empty}>No se encontraron chats.</div>
            )}
          </GuverySpace>
        </div>
      ) : (
        <div className={styles.list}>
          <GuverySpace direction="vertical" size={0}>
            {Array.from(new Array(8)).map((_data, idx) => (
              <div key={idx} className={clsx(styles.item, isMobile && styles.mobile)}>
                <Skeleton className={styles.avatar} variant="circle" width={48} height={48} />
                <div className={styles.content}>
                  <Skeleton className={styles.content} variant="text" width="40%" />
                  <Skeleton className={styles.content} variant="text" width="80%" />
                </div>
              </div>
            ))}
          </GuverySpace>
        </div>
      )}
    </>
  );

  return (
    <>
      {!isMobile ? (
        <div className={clsx(styles.root, !openList && styles.close)}>{content}</div>
      ) : (
        <Drawer
          anchor="left"
          variant="temporary"
          open={openList}
          onClose={onListClose}
          PaperProps={{
            className: styles.drawer,
            style: {
              top: toolbarMatches ? 64 : 56,
              width: '100%',
              background: 'var(--color-background-base)',
            },
          }}
          style={{
            zIndex: 1200,
          }}>
          {content}
        </Drawer>
      )}
    </>
  );
};

export default ConversationList;
