import { useState, useRef, useEffect } from 'react';
import { Drawer } from '@material-ui/core';
import { IconInfoCircle } from '@tabler/icons';

import { useResponsive, useAuth } from '#root/hooks';
import { chatService } from '#root/services';
import styles from './chatDrawer.module.scss';
import Appbar from './Appbar';
import Actions from './Actions';
import Message from './Message';

interface ChatDrawerProps {
  offerId?: string | number;
  open: boolean;
  onClose: () => void;
  extraTitle?: string;
}

const multiplo = (numero) => {
  if (numero % 3 === 0) {
    return true;
  } else {
    return false;
  }
};

const ChatDrawer = (props: ChatDrawerProps) => {
  const { offerId, open, onClose, extraTitle } = props;
  const { usuario } = useAuth();
  const { isDesktop } = useResponsive();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [chanel, setChanel] = useState(null);
  const scrollRef = useRef(null);

  const fetchChatChanel = async () => {
    setLoading(true);
    const res = await chatService.getChannelByOffer(offerId);
    if (!res.error) {
      setChanel(res.data);
    }
    setLoading(false);
  };

  const isChannelClosed =
    chanel?.chat_channel?.current_status === 'CLOSED' || chanel?.chat_channel?.closed_at != null
      ? true
      : false;
  const isChannelEmpty = chanel?.chat_channel_messages?.length === 0;

  useEffect(() => {
    open && offerId && fetchChatChanel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollMessagesToBottom();
  }, [chanel?.chat_channel_messages, isLoading]);

  const content = (
    <>
      <Appbar
        conversationUsers={chanel?.chat_channel_users}
        onClose={onClose}
        extraTitle={extraTitle}
      />
      <div className={styles.alert}>
        <IconInfoCircle size={20} />
        {!isChannelClosed && (
          <p className={styles.text}>
            <strong>Vista previa de la conversación.</strong>
            &nbsp;Actualizado hasta: {chanel?.last_udpate_at}
          </p>
        )}
        {isChannelClosed && (
          <p className={styles.text}>
            <strong>Finalizado el:</strong>
            &nbsp;{chanel?.chat_channel?.closed_at}
          </p>
        )}
      </div>
      <div className={styles.root} ref={scrollRef}>
        <div className={styles.content}>
          {!isLoading ? (
            <>
              {isChannelEmpty && (
                <p style={{ color: 'var(--color-gray)' }}>
                  Aún no hay mensajes en esta conversación.
                </p>
              )}
              {!isChannelEmpty && (
                <>
                  {chanel?.chat_channel_messages.map((message) => (
                    <Message
                      key={message?.id}
                      owner={usuario.idu === message?.user_id}
                      message={message}
                      conversationUsers={chanel?.chat_channel_users}
                    />
                  ))}
                </>
              )}
            </>
          ) : (
            <>
              {Array.from(new Array(8)).map((data, index) => (
                <Message key={index} isLoading owner={!multiplo(index)} />
              ))}
            </>
          )}
        </div>
      </div>
      <Actions
        channelId={chanel?.chat_channel?.id}
        onClose={onClose}
        isChannelClosed={isChannelClosed}
        isChannelEmpty={isChannelEmpty}
      />
    </>
  );

  return (
    <>
      {isDesktop ? (
        <Drawer
          anchor="right"
          open={open}
          onClose={onClose}
          PaperProps={{
            elevation: 0,
            style: {
              width: 576,
              height: '100%',
              background: 'var(--color-background-base)',
            },
          }}>
          {content}
        </Drawer>
      ) : (
        <Drawer
          anchor="right"
          open={open}
          onClose={onClose}
          PaperProps={{
            style: {
              width: '100%',
              background: 'var(--color-background-base)',
            },
          }}
          style={{ zIndex: 1300 }}>
          {content}
        </Drawer>
      )}
    </>
  );
};

export default ChatDrawer;
