const decodeGAT = (gat: string | undefined, ip: any): string | undefined => {
  let idu = undefined;
  let constGat = gat;
  let ip_working_string = ip;

  const gatKey1 = '473809';
  const gatKey2 = '213180';
  const gatKey3 = '093456';

  try {
    // De los 4 elementos de una IP, me quedo con los 3 primeros:
    ip_working_string =
      ip_working_string.split('.')[0] +
      '' +
      ip_working_string.split('.')[1] +
      '' +
      ip_working_string.split('.')[2];
    // Borro los . y : para quedarme solo con numeros:
    ip_working_string = ip_working_string.replaceAll('.', '');
    ip_working_string = ip_working_string.replaceAll(':', '');
    let ip_working_number = parseFloat(ip_working_string);
    // Se tiene que usar 1 variable para trabajar con float, y otra para string:
    ip_working_string = parseFloat((ip_working_number / 7).toFixed(3));
    ip_working_string = ip_working_string + '';
    ip_working_string = ip_working_string.replaceAll('.', '');

    constGat = gat.split('').reverse().join('');

    constGat = constGat.replaceAll(gatKey3, '');

    constGat = constGat.split('').reverse().join('');

    constGat = constGat.replaceAll(gatKey2, '');

    constGat = constGat.split('').reverse().join('');

    constGat = constGat.replaceAll(ip_working_string, '');

    constGat = constGat.split('').reverse().join('');

    constGat = constGat.replaceAll(gatKey1, '');

    idu = constGat;
  } catch (e) {
    idu = undefined;
  }

  return idu;
};

export default decodeGAT;
