import { Button } from '@material-ui/core';
import { IconTrash } from '@tabler/icons';

import { useDialog } from '#root/hooks';
import { dealService } from '#root/services';
import RejectDialog from '#root/views/TravelerDeals/DealCard/RejectedDialog';
import styles from './tools.module.scss';

const Tools = (props) => {
  const { dealIda, handleLoading, createMessage } = props;
  const rejectDialog = useDialog();

  const openRejectAlert = () => {
    // handleMenuClose();
    rejectDialog.openDialog();
  };

  const fetchCancel = async (reason, reasonOther, dealId) => {
    const body = {
      reason,
      reasonOther,
      dealId,
    };
    const res = await dealService.rejectedByTraveler(body);
    if (!res.error) {
      createMessage.success('Acuerdo anulado con éxito');
      handleLoading();
      return;
    }
    createMessage.error('Ocurrió un error al anular el acuerdo');
    rejectDialog.closeDialog();
  };

  return (
    <div className={styles.root}>
      <Button
        className={styles.btn}
        startIcon={<IconTrash size={20} color="var(--color-status-error)" />}
        onClick={openRejectAlert}>
        Anular acuerdo
      </Button>
      <RejectDialog
        id={dealIda}
        open={rejectDialog.open}
        onClose={rejectDialog.closeDialog}
        onConfirm={fetchCancel}
      />
    </div>
  );
};

export default Tools;
