import SvgIcon from '@material-ui/core/SvgIcon';

const MailSvg = () => (
  <>
    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
    <path d="M22 6L12 13 2 6"></path>
  </>
);

const Mail = (props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    style={{ fill: 'none' }}
    {...props}>
    <MailSvg />
  </SvgIcon>
);

export default Mail;
