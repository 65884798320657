import { useState } from 'react';

import { EmailEmpty } from '#root/assets';
import { useResponsive } from '#root/hooks';
import HeaderDetail from '../HeaderDetail';
import MessageList from '../MessageList';
import styles from './window.module.scss';

const Window = (props) => {
  const { activeConversationId, openList, onListClose, setChats } = props;
  const { isDesktop } = useResponsive();
  const [isLoading, setLoading] = useState<boolean>(isDesktop);
  const [isDetailOpen, setDetailOpen] = useState<boolean>(isDesktop);
  const [channelUsers, setChannelUsers] = useState([]);
  const [isReceptorActive, setReceptorActive] = useState<boolean>(false);

  return (
    <div className={styles.root}>
      {activeConversationId ? (
        <>
          <HeaderDetail
            openList={openList}
            onListClose={onListClose}
            openDetail={isDetailOpen}
            onDetailClose={() => setDetailOpen(!isDetailOpen)}
            loading={isLoading}
            conversationUsers={channelUsers}
            isReceptorActive={isReceptorActive}
          />
          <hr className={styles.divider} />
          {activeConversationId && (
            <MessageList
              openDetail={isDetailOpen}
              onDetailClose={() => setDetailOpen(!isDetailOpen)}
              setChats={setChats}
              setChannelUsers={setChannelUsers}
              activeConversationId={activeConversationId}
              setReceptorActive={setReceptorActive}
              setLoading={setLoading}
              isLoading={isLoading}
            />
          )}
        </>
      ) : (
        <div className={styles.emptyChat}>
          <img className={styles.emptyCover} src={EmailEmpty} alt="email empty" />
          <h4 className={styles.emptyText}>No has seleccionado una conversación</h4>
        </div>
      )}
    </div>
  );
};

export default Window;
