import { ReactNode, forwardRef } from 'react';
import { SnackbarContent as MuiSnackbarContent, IconButton } from '@material-ui/core';
import { amber, green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { MouseEventHandler } from 'react';

import { NotificationSeverity } from '#root/interfaces/NotificationReducer';

import {
  AlertCircleIcon,
  AlertTriangleIcon,
  CheckmarkCircle2Icon,
  CloseIcon,
  InfoCircleIcon,
} from '#root/icons';

const variantIcon = {
  success: CheckmarkCircle2Icon,
  warning: AlertTriangleIcon,
  error: AlertCircleIcon,
  info: InfoCircleIcon,
};

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.info.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    display: 'inline-flex',
    fontSize: 22,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    fontSize: 'var(--font-size-base)',
    fontFamily: 'var(--font-family)',
    display: 'flex',
    alignItems: 'center',
  },
}));

interface SnackbarContentWrapperProps {
  className?: string;
  message: ReactNode | string;
  onClose: MouseEventHandler<HTMLButtonElement>;
  variant: NotificationSeverity;
  icon?: ReactNode;
}

const SnackbarContentWrapper = forwardRef<HTMLDivElement, SnackbarContentWrapperProps>(
  (props, ref) => {
    const { className, message, onClose, variant, icon, ...other } = props;
    const classes = useStyles();
    const Icon = variantIcon[variant];

    return (
      <MuiSnackbarContent
        ref={ref}
        className={clsx(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <span className={clsx(classes.icon, classes.iconVariant)}>
              {icon ? icon : <Icon />}
            </span>
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    );
  },
);

export default SnackbarContentWrapper;
