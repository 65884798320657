import { useState } from 'react';
import { Button } from '@material-ui/core';
import { IconRepeat, IconTrash } from '@tabler/icons';

import { shopperService } from '#root/services';
import { GuveryDialog, GuverySpace } from '#root/ui-components';
import styles from './rejectDialog.module.scss';

const RejectDialog = (props) => {
  const { id, open = false, onClose, handleLoading, createMessage } = props;
  const [isSubmitting, setSubmitting] = useState(false);

  const handleConfirmReject = (state: number) => {
    fetchReject(state);
  };

  const fetchReject = async (state: number) => {
    setSubmitting(true);
    const res = await shopperService.dealReject(id, state);
    if (res.error) {
      createMessage.error('No se pudo completar la acción');
      setSubmitting(false);
      return;
    }
    createMessage.success('Acuerdo Rechazado...');
    handleLoading();
    setSubmitting(false);
    onClose();
  };

  return (
    <GuveryDialog open={open} onClose={onClose} title="Rechazar acuerdo" content={false}>
      <div className={styles.root}>
        <div className={styles.content}>
          <div className={styles.wrap}>
            <h5>
              El viajero invierte su tiempo para hacerte una oferta y concretar un acuerdo.
            </h5>
            <h5 className="guv-text-alt-2">
              (Simula la compra, revisa las medidas, calcula su inversión, etc.)
            </h5>
            <h5>
              Concretar un acuerdo y rechazarlo, es una conducta que perjudica a la comunidad de
              viajeros de Guvery.
            </h5>
            <br />
            <h5>
              Si el acuerdo tiene un error, intenta chatear con el viajero para corregirlo.
            </h5>
            <br />
            <h5>
              Como última alternativa, puedes rechazar este acuerdo, ya que aún no ha sido
              pagado.
            </h5>
          </div>
        </div>
        <div className={styles.actions}>
          <div className={styles.wrap}>
            <GuverySpace direction="horizontal" size="middle">
              <Button
                variant="outlined"
                disabled={isSubmitting}
                startIcon={<IconTrash style={{ color: 'var(--color-status-error)' }} />}
                onClick={() => handleConfirmReject(0)}>
                Rechazar sin republicar
              </Button>
              <Button
                variant="outlined"
                disabled={isSubmitting}
                startIcon={<IconRepeat style={{ color: 'var(--color-status-info)' }} />}
                onClick={() => handleConfirmReject(1)}>
                Rechazar y republicar pedido
              </Button>
            </GuverySpace>
          </div>
        </div>
      </div>
    </GuveryDialog>
  );
};

export default RejectDialog;
