export const AUTH_START = '/start';

// ===========================|| USUARIO ||=========================== //

export const USER_PROFILE = '/usuario/perfil';

export const USER_ORDERS = '/comprador/mis-pedidos';

export const USER_ORDERS_BY_ID = '/comprador/pedido/:idp';

export const USER_ORDERS_BY_ID_BY_OFFER = '/comprador/pedido/:idp/:ido';

export const USER_DEALS = '/comprador/acuerdos';

export const USER_DEALS_TRANSIT = '/comprador/acuerdos-transito';

export const USER_DEALS_DELIVERED = '/comprador/pedidos-entregados';

export const USER_DEALS_INACTIVE = '/comprador/pedidos-inactivos';

export const USER_DEAL_DETAIL = '/comprador/acuerdo/:ida';

export const USER_DEAL_DETAIL_RATING = '/comprador/acuerdo/:ida/calificar';

export const USER_ORDERS_STORE = '/comprador/mis-pedidos-tienda';

export const USER_CHAT = '/usuario/mischats';

export const USER_CHAT_ROOM = '/usuario/mischats/:id';

export const USER_WALLET = '/usuario/billetera';

export const USER_WALLET_REFUNDS = '/usuario/billetera/reembolsos';

export const USER_ADDRESS = '/usuario/direcciones';

export const USER_SHIPMENTS = '/usuario/envios';

export const USER_TRAVELER_SETTINGS = '/usuario/configuracion_viajero';

export const USER_COUPONS = '/usuario/miscupones';

// ===========================|| VIAJERO ||=========================== //

export const TRAVELER_DASHBOARD = '/viajero/resumen';

export const TRAVELER_TRIPS = '/viajero/misviajes';

export const TRAVELER_TRIPS_NEW = '/viajero/misviajes/nuevo';

export const TRAVELER_ORDERS = '/viajero/pedidos';

export const TRAVELER_ORDERS_PRE = '/viajero/pedidos-pre';

export const TRAVELER_ORDERS_BY_ID = '/viajero/pedidos/:id';

export const TRAVELER_OFFERS = '/viajero/ofertas';

export const TRAVELER_OFFERS_BY_ID = '/viajero/ofertas/:id';

export const TRAVELER_DEAL_NEW = '/viajero/ofertas/:id/acuerdo';

export const TRAVELER_DEALS = '/viajero/acuerdos';

export const TRAVELER_DEAL_AUTO = '/viajero/acuerdos/auto-acuerdo';

export const TRAVELER_DEALS_CREATED = '/viajero/acuerdos/creados';

export const TRAVELER_DEALS_DELIVERED = '/viajero/acuerdos/entregados';

export const TRAVELER_DEALS_CANCELED = '/viajero/acuerdos/anulados';

export const TRAVELER_DEAL_DETAIL = '/viajero/acuerdo/:ida';

export const TRAVELER_DEAL_EDIT = '/viajero/acuerdo/:ida/editar';

export const TRAVELER_DEAL_DETAIL_RATING = '/viajero/acuerdo/:ida/calificar';

export const TRAVELER_EARNINGS = '/viajero/ganancias';

export const TRAVELER_EARNINGS_UPCOMING = '/viajero/ganancias/proximos';

export const TRAVELER_EARNINGS_REQUESTED = '/viajero/ganancias/solicitados';

export const TRAVELER_EARNINGS_PAID = '/viajero/ganancias/pagados';

export const TRAVELER_DELIVERY_SETUP = '/viajero/configurar_entregas';

export const TRAVELER_TRAINING = '/viajero/capacitacion';

// ===========================|| EXTRA ||=========================== //

export const NOT_FOUND = '/404';
