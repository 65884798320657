export const RatingStatus = {
  created: 'CREATED',
  rated: 'RATED',
} as const;

export type RatingStatusType = typeof RatingStatus[keyof typeof RatingStatus];

interface RatingDTO {
  created_at: Date;
  current_status: RatingStatusType;
  deal_id: number;
  id: number;
  opinion?: string | null;
  rated_at?: Date | null;
  score?: number | null;
}

export default RatingDTO;
