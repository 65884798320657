import styles from './travelerTest.module.scss';

const Responsibilities = () => {
  return (
    <ol className={styles.responsibilities}>
      <li>
        <p>
          <strong>Sobre las ofertas:</strong>
        </p>
        <p className="guv-text-alt-2">
          El viajero debe{' '}
          <strong>
            simular correctamente la compra de un pedido, antes de hacer una oferta
          </strong>
          . De esta forma podrá saber el precio real correcto (sumando tax/shipping), y tendrá
          la certeza de que lo podrá traer en caso su oferta sea aceptada y concrete un Acuerdo
          (revisando también: las especificaciones del producto, las observaciones en la
          publicación, el tiempo de entrega de la tienda, y todos los aspectos importantes).
        </p>
      </li>
      <li>
        <p>
          <strong>Sobre las negociaciones y el acuerdo:</strong>
        </p>
        <p className="guv-text-alt-2">
          Es válido que el comprador pueda negociar sobre la comisión (utilizando el chat) o
          incluso la incorporación de más pedidos sobre el acuerdo que están creando.
          <br />
          <strong>El viajero debe responder oportunamente</strong> a los compradores interesados
          en sus ofertas, así como respetar lo conversado por el chat al momento de crear un
          acuerdo.
        </p>
      </li>
      <li>
        <p>
          <strong>Sobre las compras:</strong>
        </p>
        <p className="guv-text-alt-2">
          El viajero es el encargado de hacer las compras y también se hace responsable de todo
          lo que pueda pasar con esas compras (pérdidas, entregas retrasadas, robos, daños,
          impuestos de aduanas) hasta que el comprador lo haya recibido.
          <br />
          El viajero sólo debe comprar aquellos pedidos que están dentro de un{' '}
          <strong>Acuerdo Pagado</strong> y debe respetar las condiciones pactadas en el acuerdo{' '}
          <strong>(link de compra, empaque, fecha y lugar de entrega)</strong>.
        </p>
        <p className="guv-text-alt-2">
          El viajero{' '}
          <strong>
            debe hacer la compra de los pedidos del Acuerdo ni bien se notifique como Pagado
          </strong>
          , para evitar los cambios de precio y stock en las plataformas online.
        </p>
      </li>
      <li>
        <p>
          <strong>Sobre los acuerdos:</strong>
        </p>
        <p className="guv-text-alt-2">
          El viajero se compromete a <strong>notificar a Guvery y al comprador</strong>:
          cualquier inconveniente con la compra, o recepción de un producto en mal estado lo
          antes posible, para darnos la oportunidad de encontrar una solución a tiempo.
        </p>
        <p className="guv-text-alt-2">
          <strong>El viajero debe actualizar el estado de cada acuerdo</strong>: Comprado /
          Recibido en EEUU / Listo para Entregar / Entregado; ni bien sucedan.
        </p>
        <p className="guv-text-alt-2">
          El viajero puede anular un acuerdo,{' '}
          <strong>sin embargo, antes de intentar hacerlo: </strong>
          debe comunicarle al comprador (y a Guvery) lo que ha sucedido, para encontrar una
          solución y evitar la anulación.
        </p>
        <p className="guv-text-error">
          <strong>Cada Acuerdo Anulado incrementa la reputación negativa de un viajero.</strong>
        </p>
      </li>
    </ol>
  );
};

export default Responsibilities;
