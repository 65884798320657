import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Avatar, Button, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { USER_DEAL_DETAIL_RATING } from '#root/constants/navigation';

import { DealStatus, InStatusOfDeal } from '#root/interfaces/DealDTO';
import { RatingStatus } from '#root/interfaces/RatingDTO';
import { useNotification, useDialog } from '#root/hooks';
import { shopperService } from '#root/services';
import { Notification } from '#root/components';
import { GuveryPageHeader, GuveryLayout, GuverySpace, GuveryDialog } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import { ServiceOneLogoSvg, CanguroGuvery } from '#root/assets';
import Actions from './Actions';
import TimeLine from './Timeline';
import DealInfo from './DealInfo';
import DealTable from './DealTable';
import Payment from './Payment';
import Rating from './Rating';
import Refund from './Refund';
import Expire from './Expire';
import styles from './userDealDetail.module.scss';

const OrderLabel = {
  creado: 'creado',
  entregado: 'entregado',
  vencido: 'vencido',
  pagado: 'pagado',
  anulado: 'anulado',
} as const;

const DeliveryDialog = (props) => {
  const { open = false, onClose, images } = props;
  return (
    <GuveryDialog open={open} onClose={onClose} title="Fotos de la entrega" closeText="Cerrar">
      <div className={styles.imageDelivery}>
        {!!images.length &&
          images.map((image) => {
            return (
              <Avatar src={image.filename_full_path} alt="delivery">
                <img className={styles.holder} src={CanguroGuvery} alt="delivery" />
              </Avatar>
            );
          })}
      </div>
    </GuveryDialog>
  );
};

interface MatchParams {
  ida: string;
}

interface UserDealDetailProps extends RouteComponentProps<MatchParams> {}

const UserDealDetail = (props: UserDealDetailProps) => {
  const dealIda = props.match.params.ida;
  const isRatingOpen = props.match.path === USER_DEAL_DETAIL_RATING ? true : false;
  const deliveryDialog = useDialog();
  const [isLoading, setLoading] = React.useState(true);
  const [dealInfo, setDealInfo] = React.useState<any>({});
  const [paymentData, setPaymentData] = React.useState(null);
  const [refundData, setRefoundData] = React.useState(null);
  const [allowCoupon, setAllowCoupon] = React.useState(false);
  const { notification, createMessage, closeMessage } = useNotification();

  const statePay =
    dealInfo?.est_n === DealStatus.paying && dealInfo?.status_label !== OrderLabel.vencido;

  const isConcretized = dealInfo?.est_n === DealStatus.created;
  const isUserPay = dealInfo?.est_n === DealStatus.paying;
  const isTransit = InStatusOfDeal.PAID.includes(dealInfo?.est_n);
  const isDeliveredTraveler = dealInfo?.est_n === DealStatus.confirmedTraveler;
  const isCanceled = InStatusOfDeal.REJECTED.includes(dealInfo?.est_n);
  // const isRefunded = dealInfo?.est_n === DealStatus.refunded || dealInfo?.refund;
  const isDelivered = dealInfo?.status_label === OrderLabel.entregado;
  const isExpire = dealInfo?.status_label === OrderLabel.vencido;
  const isCourier = dealInfo?.offer?.order?.flag_envio;

  const showStatus =
    isConcretized || isTransit || isDeliveredTraveler || isDelivered || isUserPay;

  // const hasRating = isDelivered && dealInfo?.rating_to_traveler;
  const hasRating = dealInfo?.rating_to_traveler?.current_status === RatingStatus.rated;
  const hasRefund = dealInfo?.refund;

  const isOne = dealInfo?.offer?.order?.flujo === 'ONE';

  const deliverySetup = dealInfo?.delivery_setup;

  const fetchDeal = async () => {
    setLoading(true);
    const res = await shopperService.orderInfo(dealIda);
    // console.log(res.data);
    if (!res.error) {
      const dealDiscount = res.data?.deal?.dscto ? true : false;
      const applyCoupon = !dealDiscount && res.data?.coupons?.length > 0 ? true : false;
      setDealInfo(res.data.deal);
      setPaymentData(res.data?.payment_checkout_dialog);
      setRefoundData(res.data?.refund_dialog);
      setAllowCoupon(applyCoupon);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchDeal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const title = (
    <span className={styles.title}>
      <span>{`Acuerdo #${dealIda}`}</span>
      {dealInfo?.guvery_express_fee && <span>&nbsp;⚡️</span>}
      {isOne && (
        <>
          <div className={styles.badgeOne}>
            <img src={ServiceOneLogoSvg} alt="One" />
          </div>
          <span>&nbsp;⚡️</span>
        </>
      )}
      {isCourier && (
        <div className={styles.courier}>
          <span>Envío Nacional</span>
        </div>
      )}
    </span>
  );

  if (dealInfo === null) {
    return (
      <GuveryLayout>
        <GuveryPageHeader backButton title={title} />
        <h5>Error al obtener los datos del acuerdo</h5>
      </GuveryLayout>
    );
  }

  return (
    <GuveryLayout>
      <GuveryPageHeader backButton title={title} />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <GuverySpace direction="vertical" size="large">
            {!isLoading ? (
              <DealInfo deal={dealInfo} />
            ) : (
              <GuveryPaper disableHover disableElevation>
                <Skeleton variant="rect" height="319px" />
              </GuveryPaper>
            )}
            {!isLoading ? (
              <DealTable deal={dealInfo} />
            ) : (
              <GuveryPaper disableHover disableElevation>
                <Skeleton variant="rect" height="275px" />
              </GuveryPaper>
            )}
            {dealInfo.delivered_photos && (
              <>
                <Button variant="outlined" color="primary" onClick={deliveryDialog.openDialog}>
                  Ver fotos de la entrega
                </Button>
                <DeliveryDialog
                  open={deliveryDialog.open}
                  onClose={deliveryDialog.closeDialog}
                  images={dealInfo.delivered_photos}
                />
              </>
            )}
          </GuverySpace>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid container spacing={3}>
            {showStatus && !hasRating && !isExpire && (
              <Grid item xs={12}>
                <Payment
                  isRatingOpen={isRatingOpen}
                  isDelivered={isDelivered}
                  isTransit={isTransit}
                  deal={dealInfo}
                  deliverySetup={deliverySetup}
                  isConcretized={isConcretized}
                  isExpire={isExpire}
                  isUserPay={isUserPay}
                  isDeliveredTraveler={isDeliveredTraveler}
                  hasRating={hasRating}
                  ratingId={dealInfo?.rating_to_traveler?.id}
                  allowCoupon={allowCoupon}
                  dealIda={dealInfo?.ida}
                  dealPrice={dealInfo?.total}
                  tca={dealInfo?.tca}
                  fecentcom={dealInfo?.fecentcom}
                  fecentvia={dealInfo?.fecentvia}
                  paymentData={paymentData}
                  travelerData={dealInfo?.offer?.traveler}
                  statePay={statePay}
                  expirationDays={dealInfo?.fixed_expiration_days}
                  handleLoading={fetchDeal}
                  createMessage={createMessage}
                />
              </Grid>
            )}
            {hasRating && (
              <Grid item xs={12}>
                <Rating rating={dealInfo.rating_to_traveler} />
              </Grid>
            )}
            {hasRefund && (
              <Grid item xs={12}>
                <Refund
                  refund={dealInfo.refund}
                  banks={refundData?.banks}
                  createMessage={createMessage}
                  handleLoading={fetchDeal}
                />
              </Grid>
            )}
            {isExpire && (
              <Grid item xs={12}>
                <Expire />
              </Grid>
            )}
            <Grid item xs={12}>
              {!isLoading ? (
                <TimeLine deal={dealInfo} isOne={isOne} isCanceled={isCanceled} />
              ) : (
                <GuveryPaper disableHover disableElevation>
                  <Skeleton variant="rect" height="569px" />
                </GuveryPaper>
              )}
            </Grid>
            {isConcretized && !isOne && (
              <Grid item xs={12}>
                <Actions
                  dealIda={dealInfo?.ida}
                  handleLoading={fetchDeal}
                  createMessage={createMessage}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* {notification.state && <Notification onClose={closeMessage} {...notification} />} */}
    </GuveryLayout>
  );
};

export default UserDealDetail;
