import { useState, useEffect } from 'react';

interface NotificationState {
  message: string;
  state: boolean;
  variant: 'error' | 'info' | 'success' | 'warning';
}

type NotificationCallback = (state: NotificationState) => void;

class NotificationManager {
  private static instance: NotificationManager;
  private callbacks: NotificationCallback[] = [];
  private notificationState: NotificationState = {
    message: '',
    state: false,
    variant: 'info',
  };

  private constructor() {}

  public static getInstance(): NotificationManager {
    if (!NotificationManager.instance) {
      NotificationManager.instance = new NotificationManager();
    }
    return NotificationManager.instance;
  }

  public subscribe(callback: NotificationCallback) {
    this.callbacks.push(callback);
    // Envía el estado actual al suscriptor
    callback(this.notificationState);
  }

  public unsubscribe(callback: NotificationCallback) {
    this.callbacks = this.callbacks.filter((cb) => cb !== callback);
  }

  private notify() {
    this.callbacks.forEach((cb) => cb(this.notificationState));
  }

  public createMessage = {
    error: (text: string) => {
      this.notificationState = { message: text, variant: 'error', state: true };
      this.notify();
    },
    info: (text: string) => {
      this.notificationState = { message: text, variant: 'info', state: true };
      this.notify();
    },
    success: (text: string) => {
      this.notificationState = { message: text, variant: 'success', state: true };
      this.notify();
    },
    warning: (text: string) => {
      this.notificationState = { message: text, variant: 'warning', state: true };
      this.notify();
    },
  };

  public closeMessage = () => {
    this.notificationState = { ...this.notificationState, state: false };
    this.notify();
  };
}

const useNotification = () => {
  const [notification, setNotification] = useState<NotificationState>({
    message: '',
    state: false,
    variant: 'info',
  });

  const manager = NotificationManager.getInstance();

  useEffect(() => {
    const callback: NotificationCallback = (state) => {
      setNotification(state);
    };

    manager.subscribe(callback);

    return () => {
      manager.unsubscribe(callback);
    };
  }, [manager]);

  return {
    notification,
    createMessage: manager.createMessage,
    closeMessage: manager.closeMessage,
  };
};

export default useNotification;
