import styles from './travelerTest.module.scss';

const Questions = () => {
  return (
    <ol className={styles.questions}>
      <li>
        <p className="guv-semibold guv-font-size-5">
          ¿Qué pasa si el comprador se arrepiente o quiere cancelar su pedido?
        </p>
        <p className="guv-text-alt-2">
          <strong>Guvery protege al comprador y viajero en cada Acuerdo Pagado.</strong>
        </p>
        <p className="guv-text-alt-2">
          Si un comprador quiere anular un acuerdo, pero ya esta pagado, Guvery le consultará al
          viajero si es posible cancelar la compra (para intentar ayudar al comprador). Si no es
          posible, Guvery seguirá asegurando el pago total al viajero (precio real + comisión
          pactada) al momento que se confirme la entrega.
        </p>
        <p className="guv-text-alt-2">
          En caso el comprador no quiera recoger su pedido, el viajero puede entregar el pedido
          en la oficina de Guvery y confirmar la entrega.
        </p>
      </li>
      <li>
        <p className="guv-semibold guv-font-size-5">
          ¿Qué pasa si el comprador recoge/recibe su pedido pero no marca como RECIBIDO?
        </p>
        <p className="guv-text-alt-2">
          Descuida, el viajero puede marcar un acuerdo como <strong>ENTREGADO</strong> y si
          luego de 48 horas, el comprador no advierte lo contrario, el sistema lo confirma como
          <strong> ENTREGADO</strong> automáticamente.
        </p>
        <p className="guv-text-alt-2">
          En más de 14,000 entregas, nunca hemos tenido una situación de disputa por una "falsa"
          entrega.
        </p>
      </li>
      <li>
        <p className="guv-semibold guv-font-size-5">¿Cuándo y cómo me pagará Guvery?</p>
        <p className="guv-text-alt-2">
          Después de marcar un Acuerdo como Entregado, puedes solicitar el retiro desde{' '}
          <strong>MIS GANANCIAS</strong>, aunque sugerimos que esperes a entregar todos tus
          acuerdos o al menos la mayoría para hacerlo.
        </p>
        <p className="guv-text-alt-2">
          Después de marcar un Acuerdo como Entregado, puedes solicitar el retiro desde{' '}
          <strong>MIS GANANCIAS</strong>, aunque sugerimos que esperes a entregar todos tus
          acuerdos o al menos la mayoría para hacerlo.
        </p>
        <p className="guv-text-alt-2">
          Guvery demora hasta 3 días útiles para realizar la transferencia a la cuenta indicada.
        </p>
      </li>
      <li>
        <p className="guv-semibold guv-font-size-5">
          ¿Puedo hablar vía WhatsApp con un comprador?
        </p>
        <p className="guv-text-alt-2">
          Al momento de que llegas a un acuerdo y es pagado, Guvery te dará acceso al número de
          Whatsapp del comprador, el cual se debe utilizar en caso sea necesario (coordinar la
          compra, enviar fotos, coordinar la entrega, etc.).
        </p>
        <p className="guv-text-alt-2">
          También se puede utilizar para incrementar los pedidos de un Acuerdo (en ese caso
          Guvery puede modificar los Acuerdos Pagados para aumentar ó cambiar productos).
        </p>
        <p className="guv-text-error">
          <strong>
            Está prohibido negociar para traer pedidos por fuera de un Acuerdo de Guvery, lo
            cual podría ser castigado con la suspensión temporal o definitiva de la cuenta.
          </strong>
        </p>
      </li>
    </ol>
  );
};

export default Questions;
