import { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from '@material-ui/core';
import clsx from 'clsx';
import { IconWallet, IconTicket } from '@tabler/icons';

import { roundDecimal } from '#root/utils';
import { PaymentDataType } from '#root/interfaces/PaymentCheckoutDialog';
import styles from './confirmation.module.scss';

const variantCurrency = { SOLES: 'S/.', DOLARES: '$' };

const StepLabel = ({ label }) => {
  let format_label = label.replace('**', '<strong>');
  format_label = format_label.replace('/**', '</strong>');

  return (
    <div className={styles.stepLabel}>
      <span
        className={styles.label}
        dangerouslySetInnerHTML={{
          __html: format_label,
        }}
      />
    </div>
  );
};

interface ConfirmationProps {
  paymentData?: PaymentDataType;
  activeNextStep: Function;
}

const Confirmation = (props: ConfirmationProps) => {
  const { paymentData, activeNextStep } = props;
  const useCredits = paymentData.useCredits;
  const hasCoupon = paymentData.coupon.active;
  const hasZipago = paymentData.zipago?.active;

  const amount = Number(paymentData.amount);
  const discount = Number(paymentData.discount);
  const amountCredits = Number(paymentData.amountCredits);
  const amountZipago = paymentData?.zipago?.amount ? Number(paymentData.zipago.amount) : 0;
  const currency = paymentData.currency;
  const bank = paymentData.bank;

  const instructionsZipago = paymentData?.zipago?.instructions;

  const [checked, setChecked] = useState<boolean>(useCredits ? true : false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const currencyString =
    variantCurrency[currency] !== undefined ? variantCurrency[currency] : '';

  const total = amount ? amount + amountZipago : amountCredits;
  // const total = amount;

  useEffect(() => {
    activeNextStep(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (checked) {
      activeNextStep(true);
    } else {
      activeNextStep(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <h3>Resumen</h3>
      </div>
      {(useCredits || hasCoupon) && (
        <div className={styles.alert}>
          {useCredits && (
            <div className={styles.item}>
              <IconWallet />
              <span className={styles.text}>
                Usarás <strong>$ {roundDecimal(amountCredits)}</strong> del saldo de tu
                billetera para pagar este acuerdo.
              </span>
            </div>
          )}
          {hasCoupon && (
            <div className={styles.item}>
              <IconTicket />
              <span className={styles.text}>
                Se está aplicando un cupón de <strong>$ {roundDecimal(discount)}</strong>.
              </span>
            </div>
          )}
        </div>
      )}
      <div className={styles.tableWrap}>
        <TableContainer>
          <Table className={styles.table}>
            <TableHead className={styles.header}>
              <TableRow>
                <TableCell>Método de pago</TableCell>
                <TableCell></TableCell>
                <TableCell align="right">Importe</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {useCredits && !amount && (
                <TableRow>
                  <TableCell>Mi Billetera</TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">$ {roundDecimal(amountCredits)}</TableCell>
                </TableRow>
              )}
              {bank && (
                <TableRow>
                  <TableCell>
                    Transferencia {bank?.nombre} {bank?.qr?.nombre}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    {currencyString} {roundDecimal(amount)}
                  </TableCell>
                </TableRow>
              )}
              {hasZipago && (
                <TableRow>
                  <TableCell>Pago hacia Guvery</TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    {currencyString} {roundDecimal(amount)}
                  </TableCell>
                </TableRow>
              )}
              {hasZipago && (
                <TableRow>
                  <TableCell>Comisión ZiPago</TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    {currencyString} {roundDecimal(amountZipago)}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="right">
                  <span className={styles.total}>Total a pagar: </span>
                </TableCell>
                <TableCell align="right">
                  <span className={styles.total}>
                    {currencyString} {roundDecimal(total)}
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {hasZipago && (
        <>
          <div className={styles.subTitle}>
            <p>Realiza el ZiPago desde {paymentData.zipago?.name}:</p>
          </div>
          <div className={styles.steps}>
            {instructionsZipago.map((item, index) => (
              <StepLabel key={index} label={item} />
            ))}
          </div>
          <div className={styles.titleEnd} style={{ marginTop: 10 }}>
            {/*<p>Una vez realizado, presiona el botón 'Confirmar Pago'.</p>*/}
            <p>
              Después de hacer el pago, marca el check y presiona el botón "Confirmar Pago".
            </p>
          </div>
          <div className={styles.footer}>
            <Checkbox
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <p onClick={() => setChecked(!checked)}>Ya realicé el pago.</p>
          </div>
        </>
      )}
      {bank && (
        <>
          <div className={styles.subTitle}>
            <p>Realiza el pago a esta cuenta bancaria:</p>
          </div>
          <div className={styles.content}>
            {bank.qr ? (
              <div>
                <img className={styles.qr} src={bank.qr.code} alt={bank.qr.nombre} />
                <p className={styles.desc}>
                  <strong>{bank.titular}</strong>{' '}
                  <span className={styles.italic}>(titular)</span>
                </p>
              </div>
            ) : (
              <div>
                <p className={styles.desc}>
                  {bank.nombre} -{' '}
                  <strong>
                    {bank.tipo_cuenta} {currency}
                  </strong>
                </p>
                <p className={styles.desc}>
                  {bank.titular} <span className={styles.italic}>(titular)</span>
                </p>
                <p className={clsx(styles.desc, styles.stroke)}>
                  <strong>{bank[currency]}</strong>
                </p>
              </div>
            )}
          </div>
          <div className={styles.titleEnd}>
            <p>
              Después de hacer el pago, marca el check y presiona el botón "Confirmar Pago".
            </p>
          </div>
          <div className={styles.footer}>
            <Checkbox
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <p onClick={() => setChecked(!checked)}>Ya realicé el pago.</p>
          </div>
        </>
      )}
      {/*
      <div className={styles.footer}>
        <span>
          ¿Tienes problemas?{' '}
          <a href="https://m.me/guveryoficial" target="_blank" rel="noopener noreferrer">
            Avísanos
          </a>
        </span>
      </div>
      */}
    </div>
  );
};

export default Confirmation;
