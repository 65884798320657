import { Avatar, Button, Grid, Tooltip } from '@material-ui/core';
import { IconStar, IconMessageCircle, IconBrandWhatsapp, IconUsers } from '@tabler/icons';

import { useResponsive, useAuth, useDialog } from '#root/hooks';
import { ChatDrawer } from '#root/components';
import { GuverySpace } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import styles from './dealInfo.module.scss';

const DealStatusLabel = {
  creado: 'creado',
  entregado: 'entregado',
  vencido: 'vencido',
  anulado: 'anulado',
} as const;

const Item = ({ title, response }) => (
  <li className={styles.item}>
    <div className={styles.itemText}>
      <h6>{title}</h6>
      <div className={styles.response}>
        <p>{response}</p>
      </div>
    </div>
  </li>
);

const DealInfo = (props) => {
  const { usuario } = useAuth();
  const { deal = {}, whatsapp = null } = props;
  const order = deal?.offer?.order;
  const shopper = deal?.offer?.order?.user;
  const { isMobile } = useResponsive();
  const isProvincia = deal?.offer?.order?.flag_envio === 1 ? true : false;

  const dealEnded = deal?.status_label === DealStatusLabel.entregado || deal?.status_label === DealStatusLabel.anulado;
  const partnership = deal?.partnership_travelers;
  // eslint-disable-next-line eqeqeq
  const imOwner = usuario.idu == deal?.offer.idu ? true : false;
  let partnerName = imOwner
    ? partnership?.partner_traveler?.mask_fullname
    : partnership?.expert_traveler?.mask_fullname;

  const hasWhatsapp = whatsapp ? true : false;

  const whatsappNumber = whatsapp;

  const chatDrawer = useDialog();

  return (
    <GuveryPaper disableHover disableElevation className={styles.card}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <span className={styles.headerTitle}>Resumen del acuerdo</span>
        </div>
      </div>
      <hr className={styles.divider} />
      <div className={styles.body}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <ul className={styles.list}>
              <Item
                title="Observaciones"
                response={
                  order?.obs ? (
                    <span
                      className="guv-semibold"
                      style={{ color: 'var(--color-status-info)' }}>
                      {order.obs}
                    </span>
                  ) : (
                    '---'
                  )
                }
              />
              {deal?.empaque && <Item title="Empaque" response={deal?.empaque} />}
            </ul>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ul className={styles.list}>
              <Item
                title="Lugar de entrega"
                response={isProvincia ? 'MailBoxes ETC' : deal?.lugent}
              />
              <Item title="Fecha de entrega" response={deal?.fixed_delivery_date} />
            </ul>
          </Grid>
        </Grid>
        {partnership && (
          <div className={styles.partner}>
            <div className={styles.partnerWrap}>
              <IconUsers size={16} />
              <p>
                Asociado con <strong>{partnerName}</strong>
              </p>
            </div>
          </div>
        )}
        <div className={styles.traveler}>
          <div className={styles.travelerCover}>
            <Avatar className={styles.avatar} src={shopper?.picture_path}>
              {shopper?.one_firstname.charAt(0)}
            </Avatar>
          </div>
          <div className={styles.travelerText}>
            <h4 className="guv-ellipsis">{shopper?.mask_fullname}</h4>
            {shopper?.calvia && (
              <Tooltip arrow title="Puntaje del viajero" placement="bottom">
                <div className={styles.score}>
                  <IconStar />
                  <span>{shopper.calvia}</span>
                </div>
              </Tooltip>
            )}
          </div>
          <GuverySpace
            direction={isMobile ? 'horizontal' : 'vertical'}
            style={{ flexGrow: isMobile ? 1 : 0, marginTop: isMobile ? 16 : 0 }}>
            {imOwner && !dealEnded && (
              <>
                <Button
                  fullWidth
                  component="a"
                  onClick={chatDrawer.openDialog}
                  variant="outlined"
                  color="secondary"
                  startIcon={<IconMessageCircle />}>
                  Chat
                </Button>
                <ChatDrawer
                  extraTitle={`Acuerdo #${deal?.ida}`}
                  offerId={deal?.ido}
                  open={chatDrawer.open}
                  onClose={chatDrawer.closeDialog}
                />
              </>
            )}
            {imOwner && !dealEnded && hasWhatsapp && (
              <Button
                fullWidth
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                href={`https://api.whatsapp.com/send?phone=${whatsappNumber}&text=Hola!`}
                variant="outlined"
                color="secondary"
                startIcon={<IconBrandWhatsapp />}>
                Whatsapp
              </Button>
            )}
          </GuverySpace>
        </div>
      </div>
    </GuveryPaper>
  );
};

export default DealInfo;
