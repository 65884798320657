import clsx from 'clsx';

import styles from './stepIcon.module.scss';

const StepIcon = (props) => {
  const { active, completed } = props;

  return (
    <div className={clsx(styles.root, { [styles.active]: active })}>
      {completed ? (
        <div className={clsx(styles.circle, styles.completed)} />
      ) : (
        <div className={styles.circle} />
      )}
    </div>
  );
};

export default StepIcon;
