import { Button, Grid } from '@material-ui/core';
import { IconMessageCircle } from '@tabler/icons';
import { useDialog } from '#root/hooks';
import { ChatDrawer, TravelerCard, TravelerReviewsDialog } from '#root/components';
import { GuveryPaper } from '#root/ui-components/cards';
import styles from './dealInfo.module.scss';

const OrderLabel = {
  creado: 'creado',
  entregado: 'entregado',
  vencido: 'vencido',
  anulado: 'anulado',
} as const;

const Item = ({ title, response }) => (
  <li className={styles.item}>
    <div className={styles.itemText}>
      <h6>{title}</h6>
      <div className={styles.response}>
        <p>{response}</p>
      </div>
    </div>
  </li>
);

const DealInfo = (props) => {
  const { deal = {} } = props;
  const offer = deal?.offer;
  const order = offer?.order;
  const traveler = offer?.traveler;
  const chatDrawer = useDialog();
  const travelerReviewsDialog = useDialog();

  const dealEnded =
    deal?.status_label === OrderLabel.entregado ||
    // deal?.status_label === OrderLabel.vencido ||
    deal?.status_label === OrderLabel.anulado;

  // const isOne = order?.flujo === 'ONE';

  return (
    <GuveryPaper disableHover disableElevation className={styles.card}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <span className={styles.headerTitle}>Resumen del acuerdo</span>
        </div>
      </div>
      <hr className={styles.divider} />
      <div className={styles.body}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <ul className={styles.list}>
              <Item
                title="Observaciones"
                response={
                  order?.obs ? (
                    <span style={{ color: 'var(--color-status-info)' }}>{order.obs}</span>
                  ) : (
                    '---'
                  )
                }
              />
              {deal?.empaque && <Item title="Empaque" response={deal?.empaque} />}
            </ul>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ul className={styles.list}>
              <Item title="Lugar de entrega" response={deal?.lugent} />
              <Item title="Fecha de entrega" response={deal?.fixed_delivery_date} />
            </ul>
          </Grid>
        </Grid>
        <div className={styles.traveler}>
          <div>
            {traveler && (
              <>
                <TravelerCard traveler={traveler} travelerReviewsDialog={travelerReviewsDialog} />
                <TravelerReviewsDialog
                  open={travelerReviewsDialog.open}
                  onClose={travelerReviewsDialog.closeDialog}
                  data={{traveler: traveler, avoidReviews: (dealEnded)}}
                />
              </>
            )}
          </div>
          {!dealEnded && (
            <>
              <div>
                <Button
                  component="a"
                  onClick={chatDrawer.openDialog}
                  variant="outlined"
                  color="secondary"
                  startIcon={<IconMessageCircle />}>
                  Ver chat
                </Button>
              </div>
              <ChatDrawer
                extraTitle={`Acuerdo #${deal?.ida}`}
                offerId={deal?.ido}
                open={chatDrawer.open}
                onClose={chatDrawer.closeDialog}
              />
            </>
          )}
        </div>
      </div>
    </GuveryPaper>
  );
};

export default DealInfo;
