import { Avatar, Tooltip } from '@material-ui/core';
import { IconStar } from '@tabler/icons';
import styles from './styles.module.scss';
import clsx from 'clsx';

const TravelerCard = (props) => {
  const {
    traveler,
    travelerReviewsDialog = null,
    setReviewTravelerData = null,
    avoidReviews = false,
    noClickable = false,
    bigAvatar = false,
  } = props;
  const noScore = !traveler?.calvia;
  const scoreLevel = traveler?.traveler_stats?.current_score_level_details?.icon
    ? `${traveler.traveler_stats.current_score_level_details.icon} ${traveler.traveler_stats.current_score_level_details.title}`
    : '';

  const handleOpenReviews = (event) => {
    event.stopPropagation();
    if (noScore) return;
    if (setReviewTravelerData) {
      setReviewTravelerData({
        traveler: traveler,
        avoidReviews: avoidReviews,
      });
    }
    if (travelerReviewsDialog) {
      travelerReviewsDialog.openDialog();
    } else {
      alert('NO HAY');
    }
  };

  const content = (
    <div className={styles.box}>
      <Avatar className={clsx(styles.avatar, bigAvatar && styles.bigAvatar)} src={traveler.picture_path}>
        {traveler.one_firstname.charAt(0)}
      </Avatar>

      <div className={styles.userInfo}>
        {scoreLevel ? (
          <>
            <div className={clsx(styles.line, styles.emphasis)}>
              <span className={clsx(styles.name, styles.emphasis)}>
                {traveler.mask_fullname}
              </span>
              <span className={clsx(styles.ratingAvg, styles.emphasis)}>
                <IconStar />
                {traveler.calvia}
              </span>
            </div>
            <div className={clsx(styles.line, styles.emphasis)}>
              <p className={styles.scoreLevel}>{scoreLevel}</p>
            </div>
          </>
        ) : (
          <>
            <p className={clsx(styles.line, styles.name)}>{traveler.mask_fullname}</p>
            {traveler?.calvia && (
              <p className={clsx(styles.line, styles.ratingAvg)}>
                <IconStar />
                {traveler.calvia}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );

  return noClickable ? (
    <div className={styles.container}>{content}</div>
  ) : (
    <Tooltip
      arrow
      title={
        noScore ? 'Aún no tiene calificaciones' : 'Presiona para saber más de este viajero'
      }
      placement="top">
      <a className={clsx(styles.container, styles.anchor)} onClick={handleOpenReviews} rel="noopener noreferrer">
        {content}
      </a>
    </Tooltip>
  );
};

export default TravelerCard;
