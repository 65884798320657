import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Step, Stepper, StepLabel, StepConnector, List, ListItem } from '@material-ui/core';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { IconCheck } from '@tabler/icons';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import clsx from 'clsx';

// import { useResponsive } from '#root/hooks';
import { DealStatus } from '#root/interfaces/DealDTO';
import { GuveryPaper } from '#root/ui-components/cards';
import {
  ShippingArrivals,
  ShippingHandshake,
  ShippingCreditCard,
  ShippingBox,
  ShippingDeliveryMan,
  ShippingDelivered,
  ShippingSearch,
  ShippingShoppingCart,
} from '#root/assets';
import styles from './timeline.module.scss';

const formatTime = (date: string) => {
  let time: string | null;
  if (!date) {
    return;
  }
  try {
    time = format(new Date(date.replace(/ /g, 'T')), "dd 'de' MMM ',' YYY", { locale: es });
  } catch (error) {
    time = 'Error';
  }
  return time;
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 28,
  },
  active: {
    '& $line': {
      backgroundColor: 'var(--color-main-800)',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: 'var(--color-main-800)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed } = props;

  const icons: { [index: string]: string } = {
    1: ShippingHandshake,
    2: ShippingCreditCard,
    3: ShippingShoppingCart,
    4: ShippingBox,
    5: ShippingDeliveryMan,
    6: ShippingDelivered,
  };

  return (
    <div className={clsx(styles.icon, { [styles.activeState]: active })}>
      <img src={icons[String(props.icon)]} alt="step" />
      {completed && (
        <div className={styles.coverCompleted}>
          <div className={styles.iconCompleted}>
            <IconCheck size={18} stroke={3} />
          </div>
        </div>
      )}
    </div>
  );
}

const Timeline = (props) => {
  const { deal, isOne, isCanceled } = props;
  // onst { isMobile } = useResponsive();
  const isMobile = true;
  const [activeStep, setActiveStep] = React.useState(1);
  const [steps, setSteps] = React.useState([]);

  const getBasicSteps = [
    {
      title: 'Concretado',
      time: formatTime(deal?.feccre),
      icon: ShippingHandshake,
    },
    {
      title: deal.est_n === DealStatus.paying ? 'Validando Pago' : 'Pagado',
      time: formatTime(deal?.fecpagadm),
      icon: ShippingCreditCard,
    },
    {
      title: 'Comprado',
      time: formatTime(deal?.feccom),
      icon: ShippingShoppingCart,
    },
    {
      title: 'Recibido EE.UU.',
      time: formatTime(deal?.fecrecvia),
      icon: ShippingBox,
    },
    {
      title: 'En Perú',
      time: formatTime(deal?.fecporent),
      icon: ShippingArrivals,
    },
    {
      title: 'Entregado',
      time: deal?.fecentvia ? formatTime(deal?.fecentvia) : formatTime(deal?.fecentcom),
      icon: ShippingDelivered,
    },
  ];

  const getCanceledSteps = [
    {
      title: 'Concretado',
      time: formatTime(deal?.feccre),
      icon: ShippingHandshake,
    },
    {
      title: 'Pagado',
      time: formatTime(deal?.fecpagadm),
      icon: ShippingCreditCard,
    },
    {
      title: 'Anulado',
      time: formatTime(deal?.feccan),
      icon: ShippingBox,
    },
  ];

  const getOneSteps = [
    {
      title: 'Pedido realizado',
      time: formatTime(deal?.offer?.order?.feccre),
      icon: ShippingHandshake,
    },
    {
      title: 'Viajero asignado',
      time: formatTime(deal?.fecpagadm),
      icon: ShippingSearch,
    },
    {
      title: 'Comprado',
      time: formatTime(deal?.feccom),
      icon: ShippingShoppingCart,
    },
    {
      title: 'Recibido EE.UU.',
      time: formatTime(deal?.fecrecvia),
      icon: ShippingBox,
    },
    {
      title: 'En Perú',
      time: formatTime(deal?.fecporent),
      icon: ShippingArrivals,
    },
    {
      title: 'Entregado',
      time: deal?.fecentvia ? formatTime(deal?.fecentvia) : formatTime(deal?.fecentcom),
      icon: ShippingDelivered,
    },
  ];

  const handleBasicSteps = () => {
    setSteps(getBasicSteps);
    if (deal?.fecentvia || deal?.fecentcom) {
      setActiveStep(6);
      return;
    }
    if (deal?.fecporent) {
      setActiveStep(5);
      return;
    }
    if (deal?.fecrecvia) {
      setActiveStep(4);
      return;
    }
    if (deal?.feccom) {
      setActiveStep(3);
      return;
    }
    if (deal?.fecpagadm) {
      setActiveStep(2);
      return;
    }
  };

  const handleOneSteps = () => {
    setSteps(getOneSteps);
    if (deal?.fecentvia || deal?.fecentcom) {
      setActiveStep(6);
      return;
    }
    if (deal?.fecporent) {
      setActiveStep(5);
      return;
    }
    if (deal?.fecrecvia) {
      setActiveStep(4);
      return;
    }
    if (deal?.feccom) {
      setActiveStep(3);
      return;
    }
    if (deal?.fecpagadm) {
      setActiveStep(2);
      return;
    }
  };

  const handleCanceledSteps = () => {
    setSteps(getCanceledSteps);
    if (deal?.feccan) {
      setActiveStep(3);
      return;
    }
    if (deal?.fecpagadm) {
      setActiveStep(2);
      return;
    }
    if (deal?.feccre) {
      setActiveStep(1);
      return;
    }
  };

  const handleSteps = () => {
    if (isCanceled) {
      handleCanceledSteps();
      return;
    }
    if (isOne) {
      handleOneSteps();
      return;
    }
    handleBasicSteps();
  };

  React.useEffect(() => {
    handleSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOne, isCanceled]);

  return (
    <GuveryPaper disableHover disableElevation className={styles.card}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <span className={styles.headerTitle}>Historial del Acuerdo</span>
        </div>
      </div>
      <hr className={styles.divider} />
      <div className={styles.body}>
        {isMobile ? (
          <div className={styles.mobileTabs}>
            <List disablePadding className={styles.mobileList}>
              {steps.map((step, index) => {
                const active = index === activeStep ? true : false;
                const completed = index < activeStep ? true : false;
                return (
                  <ListItem key={index} style={{ padding: '14px 16px' }}>
                    <div className={styles.iconWrap}>
                      <div
                        className={clsx(styles.icon, styles.iconMobile, {
                          [styles.activeState]: active,
                        })}>
                        <img src={step.icon} alt="step" />
                        {completed && (
                          <div className={styles.coverCompleted}>
                            <div className={styles.iconCompleted}>
                              <IconCheck size={18} stroke={3} />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.mobileText}>
                      <h5
                        className={clsx(styles.status, {
                          [styles.statusComplete]: completed,
                        })}>
                        {step.title}
                      </h5>
                      {active && !step.time && <p className={styles.time}>Esperando...</p>}
                      {step.time && <p className={styles.time}>{step.time}</p>}
                    </div>
                  </ListItem>
                );
              })}
            </List>
            <div className={styles.mobileLine}></div>
          </div>
        ) : (
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
            style={{ padding: '8px 0 16px' }}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  <span>
                    <span className={styles.status}>{step.title}</span>
                    <span className={styles.time}>{step.time}</span>
                  </span>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      </div>
    </GuveryPaper>
  );
};

export default Timeline;
