import { ThemeProvider } from '@material-ui/core';
import { CookiesProvider } from 'react-cookie';

import Context from '#root/Context';
import RouterConfig from '#root/navigation/RouterConfig';
import theme from '#root/theme';
// import { Snackbar } from '#root/ui-components/extended';
import '#root/styles/index.scss';
import { Notification } from '#root/components';

const App = () => {
  return (
    <CookiesProvider>
      <Context.Provider>
        <ThemeProvider theme={theme}>
          <RouterConfig />
          <Notification />
        </ThemeProvider>
      </Context.Provider>
    </CookiesProvider>
  );
};

export default App;
