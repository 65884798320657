import { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Grid,
  Button,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Tooltip,
} from '@material-ui/core';
import {
  IconEye,
  IconDotsVertical,
  IconQuestionMark,
  IconMessageCircle,
  IconTrash,
  IconTag,
  IconAlertTriangle,
  IconBrandWhatsapp,
} from '@tabler/icons';
import { format, formatDistanceStrict } from 'date-fns';
import { es } from 'date-fns/locale';
import clsx from 'clsx';

import { replacePathParams } from '#root/utils';
import { useDialog, useAuth } from '#root/hooks';
import { shopperService } from '#root/services';
import { TravelerCard, ChatDrawer, ExpirationDays, GuveryExpressDialog, ExpressDetailsDialog } from '#root/components';
import { AlertDialog, GuverySpace } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import { ArrowDownwardIcon } from '#root/icons';
import { IconBolt } from '@tabler/icons';
import { CanguroGuvery, OneLogoSm } from '#root/assets';
import { USER_ORDERS_BY_ID } from '#root/constants/navigation';
import BasicAlert from '../BasicAlert';
import PriceDialog from '../PriceDialog';
import styles from './orderCard.module.scss';

export const OrderStatus = {
  creado: 'CREADO',
  aprobado: 'APROBADO',
  observado: 'OBSERVADO',
  publicado: 'PUBLICADO',
  rePublicado: 'REPUBLICADO',
  conOferta: 'CON_OFERTA',
} as const;

export const OfferStatus = {
  creado: '0',
  negociando: '1',
  aceptado: '2',
  rechazado: '4',
  rechazado_viajero: '5',
  rechazado_comprador: '6',
  rechazado_admin: '7',
} as const;

const Accordion = withStyles({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#bff6ed',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionSummaryObservation = withStyles({
  root: {
    backgroundColor: '#fff4e5',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const SummaryExpress = withStyles({
  root: {
    color: 'var(--color-express-fee)',
    backgroundColor: '#333;',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    display: 'flex',
    gap: 2,
    justifyContent: 'space-between',
    alignItems: 'center',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const getTime = (orderDate) => {
  let time;
  try {
    time = orderDate
      ? formatDistanceStrict(new Date(orderDate.replace(/ /g, 'T')), new Date(), { locale: es })
      : '---';
  } catch (error) {
    time = 'error';
  }
  return time;
};

const formatTime = (date: string) => {
  if (!date) return;

  let time: string | null;

  try {
    time = format(new Date(date.replace(/ /g, 'T')), "dd 'de' MMM 'del' YYY", { locale: es });
  } catch (error) {
    time = '---';
  }
  return time;
};

const commissionGuvery = (price, com, guv, guvTope, guvExtra) => {
  const sumPrices = Number(price) + Number(com);
  const getPercent = (sumPrices * Number(guv)) / 100;
  const getMaxPercent = getPercent > Number(guvTope) ? Number(guvTope) : getPercent;
  const commission = getMaxPercent + Number(guvExtra);

  return commission;
};

const OrderCard = (props) => {
  const {
    order,
    state,
    hideOffers = false,
    orderOne = false,
    handleLoading,
    createMessage,
    waitingTime,
    handleOpenOfferDrawer,
    setReviewTravelerData,
    travelerReviewsDialog
  } = props;
  const { negocio } = useAuth();
  const history = useHistory();
  const alertDialog = useDialog();
  const priceDialog = useDialog();
  const chatDrawer = useDialog();
  const guveryExpressDialog = useDialog();
  const expressDetailsDialog = useDialog();
  const alertRemoveCourier = useDialog();
  const [priceData, setPriceData] = useState({
    image: '',
    name: '',
    link: '',
    can: null,
    price: null,
    comTraveler: null,
    comGuvery: null,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const imagePath = order?.img_path;
  const isWaitingApproval =
    imagePath &&
    (order?.estado === OrderStatus.aprobado || order?.estado === OrderStatus.creado);
  const isPublished =
    order?.estado === OrderStatus.publicado || order?.estado === OrderStatus.conOferta;
  const isActive =
    order?.estado === OrderStatus.creado ||
    order?.estado === OrderStatus.aprobado ||
    order?.estado === OrderStatus.observado ||
    order?.estado === OrderStatus.publicado ||
    order?.estado === OrderStatus.rePublicado ||
    order?.estado === OrderStatus.conOferta;
  const quantityOffers = order?.offers?.length;
  const isCourier = order?.flag_envio;
  const isObserved = order?.admin_motivo && order?.estado === OrderStatus.observado;

  // Estados ONE
  const isCreatedOne = orderOne && order?.estado === OrderStatus.creado;
  const isApprovedOne = orderOne && order?.estado === OrderStatus.aprobado;

  const isExpress = order?.flujo === 'EXPRESS';
  const orderExpress = order?.order_express;
  const orderExpressIsAvailable = orderExpress && order?.order_express?.is_available;
  const orderExpressIsPaying = orderExpress && order?.order_express?.is_paying;
  const orderExpressIsPaid = orderExpress && order?.order_express?.is_paid;
  const orderExpressIsRejected = orderExpress && order?.order_express?.is_rejected;

  const expressHideOffers = (orderExpressIsPaying || orderExpressIsPaid);

  const expressDialogConfirmAction = () => {
    const orderDetailPath = replacePathParams(USER_ORDERS_BY_ID, { idp: order?.idp });
    history.push(orderDetailPath);
  }

  // ONE: Mensaje de tiempo de asignación:
  let one_textDealTimeCustom = "Asignando tu pedido al mejor viajero disponible (2 días promedio).";
  if (orderOne && order?.product_one) {
    if (order?.product_one?.deal_time_custom_message) {
      one_textDealTimeCustom = order.product_one.deal_time_custom_message;
    } else {
      if (order?.product_one?.deal_time_days) {
        one_textDealTimeCustom = `Asignando tu pedido al mejor viajero disponible (${order.product_one.deal_time_days} días promedio).`;
      }
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openOrderDrawer = (offer) => {
    handleOpenOfferDrawer(order, offer);
  };

  const openExpireAlert = () => {
    handleClose();
    alertDialog.openDialog();
  };

  const openRemoveCouriert = () => {
    handleClose();
    alertRemoveCourier.openDialog();
  };

  const handleConfirmExpiring = () => {
    fetchExpiring();
    alertDialog.closeDialog();
  };

  const handleRemoveCourier = () => {
    fetchRemoveCourier();
    alertRemoveCourier.closeDialog();
  };

  const fetchExpiring = async () => {
    const res = await shopperService.orderExpiring(order.idp);
    if (res.error) {
      createMessage.error('No se pudo completar la acción');
      return;
    }
    createMessage.success('Pedido expirado.');
    handleLoading();
  };

  const fetchRemoveCourier = async () => {
    const res = await shopperService.orderRemoveCourier(order.idp);
    if (res.error) {
      createMessage.error('No se pudo completar la acción');
      return;
    }
    createMessage.success('Pedido actualizado.');
    handleLoading();
  };

  const handlePriceDialogOpen = (can, priceOffer, comTraveler, comGuvery) => {
    setPriceData({
      image: imagePath,
      name: order.tit,
      link: order.url,
      can: can,
      price: priceOffer,
      comTraveler: comTraveler,
      comGuvery: comGuvery,
    });
    priceDialog.openDialog();
  };

  return (
    <GuveryPaper disableHover disableElevation className={styles.root}>
      <div className={styles.wrap}>
        <div className={clsx(styles.image, !imagePath && styles.holder)}>
          <a href={order?.url} target="_blank" rel="noopener noreferrer">
            {imagePath ? (
              <img src={imagePath} alt="producto" />
            ) : (
              <img className={styles.holder} src={CanguroGuvery} alt="producto" />
            )}
          </a>
        </div>
        <div className={styles.body}>
          <div className={styles.info}>
            <span className={styles.time}>Hace {getTime(order.feccre)}</span>
            <div className={styles.titleWrap}>
              <h5 className={styles.title}>Pedido #{order.id_public}</h5>
              {orderOne && (
                <div className={styles.badgeOne}>
                  <img src={OneLogoSm} alt="One" />
                </div>
              )}
              {(orderExpressIsPaying || orderExpressIsPaid) && (
                <div className={styles.badgeExpress}>
                  Express <IconBolt size={16} />
                </div>
              )}
              {state && (
                <div className={styles.badge}>
                  <span className={styles.badgeText}>{state}</span>
                </div>
              )}
              {isCourier && (
                <div className={styles.badgeCourier}>
                  <span className={styles.badgeText}>Envío Nacional</span>
                </div>
              )}
            </div>
            {order?.tit && (
              <a href={order?.url} target="_blank" rel="noopener noreferrer">
                <h4 className={styles.name}>{order.tit}</h4>
              </a>
            )}
            {hideOffers && (
              <a href={order?.url} target="_blank" rel="noopener noreferrer">
                <h5 className={styles.name}>{order?.url_host}</h5>
              </a>
            )}
            {(!orderExpressIsPaying && !orderExpressIsPaid) && (
              <>
              <p className={styles.remark}>{order?.obs}</p>
            <GuverySpace>
              {order?.empaque && (
                <div className={styles.stats}>
                  <p className={styles.attri}>{order.empaque}</p>
                </div>
              )}
              {order?.can && (
                <div className={styles.stats}>
                  <p className={styles.attri}>Cantidad: {order?.can}</p>
                </div>
              )}
              {order?.condicion && (
                <div className={styles.stats}>
                  <p className={styles.attri}>{order?.condicion}</p>
                </div>
              )}
            </GuverySpace>
              </>
            )}
          </div>
          
          <div className={styles.actions}>
          {!orderExpressIsPaying && !orderExpressIsPaid && (
            <GuverySpace>
            {order?.pre && (
              <h4 className={styles.detailPrice}>
                <div>
                <span className={styles.currency}>$</span>
                  <span className={styles.priceTotal}>{order?.pre} </span>
                  <span className={styles.currency}>&nbsp;+&nbsp;</span>
                  <span className={styles.currency}>$</span>
                  <span className={styles.priceTotal}>{order?.com} </span>
                </div>
                  
                  <span className={styles.priceHint}>
                  &nbsp;(Referencial)
                  </span>
              </h4>
            )}
            </GuverySpace>
            )}

            {(orderExpressIsPaying || orderExpressIsPaid) && (
              <>
                <GuverySpace style={{marginTop: 10}}>
            <div className={styles.detailPriceExpress}>
                  <div>
                    <h4>
                    <span className={styles.currency}>$</span>
                    <span className={styles.priceTotal}>{orderExpress?.total_price} </span>
                    </h4>
                  </div>
                  
                    <span className={styles.priceHint}>
                    &nbsp;(Total)
                    </span>
                    <Button className={styles.action} variant="outlined" color="inherit" onClick={expressDetailsDialog.openDialog}>
                          Detalle
                    </Button>
                    <ExpressDetailsDialog
                    open={expressDetailsDialog.open}
                    onClose={expressDetailsDialog.closeDialog}
                    order={order}
                    orderExpress={orderExpress}
                      />
                </div>
          </GuverySpace>
                </>
            )}
            
            <div>
              {(orderExpress && orderExpressIsAvailable) ? (
                <>
                  <Button
                  disableElevation
                  component={RouterLink}
                  to={replacePathParams(USER_ORDERS_BY_ID, { idp: order?.idp })}
                  variant="contained"
                  color="primary"
                  startIcon={<IconBolt />}>
                  Ver / Pagar Pedido
                  </Button>
                  {orderExpress?.expiration_days && (
                  <ExpirationDays expirationDays={orderExpress?.expiration_days} style={{marginTop: 4}} />
                  )}
                </>
              ) : (
                <>
                  <Button
                  disableElevation
                  component={RouterLink}
                  to={replacePathParams(USER_ORDERS_BY_ID, { idp: order?.idp })}
                  variant="outlined"
                  color="primary"
                  startIcon={<IconEye />}>
                  Ver Pedido
                  </Button>

                  {(!orderExpressIsPaying && !orderExpressIsPaid && order?.fixed_expiration_days !== undefined) && (
                  <ExpirationDays expirationDays={order.fixed_expiration_days} style={{marginTop: 4}} />
                  )}
                </>
              )}
            </div>
            
          </div>
          <div className={styles.tools}>
            <IconButton color="secondary" onClick={handleClick}>
              <IconDotsVertical size={22} />
            </IconButton>
            <Menu
              id="tools-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              <MenuItem
                component={RouterLink}
                to={replacePathParams(USER_ORDERS_BY_ID, { idp: order?.idp })}>
                <ListItemIcon style={{ minWidth: 32, color: 'inherit' }}>
                  <IconEye size={20} />
                </ListItemIcon>
                <h6>Ver Pedido</h6>
              </MenuItem>
              {/*
              <MenuItem onClick={handleClose}>
                <ListItemIcon style={{ minWidth: 32, color: 'inherit' }}>
                  <IconEdit size={20} />
                </ListItemIcon>
                <h6>Modificar</h6>
              </MenuItem>
              */}
              {isCourier && (
                <MenuItem onClick={openRemoveCouriert}>
                  <ListItemIcon style={{ minWidth: 32, color: 'var(--color-status-alert)' }}>
                    <IconTag size={20} />
                  </ListItemIcon>
                  <h6>Quitar envío nacional</h6>
                </MenuItem>
              )}
              {isActive && !orderOne && (
                <MenuItem onClick={openExpireAlert}>
                  <ListItemIcon style={{ minWidth: 32, color: 'var(--color-status-error)' }}>
                    <IconTrash size={20} />
                  </ListItemIcon>
                  <h6 className="guv-text-error">Expirar Pedido</h6>
                </MenuItem>
              )}
            </Menu>
          </div>
        </div>
      </div>
      {!imagePath && !isPublished && (
        <BasicAlert
          variant="error"
          text={
            <>
              <strong>Subir imagen:</strong>
              <span>
                &nbsp;&nbsp;Ingresa al detalle de este pedido y sube la imagen para que sea
                publicado.
              </span>
            </>
          }
        />
      )}

      {isExpress && orderExpress && orderExpressIsAvailable && (
        <SummaryExpress>
          <div style={{display: 'flex'}}>
            <div style={{minWidth: 20, marginRight: 6}}>
              <IconBolt />
            </div>
            <h5 style={{color: '#fff'}}>
              Pedido Express listo! Págalo y recíbelo en el menor tiempo garantizado.
              {' '} <strong style={{color: 'var(--color-express-fee)'}}>Total: $ {orderExpress.total_price}</strong>
            </h5>
          </div>
          <Button variant="outlined" color="inherit" onClick={expressDetailsDialog.openDialog}>
            Detalles
          </Button>
          <ExpressDetailsDialog
        open={expressDetailsDialog.open}
        onClose={expressDetailsDialog.closeDialog}
        order={order}
        orderExpress={orderExpress}
        confirmAction={expressDialogConfirmAction} 
        textAction="Ver Pagar"
      />
        </SummaryExpress>
      )}
      
      {isWaitingApproval && isExpress && !orderExpress && (
        <BasicAlert
          variant="express"
          text={`Esperando cotización express ó la aprobación de Guvery.`}
        />
      )}

      {isWaitingApproval && !orderOne && (!isExpress || orderExpressIsRejected) && (
        <BasicAlert
          variant="info"
          text={`Esperando la aprobación de Guvery (${waitingTime} horas aprox.).`}
        />
      )}
      {(isCreatedOne || orderExpressIsPaying) && (
        <BasicAlert variant="info" text="Validando el pago por parte de Guvery..." />
      )}
      {(orderExpressIsPaid) && (
        <BasicAlert variant="success" text="Asignando tu pedido al mejor viajero..." />
      )}

      {isApprovedOne && (
        <BasicAlert
          variant="success"
          text={one_textDealTimeCustom}
        />
      )}
      {isObserved && (
        <Accordion>
          <AccordionSummaryObservation
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel-offer"
            id="panel-observation">
            <div className={styles.reazon}>
              <IconAlertTriangle />
              <span className={styles.text}>
                Pedido observado: <strong>{order?.admin_motivo_details?.title}</strong>
              </span>
            </div>
          </AccordionSummaryObservation>
          <AccordionDetails>
            <div className={styles.observationInfo}>
              <p className={styles.hint}>{order?.admin_motivo_details?.message}</p>
              <p className={styles.expInfo}>
                Si no tenemos respuesta hasta el{' '}
                {formatTime(order?.admin_motivo_details?.expiration_date)}, tu pedido será
                expirado.
              </p>
              <div className={styles.solution}>
                <div className={styles.text}>
                  <p>{order?.admin_motivo_details?.solution}</p>
                  <p className={styles.code}>
                    Código de la observación: {order?.admin_motivo_details?.code}
                  </p>
                </div>
                <div className={styles.action}>
                  <Button
                    fullWidth
                    component="a"
                    href={`https://api.whatsapp.com/send?phone=51960684330&text=Hola%20Guvery,%20${order?.admin_motivo_details?.code}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="outlined"
                    color="inherit"
                    startIcon={<IconBrandWhatsapp />}>
                    Whatsapp Guvery
                  </Button>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      )}
      {!hideOffers && !expressHideOffers && isPublished && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel-offer"
            id="panel-offer">
            <h5 style={{color: (quantityOffers < 5) ? 'inherit' : ((quantityOffers === 5) ? 'var(--color-warning)' : 'var(--color-status-error-expired)')}}>
              Tienes <strong>{quantityOffers}</strong> ofertas <strong>(revísalas)</strong>
              {quantityOffers <= 0 && ', aún no se ha hecho ningún acuerdo'}
            </h5>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              {quantityOffers > 0 ? (
                <>
                  {quantityOffers < 3 && (
                    <Grid item xs={12}>
                      <div className={styles.hintOffers}>
                        <p>
                          <strong>Consejo:</strong>&nbsp; Los viajeros invierten su tiempo en
                          hacer ofertas. Mantén su motivación elevada aceptando rápido 1 oferta.
                        </p>
                      </div>
                    </Grid>
                  )}
                  {quantityOffers === 3 && (
                    <Grid item xs={12}>
                      <div className={styles.hintOffers}>
                        <p>
                          <strong>Consejo:</strong>&nbsp; Usualmente la oferta más atractiva se
                          encuentra dentro de las primeras 3 que ha recibido tu pedido. Acepta 1
                          oferta rápido.
                        </p>
                      </div>
                    </Grid>
                  )}
                  {quantityOffers === 4 && (
                    <Grid item xs={12}>
                      <div className={styles.hintOffers}>
                        <p>
                          <strong>Consejo:</strong>&nbsp; Los pedidos que reciben más de 4
                          ofertas, se vuelven menos atractivos. Acepta 1 oferta rápido.
                        </p>
                      </div>
                    </Grid>
                  )}
                  {quantityOffers === 5 && (
                    <Grid item xs={12}>
                      <div className={clsx(styles.hintOffers, styles.warning)}>
                        <p>
                          <strong>Importante:</strong>&nbsp; Tienes muchas ofertas, debes aceptar 1
                          pronto pues algunas vencerán y los viajeros llenarán su equipaje.
                          <br />
                          <span className={styles.small}>Si tienes dudas, puedes conversar con el viajero usando el chat.</span>
                        </p>
                      </div>
                    </Grid>
                  )}
                  {quantityOffers >= 6 && (
                    <Grid item xs={12}>
                      <div className={clsx(styles.hintOffers, styles.danger)}>
                        <p>
                          <strong>Acción requerida:</strong>&nbsp; Llegaste al máximo de ofertas que puedes recibir. Acepta 1 o contáctate con Guvery para recibir ayuda.
                          <br />
                          <span className={styles.small}>Recibir muchas ofertas y no aceptar ninguna es una conducta inusual y poco ética.</span>
                        </p>
                      </div>
                    </Grid>
                  )}
                  {order?.offers?.map((offer, index) => {
                    const comGuveryPCT = Number(negocio?.com_porc);
                    const priceOffer = Number(offer.prerea);
                    const commisonOffer = Number(offer?.comvia);
                    const sumPrice = priceOffer + commisonOffer;
                    const commission = commissionGuvery(
                      priceOffer,
                      commisonOffer,
                      comGuveryPCT,
                      negocio?.com_tope,
                      negocio?.com_link,
                    );
                    const price = sumPrice + commission;
                    const isOfferNegotiating =
                      offer.est === OfferStatus.negociando ? true : false;
                    const isOfferAccepted = offer.est === OfferStatus.aceptado ? true : false;
                    const isOfferRejected =
                      offer.est === OfferStatus.rechazado ||
                      offer.est === OfferStatus.rechazado_viajero ||
                      offer.est === OfferStatus.rechazado_comprador ||
                      offer.est === OfferStatus.rechazado_admin
                        ? true
                        : false;
                    const isOfferExpired = offer?.fixed_expiration_days !== undefined && offer.fixed_expiration_days < 0;

                    return (
                      <Grid key={index} item xs={12} sm={6} md={4}>
                        <GuveryPaper disableHover disableBorder className={styles.userOffer}>
                          <div className={styles.header}>
                            <TravelerCard traveler={offer?.traveler} travelerReviewsDialog={travelerReviewsDialog} setReviewTravelerData={setReviewTravelerData} />
                            
                            {(offer.est === OfferStatus.negociando ||
                              offer.est === OfferStatus.aceptado) && (
                              <>
                                <Tooltip arrow title="Chatear" placement="top">
                                  <IconButton
                                    size="small"
                                    color={isOfferExpired ? 'secondary' : 'primary'}
                                    disabled={isOfferExpired ? true : false}
                                    className={
                                      isOfferExpired ? null : 'iconMessageCircleContained'
                                    }
                                    onClick={chatDrawer.openDialog}>
                                    <IconMessageCircle />
                                  </IconButton>
                                </Tooltip>
                                <ChatDrawer
                                  extraTitle={`Oferta #${offer?.id_public}`}
                                  offerId={offer?.ido}
                                  open={chatDrawer.open}
                                  onClose={chatDrawer.closeDialog}
                                />
                              </>
                            )}
                          </div>
                          <div className={styles.content}>
                            {(isOfferNegotiating || isOfferAccepted) && (
                              <div className={styles.statusLabel}>
                                <span>{offer?.status_label}</span>
                              </div>
                            )}
                            <div className={styles.offerInfo}>
                              <h6>
                                Entrega: <strong>{offer.fixed_delivery_date} </strong>
                              </h6>
                              <h6>
                                En: <strong>{offer.lugent}</strong>
                              </h6>
                            </div>
                            <div
                              className={styles.offerPrice}
                              onClick={() =>
                                handlePriceDialogOpen(
                                  offer?.can,
                                  priceOffer,
                                  commisonOffer,
                                  commission,
                                )
                              }>
                              <div className={styles.priceInfo}>
                                <h3>
                                  <span className={styles.currency}>$</span>
                                  <span className={styles.priceTotal}>{price}</span>
                                  <span className={styles.pricetext}> Total a pagar</span>
                                  <span className={styles.offerQty}>
                                    {' '}
                                    &nbsp;x {offer?.can ? offer.can : '1'}
                                  </span>
                                </h3>
                              </div>
                              <Tooltip arrow title="Detalle del total" placement="top">
                                <IconButton
                                  size="small"
                                  color="primary"
                                  className={styles.link}>
                                  <IconQuestionMark />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </div>

                          <div className={styles.offerAction}>
                            {!isOfferRejected && (
                              <Button
                                fullWidth
                                disableElevation
                                variant={isOfferExpired ? 'outlined' : 'contained'}
                                color={isOfferExpired ? 'secondary' : 'primary'}
                                onClick={() => openOrderDrawer(offer)}
                                startIcon={isOfferExpired ? null : <IconMessageCircle />}>
                                {isOfferExpired || isOfferAccepted
                                  ? 'Ver oferta'
                                  : 'Ver / Aceptar oferta'}
                              </Button>
                            )}
                            <div className={styles.afterAction}>
                              {!isOfferRejected && (
                                <div className={styles.expirationStatus}>
                                  <ExpirationDays
                                    expirationDays={offer?.fixed_expiration_days}
                                  />
                                </div>
                              )}
                              {isOfferRejected && (
                                <span className={styles.offerRejected}>Rechazado</span>
                              )}
                            </div>
                          </div>
                        </GuveryPaper>
                      </Grid>
                    );
                  })}
                </>
              ) : (
                <Grid item xs={12}>
                  <h4
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}>
                    Sin ofertas aún.
                  </h4>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}

      {(isWaitingApproval || isPublished) && !quantityOffers && !orderOne && !isExpress && (
        <>
        <BasicAlert
        variant="express"
        text="¿Necesitas agilizar la entrega? ⚡️ Podrías calificar para Guvery Express."
        actionText={'Revisar'}
        actionClick={() => guveryExpressDialog.openDialog()}
      />
        <GuveryExpressDialog
        open={guveryExpressDialog.open}
        onClose={guveryExpressDialog.closeDialog}
        order={order}
      />
        </>
      )}

      <PriceDialog
        open={priceDialog.open}
        onClose={priceDialog.closeDialog}
        priceData={priceData}
      />
      <AlertDialog
        variant="error"
        open={alertDialog.open}
        onClose={alertDialog.closeDialog}
        message={
          <span>
            <span style={{ display: 'inline-block' }}>
              ¿Está seguro de que desea expirar este pedido?
            </span>
            <span style={{ display: 'inline-block' }}>Esta acción no se puede deshacer.</span>
          </span>
        }
        title="Expirar Pedido"
        onConfirm={handleConfirmExpiring}
      />
      <AlertDialog
        variant="error"
        open={alertRemoveCourier.open}
        onClose={alertRemoveCourier.closeDialog}
        message={
          <span>
            <span style={{ display: 'inline-block' }}>
              ¿Está seguro de que deseas desactivar el "Envio Nacional"?
            </span>
            <span style={{ display: 'inline-block' }}>Esta acción no se puede deshacer.</span>
          </span>
        }
        title="Quitar envío nacional"
        onConfirm={handleRemoveCourier}
      />
    </GuveryPaper>
  );
};

export default OrderCard;
