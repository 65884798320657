import SvgIcon from '@material-ui/core/SvgIcon';

const AwardSvg = () => (
  <>
    <circle cx="12" cy="8" r="7"></circle>
    <path d="M8.21 13.89L7 23 12 20 17 23 15.79 13.88"></path>
  </>
);

const Award = (props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    style={{ fill: 'none' }}
    {...props}>
    <AwardSvg />
  </SvgIcon>
);

export default Award;
