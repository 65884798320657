import SvgIcon from '@material-ui/core/SvgIcon';

const CopySvg = () => (
  <>
    <path stroke="none" d="M0 0h24v24H0z"></path>
    <rect width="12" height="12" x="8" y="8" rx="2"></rect>
    <path d="M16 8V6a2 2 0 00-2-2H6a2 2 0 00-2 2v8a2 2 0 002 2h2"></path>
  </>
);

const Copy = (props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.5"
    style={{ fill: 'none' }}
    {...props}>
    <CopySvg />
  </SvgIcon>
);

export default Copy;
