import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton, Tooltip } from '@material-ui/core';
import { IconQuestionMark, IconTruckDelivery } from '@tabler/icons';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import clsx from 'clsx';

import { useDialog } from '#root/hooks';
import { roundDecimal } from '#root/utils';
import { GuveryPaper } from '#root/ui-components/cards';
import { CanguroGuvery } from '#root/assets';
import * as ROUTES from '#root/constants/navigation';
import PriceDialog from './PriceDialog';
import Seller from './Seller';
import ArrivalTime from './ArrivalTime';
import styles from './orderStoreCard.module.scss';

const formatTimeNoYear = (date: string) => {
  let time: string | null;
  try {
    time = date ? format(new Date(date), "dd 'de' MMMM", { locale: es }) : '---';
  } catch (error) {
    time = null;
  }
  return time;
};

interface CoverProps {
  imagePath: string;
}

const Cover: React.FC<CoverProps> = ({ imagePath }) => (
  <div className={clsx(styles.cover, !imagePath && styles.holder)}>
    {imagePath ? (
      <img src={imagePath} alt="producto" />
    ) : (
      <img className={styles.holder} src={CanguroGuvery} alt="producto" />
    )}
  </div>
);

const OrderStoreCard = (props) => {
  const { order } = props;
  const priceDialog = useDialog();

  const product = order?.tienda_product;
  const seller = order?.tienda_seller;
  const deliveryAddress = order?.delivery_address;

  const imagePath = product?.img_path;
  const precioProduct = Number(order?.precio);
  const priceDelivery = Number(order?.delivery_precio);
  const priceEnvio = order?.envio_precio ? Number(order?.envio_precio) : 0;
  const priceTotal = precioProduct + priceDelivery + priceEnvio;
  const deliveryDate = order?.entrega_fecha + ' ' + order?.entrega_hora_max + ':00';

  // console.log(order);
  return (
    <GuveryPaper disableHover disableElevation>
      <div className={styles.root}>
        <Cover imagePath={imagePath} />
        <div className={styles.body}>
          <div className={styles.top}>
            <div className={styles.badge}>
              <span>{order?.estado}</span>
            </div>
            <div className={styles.time}>{formatTimeNoYear(order.fecha)}</div>
            <div className={styles.title}>#{order.codigo}</div>
            <div className={styles.name}>{order?.tienda_product?.nombre}</div>
            {order?.envio_id && (
              <div className={styles.provincia}>
                <IconTruckDelivery size={20} />
                <span className={styles.provinciaKey}>Envio Provincia: </span>
                <Tooltip arrow title="ID del envio a provincia" placement="top">
                  <RouterLink to={ROUTES.USER_SHIPMENTS} className={styles.provinciaLink}>
                    #{order?.envio_id}
                  </RouterLink>
                </Tooltip>
              </div>
            )}
            {order?.estado === 'PROGRAMADO' && (
              <div className={styles.delivery}>
                <div className={styles.deliveryItem}>
                  <span className={styles.deliveryTitle}>Recibe: </span>
                  <span className={styles.deliveryRes}>
                    El {formatTimeNoYear(deliveryDate)}, entre las {order?.entrega_hora_min} y{' '}
                    {order?.entrega_hora_max}
                  </span>
                </div>
                <div className={styles.deliveryItem}>
                  <span className={styles.deliveryTitle}>Dirección de entrega: </span>
                  <span className={styles.deliveryRes}>
                    {deliveryAddress?.address_1},{' '}
                    <span className={styles.format}>{deliveryAddress?.district}</span>,{' '}
                    <span className={styles.format}>{deliveryAddress?.department}</span>
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className={styles.footer}>
            <div className={styles.orderPrice} onClick={priceDialog.openDialog}>
              <span>
                <span className={styles.priceTotal}> S/. {roundDecimal(priceTotal)}</span>
                <span className={styles.priceHint}>Total</span>
              </span>
              <Tooltip arrow title="Detalle del total" placement="top">
                <IconButton size="small" color="primary" className={styles.link}>
                  <IconQuestionMark />
                </IconButton>
              </Tooltip>
            </div>
            {order.estado === 'PROGRAMADO' && (
              <ArrivalTime dateCreate={order?.fecha} dateExpire={deliveryDate} />
            )}
          </div>
        </div>
      </div>
      <Seller seller={seller} />
      <PriceDialog open={priceDialog.open} onClose={priceDialog.closeDialog} order={order} />
    </GuveryPaper>
  );
};

export default OrderStoreCard;
