import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import { format } from 'date-fns';

import DealDTO from '#root/models/DealDTO';
import { validateURL } from '#root/utils';
import { dealService } from '#root/services';
import { replacePathParams } from '#root/utils';
import { useGlobalNotification } from '#root/hooks';
import { GuveryPageHeader, GuveryLayout, GuverySpace } from '#root/ui-components';
import { TRAVELER_DEAL_DETAIL } from '#root/constants';
import { ServiceOneLogoSvg } from '#root/assets';
import styles from './travelerDealEdit.module.scss';
import DealInfo from './DealInfo';
import DealOrders from './DealOrders';
import DealForm from './DealForm';

const baseForm = {
  deal_id: '',
  delivery_date: '',
  delivery_place: '',
  expiration_date: '',
  deal_orders: [],
};

const reducerQty = (accumulator, current) => Number(accumulator) + Number(current?.can);
const reducerPrice = (accumulator, current) => Number(accumulator) + Number(current?.pre);
const reducerCom = (accumulator, current) => Number(accumulator) + Number(current?.com);

const TravelerDealEdit = (props) => {
  const dealIda = props.match.params.ida;
  const globalNotification = useGlobalNotification();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isAllow, setAllow] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(false);
  const [dealInfo, setDealInfo] = useState(null);
  const [dealOrders, setDealOrders] = useState([]);
  const [dealForm, setDealForm] = useState(baseForm);
  const history = useHistory();

  const isProvince = dealInfo?.offer?.order?.flag_envio === 1 ? true : false;
  const isOne = dealInfo?.offer?.order?.flujo === 'ONE';

  const qtyTotal = dealForm?.deal_orders?.reduce(reducerQty, 0);
  const priceTotal = dealForm?.deal_orders?.reduce(reducerPrice, 0);
  const comTotal = dealForm?.deal_orders?.reduce(reducerCom, 0);

  const goBack = () => history.push(replacePathParams(TRAVELER_DEAL_DETAIL, { ida: dealIda }));

  const handleValidate = () => {
    if (dealForm?.deal_orders.length > 0) {
      const found = dealForm?.deal_orders?.find((e) => !validateURL(e?.url));
      if (found) {
        setSendDisabled(true);
        return;
      }
    }
    if (!dealForm?.delivery_date) {
      setSendDisabled(true);
      return;
    }
    if (!dealForm?.delivery_place) {
      setSendDisabled(true);
      return;
    }
    if (!dealForm?.expiration_date) {
      setSendDisabled(true);
      return;
    }
    if (qtyTotal <= 0) {
      setSendDisabled(true);
      return;
    }
    if (priceTotal <= 0) {
      setSendDisabled(true);
      return;
    }
    if (comTotal <= 0) {
      setSendDisabled(true);
      return;
    }
    setSendDisabled(false);
  };

  const fetchDeal = async () => {
    setLoading(true);
    const res = await dealService.travelerDealInfo(dealIda);
    if (!res.error) {
      const deal: DealDTO = res.data?.deal;
      setDealInfo(deal);
      setAllow(deal ? true : false);
      setDealOrders(deal.deal_orders);
      setDealForm({
        deal_id: deal?.ida?.toString(),
        delivery_date: deal?.fecent.toString(),
        delivery_place: deal?.lugent,
        expiration_date: deal?.fecmax + 'T23:59:59',
        deal_orders: [...deal?.deal_orders],
      });
    }
    setLoading(false);
  };

  const fetchEditDeal = async () => {
    setSendDisabled(true);
    const body = {
      deal_id: dealForm.deal_id,
      delivery_date: format(new Date(dealForm.delivery_date), 'yyyy-MM-dd'),
      delivery_place: dealForm.delivery_place,
      expiration_date: format(new Date(dealForm.expiration_date), 'yyyy-MM-dd'),
      deal_orders: dealForm.deal_orders,
    };
    const res = await dealService.editByTraveler(body);
    if (!res.error) {
      globalNotification.banner('Acuerdo actualizado con éxito', 'success');
      history.push(replacePathParams(TRAVELER_DEAL_DETAIL, { ida: dealIda }));
      return;
    }
    globalNotification.banner('No se pudo actualizar el acuerdo.', 'error');
    setSendDisabled(false);
  };

  useEffect(() => {
    fetchDeal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleValidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qtyTotal, priceTotal, comTotal, dealForm]);

  const title = (
    <span className={styles.title}>
      <span>{`Editando Acuerdo #${dealIda}`}</span>
      {isOne && (
        <div className={styles.badgeOne}>
          <img src={ServiceOneLogoSvg} alt="One" />
        </div>
      )}
      {isProvince && (
        <div className={styles.badgeOffice}>
          <span className={styles.text}>Envío Nacional</span>
        </div>
      )}
    </span>
  );

  if (isLoading) {
    return (
      <GuveryLayout>
        <GuveryPageHeader backButton title={title} onBack={goBack} />
        <h4>Cargando...</h4>
      </GuveryLayout>
    );
  }

  if (!isAllow && !isLoading) {
    return (
      <GuveryLayout>
        <GuveryPageHeader backButton title={title} onBack={goBack} />
        <h5>Error al obtener los datos del acuerdo</h5>
      </GuveryLayout>
    );
  }

  return (
    <GuveryLayout>
      <GuveryPageHeader backButton title={title} onBack={goBack} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DealInfo dealInfo={dealInfo} />
        </Grid>
        <Grid item xs={12} md={8}>
          <DealOrders
            dealForm={dealForm}
            setDealForm={setDealForm}
            dealOrders={dealOrders}
            setSendDisabled={setSendDisabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <GuverySpace direction="vertical" size="middle">
            <DealForm isProvince={isProvince} dealForm={dealForm} setDealForm={setDealForm} />
            <Button
              fullWidth
              disableElevation
              disabled={sendDisabled}
              variant="contained"
              color="primary"
              onClick={fetchEditDeal}>
              Guardar y Re-enviar Acuerdo
            </Button>
          </GuverySpace>
        </Grid>
      </Grid>
    </GuveryLayout>
  );
};

export default TravelerDealEdit;
