import { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { useResponsive } from '#root/hooks';
import { replacePathParams } from '#root/utils';
import { chatService } from '#root/services';
import { GuveryLayout } from '#root/ui-components';
import ConversationList from './ConversationList';
import Window from './Window';
import ConversationDTO from '#root/interfaces/ConversationDTO';
import * as ROUTES from '#root/constants/navigation';
import styles from './userChat.module.scss';

const UserChat = (props) => {
  const roomId = props.match.params.id;
  const history = useHistory();
  const { isMobile } = useResponsive();
  const state = roomId ? true : false;
  // const { usuario } = useAuth();
  const [isConversationListOpen, setConversationListOpen] = useState<boolean>(
    state ? !isMobile : true,
  );
  const [isloading, setLoading] = useState<boolean>(true);
  const [chats, setChats] = useState<ConversationDTO[]>([]);
  const [activeConversationId, setActiveConversationId] = useState(null);

  const handleSelectConversation = (conversationId: string | number) => {
    setActiveConversationId(conversationId);
    if (isMobile) {
      setConversationListOpen(!isConversationListOpen);
    }
    history.push(replacePathParams(ROUTES.USER_CHAT_ROOM, { id: conversationId }));
    // window.location.reload();
  };

  const ApplyFilter = (type: 'open' | 'closed' | 'all') => {
    fetchChats(type);
  };

  const fetchChats = async (type: 'open' | 'closed' | 'all') => {
    setLoading(true);
    const res = await chatService.list(type);
    // const res = await chatService.listByUser();
    if (!res.error) {
      const chats_ordered = res.data.chat_channels.sort(
        (a, b) =>
          new Date(a.last_channel_message?.created_at.replace(/ /g, 'T')).getTime() -
          new Date(b.last_channel_message?.created_at.replace(/ /g, 'T')).getTime(),
      );
      setChats(chats_ordered.reverse());
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchChats('open');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GuveryLayout container={false} disableGutters>
      <Container maxWidth={false} disableGutters>
        <div className={styles.root}>
          <ConversationList
            openList={isConversationListOpen}
            onListClose={() => setConversationListOpen(!isConversationListOpen)}
            loading={isloading}
            conversations={chats}
            activeConversationId={activeConversationId ? activeConversationId : roomId}
            onSelectConversation={handleSelectConversation}
            applyFilter={ApplyFilter}
          />
          <Window
            activeConversationId={activeConversationId ? activeConversationId : roomId}
            setChats={setChats}
            openList={isConversationListOpen}
            onListClose={() => setConversationListOpen(!isConversationListOpen)}
          />
        </div>
      </Container>
    </GuveryLayout>
  );
};

export default UserChat;
