import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Tooltip,
} from '@material-ui/core';
import {
  IconEye,
  IconCreditCard,
  IconDotsVertical,
  IconStar,
  IconQuestionMark,
  IconClock,
  IconBorderAll,
  IconCircleCheck,
  IconReceiptRefund,
  IconTrash,
  IconPhoto,
  IconMapPins,
} from '@tabler/icons';
import { formatDistanceStrict } from 'date-fns';
import { es } from 'date-fns/locale';
import clsx from 'clsx';

import { DealStatus } from '#root/interfaces/DealDTO';
import { RatingStatus } from '#root/interfaces/RatingDTO';
import { useDialog } from '#root/hooks';
import { shopperService } from '#root/services';
import { roundDecimal, replacePathParams } from '#root/utils';
import { CanguroGuvery, OneLogoSm } from '#root/assets';
import { RatingDialog, ExpirationDays, ShopperDeliverySetupDialog } from '#root/components';
import { AlertDialog, GuveryDialog } from '#root/ui-components';
import { CheckmarkCircle2Icon } from '#root/icons';
import * as ROUTES from '#root/constants/navigation';
import PriceDialog from '../PriceDialog';
import RejectDialog from '../RejectDialog';
import ArrivalTime from '../ArrivalTime';
import RefundDialog from '#root/views/UserDealDetail/Refund/RefundDialog';
import styles from './summary.module.scss';

const OrderLabel = {
  creado: 'creado',
  entregado: 'entregado',
  vencido: 'vencido',
  pagado: 'pagado',
  anulado: 'anulado',
} as const;

const RefundStatus = {
  creado: 'CREATED',
  solicitado: 'REQUESTED',
  pagado: 'PAID',
} as const;

const getTime = (orderDate: string | null) => {
  let time: string | null;
  try {
    time = orderDate
      ? formatDistanceStrict(new Date(orderDate.replace(/ /g, 'T')), new Date(), {
          locale: es,
        })
      : '---';
  } catch (error) {
    time = 'error';
  }
  return time;
};

const DeliveryDialog = (props) => {
  const { open = false, onClose, images } = props;
  return (
    <GuveryDialog open={open} onClose={onClose} title="Fotos de la entrega" closeText="Cerrar">
      <div className={styles.imageDelivery}>
        {!!images.length &&
          images.map((image) => {
            return (
              <Avatar src={image.filename_full_path} alt="delivery">
                <img className={styles.holder} src={CanguroGuvery} alt="delivery" />
              </Avatar>
            );
          })}
      </div>
    </GuveryDialog>
  );
};

const Summary = (props) => {
  const { deal, state, handleLoading, noTraveler, banks, createMessage } = props;
  const alertDialog = useDialog();
  const rejectDialog = useDialog();
  const priceDialog = useDialog();
  const ratingDialog = useDialog();
  const refundDialog = useDialog();
  const deliveryDialog = useDialog();
  const shopperDeliverySetupDialog = useDialog();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const traveler = deal?.offer?.traveler;
  const order = deal?.offer?.order;

  const imagePath = order?.img_path;
  const dealName = order?.tit;
  const dealObs = order?.obs;
  const total = Number(deal.total);

  const statePay =
    deal?.est_n === DealStatus.paying && deal?.status_label !== OrderLabel.vencido;
  const isCreated = deal?.est_n === DealStatus.created;
  const isExpire = deal?.status_label === OrderLabel.vencido;
  const isDeliveredTraveler = deal?.est_n === DealStatus.confirmedTraveler;
  const isCanceled =
    deal?.status_label === OrderLabel.vencido || deal.est_n === DealStatus.rejectedTraveler;
  const isTransit =
    deal?.est_n === DealStatus.paid ||
    deal?.est_n === DealStatus.buyed ||
    deal?.est_n === DealStatus.recivedTraveler ||
    deal?.est_n === DealStatus.arrived;

  const isCourier = deal?.offer?.order?.flag_envio;
  const hasRefund = deal?.refund;
  const isRefundCreated = hasRefund && hasRefund?.current_status === RefundStatus.creado;
  const isRefundRequested = hasRefund && hasRefund?.current_status === RefundStatus.solicitado;
  const isRefundPaid = hasRefund && hasRefund?.current_status === RefundStatus.pagado;
  const hasRating = deal?.rating_to_traveler?.current_status === RatingStatus.rated;

  const isOne = order?.flujo === 'ONE';

  const hasDeliverySetup = deal?.delivery_setup ? true : false;

  const handlePriceDialogOpen = () => {
    priceDialog.openDialog();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openDeliveredAlert = () => {
    handleMenuClose();
    alertDialog.openDialog();
  };

  const openRejectAlert = () => {
    handleMenuClose();
    rejectDialog.openDialog();
  };

  const openRefundDialog = () => {
    handleMenuClose();
    refundDialog.openDialog();
  };

  const openDeliveryDialog = () => {
    handleMenuClose();
    deliveryDialog.openDialog();
  };

  const handleConfirmDelivered = () => {
    fetchReceiving();
  };

  const fetchReceiving = async () => {
    const res = await shopperService.dealReciving(deal.ida);
    if (res.error) {
      createMessage.error('No se pudo completar la acción');
      return;
    }
    createMessage.success('Acuerdo recibido...');
    handleLoading();
    alertDialog.closeDialog();
    ratingDialog.openDialog();
  };

  return (
    <div className={styles.root}>
      <div className={clsx(styles.image, !imagePath && styles.holder)}>
        <a href={deal?.url} target="_blank" rel="noopener noreferrer">
          {imagePath ? (
            <img src={imagePath} alt="producto" />
          ) : (
            <img className={styles.holder} src={CanguroGuvery} alt="producto" />
          )}
        </a>
      </div>
      <div className={styles.body}>
        <div className={styles.info}>
          <span className={styles.time}>
            Hace {deal?.feccre ? getTime(deal?.feccre) : getTime(deal?.feccan)}
          </span>
          <div className={styles.title}>
            <h4 className={styles.titleText}>
              <RouterLink to={replacePathParams(ROUTES.USER_DEAL_DETAIL, { ida: deal?.ida })}>
                Acuerdo #{deal.ida}
              </RouterLink>
            </h4>
            {isOne && (
              <div className={styles.badgeOne}>
                <img src={OneLogoSm} alt="One" />
              </div>
            )}
            {isTransit && deal?.status_label && (
              <div className={styles.state}>
                <span className={styles.stateText}>{deal?.status_label}</span>
              </div>
            )}
            {!statePay && state && !isExpire && (
              <div className={styles.badge}>
                <span className={styles.badgeText}>{state}</span>
              </div>
            )}
            {statePay && !isExpire && (
              <div className={styles.validate}>
                <span className={styles.expireText}>{deal?.status_label}.</span>
              </div>
            )}
            {isCourier && (
              <div className={styles.courier}>
                <span>Envío Nacional</span>
              </div>
            )}
          </div>
          <div className={styles.nameWrap}>
            <h5 className={styles.name}>{dealName ? dealName : dealObs}</h5>
          </div>
          {dealName && dealObs && (
            <div className={styles.obsWrap}>
              <p className={styles.obs}>{dealObs}</p>
            </div>
          )}
          {deal?.deal_orders && (
            <>
              {deal?.deal_orders?.length === 1 ? (
                <>
                  {deal?.deal_orders.map((deal_order, index) => (
                    <div className={styles.product} key={index}>
                      <h5>
                        <span>{deal_order.can} x</span>
                        <a
                          className={styles.link}
                          href={deal_order.url}
                          target="_blank"
                          rel="noopener noreferrer">
                          {deal_order.fixed_url}
                        </a>
                      </h5>
                    </div>
                  ))}
                </>
              ) : (
                <Tooltip arrow title="Detalle del acuerdo" placement="top">
                  <div
                    className={clsx(styles.product, styles.several)}
                    onClick={handlePriceDialogOpen}>
                    <h5>
                      <span>
                        {deal?.deal_orders?.length} Productos / {deal?.cantot} items
                      </span>
                    </h5>
                    <IconBorderAll size={20} />
                  </div>
                </Tooltip>
              )}
            </>
          )}
          {deal.est_n === DealStatus.rejectedTraveler && (
            <p className={styles.cancelTraveler}>Anulado por viajero</p>
          )}
          {isRefundRequested && (
            <p style={{ marginTop: 10, color: 'var(--color-gray)' }}>
              <strong>El reembolso se está procesando...</strong>
            </p>
          )}
          {isRefundPaid && (
            <p style={{ marginTop: 10, color: 'var(--color-gray)' }}>
              <strong>Reembolso pagado.</strong>
            </p>
          )}
        </div>
        <div className={styles.actions}>
          <div>
            {!isCanceled && total > 0 && (
              <div className={styles.offerPrice} onClick={handlePriceDialogOpen}>
                <h4>
                  {deal?.guvery_express_fee && <span className={styles.expressFee}>⚡️</span>}
                  <span className={styles.currency}>$</span>
                  <span className={styles.priceTotal}>{roundDecimal(total)} </span>
                  <span className={styles.priceHint}>
                    {deal.est_n === DealStatus.created ? 'Total a pagar' : 'Total'}
                  </span>
                </h4>
                <Tooltip arrow title="Detalle del total" placement="top">
                  <IconButton size="small" color="primary" className={styles.link}>
                    <IconQuestionMark />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
          <div className={styles.btns}>
            {noTraveler && (
              <Button
                disableElevation
                component={RouterLink}
                to={replacePathParams(ROUTES.USER_DEAL_DETAIL, { ida: deal?.ida })}
                // href={VARIABLES.URL_PHP_PAGE + 'comprador/acuerdo/' + deal?.ida}
                variant="outlined"
                color="primary"
                startIcon={<IconEye />}>
                Ver Acuerdo
              </Button>
            )}
            {deal?.status_label === OrderLabel.creado && (
              <div>
                <Button
                  disableElevation
                  component={RouterLink}
                  to={replacePathParams(ROUTES.USER_DEAL_DETAIL, { ida: deal?.ida })}
                  // href={VARIABLES.URL_PHP_PAGE + 'comprador/acuerdo/' + deal?.ida}
                  variant="contained"
                  color="primary"
                  startIcon={<IconCreditCard />}>
                  Ver / Pagar acuerdo
                </Button>
                {!statePay && state === 'Sin Pagar' && !isExpire && (
                  <ExpirationDays
                    expirationDays={deal?.fixed_expiration_days}
                    style={{ marginTop: 4 }}
                  />
                )}
              </div>
            )}
            {deal?.status_label === OrderLabel.entregado &&
              !hasRating &&
              isDeliveredTraveler && (
                <Button
                  disableElevation
                  variant="outlined"
                  color="primary"
                  startIcon={<IconCircleCheck />}
                  onClick={openDeliveredAlert}>
                  Ya lo recibí
                </Button>
              )}
            {deal?.status_label === OrderLabel.entregado &&
              !hasRating &&
              !isDeliveredTraveler && (
                <Button
                  disableElevation
                  onClick={ratingDialog.openDialog}
                  variant="outlined"
                  color="primary"
                  startIcon={<IconStar />}>
                  Calificar
                </Button>
              )}
            {hasRating && (
              <p className={styles.rating}>
                <span className={styles.text}>Calificaste al viajero con</span>
                <span className={styles.score}>
                  {deal?.rating_to_traveler?.score} <IconStar />
                </span>
              </p>
            )}
            {isTransit && !hasDeliverySetup && (
              <ArrivalTime
                originalRemainingDays={deal?.original_remaining_days}
                remainingDays={deal?.remaining_days}
                dateCreate={deal?.fecpagadm ? deal?.fecpagadm : deal?.feccre}
                dateExpire={deal?.fecent}
              />
            )}
            {hasDeliverySetup && (
              <>
                <div className={styles.deliverySetup}>
                  <p>Ya puedes recoger tu pedido</p>
                  <Button
                    disableElevation
                    variant="contained"
                    color="inherit"
                    startIcon={<IconMapPins />}
                    onClick={shopperDeliverySetupDialog.openDialog}>
                    Indicaciones para recoger
                  </Button>
                </div>
                <ShopperDeliverySetupDialog
                  open={shopperDeliverySetupDialog.open}
                  onClose={shopperDeliverySetupDialog.closeDialog}
                  deal={deal}
                />
              </>
            )}
            {deal.status_label === OrderLabel.vencido && (
              <span className={styles.inactiveWrap}>
                <span className={styles.inactive}>
                  <IconClock size={14} />
                  El acuerdo venció.
                </span>
                <span className={styles.inactiveHint}>
                  Consulta al viajero si puede extender la vigencia.
                </span>
              </span>
            )}
          </div>
        </div>
        <div className={styles.tools}>
          <IconButton color="secondary" onClick={handleClick} className={styles.toolsButton}>
            <IconDotsVertical size={22} />
          </IconButton>
          <Menu
            id="tools-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}>
            <MenuItem
              component={RouterLink}
              to={replacePathParams(ROUTES.USER_DEAL_DETAIL, { ida: deal?.ida })}>
              <ListItemIcon style={{ minWidth: 32, color: 'inherit' }}>
                <IconEye size={20} />
              </ListItemIcon>
              <h6>Ver Acuerdo</h6>
            </MenuItem>
            {deal?.delivered_photos && deal?.delivered_photos.length > 0 && (
              <MenuItem onClick={openDeliveryDialog}>
                <ListItemIcon style={{ minWidth: 32 }}>
                  <IconPhoto size={20} color="var(--color-status-info)" />
                </ListItemIcon>
                <h6>Ver fotos de la entrega</h6>
              </MenuItem>
            )}
            {isTransit && (
              <MenuItem onClick={openDeliveredAlert}>
                <ListItemIcon style={{ minWidth: 32 }}>
                  <CheckmarkCircle2Icon
                    style={{ fontSize: 20 }}
                    color="var(--color-status-success)"
                  />
                </ListItemIcon>
                <h6>Ya lo recibí</h6>
              </MenuItem>
            )}
            {isRefundCreated && (
              <MenuItem onClick={openRefundDialog}>
                <ListItemIcon style={{ minWidth: 32 }}>
                  <IconReceiptRefund size={20} />
                </ListItemIcon>
                <h6>Solicita tu reembolso</h6>
              </MenuItem>
            )}
            {isCreated && !isOne && (
              <MenuItem onClick={openRejectAlert}>
                <ListItemIcon style={{ minWidth: 32 }}>
                  <IconTrash size={20} color="var(--color-status-error)" />
                </ListItemIcon>
                <h6>Rechazar acuerdo</h6>
              </MenuItem>
            )}
          </Menu>
        </div>
      </div>
      <AlertDialog
        variant="success"
        open={alertDialog.open}
        onClose={alertDialog.closeDialog}
        title="Marcar el acuerdo como entregado"
        message={
          <span>
            <span style={{ display: 'inline-block' }}>
              ¿Está seguro de que desea marcar este acuerdo como entregado?
            </span>
            <span style={{ display: 'inline-block' }}>Esta acción no se puede deshacer.</span>
          </span>
        }
        onConfirm={handleConfirmDelivered}
      />
      <RejectDialog
        id={deal?.ida}
        open={rejectDialog.open}
        onClose={rejectDialog.closeDialog}
        handleLoading={handleLoading}
        createMessage={createMessage}
      />
      <PriceDialog open={priceDialog.open} onClose={priceDialog.closeDialog} deal={deal} />
      <RatingDialog
        id={deal?.ida}
        ratingId={deal?.rating_to_traveler?.id}
        open={ratingDialog.open}
        onClose={ratingDialog.closeDialog}
        title="Estas calificando a tu viajero"
        date1={deal?.fecentcom}
        date2={deal?.fecentvia}
        hint="Esta es una calificación anónima. El viajero no será notificado ni podrá saber su calificación."
        userData={traveler}
        handleLoading={handleLoading}
        createMessage={createMessage}
      />
      <RefundDialog
        open={refundDialog.open}
        onClose={refundDialog.closeDialog}
        refundId={hasRefund?.id}
        banks={banks}
        createMessage={createMessage}
        handleLoading={handleLoading}
      />
      {deal?.delivered_photos && (
        <DeliveryDialog
          open={deliveryDialog.open}
          onClose={deliveryDialog.closeDialog}
          images={deal?.delivered_photos}
        />
      )}
    </div>
  );
};

export default Summary;
