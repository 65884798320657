import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { roundDecimal } from '#root/utils';
import { GuveryDialog } from '#root/ui-components';
import styles from './priceDialog.module.scss';

interface PriceDialogProps {
  open: boolean;
  onClose: () => void;
  deal: any;
}

const PriceDialog = (props: PriceDialogProps) => {
  const { open = false, onClose, deal } = props;

  return (
    <GuveryDialog
      open={open}
      onClose={onClose}
      content={false}
      title="Detalle del total"
      closeText="Cerrar">
      <TableContainer>
        <Table className={styles.table}>
          <TableHead className={styles.header}>
            <TableRow>
              <TableCell>Producto</TableCell>
              <TableCell>Precio Real</TableCell>
              <TableCell>Viajero</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deal?.deal_orders?.map((order, index) => (
              <TableRow key={index}>
                <TableCell>
                  <span className={styles.name}>
                    <span>{`x ${order.can}`}</span>
                    <a
                      className={styles.link}
                      href={order.url}
                      target="_blank"
                      rel="noopener noreferrer">
                      <span>{order.fixed_url}</span>
                    </a>
                  </span>
                </TableCell>
                <TableCell>$ {roundDecimal(order.pre)}</TableCell>
                <TableCell>$ {roundDecimal(order.com)}</TableCell>
                <TableCell>$ {roundDecimal(order.pre + order.com)}</TableCell>
              </TableRow>
            ))}
            {deal.guvery_fee && (
              <TableRow>
              <TableCell>
                <span style={{ paddingLeft: 24 }}>Guvery</span>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>$ {roundDecimal(deal.guvery_fee)}</TableCell>
            </TableRow>
            )}
            {deal.guvery_express_fee && (
              <TableRow>
              <TableCell>
                <span style={{ paddingLeft: 24 }}>Express ⚡️</span>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>$ {roundDecimal(deal.guvery_express_fee)}</TableCell>
            </TableRow>
            )}
            {deal.dscto && (
              <TableRow>
                <TableCell>
                  <span style={{ paddingLeft: 24 }}>Descuento</span>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <span className="guv-text-error">- $ {roundDecimal(deal.dscto)}</span>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <span className={styles.total}>Total</span>
              </TableCell>
              <TableCell>
                <span className={styles.total}>$ {roundDecimal(deal.total)}</span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </GuveryDialog>
  );
};

export default PriceDialog;
