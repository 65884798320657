import { useHistory } from 'react-router-dom';
import { Button, Drawer, useMediaQuery } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { formatDistanceStrict, compareAsc } from 'date-fns';
import { es } from 'date-fns/locale';
import clsx from 'clsx';

import { offerService } from '#root/services';
import { useAuth, useNotification } from '#root/hooks';
import { replacePathParams } from '#root/utils';
import { GuverySpace, GuveryObsToolTip } from '#root/ui-components';
import { BriefcaseOutlineIcon, LinkIcon, InfoCircleIcon } from '#root/icons';
import { ImageHorizontal } from '#root/assets';
import { TRAVELER_OFFERS_BY_ID } from '#root/constants';
import * as VARIABLES from '#root/constants/variables';
import styles from './messageDetail.module.scss';

const EntityType = {
  pedido: 'Pedido',
  acuerdo: 'Acuerdo',
} as const;

const ContentOrder = (props) => {
  const { createMessage } = useNotification();
  const { entityData = {}, isTraveler } = props;
  const history = useHistory();
  const { negocio } = useAuth();

  const entityTime = entityData?.data?.feccre
    ? entityData?.data?.feccre.toString().replace(/ /g, 'T')
    : 'Fri Jul 02 2021 18:15:13 GMT-0500';

  const offerReplied = entityData.data?.est === '1' ? true : false;
  const offerAccepted = entityData.data?.est === '2' ? true : false;

  const commissionGuvery = (price, com, guv, guvTope, guvExtra) => {
    const sumPrices = Number(price) + Number(com);
    const getPercent = (sumPrices * Number(guv)) / 100;
    const getMaxPercent = getPercent > Number(guvTope) ? Number(guvTope) : getPercent;
    const commission = getMaxPercent + Number(guvExtra);

    return commission;
  };

  const commissionGuveryText = commissionGuvery(
    Number(entityData.data?.prerea),
    Number(entityData.data?.comvia),
    negocio?.com_porc,
    negocio?.com_tope,
    negocio?.com_link,
  );

  const entityDesc = entityData.data?.dscto ? Number(entityData.data?.dscto) : 0;
  const entityPrice =
    Number(entityData.data?.prerea) +
    Number(entityData.data?.comvia) +
    Number(commissionGuveryText) -
    entityDesc;

  const dateExp = entityData?.data?.fecmax
    ? entityData?.data?.fecmax.toString().replace(/ /g, 'T')
    : 'Fri Jul 02 2021 18:15:13 GMT-0500';

  const entityExpire = compareAsc(new Date(dateExp), new Date()) === 1 ? true : false;

  const fetch_accept_offer = async () => {
    const res = await offerService.acceptByShopper(entityData?.data?.ido);
    if (!res.error) {
      history.push('/comprador/pedido/' + entityData?.data?.idp + '/' + entityData?.data?.ido);
    } else {
      createMessage.error('No se pudo actualizar la oferta.');
    }
  };

  const handleCreateDeal = () => {
    history.push('/viajero/ofertas/' + entityData?.data?.ido + '/acuerdo');
  }

  const handleDetailRedirect = () => {
    if (isTraveler) {
      history.push(replacePathParams(TRAVELER_OFFERS_BY_ID, { id: entityData?.data?.ido }));
    } else {
      history.push('/comprador/pedido/' + entityData?.data?.idp + '/' + entityData?.data?.ido);
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <div className={styles.chip}>
          <span>Oferta {entityData.data.status_label}</span>
        </div>
        <h4>
          {entityData.type} #{entityData.id}
        </h4>
        <p>hace {formatDistanceStrict(new Date(entityTime), new Date(), { locale: es })}</p>
      </div>
      <div className={styles.product}>
        <div className={styles.cover}>
          {entityData.data?.order?.img_path ? (
            <img
              className={styles.image}
              src={entityData.data?.order?.img_path}
              alt={entityData.data?.order?.tit}
            />
          ) : (
            <img className={styles.holder} src={ImageHorizontal} alt={entityData.data?.tit} />
          )}
        </div>
        <h5 className={styles.title}>{entityData.data?.order?.tit}</h5>
        {entityData.data?.order?.obs && (
          <GuveryObsToolTip arrow title={entityData.data?.order?.obs} placement="bottom">
            <p className={styles.info}>{entityData.data?.order?.obs}</p>
          </GuveryObsToolTip>
        )}

        <div className={styles.item}>
          <div className={styles.head}>
            <LinkIcon />
          </div>
          <a href={entityData.data?.order?.url} target="_blank" rel="noopener noreferrer">
            {entityData.data?.order?.url}
          </a>
        </div>
        <div className={styles.priceList}>
          <dl className={styles.charge}>
            <dt>Cantidad</dt>
            <dd>{entityData.data?.can}</dd>
          </dl>
          <dl className={styles.charge}>
            <dt>Precio real</dt>
            <dd>{entityData.data?.prerea?.toFixed(2)}</dd>
          </dl>
          <dl className={styles.charge}>
            <dt>Viajero</dt>
            <dd>{entityData.data?.comvia?.toFixed(2)}</dd>
          </dl>
          {!isTraveler && (
            <>
              <dl className={styles.charge}>
                <dt>Guvery</dt>
                <dd>{commissionGuveryText}</dd>
              </dl>
              {entityData.data?.dscto && (
                <dl className={styles.charge}>
                  <dt>Descuento</dt>
                  <dd>- {entityData.data?.dscto}</dd>
                </dl>
              )}
              <dl className={styles.charge}>
                <dt>Total</dt>
                <dd>{entityPrice?.toFixed(2)}</dd>
              </dl>
            </>
          )}
        </div>
        <GuverySpace direction="vertical" className={styles.moreInfo}>
          {entityExpire && (
            <>
              {offerReplied && !isTraveler && (
                <Button
                  disableElevation
                  fullWidth
                  onClick={fetch_accept_offer}
                  variant="contained"
                  color="primary"
                  startIcon={<BriefcaseOutlineIcon />}>
                  Aceptar Oferta
                </Button>
              )}
              {offerAccepted && isTraveler && (
                <Button
                  disableElevation
                  fullWidth
                  onClick={handleCreateDeal}
                  variant="contained"
                  color="primary"
                  startIcon={<BriefcaseOutlineIcon />}>
                  Crear Acuerdo
                </Button>
              )}
            </>
          )}
          <Button
            disableElevation
            fullWidth
            onClick={handleDetailRedirect}
            variant="outlined"
            color="secondary"
            startIcon={<InfoCircleIcon />}>
            Mas Detalles
          </Button>
        </GuverySpace>
      </div>
      {/* Normas comprador oferta */}
      {!isTraveler && (
      <div style={{color: 'var(--color-dark-gray)', marginTop: '20px', borderTop: '1px dashed var(--border-color-2)', paddingTop: '12px'}}>
          <h4>
            <p>Tips súper útiles:</p>
          </h4>
          <div style={{color: 'var(--color-dark)', marginTop: '6px', marginBottom: '50px', lineHeight: '18px', paddingBottom: '40px'}}>
              <p style={{marginTop: '6px', fontSize: 'var(--font-size-7)'}}>🔍 &nbsp;Guvery monitorea los chats.</p>
              <p style={{marginTop: '6px', fontSize: 'var(--font-size-7)'}}>✖️ &nbsp;No compartas datos de contacto personal, ni insinues algún trato por fuera de Guvery.</p>
              <p style={{marginTop: '6px', fontSize: 'var(--font-size-7)'}}>💡 &nbsp;Sé amable y utiliza un leguaje claro. Si te atrae una oferta, acéptala rápido para que el viajero pueda traer tu pedido sin problemas.</p>
              <p style={{marginTop: '6px', fontSize: 'var(--font-size-7)'}}>💡 &nbsp;Comunícale oportunamente al viajero si ya no estás interesado en su oferta.</p>
              <p style={{marginTop: '6px', fontSize: 'var(--font-size-7)'}}>✔️ &nbsp;Para que el viajero pueda hacer ajustes sobre la oferta, primero debes aceptarla. Luego, el viajero creará el acuerdo con los ajustes conversados.</p>
              <p style={{marginTop: '6px', fontSize: 'var(--font-size-7)'}}>✔️ &nbsp;Guvery acepta pagos en dólares y soles, vía transferencia bancaria o tarjeta de crédito (con recargo) de los bancos: BCP / Interbank y BBVA.</p>
          </div>
      </div>
      )}
      {/* Normas viajero oferta */}
      {isTraveler && (
      <div style={{color: 'var(--color-dark-gray)', marginTop: '20px', borderTop: '1px dashed var(--border-color-2)', paddingTop: '12px'}}>
          <h4>
            <p>Tips súper útiles:</p>
          </h4>
          <div style={{color: 'var(--color-dark)', marginTop: '6px', marginBottom: '50px', lineHeight: '18px', paddingBottom: '40px'}}>
              <p style={{marginTop: '6px', fontSize: 'var(--font-size-7)'}}>🔍 &nbsp;Guvery monitorea los chats.</p>
              <p style={{marginTop: '6px', fontSize: 'var(--font-size-7)'}}>✖️ &nbsp;No compartas datos de contacto personal, ni insinues algún trato por fuera de Guvery.</p>
              <p style={{marginTop: '6px', fontSize: 'var(--font-size-7)'}}>💡 &nbsp;Sé amable y utiliza un leguaje claro. Responde rápido a las preguntas de los compradores.</p>
              <p style={{marginTop: '6px', fontSize: 'var(--font-size-7)'}}>✔️ &nbsp;¿Cambios/ajustes? Pídele al comprador que acepte la oferta. Así, podrás crear el acuerdo con los ajustes necesarios.</p>
          </div>
      </div>
      )}
    </div>
  );
};

const ContentAgreement = (props) => {
  const { entityData = {}, isTraveler } = props;
  const entityUrl = isTraveler
    ? VARIABLES.URL_PHP_PAGE + 'viajero/revisarAcuerdo/' + entityData.id
    : '/comprador/acuerdo/' + entityData.id;
  const entityTime = entityData?.data?.feccre
    ? entityData?.data?.feccre.toString().replace(/ /g, 'T')
    : 'Fri Jul 02 2021 18:15:13 GMT-0500';

  const entityDesc = entityData.data?.dscto ? Number(entityData.data?.dscto) : 0;

  const dealCreated = entityData.data?.est_n === 'CREADO' ? true : false;

  const entityPrice =
    Number(entityData.data?.pretot) +
    Number(entityData.data?.comtot) +
    Number(entityData.data?.guvery_fee) -
    entityDesc;

  const dateExp = entityData?.data?.fecmax
    ? entityData?.data?.fecmax.toString().replace(/ /g, 'T')
    : 'Fri Jul 02 2021 18:15:13 GMT-0500';

  const entityExpire = compareAsc(new Date(dateExp), new Date()) === 1 ? true : false;

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <div className={clsx(styles.chip)}>
          <span>Acuerdo {entityData.data.status_label}</span>
        </div>
        <h4>
          {entityData.type} #{entityData.id}
        </h4>
        <p>hace {formatDistanceStrict(new Date(entityTime), new Date(), { locale: es })}</p>
      </div>
      <div className={styles.list}>
        <div className={styles.product}>
          <div className={styles.cover}>
            {entityData.data?.offer?.order?.img_path ? (
              <img
                className={styles.image}
                src={entityData.data?.offer?.order?.img_path}
                alt="Productro Acuerdo"
              />
            ) : (
              <img className={styles.holder} src={ImageHorizontal} alt={entityData.data?.tit} />
            )}
          </div>
          <h5 className={styles.title}>{entityData.data?.offer?.order?.tit}</h5>
          <p className={styles.info}>{entityData.data?.offer?.order?.obs}</p>
          {entityData.data.deal_orders.map((item, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.cant}>{`${item.can} X `}</div>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                {item.url}
              </a>
            </div>
          ))}
          <div className={styles.priceList}>
            <dl className={styles.charge}>
              <dt>Precio real</dt>
              <dd>{entityData.data?.pretot?.toFixed(2)}</dd>
            </dl>
            <dl className={styles.charge}>
              <dt>Viajero</dt>
              <dd>{entityData.data?.comtot?.toFixed(2)}</dd>
            </dl>
            {!isTraveler && (
              <>
                <dl className={styles.charge}>
                  <dt>Guvery</dt>
                  <dd>{entityData.data?.guvery_fee}</dd>
                </dl>
                {entityData.data?.dscto && (
                  <dl className={styles.charge}>
                    <dt>Descuento</dt>
                    <dd>- {entityData.data?.dscto}</dd>
                  </dl>
                )}
                <dl className={styles.charge}>
                  <dt>Total</dt>
                  <dd>{entityPrice?.toFixed(2)}</dd>
                </dl>
              </>
            )}
          </div>
          <GuverySpace direction="vertical" className={styles.moreInfo}>
            {entityExpire && (
              <>
                {dealCreated && !isTraveler && (
                  <Button
                    disableElevation
                    fullWidth
                    component="a"
                    href={entityUrl}
                    variant="contained"
                    color="primary"
                    startIcon={<BriefcaseOutlineIcon />}>
                    Pagar Acuerdo
                  </Button>
                )}
              </>
            )}
            <Button
              disableElevation
              fullWidth
              component="a"
              href={entityUrl}
              variant="outlined"
              color="secondary"
              startIcon={<InfoCircleIcon />}>
              Mas Detalles
            </Button>
          </GuverySpace>
        </div>
      </div>
    </div>
  );
};

const MessageDetail = (props) => {
  const { isLoading, openDetail, onDetailClose, entityData, isTraveler } = props;
  const toolbarMatches = useMediaQuery('(min-width:600px)');
  const drawerMatches = useMediaQuery('(min-width:1200px)');
  const type = entityData.type;

  return (
    <>
      {drawerMatches ? (
        <div className={clsx(styles.root, !openDetail && styles.close)}>
          {!isLoading ? (
            <>
              {type === EntityType.pedido && (
                <ContentOrder entityData={entityData} isTraveler={isTraveler} />
              )}
              {type === EntityType.acuerdo && (
                <ContentAgreement entityData={entityData} isTraveler={isTraveler} />
              )}
            </>
          ) : (
            <div className={styles.content}>
              <Skeleton height={28} width="35%" />
              <Skeleton height={32} width="85%" />
              <Skeleton height={24} width="50%" />
              <div className={styles.product} style={{ marginTop: 16 }}>
                <div className={styles.cover}>
                  <div className={styles.image}>
                    <Skeleton variant="rect" height="100%" />
                  </div>
                </div>
                <div className={styles.title}>
                  <Skeleton height={28} width="85%" />{' '}
                </div>
                <Skeleton height={24} width="60%" />
                <Skeleton height={20} />
                <Skeleton height={20} />
                <Skeleton height={20} width="60%" />
              </div>
            </div>
          )}
        </div>
      ) : (
        <Drawer
          anchor="right"
          variant="temporary"
          open={openDetail}
          onClose={onDetailClose}
          PaperProps={{
            style: {
              position: 'absolute',
              top: toolbarMatches ? 64 : 56,
              width: toolbarMatches ? '420px' : '272px',
              background: 'var(--color-background-base)',
            },
          }}
          ModalProps={{
            disablePortal: true,
          }}
          style={{
            zIndex: 1000,
          }}>
          {type === EntityType.pedido && (
            <ContentOrder entityData={entityData} isTraveler={isTraveler} />
          )}
          {type === EntityType.acuerdo && (
            <ContentAgreement entityData={entityData} isTraveler={isTraveler} />
          )}
        </Drawer>
      )}
    </>
  );
};

export default MessageDetail;
