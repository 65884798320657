interface MessageModel {
  error?: string;
  success?: string;
}

interface FetchApiProps {
  url: string;
  options: RequestInit;
  message: MessageModel;
  avoidOverwriteData?: boolean;
}

export const Status = {
  success: 'success',
  fail: 'fail',
  error: 'error',
  redirect: 'redirect',
} as const;

export type StatusType = typeof Status[keyof typeof Status];

export interface FetchApiResponse {
  status: StatusType;
  error: boolean;
  message: string | null;
  data: any;
}

const guveryFetchApi = async (data: FetchApiProps) => {
  const { url, options, message, avoidOverwriteData = false } = data;

  const response: FetchApiResponse = {
    status: Status.error,
    error: false,
    message: null,
    data: {},
  };

  try {
    const res = await fetch(url, options);
    const json = await res.json();

    if (res.ok) {
      response.message = json.message ? json.message : message.success;
      response.status = json.status;
      if (json.status !== Status.success) {
        response.message = json.message ? json.message : message.error;
        response.error = true;
      }
      if (avoidOverwriteData) {
        response.data = json.data;
      } else {
        response.data = json.data ?? json;
      }
    } else {
      response.status = json.status ? json.status : Status.error;
      response.message = json.message ? json.message : message.error;
      response.error = true;
    }
  } catch (e) {
    response.message = `Error: ${e.message}`;
    response.error = true;
  }

  return { response };
};

export default guveryFetchApi;
