import { useState } from 'react';
import { Button } from '@material-ui/core';
import { IconCameraPlus } from '@tabler/icons';
import styles from './actions.module.scss';

import { useDialog } from '#root/hooks';
import { shopperService } from '#root/services';
import { ImageCropModal } from '#root/components';
import { GuverySpace } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';

interface ImageProps {
  file?: string;
  imagePreviewUrl?: string | ArrayBuffer;
}

const Actions = (props) => {
  const { orderId, handleLoading, createMessage } = props;
  const imageCropModal = useDialog();
  const [image, setImage] = useState<ImageProps>({ file: '', imagePreviewUrl: '' });

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setImage({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    if (file && file.type.match('image.*')) {
      reader.readAsDataURL(file);
      imageCropModal.openDialog();
    }
  };

  const handleImageSuccess = (blobImage, croppedImageUrl) => {
    imageCropModal.closeDialog();
    if (blobImage) {
      fetchUploadImage(orderId, blobImage);
    }
  };

  const fetchUploadImage = async (orderId, image) => {
    const body = {
      orderId,
      image,
    };
    const res = await shopperService.orderUploadImage(body);
    if (!res.error) {
      createMessage.success('Imagen del pedido actualizada.');
      handleLoading();
    } else {
      createMessage.error('No se pudo actualizar el acuerdo');
    }
  };

  return (
    <GuveryPaper disableElevation disableHover className={styles.root}>
      <GuverySpace direction="vertical" size="middle">
        <div>
          <input
            className="guv-hidden"
            id="order-upload-image"
            type="file"
            accept=".jpg,.jpeg,.png,.gif"
            onChange={handleImageChange}
          />
          <Button
            fullWidth
            disableElevation
            variant="contained"
            color="primary"
            startIcon={<IconCameraPlus />}
            component="label"
            htmlFor="order-upload-image">
            Subir imagen
          </Button>
        </div>
        <div className={styles.hint}>
          <span>Formato JPEG o PNG. 2 MB max.</span>
        </div>
      </GuverySpace>
      <ImageCropModal
        open={imageCropModal.open}
        onCancel={imageCropModal.closeDialog}
        onConfirm={handleImageSuccess}
        title="Actualizar la imagen del pedido"
        src={image.imagePreviewUrl}
      />
    </GuveryPaper>
  );
};

export default Actions;
