import { Button } from '@material-ui/core';
import { IconChecks } from '@tabler/icons';

import { GuveryDialog } from '#root/ui-components';
import styles from './howOfferDialog.module.scss';

const HowOfferDialog = (props) => {
  const { open = false, onClose } = props;

  return (
    <GuveryDialog
      open={open}
      onClose={onClose}
      title="Cómo hacer una oferta"
      closeText="Cerrar">
      <div className={styles.root}>
        <p className="guv-font-size-4 guv-semibold">
          <span className="guv-text-primary guv-bold">*IMPORTANTE*:</span> Debes tener un viaje
          seleccionado.
        </p>
        <p className="guv-font-size-5 guv-regular">
          <strong>Para hacer una oferta, primero debes SIMULAR LA COMPRA del pedido.</strong>{' '}
          <span className="guv-text-alt-2">
            Ingresa al link del producto, agregalo al carrito de compras y dirígete hacia el
            'checkout'.
          </span>
        </p>
        <p className="guv-font-size-5 guv-regular guv-text-alt-2 ">
          Coloca tu dirección de entrega en EE.UU. y los datos necesarios para que la tienda
          pueda calcular el <strong className="guv-text-base">TAX</strong> (impuestos) y el{' '}
          <strong className="guv-text-base">SHIPPING</strong> (costo y tiempo de envío).
        </p>
        <p className="guv-font-size-4 guv-semibold">
          Para llenar los campos de la oferta, considera:
        </p>
        <ul>
          <li className="guv-font-size-5 guv-regular guv-text-alt-2">
            El Precio Real y la Comisión es por la cantidad seleccionada (no es unitario).
          </li>
          <li className="guv-font-size-5 guv-regular guv-text-alt-2">
            El Precio Real debe incluir TAX / Shipping.
          </li>
          <li className="guv-font-size-5 guv-regular guv-text-alt-2">
            Puedes proponer la comisión que creas adecuada.
          </li>
          <li className="guv-font-size-5 guv-regular guv-text-alt-2">
            La fecha de entrega normalmente es 1 o 2 días después de tu arribo a Perú.
          </li>
          <li className="guv-font-size-5 guv-regular guv-text-alt-2">
            Si tienes opción de entregar el pedido en más de 1 distrito, colócalos. Es más
            atractivo para el comprador.
          </li>
        </ul>
        <p className="guv-font-size-5 guv-regular guv-text-alt-2">
          Llena los campos obligatorios para que el botón de{' '}
          <strong className="guv-text-base">ENVIAR OFERTA</strong> se active.
        </p>
        <div className={styles.actions}>
          <Button
            disableElevation
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            onClick={onClose}
            className="guv-no-decoration"
            startIcon={<IconChecks />}>
            Entendido
          </Button>
        </div>
      </div>
    </GuveryDialog>
  );
};

export default HowOfferDialog;
