import SvgIcon from '@material-ui/core/SvgIcon';

const MedalSvg = () => (
  <>
    <path
      fill="#E95454"
      d="M151.352 221.248L39.592 431.808 141.08 422.432 190.2 511.744 301.96 301.184z"></path>
    <g fill="#ED6362">
      <path d="M164.152 450.432c3.136-6.736-.144-14.784-6.704-18.272l-6.432-3.424c-6.432-3.664-8.88-11.808-5.376-18.4 3.552-6.704 11.904-9.264 18.608-5.712l18.176 9.648c5.616 2.976 12.656.816 15.552-4.832l.448-.864c3.328-5.328 1.36-12.368-4.176-15.296l-54.368-28.848c-6.624-3.52-9.872-11.536-6.784-18.288 3.408-7.184 12.016-10.016 18.944-6.336l50.432 26.768c5.616 2.976 12.656.816 15.632-4.8l.288-.736.08-.16c3.216-5.888.96-13.28-4.976-16.432l-28.288-15.008c-6.624-3.52-9.872-11.536-6.736-18.368 3.376-7.104 12.016-10.016 18.944-6.336l31.248 16.592c5.84 3.104 12.816.512 15.84-5.376.048-.08.048-.08.08-.16.048-.08.048-.08.08-.16 3.184-5.808 1.424-13.04-4.432-16.128l-33.712-17.888c-6.544-3.472-9.792-11.488-6.704-18.24 3.408-7.184 12.016-10.016 18.944-6.336l83.168 44.144-111.76 210.56-30.8-55.984c1.984-1.344 3.712-3.008 4.784-5.328z"></path>
      <path d="M360.408 221.248L472.168 431.808 370.68 422.432 321.56 511.744 209.8 301.184z"></path>
    </g>
    <path
      fill="#E95454"
      d="M347.608 450.432c-3.136-6.736.144-14.784 6.704-18.272l6.432-3.424c6.432-3.664 8.88-11.808 5.376-18.4-3.552-6.704-11.904-9.264-18.608-5.712l-18.176 9.648c-5.616 2.976-12.656.816-15.552-4.832l-.448-.864c-3.328-5.328-1.36-12.368 4.176-15.296l54.384-28.864c6.624-3.52 9.872-11.536 6.784-18.288-3.408-7.184-12.016-10.016-18.944-6.336l-50.432 26.768c-5.616 2.976-12.656.816-15.632-4.8l-.288-.736-.08-.16c-3.216-5.888-.96-13.28 4.976-16.432l28.288-15.008c6.624-3.52 9.872-11.536 6.736-18.368-3.376-7.104-12.016-10.016-18.944-6.336l-31.28 16.624c-5.84 3.104-12.816.512-15.84-5.376-.048-.08-.048-.08-.08-.16-.048-.08-.048-.08-.08-.16-3.184-5.808-1.424-13.04 4.432-16.128l33.712-17.888c6.544-3.472 9.792-11.488 6.704-18.24-3.408-7.184-12.016-10.016-18.944-6.336L209.816 301.2l111.76 210.56 30.8-55.984c-1.968-1.36-3.696-3.024-4.768-5.344z"></path>
    <circle cx="255.88" cy="186.736" r="186.736" fill="#FFCC5B"></circle>
    <path
      fill="#FDBC4B"
      d="M281.208 165.312H162.872c-9.2 0-16.752-7.552-16.752-16.752l.128-1.152v-.256c-.128-9.712 7.792-17.648 17.52-17.648h82.016c10.864 0 20.464-8.048 21.104-18.928.512-11.376-8.56-20.976-19.952-20.976h-86.88c-9.584 0-16.752-8.048-16.624-17.648v-.256-.256c-.128-9.584 7.024-17.648 16.624-17.648h90.912c10.736 0 20.336-8.048 20.976-18.8.512-11.504-8.56-20.976-19.952-20.976h-66.576C117.528 42 69.688 108.752 69.688 186.736s47.84 144.72 115.744 172.672h84.528c10.736 0 20.336-8.048 20.976-18.8.512-11.504-8.56-20.96-19.952-20.96l-96.16-.016c-11.344 0-20.48-9.488-19.872-20.976.56-10.752 10.224-18.832 20.992-18.832h37.28c10.72-.304 19.376-9.088 19.376-19.888 0-10.992-8.944-19.952-19.952-19.952h-56.512c-9.2 0-16.752-7.536-16.624-16.752v-1.408c-.64-9.072 6.656-16.752 15.728-16.752h124.816c10.864 0 20.464-8.048 21.104-18.8.512-11.488-8.576-20.96-19.952-20.96z"></path>
    <path
      fill="#FFDB70"
      d="M255.88 320.016c-73.504 0-133.296-59.808-133.296-133.296S182.376 53.44 255.88 53.44s133.296 59.808 133.296 133.296-59.792 133.28-133.296 133.28z"></path>
    <path
      fill="#FFF"
      d="M389.176 186.736c0-70.912-55.712-128.912-125.648-132.912h-12.416c-8.192 0-15.504 6.144-16 14.336-.384 8.768 6.528 16 15.216 16l73.344.016c8.656 0 15.616 7.232 15.152 16-.432 8.208-7.792 14.368-16.016 14.368h-28.432c-8.176.24-14.784 6.928-14.784 15.168 0 8.384 6.832 15.216 15.216 15.216h43.104c7.024 0 12.768 5.76 12.672 12.768v1.056c.48 6.928-5.072 12.768-12 12.768H243.4c-8.288 0-15.6 6.144-16.096 14.336-.384 8.768 6.528 16 15.216 16h90.24c7.024 0 12.768 5.76 12.768 12.768l-.096.88v.192c.096 7.408-5.952 13.456-13.36 13.456h-62.56c-8.288 0-15.6 6.144-16.096 14.432-.384 8.672 6.528 16 15.216 16h66.256c7.312 0 12.768 6.144 12.672 13.456v.384c.096 7.312-5.36 13.456-12.672 13.456H265.56c-8.192 0-15.504 6.144-16 14.336-.384 8.784 6.528 16 15.216 16h18.288c60.528-12.608 106.112-66.32 106.112-130.48z"
      enableBackground="new"
      opacity="0.2"></path>
    <path
      fill="#EC9922"
      d="M348.936 157.216h-64.832l-20.048-61.664c-2.576-7.92-13.792-7.92-16.368 0l-20.032 61.664h-64.832c-8.336 0-11.808 10.672-5.056 15.568l52.448 38.112-20.032 61.664c-2.576 7.92 6.496 14.512 13.248 9.616l52.448-38.112 52.448 38.112c6.752 4.896 15.824-1.696 13.248-9.616l-20.032-61.664 52.448-38.112c6.736-4.896 3.28-15.568-5.056-15.568z"></path>
  </>
);

const Medal = (props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 511.76 511.76"
    enableBackground="new 0 0 511.76 511.76"
    xmlSpace="preserve"
    {...props}>
    <MedalSvg />
  </SvgIcon>
);

export default Medal;
