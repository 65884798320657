import configService from './config';
import fetchApi from './fetchApi';
import guveryFetchApi from './guveryFetchApi';
import * as METHODS from './methods';

const baseUrl = configService.apiUrl;
const guveryApi = configService.apiGuvery;

const wallet = async () => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/user/wallet`,
    options: METHODS.GET(),
    message: {
      success: 'Billetera obtenida con exito.',
      error: 'No se puedo obtener la billetera.',
    },
  });

  return { ...response };
};

const refunds = async () => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/user/refunds`,
    options: METHODS.GET(),
    message: {
      success: 'Billetera obtenida con exito.',
      error: 'No se puedo obtener la billetera.',
    },
  });

  return { ...response };
};

const requestRefund = async (data: any) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/user/refunds/request`,
    options: METHODS.POST({
      bank: data.bank,
      bank_account_number: data.bank_account_number,
      bank_account_holder: data.bank_account_holder,
      currency: data.currency,
    }),
    message: {
      success: 'Reembolso solicitado.',
      error: 'Ocurrio un error al solicitar.',
    },
  });

  return { ...response };
};

const address = async () => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/user/addresses`,
    options: METHODS.GET(),
    message: {
      success: 'Direcciones obtenidas con exito.',
      error: 'No se puedo obtener las direcciones.',
    },
  });

  return { ...response };
};

const shipments = async () => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/user/shippings`,
    options: METHODS.GET(),
    message: {
      success: 'Envios obtenidos con exito.',
      error: 'No se puedo obtener los envios.',
    },
  });

  return { ...response };
};

const updateData = async (arg) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/user/profile`,
    options: METHODS.PUT({
      user_id: arg.idu,
      first_name: arg.nom,
      last_name: arg.ape,
      whatsapp: arg.wsp,
    }),
    message: {
      success: 'Tus datos se han actualizado correctamente.',
      error: 'Ocurrio un error al actualizar tus datos.',
    },
  });
  return { ...response };
};

const updateProfileImage = async (arg) => {
  const formData = new FormData();
  formData.append('idu', arg.idu);
  formData.append('imagen', arg.img);

  const { response } = await fetchApi({
    url: baseUrl + '/post_usuario_imagen',
    options: METHODS.POST_FORM_DATA(formData),
    dataType: {},
    message: {
      success: 'Imagen actualizada.',
      error: 'No se pudo actualizar la imagen.',
    },
  });

  return { ...response };
};

const addAddress = async (arg) => {
  const { response } = await fetchApi({
    url: baseUrl + '/post_usuario_direccion',
    options: METHODS.POST({
      first_name: arg.name,
      last_name: arg.lastName,
      whatsapp: arg.phone,
      address_1: arg.address,
      address_2: arg.address2,
      district: arg.district,
      province: arg.province,
      department: arg.department,
      is_primary_address: arg.isPrimary,
    }),
    dataType: {},
    message: {
      success: 'Tu dirección se agregó correctamente.',
      error: 'Ocurrio un error al agregar sus datos.',
    },
  });

  return { ...response };
};

const addTravelerSettings = async (arg) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/user/profile/extra`,
    options: METHODS.PUT({
      country_residence: arg.pais_residencia,
      document_type: arg.tipo_documento,
      document_number: arg.numero_documento,
    }),
    message: {
      success: 'Tus datos se han actualizado correctamente.',
      error: 'Ocurrio un error al actualizar tus datos.',
    },
  });
  return { ...response };
};

const addPaymentShipping = async (arg) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/user/shipping/paying`,
    options: METHODS.POST({
      id: arg.id,
      bank: arg.bank,
      currency: arg.currency,
      amount: arg.amount,
    }),
    message: {
      success: 'Envío actualizado.',
      error: 'Envío no existe o no se puede pagar.',
    },
  });

  return { ...response };
};

const addPaymentWallet = async (arg) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/user/wallet/recharge`,
    options: METHODS.POST({
      bank: arg.bank,
      currency: arg.currency,
      amount: arg.amount,
      zipago_bank: arg.zipago_bank,
      zipago_fee: arg.zipago_fee,
    }),
    message: {
      success: 'El envío actualizado.',
      error: 'El envío no existe o no se puede pagar.',
    },
  });

  return { ...response };
};

const coupons = async () => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/user/coupons`,
    options: METHODS.GET(),
    message: {
      success: 'Cupones obtenidos con exito.',
      error: 'No se puedo obtener los cupones.',
    },
  });

  return { ...response };
};

const notificationsClose = async () => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/user/notifications/close_all`,
    options: METHODS.GET(),
    message: {
      success: 'Cupones obtenidos con exito.',
      error: 'No se puedo obtener los cupones.',
    },
  });

  return { ...response };
};

const userService = {
  wallet,
  refunds,
  requestRefund,
  address,
  shipments,
  updateData,
  updateProfileImage,
  addAddress,
  addTravelerSettings,
  addPaymentShipping,
  addPaymentWallet,
  coupons,
  notificationsClose,
};

export default userService;
