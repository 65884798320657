import { InputBaseClassKey, StyleRules } from '@material-ui/core';

const MuiInputBase: Partial<StyleRules<InputBaseClassKey, {}>> = {
  root: {
    fontWeight: 700,
    fontSize: '1rem',
    '&.Mui-disabled': {
      color: '#637381',
      backgroundColor: '#ecf1f4',
    },
  },
};

export default MuiInputBase;
