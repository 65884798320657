import { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';

import { Package } from '#root/assets';
import styles from './empty.module.scss';

export interface EmptyProps {
  className?: string;
  style?: CSSProperties;
  imageStyle?: CSSProperties;
  image?: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
}

const Empty = (props: EmptyProps) => {
  const {
    className,
    style,
    imageStyle,
    image = Package,
    description,
    children,
    ...restProps
  } = props;

  // const localDescription = 'Sin datos';
  // const des = typeof description !== 'undefined' ? description : localDescription;

  let imageNode: ReactNode = null;

  if (typeof image === 'string') {
    imageNode = <img alt="empty" src={image} />;
  } else {
    imageNode = image;
  }

  return (
    <div className={clsx(styles.root, className)} style={style} {...restProps}>
      <div className={styles.image} style={imageStyle}>
        {imageNode}
      </div>
      {description && <div className={styles.desc}>{description}</div>}
      {children && <div className={styles.footer}>{children}</div>}
    </div>
  );
};

export default Empty;
