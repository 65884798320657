import { useState, useEffect, MouseEventHandler } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { AppBar, Avatar, Badge, Button, IconButton, Toolbar } from '@material-ui/core';
import { IconMenu2, IconLayoutGrid } from '@tabler/icons';
import clsx from 'clsx';

import { LogoGuverySM } from '#root/assets';
import { UserDropdownMenu, NotificationPopover, ServicesPopover } from '#root/components';
import { GuverySpace } from '#root/ui-components';
import * as ROUTES from '#root/constants/navigation';
import * as VARIABLES from '#root/constants/variables';
import { useResponsive, useAnchorEl, useAuth } from '#root/hooks';
import {
  BellOutlineIcon,
  MessageSquareIcon,
  PersonIcon,
  CloseIcon,
  ShieldIcon,
} from '#root/icons';
// import * as ROUTES from '#root/navigation/CONSTANTS';
import { authService } from '#root/services';

import styles from './travelerNavbar.module.scss';

interface TravelerNavbarProps {
  onMobileNavChange?: MouseEventHandler<HTMLButtonElement>;
  openMobile: boolean;
}

const TravelerNavbar = (props: TravelerNavbarProps) => {
  const { openMobile, onMobileNavChange, ...rest } = props;
  const { usuario, global_is_admin, setSessionData, removeAuth } = useAuth();
  const { isMobile, isDesktop } = useResponsive();
  const userMenu = useAnchorEl();
  const notification = useAnchorEl();
  const services = useAnchorEl();
  const location = useLocation();
  const [chatsCount, setChatsCount] = useState(0);
  const [notificationArray, setNotificationArray] = useState([]);
  const notiViewArray = notificationArray.filter(
    (noti) => noti.est === 0 || noti.est === 1,
  );

  // TODO: Revisar respuesta del fetch
  const fetchAuthSession = async () => {
    const res = await authService.session({ idu: usuario.idu });
    if (!res.error) {
      setSessionData(res.data);
      setNotificationArray(res.data.notificaciones);
      setChatsCount(res.data.chat_channels_unseen_count);
    } else {
      removeAuth();
      // window.location.href = VARIABLES.URL_PHP_PAGE + 'usuario/ingreso';
    }
  };

  useEffect(() => {
    userMenu.onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    fetchAuthSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Toolbar>
      {!isDesktop && (
        <IconButton
          className={styles.menuButton}
          color="inherit"
          size="small"
          onClick={onMobileNavChange}>
          {openMobile ? <CloseIcon /> : <IconMenu2 />}
        </IconButton>
      )}
      <div className={styles.logoWrap}>
        <a href={VARIABLES.URL_PHP_PAGE} className="guv-no-decoration guv-block">
          <img
            alt="Logo"
            className={styles.logoImage}
            style={{ width: isDesktop ? '112px' : '96px' }}
            src={LogoGuverySM}
          />
        </a>
      </div>

      <div className={styles.space} />
      <GuverySpace size={isMobile ? 5 : 10}>
        {!isMobile && global_is_admin && (
          <div className="guv-effect-grow">
            <IconButton
              color="inherit"
              component={'a'}
              size="small"
              href={VARIABLES.URL_PHP_PAGE + 'admin'}
              className={clsx(styles.item, styles.adminBtn)}>
              <ShieldIcon />
            </IconButton>
          </div>
        )}
        {isMobile ? (
          <div className="guv-effect-grow">
            <IconButton
              color="inherit"
              size="small"
              className={styles.item}
              onClick={userMenu.onChange}>
              <Badge badgeContent={chatsCount} color="error" overlap="rectangular">
                <Avatar
                  src={VARIABLES.IMAGE_PROFILE_PATH + usuario.pic + '.png'}
                  className={styles.avatar}
                  alt={usuario.nom}>
                  <PersonIcon />
                </Avatar>
              </Badge>
            </IconButton>
          </div>
        ) : (
          <Button
            aria-controls="user-menu"
            aria-haspopup="true"
            color="inherit"
            onClick={userMenu.onChange}
            className={clsx([
              styles.avatarButton,
              Boolean(userMenu.anchorEl) && styles.avatarButtonActive,
            ])}>
            <Avatar
              src={VARIABLES.IMAGE_PROFILE_PATH + usuario.pic + '.png'}
              className={styles.avatar}
              alt={usuario.nom}>
              <PersonIcon />
            </Avatar>
            <span className={styles.userName}>{usuario.nom}</span>
          </Button>
        )}
        {!isMobile && (
          <div className="guv-effect-grow">
            <IconButton
              color="inherit"
              size="small"
              component={RouterLink}
              to={ROUTES.USER_CHAT}
              className={styles.item}>
              <Badge badgeContent={chatsCount} color="error" overlap="rectangular">
                <MessageSquareIcon />
              </Badge>
            </IconButton>
          </div>
        )}
        <div className="guv-effect-grow">
          <IconButton
            color="inherit"
            size="small"
            className={clsx(
              styles.item,
              Boolean(notification.anchorEl) && styles.bellButtonActive,
              { [styles.itemMobile]: isMobile },
            )}
            onClick={notification.onChange}>
            <Badge badgeContent={notiViewArray.length} color="error" overlap="rectangular">
              <BellOutlineIcon />
            </Badge>
          </IconButton>
        </div>
        <div className="guv-effect-grow">
          <IconButton
            className={styles.item}
            size="small"
            color="inherit"
            onClick={services.onChange}>
            <IconLayoutGrid strokeWidth={1.5} />
          </IconButton>
        </div>
      </GuverySpace>
    </Toolbar>
  );

  const appBarStyles = isDesktop
    ? {
        background: 'var(--color-background-base)',
        backdropFilter: 'blur(8px)',
        boxShadow: 'none',
        width: '100%',
        borderBottom: '1px solid rgba(135,153,160,0.25)',
      }
    : {
        background: 'var(--color-background-base)',
        boxShadow: 'none',
        borderBottom: '1px solid rgba(135,153,160,0.25)',
        zIndex: 1300,
      };

  return (
    <>
      <AppBar color="default" elevation={0} style={appBarStyles} {...rest}>
        {content}
      </AppBar>
      <UserDropdownMenu
        // id="user-menu"
        chatsCount={chatsCount}
        anchorEl={userMenu.anchorEl}
        onClose={userMenu.onClose}
        open={Boolean(userMenu.anchorEl)}
      />
      <NotificationPopover
        anchorEl={notification.anchorEl}
        data={notificationArray}
        onClose={notification.onClose}
        fetchLoading={fetchAuthSession}
      />
      <ServicesPopover anchorEl={services.anchorEl} onClose={services.onClose} />
    </>
  );
};

export default TravelerNavbar;
