import React, { MouseEventHandler } from 'react';
import { Dialog, Popover } from '@material-ui/core';
import clsx from 'clsx';

import { useResponsive } from '#root/hooks';
import { OneLogoSm, CanguroGuvery, Calculator, Coupon, ShoppingBag, Love } from '#root/assets';
import styles from './servicesPopover.module.scss';
import * as VARIABLES from '#root/constants/variables';

const ItemLink = (props) => {
  const { icon, text, color, link } = props;
  return (
    <div className={styles.wrap}>
      <a className={styles.link} href={link}>
        <div className={clsx(styles.icon, styles[color])}>{icon}</div>
        <p className={styles.text}>{text}</p>
      </a>
    </div>
  );
};

interface ServicesPopoverProps {
  anchorEl: any;
  onClose: MouseEventHandler<HTMLButtonElement>;
}

const ServicesPopover = (props: ServicesPopoverProps) => {
  const { anchorEl, onClose } = props;
  const { isMobile } = useResponsive();
  const open = Boolean(anchorEl);
  const id = open ? 'services-popover' : undefined;

  const content = (
    <div className={styles.box}>
      <div className={styles.container}>
        <h4 className={styles.title}>Accesos directos</h4>
        <div className={styles.group}>
          <ItemLink
            icon={<img src={CanguroGuvery} alt="guvery" />}
            color="guvery"
            text="Guvery"
            link={VARIABLES.URL_PHP_PAGE}
          />
          <ItemLink
            icon={<img src={ShoppingBag} alt="Guvery Tienda" />}
            color="tienda"
            text="Tienda"
            link={VARIABLES.URL_PHP_PAGE + 'tienda'}
          />
          <ItemLink
            icon={<img src={OneLogoSm} alt="ONE Logo" />}
            color="one"
            text="One"
            link={VARIABLES.URL_PHP_PAGE + 'one'}
          />
          <ItemLink
            icon={<img src={Love} alt="Guvery Testimonios" />}
            color="test"
            text="Testimonios"
            link={VARIABLES.URL_PHP_PAGE + 'testimonios'}
          />
          <ItemLink
            icon={<img src={Calculator} alt="Guvery Calculadora" />}
            color="calculadora"
            text="Calculadora"
            link={VARIABLES.URL_PHP_PAGE + 'calculadora'}
          />
          <ItemLink
            icon={<img src={Coupon} alt="Guvery Cupon" />}
            color="coupons"
            text="Cupones"
            link={VARIABLES.URL_PHP_PAGE + 'cupones'}
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      {!isMobile ? (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{ className: styles.servicesPaper }}>
          <span className={styles.arrow}></span>
          {content}
        </Popover>
      ) : (
        <Dialog fullWidth open={open} onClose={onClose}>
          {content}
        </Dialog>
      )}
    </>
  );
};

export default ServicesPopover;
