import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarOrigin } from '@material-ui/core/Snackbar';

import {
  NotificationVariant,
  NotificationSeverity,
  NotificationTransition,
} from '#root/interfaces/NotificationReducer';
import { NOTIFICATION_OPEN, NOTIFICATION_CLOSED } from '#root/store/actions';
import {
  NotificationOpenAction,
  NotificationClosedAction,
} from '#root/store/notificationReducer';

export interface ArgsProps {
  duration?: number;
  message: ReactNode | string;
  icon?: ReactNode;
  anchorOrigin?: SnackbarOrigin;
  variant?: NotificationVariant;
  severity?: NotificationSeverity;
  transition?: NotificationTransition;
  close?: boolean;
  actionButton?: boolean;
}

type ConfigContent = ReactNode | string;
type ConfigDuration = number | (() => void);
type JointContent = ConfigContent | ArgsProps;

export interface MessageInstance {
  snackbar(
    content: JointContent,
    severity?: NotificationSeverity,
    duration?: ConfigDuration,
  ): void;
  alert(
    content: JointContent,
    severity?: NotificationSeverity,
    duration?: ConfigDuration,
  ): void;
  banner(
    content: JointContent,
    severity?: NotificationSeverity,
    duration?: ConfigDuration,
  ): void;
  close(): void;
  // loading(content: JointContent, severity?: NotificationSeverity, duration?: ConfigDuration): void;
}

function isArgsProps(message: JointContent): message is ArgsProps {
  return (
    Object.prototype.toString.call(message) === '[object Object]' &&
    !!(message as ArgsProps).message
  );
}

const useGlobalNotification = () => {
  const dispatch = useDispatch();

  const closeMessage = () => {
    return dispatch<NotificationClosedAction>({
      type: NOTIFICATION_CLOSED,
    });
  };

  const setMessageConfig = (content: ArgsProps) => {
    return dispatch<NotificationOpenAction>({
      type: NOTIFICATION_OPEN,
      open: true,
      close: false,
      ...content,
    });
  };

  function attachTypeApi(
    content: JointContent,
    variant: NotificationVariant,
    severity: NotificationSeverity,
  ) {
    if (isArgsProps(content)) {
      return setMessageConfig({
        ...content,
        variant: variant,
      });
    }

    return setMessageConfig({
      message: content,
      variant: variant,
      severity: severity,
    });
  }

  const snackbar = (content: JointContent, severity: NotificationSeverity) => {
    attachTypeApi(content, 'default', severity);
  };

  const alert = (content: JointContent, severity: NotificationSeverity) => {
    attachTypeApi(content, 'alert', severity);
  };

  const banner = (content: JointContent, severity: NotificationSeverity) => {
    attachTypeApi(content, 'banner', severity);
  };

  const close = () => {
    closeMessage();
  };

  const createMessage: MessageInstance = {
    snackbar,
    alert,
    banner,
    close,
  };

  return createMessage;
};

export default useGlobalNotification;
