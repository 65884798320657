import { ReactNode, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import ArrowBack from '#root/icons/ArrowBack';

import styles from './pageHeader.module.scss';

interface PageHeaderProps {
  title: ReactNode | string;
  subTitle?: ReactNode | string;
  extra?: ReactNode | ReactNode[] | null;
  backButton?: boolean;
  onBack?: () => void;
}

const PageHeader = (props: PageHeaderProps) => {
  const { title, subTitle, extra, backButton = false, onBack } = props;
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        {backButton && (
          <IconButton color="secondary" size="small" onClick={onBack ? onBack : handleGoBack}>
            <ArrowBack />
          </IconButton>
        )}
        <div className={styles.box}>
          <h2 className={styles.title}>{title}</h2>
          
        </div>
        {extra && (
          <div className={styles.action}>{Array.isArray(extra) ? (
            extra.map((element, index) => (
              <Fragment key={index}>{element}</Fragment>
            ))
        ) : (
          <>{extra}</>
        )}</div>
        )}
      </div>
      {subTitle && <span className={styles.subTitle}>{subTitle}</span>}
    </div>
  );
};

export default PageHeader;
