import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { SparksIcon } from '#root/icons';

import { roundDecimal } from '#root/utils';
import { GuveryDialog } from '#root/ui-components';
import styles from './priceDialog.module.scss';

interface PriceDialogProps {
  open: boolean;
  onClose: () => void;
  deal: any;
}

const PriceDialog = (props: PriceDialogProps) => {
  const { open = false, onClose, deal } = props;
  const priceReal = deal.pretot ? Number(deal.pretot) : 0;
  const comTraveler = deal.comtot ? Number(deal.comtot) : 0;
  const total = priceReal + comTraveler;

  return (
    <GuveryDialog
      open={open}
      onClose={onClose}
      content={false}
      title="Detalle del total"
      closeText="Cerrar">
      <TableContainer>
        <Table className={styles.table}>
          <TableHead className={styles.header}>
            <TableRow>
              <TableCell>Producto</TableCell>
              <TableCell>Precio Real</TableCell>
              <TableCell>Viajero</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deal?.deal_orders?.map((order, index) => (
              <TableRow key={index}>
                <TableCell>
                  <span className={styles.name}>
                    <span>{`${order.can} x`}</span>
                    <a
                      className={styles.link}
                      href={order.url}
                      target="_blank"
                      rel="noopener noreferrer">
                      <span>{order.fixed_url}</span>
                    </a>
                  </span>
                </TableCell>
                <TableCell>$ {order.pre ? roundDecimal(order.pre) : '---'}</TableCell>
                <TableCell>$ {order.com ? roundDecimal(order.com) : '---'}</TableCell>
                <TableCell>
                  $ {order.pre ? roundDecimal(order.pre + order.com) : '---'}
                </TableCell>
              </TableRow>
            ))}
            {deal.guvery_match_fee && (
              <TableRow>
              <TableCell>
              <span style={{ paddingLeft: 24 }}>Match <SparksIcon inlineDefault /></span>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                - $ {roundDecimal(deal.guvery_match_fee)}
              </TableCell>
            </TableRow>
            )}
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <span className={styles.total}>Total</span>
              </TableCell>
              <TableCell>
                <span className={styles.total}>$ {roundDecimal(deal.total_traveler)}</span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </GuveryDialog>
  );
};

export default PriceDialog;
