import React from 'react';
import {
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  TextField,
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { IconEdit } from '@tabler/icons';
import { format, isValid, compareAsc, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import clsx from 'clsx';

import { shopperService, travelerService } from '#root/services';
import { GuveryDialog } from '#root/ui-components';
import styles from './ratingDialog.module.scss';

const labels: { [index: string]: string } = {
  0.5: 'Muy Malo',
  1: 'Muy Malo',
  1.5: 'Malo',
  2: 'Malo',
  2.5: 'Regular',
  3: 'Regular',
  3.5: 'Bueno',
  4: 'Bueno',
  4.5: 'Excelente',
  5: 'Excelente',
};

const getMinorDate = (date1, date2) => {
  if (!date1) {
    return date2;
  }
  if (!date2) {
    return date1;
  }
  if (!isValid(date1) || !isValid(date2)) {
    return null;
  }
  const replaceDate1 = date1?.replace(/ /g, 'T');
  const replaceDate2 = date2?.replace(/ /g, 'T');

  const validDate1 = new Date(replaceDate1);
  const validDate2 = new Date(replaceDate2);

  const result = compareAsc(validDate1, validDate2);

  if (result === 1) {
    return date2;
  } else {
    return date1;
  }
};

const formatTime = (date) => {
  if (!isValid(parseISO(date))) {
    return '...';
  }
  const replaceDate = date?.replace(/ /g, 'T');
  const validDate = new Date(replaceDate);
  return format(validDate, "dd'/'MM'/'YYY", { locale: es });
};

const RatingDialog = (props) => {
  const {
    id,
    ratingId,
    open = false,
    onClose,
    ratingType = 'shopper',
    title,
    date1,
    date2,
    hint,
    userData,
    handleLoading,
    createMessage,
    confirmText,
  } = props;
  const [disableSend, setDisableSend] = React.useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [score, setScore] = React.useState<number | null>(0);
  const [hover, setHover] = React.useState(-1);
  const [opinion, setOpinion] = React.useState('');

  const time = getMinorDate(date1, date2);
  const displayTime = time ? formatTime(time) : '...';

  const handleExpandedChange = (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(true);
  };

  const handleOpinionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpinion(event.target.value);
  };

  const handleSend = () => {
    const arg = {
      deal_id: id,
      rating_id: ratingId,
      score: score,
      opinion: opinion,
    };
    if (ratingType === 'shopper') {
      fetchRatingShopper(arg);
      return;
    }
    if (ratingType === 'traveler') {
      fetchRatingTraveler(arg);
      return;
    }
  };

  const fetchRatingShopper = async (arg) => {
    setDisableSend(true);
    const res = await shopperService.dealRating(arg);
    if (res.error) {
      createMessage.error('No se pudo enviar la calificación');
      setDisableSend(false);
      return;
    }
    createMessage.success('Gracias por tu calificación');
    handleLoading();
    onClose();
  };

  const fetchRatingTraveler = async (arg) => {
    setDisableSend(true);
    const res = await travelerService.dealRating(arg);
    if (res.error) {
      createMessage.error('No se pudo enviar la calificación');
      setDisableSend(false);
      return;
    }
    createMessage.success('Gracias por tu calificación');
    handleLoading();
    onClose();
  };

  return (
    <GuveryDialog disableActions content={false} title={title} open={open} onClose={onClose}>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.user}>
            <Avatar className={styles.avatar} src={userData?.picture_path}></Avatar>
            <h4 className={styles.name}>{userData?.one_fullname}</h4>
          </div>
          <p className={styles.desc}>
            Por el acuerdo #{id}, entregado el {displayTime} aprox.
          </p>
          <div className={styles.score}>
            <Rating
              className={styles.rating}
              name="score"
              size="large"
              value={score}
              onChange={(event, newValue) => {
                setScore(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
            />
            {score !== null && (
              <div className={styles.text}>{labels[hover !== -1 ? hover : score]}</div>
            )}
          </div>
          <div className={styles.form}>
            <Accordion
              className={clsx(styles.accordion, { [styles.expanded]: expanded })}
              expanded={expanded}
              onChange={handleExpandedChange}>
              <AccordionSummary
                className={clsx(styles.summary)}
                expandIcon={!expanded && <IconEdit />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <h6 className={styles.heading}>Escribe una reseña (opcional)</h6>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  inputRef={(input) => input && input.focus()}
                  className={styles.field}
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  placeholder="..."
                  value={opinion}
                  onChange={handleOpinionChange}
                />
              </AccordionDetails>
            </Accordion>
          </div>
          <Button
            fullWidth
            disableElevation
            size="large"
            variant="contained"
            color="primary"
            disabled={disableSend}
            onClick={handleSend}>
            {confirmText ? confirmText : 'Calificar'}
          </Button>
          {!confirmText && (
            <Button
              fullWidth
              disableElevation
              size="large"
              variant="outlined"
              color="secondary"
              onClick={onClose}
              style={{ marginTop: 16 }}>
              Lo haré después
            </Button>
          )}
          {hint && <span className={styles.hint}>{hint}</span>}
        </div>
      </div>
    </GuveryDialog>
  );
};

export default RatingDialog;
