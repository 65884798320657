import SvgIcon from '@material-ui/core/SvgIcon';

const ArrowCircleLeftSvg = () => (
  <>
    <g data-name="Layer 2">
      <g data-name="arrow-circle-left">
        <path d="M16 11h-5.66l1.25-1.31a1 1 0 00-1.45-1.38l-2.86 3a1 1 0 00-.09.13.72.72 0 00-.11.19.88.88 0 00-.06.28L7 12a1 1 0 00.08.38 1 1 0 00.21.32l3 3a1 1 0 001.42 0 1 1 0 000-1.42L10.41 13H16a1 1 0 000-2z"></path>
        <path d="M12 2a10 10 0 1010 10A10 10 0 0012 2zm0 18a8 8 0 118-8 8 8 0 01-8 8z"></path>
      </g>
    </g>
  </>
);

const ArrowCircleLeft = (props) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <ArrowCircleLeftSvg />
  </SvgIcon>
);

export default ArrowCircleLeft;
