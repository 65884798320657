import configService from './config';
import fetchApi from './fetchApi';
import * as METHODS from './methods';

// const baseUrl = configService.apiUrl;
const guveryApi = configService.apiGuvery;

// const getIp = async () => {
//   const { response } = await fetchApi({
//     url: 'https://api.ipify.org/?format=json',
//     options: METHODS.GET_EXTERNAL(),
//     dataType: {},
//     message: {
//       success: 'IP Obtenida.',
//       error: 'No se pudo obtener el IP.',
//     },
//   });

//   return { ...response };
// };

// const getIpIncan = async () => {
//   const { response } = await fetchApi({
//     url: 'https://icanhazip.com/',
//     options: METHODS.GET_EXTERNAL_2(),
//     dataType: {},
//     message: {
//       success: 'IP Obtenida.',
//       error: 'No se pudo obtener el IP.',
//     },
//   });

//   return { ...response };
// };

const getIpGuvery = async (token_ip) => {
  const { response } = await fetchApi({
    // url: baseUrl + '/ip/ey_724.542sgejc.asge$!Rtsj938.x3',
    url: guveryApi + '/auth/ip/' + token_ip,
    options: METHODS.GET_EXTERNAL(),
    dataType: {},
    message: {
      success: 'IP Obtenida.',
      error: 'No se pudo obtener el IP.',
    },
  });

  return { ...response };
};

const ipifyService = {
  // getIp,
  // getIpIncan,
  getIpGuvery,
};

export default ipifyService;
