import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import { roundDecimal } from '#root/utils';
import { GuveryPaper } from '#root/ui-components/cards';
import styles from './dealTable.module.scss';

import { SparksIcon } from '#root/icons';

const DealTable = (props) => {
  const { deal = {} } = props;
  const priceReal = Number(deal?.pretot);
  const comTraveler = Number(deal?.comtot);
  const total = priceReal + comTraveler;

  return (
    <GuveryPaper disableHover disableElevation className={styles.card}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <span className={styles.headerTitle}>Detalle del acuerdo</span>
        </div>
      </div>
      <hr className={styles.divider} />
      <div className={styles.body}>
        <Table className={styles.table}>
          <TableHead className={styles.headerTable}>
            <TableRow>
              <TableCell>Producto</TableCell>
              <TableCell className="guv-ellipsis">Precio Real</TableCell>
              <TableCell>Viajero</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={styles.bodyTable}>
            {deal?.deal_orders?.map((order, index) => (
              <TableRow key={index}>
                <TableCell>
                  <span className={styles.name}>
                    <span>{`${order.can} x`}</span>
                    <a
                      className={styles.link}
                      href={order.url}
                      target="_blank"
                      rel="noopener noreferrer">
                      <span>{order.fixed_url}</span>
                    </a>
                  </span>
                </TableCell>
                <TableCell>$ {roundDecimal(order.pre)}</TableCell>
                <TableCell>$ {roundDecimal(order.com)}</TableCell>
                <TableCell>$ {roundDecimal(order.pre + order.com)}</TableCell>
              </TableRow>
            ))}
            {deal.guvery_match_fee && (
              <TableRow>
              <TableCell>
                <span style={{ paddingLeft: 24 }}>Match <SparksIcon inlineDefault /></span>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                - $ {roundDecimal(deal.guvery_match_fee)}
              </TableCell>
            </TableRow>
            )}
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <span className={styles.total}>Total</span>
              </TableCell>
              <TableCell>
                <span className={styles.total}>$ {roundDecimal(deal.total_traveler)}</span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </GuveryPaper>
  );
};

export default DealTable;
