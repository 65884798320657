import { Button } from '@material-ui/core';
import { IconTrash, IconTag } from '@tabler/icons';

import { useDialog } from '#root/hooks';
import { shopperService } from '#root/services';
import { AlertDialog } from '#root/ui-components';
import styles from './extraActions.module.scss';

const ExtraActions = (props) => {
  const { orderId, isExpired, isCourier, handleLoading, createMessage } = props;
  const alertDialog = useDialog();
  const alertRemoveCourier = useDialog();

  const handleConfirmExpiring = () => {
    fetchExpiring();
    alertDialog.closeDialog();
  };

  const handleRemoveCourier = () => {
    fetchRemoveCourier();
    alertRemoveCourier.closeDialog();
  };

  const fetchExpiring = async () => {
    const res = await shopperService.orderExpiring(orderId);
    if (res.error) {
      createMessage.error('No se pudo completar la acción');
      return;
    }
    createMessage.success('Pedido expirado.');
    handleLoading();
  };

  const fetchRemoveCourier = async () => {
    const res = await shopperService.orderRemoveCourier(orderId);
    if (res.error) {
      createMessage.error('No se pudo completar la acción');
      return;
    }
    createMessage.success('Pedido actualizado.');
    handleLoading();
  };

  return (
    <div className={styles.root}>
      {!isExpired && isCourier && (
        <Button
          className={styles.btn}
          startIcon={<IconTag size={20} color="var(--color-status-alert)" />}
          onClick={alertRemoveCourier.openDialog}>
          Quitar envío nacional
        </Button>
      )}
      {!isExpired && (
        <Button
          className={styles.btn}
          startIcon={<IconTrash size={20} color="var(--color-status-error)" />}
          onClick={alertDialog.openDialog}>
          Expirar Pedido
        </Button>
      )}
      <AlertDialog
        variant="error"
        open={alertRemoveCourier.open}
        onClose={alertRemoveCourier.closeDialog}
        message={
          <span>
            <span style={{ display: 'inline-block' }}>
              ¿Estás seguro que deseas cambiar el "Envio Nacional" por un recojo en Lima?
            </span>
            <span style={{ display: 'inline-block' }}>Esta acción no se puede deshacer.</span>
          </span>
        }
        title="Quitar envío nacional"
        onConfirm={handleRemoveCourier}
      />
      <AlertDialog
        variant="error"
        open={alertDialog.open}
        onClose={alertDialog.closeDialog}
        message={
          <span>
            <span style={{ display: 'inline-block' }}>
              ¿Está seguro de que desea expirar este pedido?
            </span>
            <span style={{ display: 'inline-block' }}>Esta acción no se puede deshacer.</span>
          </span>
        }
        title="Expirar Pedido"
        onConfirm={handleConfirmExpiring}
      />
    </div>
  );
};

export default ExtraActions;
