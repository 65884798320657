import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, TextField, Grid, MenuItem } from '@material-ui/core';
import { changeCurrencySymbol } from '#root/utils';
import styles from './refundDialogForma.module.scss';
import { userService } from '#root/services';
import { useNotification } from '#root/hooks';

export default function RefundDialogForm({ refundOptions, amount, onClose }) {
    const { createMessage } = useNotification();
  const fetchPostData = async (data) => {
    const body = {
      bank: data.bank,
      currency: data.currency,
      bank_account_number: data.bank_account_number.replace(/-/g, '').replace(/ /g, ''),
      bank_account_holder: data.bank_account_holder,
    };
    const res = await userService.requestRefund(body);
    if (res.error) {
      createMessage.error(res.message);
      return;
    }
    createMessage.success(res.message);
    onClose();
  };

  const formik = useFormik({
    initialValues: {
      bank: '',
      currency: '',
      bank_account_number: '',
      bank_account_holder: '',
    },
    validationSchema: Yup.object().shape({
      bank: Yup.string().required('Campo requerido'),
      currency: Yup.string().required('Campo requerido'),
      bank_account_number: Yup.string().min(8, '¡Demasiado corto!').required('Campo requerido'),
      bank_account_holder: Yup.string().min(4, '¡Demasiado corto!').required('Campo requerido'),
    }),
    onSubmit: (values) => {
      console.log(values);
      fetchPostData(values);
      formik.setSubmitting(false);
    },
  });
  return (
    <div>
      <div className={styles.badge}>
        <p>
          El monto a reembolsar será{' '}
          <strong>[ {changeCurrencySymbol('DOLARES') + ' ' + amount} ]</strong>.
        </p>
        <p>
          Recuerda colocar una cuenta bancaria en la misma moneda en la que hiciste el pago.
        </p>
      </div>
      <p style={{ marginBlock: 16, textAlign: 'center', color: 'var(--color-accent-darker)' }}>
        El tiempo en el que se procesa la solicitud es de{' '}
        {refundOptions?.processing_time?.min_days +
          ' - ' +
          refundOptions?.processing_time?.max_days}{' '}
        días útiles.
      </p>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={6} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Banco"
              select
              id="bank"
              name="bank"
              value={formik.values.bank}
              onChange={formik.handleChange}
              error={Boolean(formik.touched.bank && formik.errors.bank)}
              helperText={formik.errors.bank}>
              {refundOptions.banks?.map((bank, index) => (
                <MenuItem key={index} value={bank}>
                  {bank}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Moneda"
              select
              id="currency"
              name="currency"
              value={formik.values.currency}
              onChange={formik.handleChange}
              error={Boolean(formik.touched.currency && formik.errors.currency)}
              helperText={formik.errors.currency}>
              {refundOptions.currencies?.map((currency, index) => (
                <MenuItem key={index} value={currency}>
                  {currency}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Número de cuenta"
              id="bank_account_number"
              value={formik.values.bank_account_number}
              onChange={formik.handleChange}
              error={Boolean(
                formik.touched.bank_account_number && formik.errors.bank_account_number,
              )}
              helperText={formik.errors.bank_account_number}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Titular"
              id="bank_account_holder"
              value={formik.values.bank_account_holder}
              onChange={formik.handleChange}
              error={Boolean(
                formik.touched.bank_account_holder && formik.errors.bank_account_holder,
              )}
              helperText={formik.errors.bank_account_holder}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              disableElevation
              disabled={formik.isSubmitting}
              type="submit"
              variant="contained"
              color="primary">
              Confirmar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
