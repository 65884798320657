import { withStyles } from '@material-ui/core/styles';
import { StepConnector } from '@material-ui/core';

const Connector = withStyles({
  root: {
    top: 10,
    left: 'calc(-50% + 5px)',
    right: 'calc(50% + 5px)',
  },
  active: {
    '& $line': {
      borderColor: 'var(--color-primary)',
    },
  },
  completed: {
    '& $line': {
      borderColor: 'var(--color-primary)',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

export default Connector;
