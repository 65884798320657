import { Link as RouterLink } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';

import NotesDTO from '#root/models/NotesDTO';
import { GuveryMainCard } from '#root/ui-components/cards';
import styles from './notes.module.scss';

interface NotesProps {
  notes: NotesDTO[];
  loading?: boolean;
}

const Notes = (props: NotesProps) => {
  const { notes = [], loading = false } = props;

  const list = notes?.map((item, index) => (
    <RouterLink key={index} to={`/${item?.link}`} className={styles.link}>
      <div className={styles.item}>
        <div className={styles.title}>
          <span className={styles.dot}></span>
          <span>{item?.type}</span>
        </div>
        <div className={styles.text}>{item?.text}</div>
      </div>
    </RouterLink>
  ));

  if (loading) {
    return (
      <GuveryMainCard title="Notas" content={false}>
        <div className={styles.list}>
          {Array.from(new Array(2)).map((item, index) => (
            <Skeleton key={index} variant="rect" height="70px" className={styles.itemLoading} />
          ))}
        </div>
      </GuveryMainCard>
    );
  }

  return (
    <GuveryMainCard title="Notas" content={false}>
      <div className={styles.list}>
        {notes?.length > 0 ? (
          list
        ) : (
          <div className={styles.empty}>No se encontraron notas.</div>
        )}
      </div>
    </GuveryMainCard>
  );
};

export default Notes;
