import { useState } from 'react';
import { useDialog } from '#root/hooks';
import { Button, Tooltip, Grid, ClickAwayListener } from '@material-ui/core';
import {
  IconTruckDelivery,
  IconCreditCard,
  IconCalendarEvent,
  IconPackage,
} from '@tabler/icons';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import clsx from 'clsx';
import { GUVERY_WHATSAPP_NUMBER_FULL } from '#root/constants/variables';

import { ShippingStatus } from '#root/models/Shipping';
import { useResponsive } from '#root/hooks';
import { changeCurrencySymbol } from '#root/utils';
import { GuveryPaper } from '#root/ui-components/cards';
import { GuveryDialog } from '#root/ui-components';
import styles from './shipmentCard.module.scss';

const BadgeLabel = ({ status, paid_at, shipped_at, is_closed_debited = false }) => {
  const variantStatus = {
    CREADO: 'Creado',
    REGISTRADO: 'Registrado',
    POR_RECOGER: 'Para recoger...',
    RECOGIDO: 'Recogido',
    CONFIRMADO: 'Confirmado',
    PROCESANDO: 'Procesando...',
    READY: 'Listo para despacho...',
    DESPACHADO: 'Enviado',
    PAGADO_COMPRADOR: 'Validando Pago...',
    PAGADO_ADMIN: 'Pagado',
    PAGADO_ORDEN: 'Pagado',
    CERRADO: 'Cerrado',
  };
  const extraStatus = {
    REGISTRADO: 'Debes seleccionar tu dirección de envío.',
    POR_RECOGER: 'Acércate a recoger tu pedido con tu Documentio de Identidad.',
    CONFIRMADO: 'Se iniciará el embalaje y procesamiento en breve.',
    PROCESANDO: 'Se está embalando y registrando. Pronto lo podrás pagar.',
    READY: 'Confirma el pago para que pueda ser despachado.',
  };
  let label = variantStatus[status] !== undefined && variantStatus[status];

  let extraLabel = extraStatus[status] !== undefined && extraStatus[status];
  if (paid_at && shipped_at) label = 'Despachado';
  if (paid_at && !shipped_at) label = 'Pagado. Despachandose...';
  if (!paid_at && shipped_at) label = 'Falta pagar';
  if (is_closed_debited) label = 'Procesado y cerrado';

  const label_red =
    status === 'REGISTRADO' ||
    status === 'POR_RECOGER' ||
    status === 'READY' ||
    (!paid_at && shipped_at)
      ? 'RED_LABEL'
      : '';

  return (
    <div>
      <span className={clsx(styles.statusBadge, styles[label_red])}>{label}</span>
      {extraLabel && (
        <span className={clsx(styles.statusBadge, styles['extra'])}>{extraLabel}</span>
      )}
    </div>
  );
};

const ShipInfo = ({ title, icon, content }) => (
  <div className={styles.shipInfo}>
    <p className={styles.title}>{title}</p>
    <div className={styles.content}>
      {icon}
      <h6 className={styles.text}>{content}</h6>
    </div>
  </div>
);

const ShipmentCard = (props) => {
  const pickupDialog = useDialog();
  const { shipment, handleOpenPaymentDialog, handleShipmentDetail } = props;
  const { isMobile } = useResponsive();
  const [openTool, setOpenToool] = useState(false);

  const handleTooltipClose = () => {
    setOpenToool(false);
  };

  const handleTooltipOpen = () => {
    setOpenToool(true);
  };

  const openPickupDialog = () => {
    pickupDialog.openDialog();
  };

  const address = shipment?.delivery_address?.address_1;
  const adrDepartment = shipment?.delivery_address?.department;
  const adrDistrict = shipment?.delivery_address?.district;

  const dateShipped = shipment.shipped_at
    ? format(new Date(shipment.shipped_at.replace(/ /g, 'T')), "dd 'de' MMMM 'del' yyyy", {
        locale: es,
      })
    : '';

  const dateClosedDebited =
    shipment?.is_closed_debited && shipment?.closed_at
      ? format(new Date(shipment.closed_at.replace(/ /g, 'T')), "dd 'de' MMMM 'del' yyyy", {
          locale: es,
        })
      : '';

  const currency = shipment.currency && changeCurrencySymbol(shipment.currency, 'Symbol');

  const isCreated = shipment?.current_status === ShippingStatus.created;
  const isRegistered = shipment?.current_status === ShippingStatus.registered;
  const isForPickup = shipment?.current_status === ShippingStatus.for_pickup;
  const isPicked = shipment?.current_status === ShippingStatus.picked;
  const isConfirmed = shipment?.current_status === ShippingStatus.confirmed;
  const isProcesando = shipment?.current_status === ShippingStatus.processing;
  const isReady = shipment?.current_status === ShippingStatus.ready;
  const isShipped = shipment?.current_status === ShippingStatus.shipped;
  const isClosedDebited = shipment?.is_closed_debited;

  const needPayment = (isReady || isShipped || isClosedDebited) && !shipment.paid_at;

  const isPay =
    shipment?.current_status === ShippingStatus.paid ||
    shipment?.current_status === ShippingStatus.paid_order ||
    shipment?.current_status === ShippingStatus.paying;

  const addressNode = (
    <span>
      <span>{address}, </span>
      <span className="guv-force-capitalize">{adrDepartment}, </span>
      <span className="guv-force-capitalize">{adrDistrict}</span>
    </span>
  );

  return (
    <GuveryPaper disableHover>
      <div className={styles.root}>
        {/*
        {!isMobile && (
          <div className={styles.expandButton}>
            <IconButton size="small">
              <IconChevronDown />
            </IconButton>
          </div>
        )}
        */}
        <div className={styles.body}>
          <div className={styles.header}>
            <div className={styles.headerTitle}>
              {(isRegistered || needPayment) && <span className={styles.dot}></span>}
              <h4 className={styles.id}>#{shipment.id}</h4>
              <BadgeLabel
                status={shipment?.current_status}
                paid_at={shipment?.paid_at}
                shipped_at={shipment?.shipped_at}
                is_closed_debited={shipment?.is_closed_debited}
              />
            </div>
            {isMobile && (
              <div className={styles.headerPrice}>
                {shipment?.amount && (
                  <div className={styles.price}>
                    <h4>{currency + ' ' + shipment.amount}</h4>
                  </div>
                )}
              </div>
            )}
          </div>
          <Grid container spacing={2}>
            {!isForPickup && !isPicked && (
              <>
                <Grid item xs={12} sm={6}>
                  {dateClosedDebited ? (
                    <ShipInfo
                      title="Fecha de envío"
                      icon={<IconCalendarEvent size={18} stroke={1.5} />}
                      content={<span>{dateClosedDebited}</span>}
                    />
                  ) : (
                    <ShipInfo
                      title="Fecha de envío"
                      icon={<IconCalendarEvent size={18} stroke={1.5} />}
                      content={<span>{dateShipped ? dateShipped : '...'}</span>}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ShipInfo
                    title="Destino"
                    icon={<IconTruckDelivery size={18} stroke={1.5} />}
                    content={
                      <ClickAwayListener onClickAway={handleTooltipClose}>
                        <span className="guv-block">
                          {adrDepartment ? (
                            <Tooltip
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              arrow
                              title={addressNode}
                              open={openTool}
                              onClose={handleTooltipClose}>
                              <span
                                className="guv-force-capitalize guv-underline guv-pointer"
                                onClick={handleTooltipOpen}>
                                {adrDepartment}
                              </span>
                            </Tooltip>
                          ) : (
                            <span>...</span>
                          )}
                        </span>
                      </ClickAwayListener>
                    }
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={12}>
              <ShipInfo
                title="Descripción"
                icon={<IconPackage size={18} stroke={1.5} />}
                content={<span>{shipment?.description ? shipment?.description : '...'}</span>}
              />
            </Grid>
          </Grid>
        </div>
        <div className={styles.right}>
          {!isMobile && (
            <div className={styles.top}>
              {shipment?.amount && (
                <div className={styles.price}>
                  <h4>{currency + ' ' + shipment.amount}</h4>
                </div>
              )}
            </div>
          )}
          {/* Acciones */}
          <div className={styles.actions}>
            {(isCreated ||
              isPay ||
              isForPickup ||
              isPicked ||
              isConfirmed ||
              isProcesando ||
              isReady ||
              isShipped) && (
              <Button
                disableElevation
                variant="outlined"
                color="secondary"
                onClick={() => handleShipmentDetail(shipment)}>
                Detalle
              </Button>
            )}
            {needPayment && (
              <Button
                disableElevation
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<IconCreditCard size={22} />}
                onClick={() => handleOpenPaymentDialog(shipment)}>
                Pagar
              </Button>
            )}
            {isRegistered && (
              <>
                <Button
                  style={{ padding: '6px 12px' }}
                  disableElevation
                  variant="outlined"
                  color="secondary"
                  onClick={() => openPickupDialog()}>
                  Recoger en persona
                </Button>
                <GuveryDialog
                  open={pickupDialog.open}
                  onClose={pickupDialog.closeDialog}
                  title={`Envío #${shipment?.id} / Recoger`}
                  disableActions
                  content={false}>
                  <div className={styles.root}>
                    <div className={styles.body}>
                      <h4 style={{ textDecoration: 'underline' }}>Instrucciones:</h4>
                      <p style={{ marginTop: 10 }}>
                        Contacta con Guvery vía whatsapp e indica que quieres acercarte a
                        recoger tu pedido.
                      </p>
                      <p style={{ marginBlock: 10 }}>
                        Pregunta por la dirección de la oficina en donde está y brinda los
                        siguientes datos:
                      </p>
                      <ul
                        style={{
                          fontWeight: 'bolder',
                          listStyleType: 'disc',
                          paddingLeft: 20,
                        }}>
                        <li>Nombre y apellido de la persona</li>
                        <li>Tipo y número de documento (DNI / CEX)</li>
                        <li>Fecha y rango de hora</li>
                      </ul>
                      <p style={{ marginBlock: 10 }}>
                        Una vez que Guvery te confirme, recuerda que deberás presentar el
                        documento de identidad físico en la oficina para recoger tu pedido,
                        respetando la fecha y horario pactado.
                      </p>
                      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                        <Button
                          disableElevation
                          variant="outlined"
                          color="primary"
                          href={`https://api.whatsapp.com/send?phone=${GUVERY_WHATSAPP_NUMBER_FULL}&text=Hola%20Guvery%20`} 
                          target='_blank'>
                          Whatsapp Guvery
                        </Button>
                      </div>
                    </div>
                  </div>
                </GuveryDialog>
              </>
            )}
            {isRegistered && (
              <Button
                style={{ padding: '6px 12px' }}
                disableElevation
                variant="contained"
                color="primary"
                onClick={() => handleShipmentDetail(shipment)}>
                Seleccionar Dirección
              </Button>
            )}
          </div>
        </div>
      </div>
    </GuveryPaper>
  );
};

export default ShipmentCard;
