import { ReactNode, useState } from 'react';

import { useAuth } from '#root/hooks';
import { TravelerNavbar, ProfileSidebar } from '#root/components';
import { GuveryAlert } from '#root/ui-components';
import { Banner } from '#root/ui-components/extended';
import { USER_PROFILE } from '#root/constants/navigation';

import styles from './profile.module.scss';

interface ProfileProps {
  children: ReactNode;
}

const Profile = (props: ProfileProps) => {
  const { children } = props;
  const { usuario } = useAuth();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const activeAlert = usuario.is_active !== 1 || usuario.wsp === null;
  const textAlert =
    usuario.wsp === null
      ? 'Ingresa tu Whatsapp para activar la cuenta.'
      : 'Verifica tu correo para activar tu cuenta.';

  return (
    <div className={styles.profileLayout}>
      <TravelerNavbar
        openMobile={isMobileNavOpen}
        onMobileNavChange={() => setMobileNavOpen(!isMobileNavOpen)}
      />
      <ProfileSidebar
        onMobileClose={() => setMobileNavOpen(!isMobileNavOpen)}
        openMobile={isMobileNavOpen}
      />
      <div className={styles.layoutWrap}>
        <div className={styles.layoutContainer}>
          <div className={styles.layoutContent}>
            <Banner />
            {activeAlert && (
              <GuveryAlert
                severity="warning"
                message={textAlert}
                actionText="Activar Cuenta"
                href={USER_PROFILE}
              />
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
