import configService from './config';
// import fetchApi from './fetchApi';
import guveryFetchApi from './guveryFetchApi';
import * as METHODS from './methods';

const guveryApi = configService.apiGuvery;

const travelerReviews = async (user_id) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/shopper/traveler_reviews/${user_id}`,
    options: METHODS.GET(),
    message: {
      success: 'Reseñas del viajero obtenidas con exito.',
      error: 'No se puedo obtener las reseñas.',
    },
  });

  return { ...response };
}

const ordersQuantities = async () => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/shopper/orders`,
    options: METHODS.GET(),
    message: {
      success: 'Pedidos obtenidos con exito.',
      error: 'No se puedo obtener los pedidos.',
    },
  });

  return { ...response };
};

const orders = async (filter = null, offset = 0) => {
  if (!filter) return;
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/shopper/orders/${filter}?offset=${offset}`,
    options: METHODS.GET(),
    message: {
      success: 'Pedidos obtenidos con exito.',
      error: 'No se puedo obtener los pedidos.',
    },
  });

  return { ...response };
};

const orderSetExpress = async (order_id) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/shopper/order/set_express`,
    options: METHODS.POST({
      order_id: order_id,
    }),
    message: {
      success: 'Pedido Express Solicitado',
      error: 'No se puede solicitar.',
    },
  });

  return { ...response };
}

const addPaymentOrderExpress = async (arg) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/order/express/add_payment`,
    options: METHODS.POST({
      id: arg.id,
      bank: arg.bank,
      currency: arg.currency,
      amount: arg.amount,
      using_wallet: arg.using_wallet,
      zipago_bank: arg.zipago_bank,
      zipago_fee: arg.zipago_fee,
    }),
    message: {
      success: '!Pedido Express Pagado!',
      error: 'No se puede pagar el pedido.',
    },
  });

  return { ...response };
};

const orderById = async (orderId) => {
  const { response } = await guveryFetchApi({
    url: guveryApi + `/order/${orderId}`,
    options: METHODS.GET(),
    message: {
      success: 'Pedido obtenido con exito.',
      error: 'No se puedo obtene el pedidos.',
    },
  });

  return { ...response };
};

const orderUploadImage = async (arg) => {
  const formData = new FormData();
  formData.append('order_id', arg.orderId);
  formData.append('imagen', arg.image);

  const { response } = await guveryFetchApi({
    url: guveryApi + `/order/upload_image`,
    options: METHODS.POST_FORM_DATA(formData),
    message: {
      success: 'Pedido actualizado con exito.',
      error: 'No se pudo actualizar el pedido.',
    },
  });

  return { ...response };
};

const ordersStore = async () => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/shopper/orders_tienda`,
    options: METHODS.GET(),
    message: {
      success: 'Ordenes de la tienda obtenidos con exito.',
      error: 'No se puedo obtener las ordenes de la tienda.',
    },
  });

  return { ...response };
};

const orderInfo = async (ida) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/shopper/deal/${ida}`,
    options: METHODS.GET(),
    message: {
      success: 'Acuerdo obtenido con exito',
      error: 'No se puedo obtener el acuerdo.',
    },
  });

  return { ...response };
};

const couponDeal = async (dealId: string | number, couponCode: string) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/shopper/coupon/deal`,
    options: METHODS.POST({
      coupon_code: couponCode,
      deal_id: dealId,
    }),
    message: {
      success: '¡Cupon aplicado con exito!',
      error: 'No se puedo aplicar el cupon.',
    },
  });

  return { ...response };
};

const dealPaying = async (arg) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/shopper/deal/paying`,
    options: METHODS.POST({
      deal_id: arg.deal_id,
      bank: arg.bank,
      currency: arg.currency,
      amount: arg.amount,
      coupon_code: arg.coupon_code,
      using_wallet: arg.using_wallet,
      zipago_bank: arg.zipago_bank,
      zipago_fee: arg.zipago_fee,
    }),
    message: {
      success: '!Acuerdo pagado!',
      error: 'No se puede pagar el acuerdo.',
    },
  });

  return { ...response };
};

const dealReciving = async (dealIda: string | number) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/shopper/deal/receiving`,
    options: METHODS.POST({
      deal_id: dealIda,
    }),
    message: {
      success: 'Acuerdo recibido',
      error: 'No se pudo completar la acción.',
    },
  });

  return { ...response };
};

const orderExpiring = async (orderId: string | number) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/order/expiring`,
    options: METHODS.POST({
      order_id: orderId,
    }),
    message: {
      success: 'Pedido expirado',
      error: 'No se pudo completar la acción.',
    },
  });

  return { ...response };
};

const orderRemoveCourier = async (orderId: string | number) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/order/quit_courier`,
    options: METHODS.POST({
      order_id: orderId,
    }),
    message: {
      success: 'Pedido actualizado',
      error: 'No se pudo completar la acción.',
    },
  });

  return { ...response };
};

const dealRating = async (arg) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/shopper/rating/deal`,
    options: METHODS.POST({
      deal_id: arg.deal_id,
      rating_id: arg.rating_id,
      score: arg.score,
      opinion: arg.opinion,
    }),
    message: {
      success: 'Acuerdo recibido',
      error: 'No se pudo completar la acción.',
    },
  });

  return { ...response };
};

const dealReject = async (dealIda: string | number, republish: number) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/deal/reject/shopper`,
    options: METHODS.POST({
      deal_id: dealIda,
      republish: republish,
    }),
    message: {
      success: 'Acuerdo rechazado',
      error: 'No se pudo completar la acción.',
    },
  });

  return { ...response };
};

const dealRefund = async (arg) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/shopper/refund`,
    options: METHODS.POST({
      refund_id: arg.refund_id,
      bank: arg.bank,
      currency: arg.currency,
      account_number: arg.account_number,
      account_holder: arg.account_holder,
    }),
    message: {
      success: 'Acción realizada',
      error: 'No se pudo completar la acción.',
    },
  });

  return { ...response };
};

const shopperService = {
  travelerReviews,
  ordersQuantities,
  orders,
  orderSetExpress,
  addPaymentOrderExpress,
  orderById,
  orderUploadImage,
  ordersStore,
  orderInfo,
  couponDeal,
  dealPaying,
  dealReciving,
  orderExpiring,
  orderRemoveCourier,
  dealRating,
  dealReject,
  dealRefund,
};

export default shopperService;
