import { Alert } from '@material-ui/lab';

import styles from './photoAlert.module.scss';

const PhotoAlert = () => {
  return (
    <div className={styles.root}>
      <Alert severity="error">
        <h5>Foto de perfil obligatoria</h5>
        Sube tu foto de perfil para completar la configuración de viajero.
      </Alert>
    </div>
  );
};

export default PhotoAlert;
