const UserRole = {
  admin: 'admin',
  comprador: 'comprador',
  transportista: 'transportista',
  viajero: 'viajero',
  viajeroExperto: 'viajeroExperto',
} as const;

export type UserRoleType = typeof UserRole[keyof typeof UserRole];

export default UserRole;
