import React from 'react';
import { Container } from '@material-ui/core';
import clsx from 'clsx';

interface GuverySpaceProps {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
  disableGutters?: boolean;
  container?: boolean;
}

const prefixCls = 'guv-main-container';

const GuveryLayout = (props: GuverySpaceProps) => {
  const { className, style, children, disableGutters = false, container = true } = props;

  const cn = clsx(
    prefixCls,
    {
      [`${prefixCls}-disable-gutters`]: disableGutters,
    },
    className,
  );

  return (
    <div
      className={cn}
      style={{
        ...style,
      }}>
      {container && <Container maxWidth="lg">{children}</Container>}
      {!container && children}
    </div>
  );
};

export default GuveryLayout;
