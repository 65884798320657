import { PaletteOptions } from '@material-ui/core/styles/createPalette';

const white = '#FFFFFF';
const black = '#000000';

const palette = {
  black,
  white,
  primary: {
    contrastText: white,
    dark: '#002f27',
    main: '#00594f',
    light: '#3e867b',
  },
  secondary: {
    contrastText: white,
    dark: '#000f15',
    main: '#2b353b',
    light: '#545f65',
  },
  text: {
    primary: '#2b353b',
    secondary: '#a7b7be',
  },
  icon: '#2b353b',
  // divider: colors.grey[200],
} as PaletteOptions;

export default palette;
