import { useEffect } from 'react';
import { Button, Drawer, List } from '@material-ui/core';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { NavItem } from '#root/components';
import * as ROUTES from '#root/constants/navigation';
import * as VARIABLES from '#root/constants/variables';
import { useResponsive, useAuth } from '#root/hooks';
import {
  AwardIcon,
  BriefcaseOutlineIcon,
  // HomeIcon,
  InboxIcon,
  PackageIcon,
  PlanenIcon,
  MoneyBagIcon,
  WandIcon,
} from '#root/icons';
import { IconShield, IconMapPins, IconChartBar } from "@tabler/icons";
import ItemModel from '#root/interfaces/Item';
import styles from './travelerSidebar.module.scss';

interface TravelerSidebarProps {
  onMobileClose?: any;
  openMobile?: boolean;
  viewOrdersPre?: boolean | false;
}

const TravelerSidebar = (props: TravelerSidebarProps) => {
  const { onMobileClose = () => {}, openMobile = false, viewOrdersPre = false } = props;
  const { usuario, traveler_trips_count, global_is_admin } = useAuth();
  const { isDesktop } = useResponsive();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const hasTrip = traveler_trips_count > 0 ? true : false;

  const items: ItemModel[] = [
    {
      href: ROUTES.TRAVELER_DASHBOARD,
      icon: IconChartBar,
      title: 'Mi Resumen',
    },
    {
      // href: VARIABLES.URL_PHP_PAGE + 'viajero/misviajes',
      // external: true,
      href: ROUTES.TRAVELER_TRIPS,
      icon: PlanenIcon,
      title: 'Mis Viajes',
      alert: !hasTrip,
    },
    {
      href: ROUTES.TRAVELER_ORDERS,
      // href: VARIABLES.URL_PHP_PAGE + 'viajero/listado',
      // external: true,
      icon: PackageIcon,
      title: 'Ver Pedidos',
    },
    {
      href: ROUTES.TRAVELER_ORDERS_PRE,
      // href: VARIABLES.URL_PHP_PAGE + 'viajero/listado',
      // external: true,
      icon: PackageIcon,
      title: 'Ver Pedidos Pre',
    },
    {
      href: ROUTES.TRAVELER_OFFERS,
      // href: VARIABLES.URL_PHP_PAGE + 'viajero/misofertas',
      // external: true,
      icon: InboxIcon,
      title: 'Mis Ofertas',
    },
    {
      href: ROUTES.TRAVELER_DEALS,
      // href: VARIABLES.URL_PHP_PAGE + 'viajero/misacuerdos',
      // external: true,
      icon: BriefcaseOutlineIcon,
      title: 'Mis Acuerdos',
    },
    {
      href: ROUTES.TRAVELER_EARNINGS,
      // href: VARIABLES.URL_PHP_PAGE + 'viajero/misganancias',
      // external: true,
      icon: MoneyBagIcon,
      title: 'Mis Ganancias',
    },
  ];

  const content = (
    <div className={styles.content}>
      <div className={styles.list}>
        <List style={{ padding: 0 }}>
          {items.map((item) => {
            if (!viewOrdersPre && item.href === ROUTES.TRAVELER_ORDERS_PRE) {
              return;
            }
            return (
              <NavItem
                external={item.external}
                href={item.href}
                icon={item.icon}
                key={item.title}
                title={item.title}
                {...item}
              />
            )
          })}
        </List>
      </div>
      <div className={styles.space} />
      <div className={styles.bottom}>
        <NavItem
          alert={usuario.capable_traveler_at ? false : true}
          href={ROUTES.TRAVELER_DELIVERY_SETUP}
          icon={IconMapPins}
          title="Configurar Entregas"
        />
        <NavItem
          alert={usuario.capable_traveler_at ? false : true}
          href={ROUTES.TRAVELER_TRAINING}
          icon={AwardIcon}
          title="Capacitación"
        />
        {false && (
          <NavItem
          external
          href="https://bit.ly/ViajerosGuvery"
          icon={WandIcon}
          redirect
          title="Manual Viajero"
        />
        )}

        <div className={styles.bottomActions}>
        {global_is_admin && (
          <div className={styles.action}>
          <Button
            color="secondary"
            variant="outlined"
            component={'a'}
            disableElevation
            fullWidth
            href={VARIABLES.URL_PHP_PAGE + 'admin'}
            startIcon={<IconShield size={18} strokeWidth={1.5} />}>
            Admin
          </Button>
          </div>
        )}
        <div className={styles.action}>
          <Button
            disableElevation
            fullWidth
            color="primary"
            component={RouterLink}
            to={ROUTES.USER_ORDERS}
            // href={VARIABLES.URL_PHP_PAGE + 'comprador/mispedidos'}
            variant="outlined">
            Ir a Comprador
          </Button>
        </div>
        </div>
        
      </div>
    </div>
  );

  return (
    <>
      {isDesktop ? (
        <Drawer
          anchor="left"
          open
          PaperProps={{
            elevation: 0,
            style: {
              background: 'var(--color-background-alt)',
              // borderRight: '1px solid rgba(135,153,160,0.25)',
              borderRight: 'none',
              width: 208,
              top: 64,
              height: '100%',
              zIndex: 1000,
            },
          }}
          variant="persistent">
          {content}
        </Drawer>
      ) : (
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            style: {
              width: 256,
              top: 57,
              background: 'var(--color-background-alt)',
            },
          }}
          style={{
            zIndex: 1200,
          }}
          variant="temporary">
          {content}
        </Drawer>
      )}
    </>
  );
};

export default TravelerSidebar;
