const LOCAL_PHP_API = process.env.REACT_APP_LOCAL_PHP_API ?? 'https://www.guvery.com/rapi';
const LOCAL_GUVERY_API =
  process.env.REACT_APP_LOCAL_GUVERY_API ?? 'https://www.guvery.com/rrapi';
const LOCAL_NODE_CHAT = process.env.REACT_APP_LOCAL_NODE_CHAT ?? 'https://sh3.guvery.com';

const configService = {
  apiUrl: LOCAL_PHP_API,
  apiGuvery: LOCAL_GUVERY_API,
  apiUrlChatServer: LOCAL_NODE_CHAT,
};

export default configService;
