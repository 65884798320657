import { Snackbar } from '@material-ui/core';
import MySnackbarContentWrapper from './MySnackbarContentWrapper';
import { useNotification } from '#root/hooks';

const Notification = () => {
  const { notification, closeMessage } = useNotification();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={notification.state}
      autoHideDuration={4500}
      onClose={closeMessage}
    >
      <div>
      <MySnackbarContentWrapper
        onClose={closeMessage}
        variant={notification.variant}
        message={notification.message}
      />
      </div>
    </Snackbar>
  );
};

export default Notification;