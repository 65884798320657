import SvgIcon from '@material-ui/core/SvgIcon';

const SparksSvg = () => (
  <>
    <g>
      <path
        fill="none"
        opacity="1.000000"
        stroke="none"
        d=" M512.695679,408.774475   C513.000000,442.492859 512.954834,475.985840 513.079163,509.478241   C513.090027,512.399048 512.566650,513.087585 509.522888,513.085815   C341.225555,512.989197 172.928177,512.986206 4.630873,513.097656   C1.215548,513.099915 0.943666,511.973785 0.944711,509.132385   C1.006535,341.001648 1.010380,172.870911 0.920456,4.740195   C0.918774,1.594756 1.657390,0.922186 4.758285,0.923773   C172.889008,1.009816 341.019745,1.017123 509.150421,0.889027   C512.879456,0.886186 513.073669,2.044332 513.071777,5.148839   C512.988525,139.120087 513.000000,273.091400 513.000000,407.531342   C457.987122,412.813416 416.494141,357.195190 420.274109,322.888367   C420.846191,328.344666 419.947327,335.503540 417.800751,342.521423   C415.644196,349.571869 412.560974,356.215973 408.932068,362.597534   C405.210785,369.141479 400.615997,375.025238 395.239899,380.340088   C389.897400,385.621674 384.520081,390.805328 378.077728,394.776367   C371.810577,398.639496 365.230530,401.802734 358.297577,404.279358   C351.179749,406.822021 343.981049,408.751984 337.252594,407.956085   C373.561707,406.257111 423.503235,448.927460 420.452423,496.007996   C419.382660,492.538940 420.386139,488.418854 420.372375,484.328094   C420.357880,480.033295 422.060638,475.873291 423.345551,471.740112   C424.633362,467.597656 426.247223,463.588501 428.227814,459.716644   C430.202484,455.856445 432.212280,452.012390 434.641235,448.426331   C448.736328,427.616852 468.138947,414.437561 492.566772,408.814240   C499.053528,407.320953 505.768005,407.640564 512.695679,408.774475  z"
      />
      <path
        fill="#none"
        opacity="1.000000"
        stroke="none"
        d=" M513.000000,408.750000   C467.173615,407.378632 421.736542,445.552704 420.376709,500.278168   C419.116364,475.320343 410.491302,453.260559 392.786743,435.719635   C375.181305,418.276855 353.723267,408.560638 328.219025,408.516296   C384.030487,402.283356 414.311188,370.894775 420.821533,314.669067   C420.950287,326.688110 422.949432,337.300659 426.869629,347.627960   C433.251587,364.440704 443.566864,378.172089 457.861237,389.016815   C470.393372,398.524567 484.648529,403.960938 499.938324,406.903046   C503.942383,407.673492 508.203583,406.105927 512.574463,407.986450   C513.000000,408.000000 513.000000,408.500000 513.000000,408.750000  z"
      />
      <path
        fill="#none"
        opacity="1.000000"
        stroke="none"
        d=" M174.695923,187.321686   C176.619461,197.785629 180.094131,207.325470 183.951462,216.763672   C192.910492,238.684860 206.293762,257.607239 223.404327,273.794861   C245.762207,294.946747 271.985535,309.164185 302.102478,316.015045   C313.950500,318.710175 325.905273,320.366577 338.131042,320.366791   C323.752625,321.874603 309.373505,323.158478 295.410126,327.023438   C281.338348,330.918396 268.009247,336.476471 255.345718,343.856995   C242.445908,351.375275 230.565353,360.172333 220.000534,370.624725   C209.525665,380.988068 200.460739,392.639618 193.305740,405.511597   C186.258377,418.189941 180.206070,431.350189 176.630035,445.600281   C173.030777,459.942902 170.464615,474.334137 170.493729,489.336487   C168.673782,443.629700 152.912476,403.782806 120.623756,371.260132   C88.225571,338.627228 48.376007,322.573425 2.554448,320.655853   C48.611027,318.878021 88.254662,302.631134 120.702309,269.770874   C152.956650,237.106354 168.619110,197.381622 170.449890,151.716141   C170.513062,163.693161 172.057556,175.376633 174.695923,187.321686  z"
      />
      <path
        fill="#none"
        opacity="1.000000"
        stroke="none"
        d=" M251.945038,124.000412   C272.087097,120.897034 289.845551,112.901054 304.949524,100.248878   C325.419342,83.101936 339.247711,61.499794 344.078369,34.696819   C345.334320,27.728254 346.251862,20.781281 346.529968,13.540905   C348.017059,44.099762 358.515045,70.866470 380.159912,92.503967   C401.630615,113.967316 428.077026,124.838089 458.519135,126.182465   C396.376465,127.824013 349.853516,175.276031 346.373840,238.064438   C346.357086,228.543472 344.763062,219.262131 342.569519,210.049835   C340.293365,200.490509 336.311890,191.601822 331.489136,183.169998   C326.694580,174.787445 320.775421,167.176315 313.901489,160.247986   C307.025848,153.317902 299.664459,146.981232 291.260681,142.215012   C282.843170,137.440994 274.011932,133.358856 264.475555,130.890503   C255.074448,128.457169 245.694641,126.488762 235.589417,127.088181   C237.949051,124.557922 240.668823,124.869492 243.221390,125.048820   C246.084351,125.249969 248.754929,124.452492 251.945038,124.000412  z"
      />
    </g>
  </>
);

const Sparks = (props) => {
  const { inlineDefault = false } = props;
  return inlineDefault ? (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      style={{ color: 'var(--color-match-fee)', fontSize: '1.4em', margin: '-4px 0px' }}>
      <SparksSvg />
    </SvgIcon>
  ) : (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <SparksSvg />
    </SvgIcon>
  );
};

export default Sparks;
