import { ReactNode } from 'react';

import { GuveryDialog } from '#root/ui-components';
import styles from './provinceDialog.module.scss';

interface ProvinceDialogProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  content?: ReactNode | string;
}

const ProvinceDialog = (props: ProvinceDialogProps) => {
  const { open = false, onClose, title = 'Acuerdos con Envío Nacional', content } = props;

  const body = (
    <>
      <p>
        Los acuerdos marcados como <strong>Envío Nacional</strong>
        &nbsp;se deben entregar en alguna de las oficinas de&nbsp;
        <strong>MailBoxes ETC </strong>
        (Miraflores, San Isidro ó Surco).
      </p>

      <p>
        Contacta a un administrador de Guvery para obtener las direcciones e instrucciones de
        dónde/cómo entregar estos pedidos.
      </p>
      <p>
        Una vez que los hayas entregado y enviado las fotos correspondientes, podrás colocar{' '}
        <strong>ENTREGADO</strong> sobre el acuerdo (subiendo una foto).
      </p>
    </>
  );

  return (
    <GuveryDialog open={open} onClose={onClose} title={title} content={false}>
      <div className={styles.root}>
        <div className={styles.wrap}>{content ? content : body}</div>
      </div>
    </GuveryDialog>
  );
};

export default ProvinceDialog;
