import SvgIcon from '@material-ui/core/SvgIcon';

const ShieldSvg = () => (
  <>
    <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
  </>
);

const Shield = (props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    // style={{ fill: 'none' }}
    {...props}>
    <ShieldSvg />
  </SvgIcon>
);

export default Shield;
