import { useDialog } from '#root/hooks';
import { Avatar, Button } from '@material-ui/core';
import { formatDistanceStrict } from 'date-fns';
import { es } from 'date-fns/locale';

import { DistanceExpire, ExpressDetailsDialog } from '#root/components';
import { GuverySpace, GuveryObsToolTip } from '#root/ui-components';
import { GuveryMainCard } from '#root/ui-components/cards';
import { CanguroGuvery } from '#root/assets';
import styles from './orderInfo.module.scss';

const getTime = (orderDate) => {
  let time;
  try {
    time = orderDate
      ? formatDistanceStrict(new Date(orderDate.replace(/ /g, 'T')), new Date(), { locale: es })
      : '---';
  } catch (error) {
    time = 'error';
  }
  return time;
};

const Tag = ({ title, response }) => (
  <div className={styles.tag}>
    <span className={styles.text}>
      {title} <strong>{response}</strong>
    </span>
  </div>
);

const DealInfo = (props) => {
  const { order = null } = props;
  const imagePath = order?.img_path;
  const orderExpress = (order?.order_express && (order?.order_express?.is_paying || order?.order_express?.is_paid)) ? order.order_express : null;

  const title = (orderExpress) ? 'Pedido Express ⚡️' : 'Resumen del Pedido';

  const expressDetailsDialog = useDialog();

  return (
    <GuveryMainCard className={styles.root} title={title} content={false}>
      <div className={styles.body}>
        <div className={styles.resume}>
          <div className={styles.wrap}>
            <Avatar className={styles.cover} alt={order?.tit} src={imagePath}>
              <img alt={order?.tit} src={CanguroGuvery} />
            </Avatar>
            <div className={styles.info}>
              <span className={styles.time}>Hace {getTime(order?.feccre)}</span>
              <h4 className={styles.name}>{order?.tit}</h4>
              <div className={styles.link}>
                <a href={order?.url} target="_blank" rel="noopener noreferrer">
                  <span className={styles.text}>{order?.url_host}</span>
                </a>
              </div>
              {order?.condicion && <>{' '}<Tag title="" response={order.condicion} /></>}
              {(orderExpress) ? (
                <>
                <GuverySpace style={{marginTop: 10}}>
            <div className={styles.detailPriceExpress}>
                  <div>
                    <h4>
                    <span className={styles.currency}>$</span>
                    <span className={styles.priceTotal}>{orderExpress?.total_price} </span>
                    </h4>
                  </div>
                  
                    <span className={styles.priceHint}>
                    &nbsp;(Total)
                    </span>
                    <Button className={styles.action} variant="outlined" color="inherit" onClick={expressDetailsDialog.openDialog}>
                          Detalle
                    </Button>
                    <ExpressDetailsDialog
                    open={expressDetailsDialog.open}
                    onClose={expressDetailsDialog.closeDialog}
                    order={order}
                    orderExpress={orderExpress}
                      />
                </div>
          </GuverySpace>
                </>
              ) : (
                <>
                {order?.obs && (
                <GuveryObsToolTip arrow title={order.obs} placement="bottom">
                  <span className={styles.obs}>{order.obs}</span>
                </GuveryObsToolTip>
              )}
              <GuverySpace direction="horizontal" size={2} className={styles.list}>
                {order?.can && <Tag title="Cantidad:" response={order.can} />}
                {order?.empaque && (
                  <Tag title="Empaque:" response={order?.empaque} />
                )}
                {!order?.empaque && <Tag title="Empaque:" response="(sin preferencias)" />}
              </GuverySpace>
                </>
              )}
              
              
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          {!orderExpress && (
            <>
            <GuverySpace>
                {order?.pre && (
                <h4 className={styles.detailPrice}>
                  <div>
                  <span className={styles.currency}>$</span>
                    <span className={styles.priceTotal}>{order?.pre} </span>
                    <span className={styles.currency}>&nbsp;+&nbsp;</span>
                    <span className={styles.currency}>$</span>
                    <span className={styles.priceTotal}>{order?.com} </span>
                  </div>
                    
                    <span className={styles.priceHint}>
                    &nbsp;(Referencial)
                    </span>
                </h4>
              )}
          </GuverySpace>
          {order?.fecmax && (
            <div className={styles.expire}>
              <DistanceExpire date={order?.fecmax} />
            </div>
          )}
            </>
          )}

          {orderExpress && (
            <>
            
            </>
          )}
          
        </div>
      </div>
    </GuveryMainCard>
  );
};

export default DealInfo;
