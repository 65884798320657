import { Fragment } from 'react';
import { Box, LinearProgress } from '@material-ui/core';

import styles from './rankingProgress.module.scss';

const RankingProgress = (props) => {
  const {
    noScore=false,
    score,
    metric,
    totalMin,
    totalMax,
    levelMax,
    listMin = [],
    listMinHeader = [],
    headerSufix = '',
    bufferOn = false,
    hideExtraText = false,
    minHeight = 48,
  } = props;

  // Para calcular el buffer:
  const metricAbove = listMinHeader.find((point) => point >= metric);
  const metricBelong = listMinHeader.slice().reverse().find((point) => point <= metric);
  const normalise = (value) => ((value - totalMin) * 100) / (totalMax - totalMin);
  const normaliseBuffer = (value) => ((value - metricBelong) * 100) / (metricAbove - metricBelong);

  const scorePercentage = normalise(score);
  const nextScore = listMin.find((point) => point > score);
  const nextScorePercentage = normalise(nextScore);
  const tempPercentageBuffer = normaliseBuffer(metric) / 100;
  const scorePercentageBuffer = scorePercentage + ((nextScorePercentage - scorePercentage) * tempPercentageBuffer);
  

  const totalMaxPtsPercentage = normalise(totalMax);
  const userMissingPoints = levelMax + 1 - score;

  const hasMin = score > totalMin;

  const linearProgressVariant = (bufferOn) ? "buffer" : "determinate";

  return (
    <Box height={minHeight} display="flex" flexDirection="column" justifyContent="center">
      <Box display="flex" alignItems="center">
        <Box width="100%" className={styles.progressBar}>
          {noScore ? (
            <LinearProgress variant={"determinate"} value={null} valueBuffer={0} />
          ) : (
            <LinearProgress variant={linearProgressVariant} value={scorePercentage} valueBuffer={scorePercentageBuffer} />
          )}
        </Box>
      </Box>
      <Box className={styles.hints}>
        {listMin?.map((levelMin, key) => {
          const levelMinPercentage = normalise(levelMin);
          const header = listMinHeader[key];
          return (
            <Fragment key={key}>
              {header && (
                <div className={styles.numHeader} style={{ left: `${levelMinPercentage}%` }}>
                  <span>
                    {header}
                    {headerSufix}
                  </span>
                </div>
              )}
              <div className={styles.num} style={{ left: `${levelMinPercentage}%` }}>
                <span>{levelMin}</span>
              </div>
            </Fragment>
          );
        })}
        {hasMin && (
          <div className={styles.num} style={{ left: `${scorePercentage}%` }}>
            <span>
              <strong>{score}</strong>
            </span>
          </div>
        )}
        <div className={styles.num} style={{ left: `${totalMaxPtsPercentage}%` }}>
          <span>{totalMax}</span>
        </div>
      </Box>
      {!hideExtraText && levelMax && (
        <>
          {listMin[listMin.length - 1] > score && (
            <Box mt={3} mb={-2}>
              <p className="guv-text-alt-2">
                Gana <strong>{userMissingPoints}</strong> puntos más para subir de nivel
              </p>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default RankingProgress;
