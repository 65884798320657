import { Rating as MuiRating } from '@material-ui/lab';

import { GuveryPaper } from '#root/ui-components/cards';
import styles from './rating.module.scss';

interface RatingProps {
  clientRating?: any;
  travelerRating?: any;
}

const Rating = (props: RatingProps) => {
  const { clientRating, travelerRating } = props;
  const client = clientRating ? Number(clientRating) : 0;
  const traveler = travelerRating ? Number(travelerRating) : 0;

  return (
    <GuveryPaper disableHover disableElevation className={styles.root}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <span>Calificación</span>
        </div>
      </div>
      <div className={styles.stack}>
        {clientRating && (
          <div className={styles.box}>
            <p>Comprador</p>
            <h4>{client}</h4>
            <MuiRating name="simple-controlled" value={client} precision={0.5} readOnly />
          </div>
        )}
        <hr className={styles.divider} />
        {travelerRating && (
          <div className={styles.box}>
            <p>Viajero</p>
            <h4>{traveler}</h4>
            <MuiRating name="simple-controlled" value={traveler} precision={0.5} readOnly />
          </div>
        )}
        {!clientRating && !travelerRating && (
          <div className={styles.box}>
            <h5>Aqui veras tu calificacion promedio de los acuerdos concretados</h5>
          </div>
        )}
      </div>
    </GuveryPaper>
  );
};

export default Rating;
