import SvgIcon from '@material-ui/core/SvgIcon';

const PlaneSvg = () => (
  <>
    <path
      fill="currentColor"
      d="M239.57 48l100.57 176H456c26.03 0 62.87 19.73 71.1 32-8.23 12.27-45.07 32-71.1 32H340.14L239.57 464h-37.14l50.29-176H136l-36 48H58.68L82 256l-23.32-80H100l36 48h116.72L202.43 48h37.14m18.57-48h-98.13c-10.63 0-18.3 10.17-15.38 20.39L189.08 176H160l-31.2-41.6c-3.02-4.03-7.77-6.4-12.8-6.4H16.01C5.6 128-2.04 137.78.49 147.88L32 256 .49 364.12C-2.04 374.22 5.6 384 16.01 384H116c5.04 0 9.78-2.37 12.8-6.4L160 336h29.08l-44.46 155.6C141.7 501.82 149.37 512 160 512h98.13c5.74 0 11.04-3.08 13.89-8.06L368 336h88c44.18 0 120-35.82 120-80 0-44.19-75.82-80-120-80h-88L272.03 8.06A15.998 15.998 0 00258.14 0z"></path>
  </>
);

const Plane = (props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
    style={{ transform: 'rotate(320deg)' }}
    {...props}>
    <PlaneSvg />
  </SvgIcon>
);

export default Plane;
