import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, MenuItem, TextField, Grid, Divider } from '@material-ui/core';
import { IconMapPin, IconInfoCircle } from '@tabler/icons';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

import { ShippingStatus } from '#root/models/Shipping';
import { userService, shippingService } from '#root/services';
import { GuveryDialog } from '#root/ui-components';
import { CreditCardIcon, PackageIcon } from '#root/icons';
import { USER_ADDRESS } from '#root/constants/navigation';
import styles from './dialogShipmentDetail.module.scss';

import clsx from 'clsx';

const variantStatus = {
  CREADO: 'Creado',
  REGISTRADO: 'Registrado',
  POR_RECOGER: 'Para recoger...',
  RECOGIDO: 'Recogido',
  CONFIRMADO: 'Confirmado',
  PROCESANDO: 'Procesando...',
  READY: 'Listo para despacho...',
  DESPACHADO: 'Despachado',
  PAGADO_COMPRADOR: 'Validando Pago...',
  PAGADO_ADMIN: 'Pagado',
  PAGADO_ORDEN: 'Pagado',
  CERRADO: 'Cerrado',
  BLOQUEADO: 'Bloqueado',
} as const;

const extraStatus = {
  REGISTRADO: 'Debes seleccionar tu dirección de envío.',
  POR_RECOGER: 'Acércate a recoger tu pedido con tu Documentio de Identidad.',
  RECOGIDO: 'Tú o una persona autorizada por ti, recogió tu pedido.',
  CONFIRMADO: 'Se iniciará el embalaje y procesamiento en breve.',
  PROCESANDO: 'Se está embalando y registrando. Pronto lo podrás pagar.',
  READY: 'Confirma el pago para que pueda ser despachado.',
};

const LabelBadge = ({ status, paid_at, shipped_at }) => {
  let label = variantStatus[status] !== undefined ? variantStatus[status] : '-';
  let extraLabel = extraStatus[status] !== undefined && extraStatus[status];
  if (paid_at && shipped_at) label = 'Despachado';
  if (paid_at && !shipped_at) label = 'Pagado. Despachandose...';
  if (!paid_at && shipped_at) label = 'Falta pagar';

  return (
    <div>
      <span className={styles.statusBadge}>{label}</span>
      {extraLabel && (
        <span className={clsx(styles.statusBadge, styles['extra'])}>{extraLabel}</span>
      )}
    </div>
  );
};

const AmountText = (props) => {
  const { currency, amount } = props;
  const variantCurrency = { SOLES: 'PEN', DOLARES: 'USD' };
  const type = variantCurrency[currency] !== undefined ? variantCurrency[currency] : '-';
  const amountText = amount ? amount : '-';

  return (
    <span className={`amount-text guv-ellipsis`}>
      {`${amountText} `}
      <span className="currency">{type}</span>
    </span>
  );
};

interface DialogShipmentDetailProps {
  open: boolean;
  onClose: (e?: React.MouseEvent<HTMLElement>) => void;
  data: any;
  handleOpenPaymentDialog: any;
  handleLoading: any;
  createMessage: any;
}

const DialogShipmentDetail = (props: DialogShipmentDetailProps) => {
  const {
    open = false,
    onClose,
    data,
    handleOpenPaymentDialog,
    handleLoading,
    createMessage,
  } = props;
  const [addressArray, setAddressArray] = useState([]);
  const [addressId, setAddressId] = useState(null);
  const [savedisable, setSaveDisable] = useState(false);

  // Status
  const isRegister = data?.current_status === ShippingStatus.registered;
  const isReady = data?.current_status === ShippingStatus.ready;
  const isShipped = data?.current_status === ShippingStatus.shipped;

  const needPayment = (isReady || isShipped) && !data.paid_at;

  const hasAddress = addressArray.length > 0 ? true : false;

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddressId(event.target.value);
  };

  const datePicked = data?.picked_at
    ? format(new Date(data.picked_at.replace(/ /g, 'T')), "dd 'de' MMMM 'del' yyyy", {
        locale: es,
      })
    : '---';
  const dateShipped = data?.shipped_at
    ? format(new Date(data.shipped_at.replace(/ /g, 'T')), "dd 'de' MMMM 'del' yyyy", {
        locale: es,
      })
    : '---';

  const deals = data?.shipping_details?.filter((e) => e.tabla === 'acuerdo');
  const orders = data?.shipping_details?.filter((e) => e.tabla === 'tienda_orden');

  const closeModal = () => {
    onClose();
    handleOpenPaymentDialog(data);
  };

  const handleSaveAddress = () => {
    fetchSaveAddress(data.id, addressId);
  };

  const fetchUserAddress = async () => {
    // setLoading(true);
    const res = await userService.address();
    if (!res.error) {
      setAddressArray(res.data?.addresses_list);
    }
    // setLoading(false);
  };

  const fetchSaveAddress = async (shippingId, addressId) => {
    setSaveDisable(true);
    const res = await shippingService.saveAddress(shippingId, addressId);
    if (res.error) {
      createMessage.error('No se pudo guardar la dirección');
      setSaveDisable(false);
      return;
    }
    createMessage.success(res.message);
    handleLoading();
    setSaveDisable(false);
    onClose();
  };

  useEffect(() => {
    fetchUserAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GuveryDialog
      open={open}
      onClose={onClose}
      title={`Envío #${data?.id}`}
      disableActions
      content={false}>
      <div className={styles.root}>
        <div className={styles.body}>
          <div className={styles.header}>
            <LabelBadge
              status={data?.current_status}
              paid_at={data?.paid_at}
              shipped_at={data?.shipped_at}
            />
          </div>
          {!data?.picked_at && !data?.for_pickup_at ? (
            <div className={styles.infoBox}>
              <div className={styles.icon}>
                <PackageIcon />
              </div>
              <div className={styles.content}>
                <p>
                  Enviado por{' '}
                  <strong>{data?.carrier ? data?.carrier?.company_alias : '---'}</strong>
                </p>
                <p>el {dateShipped}</p>
                <p>
                  Seguimiento{' '}
                  <strong className="guv-font-size-4">
                    #{data?.carrier_tracking_code ? data.carrier_tracking_code : '---'}
                  </strong>
                </p>
              </div>
            </div>
          ) : (
            <div className={styles.infoBox}>
              <div className={styles.icon}>
                <PackageIcon />
              </div>
              <div className={styles.content}>
                <p>
                  Recojo en{' '}
                  <strong>{data?.carrier ? data?.carrier?.company_name : '---'}</strong>
                </p>
                <p>
                  por{' '}
                  <strong className="guv-font-size-4">
                    {data?.picked_by_name} / {data?.picked_by_doc}
                  </strong>
                </p>
                {datePicked !== '---' && <p>el {datePicked}</p>}
              </div>
            </div>
          )}

          <div className={styles.section}>
            <div className={styles.subTitle}>
              <h4>Resumen:</h4>
            </div>

            <div className={styles.info}>
              {deals?.length > 0 && (
                <p>
                  Acuerdos ({deals?.length}):{' '}
                  {deals.map((item, index) => (
                    <span key={index} className={styles.condition}>
                      {index > 0 && ', '}
                      <strong>{item.idx} </strong>
                    </span>
                  ))}
                </p>
              )}
              {orders?.length > 0 && (
                <p>
                  Ordenes Tienda ({orders.length}):{' '}
                  {orders.map((item, index) => (
                    <span key={index} className={styles.condition}>
                      {index > 0 && ', '}
                      <strong>{item.idx} </strong>
                    </span>
                  ))}
                </p>
              )}
              <p>
                <strong>{data?.description}</strong>
              </p>
              {data.amount && (
                <p>
                  Total:{' '}
                  <strong>
                    <AmountText currency={data?.currency} amount={data?.amount} />
                  </strong>
                </p>
              )}
            </div>
          </div>
          {!data?.picked_at && !data?.for_pickup_at && (
            <>
              <hr className={styles.divider} />
              <div className={styles.section}>
                <div className={styles.subTitle}>
                  <h4>Dirección:</h4>
                </div>
                {data?.delivery_address && (
                  <>
                    <p>
                      {data?.delivery_address?.first_name} {data?.delivery_address?.last_name}
                    </p>
                    <p>{data?.delivery_address?.address_1}</p>
                    <p className={styles.direction}>
                      <span>{data?.delivery_address?.district},</span>
                      <span>{data?.delivery_address?.province},</span>
                      <span>{data?.delivery_address?.department}</span>
                    </p>
                  </>
                )}
                {isRegister && (
                  <div className={styles.addresForm}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        {addressId === null &&
                          (hasAddress ? (
                            <div className={styles.confirmHelp}>
                              <IconInfoCircle size={20} />
                              <h6>Selecciona tu dirección o crea una nueva.</h6>
                            </div>
                          ) : (
                            <div className={styles.confirmHelp}>
                              <IconInfoCircle size={20} />
                              <h6>Crea una dirección y luego seleccionala.</h6>
                            </div>
                          ))}
                      </Grid>
                      {hasAddress && (
                        <Grid item xs={12}>
                          <TextField
                            select
                            fullWidth
                            size="medium"
                            label="Seleccionar Dirección"
                            variant="outlined"
                            value={addressId}
                            onChange={handleAddressChange}>
                            {addressArray.map((item, index) => (
                              <MenuItem key={index} value={item.id}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <p>{item.address_1}</p>
                                  <p>
                                    {item.district}, {item.province}, {item.department}
                                  </p>
                                </div>
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      )}
                      <Grid item xs={12} style={{ padding: 0 }}>
                        <div style={{ alignItems: 'center', display: 'flex' }}>
                          <Divider style={{ flexGrow: 1 }} orientation="horizontal" />
                          <p className={styles.dividerText}>ó</p>
                          <Divider style={{ flexGrow: 1 }} orientation="horizontal" />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          disableElevation
                          size="large"
                          variant={hasAddress ? 'outlined' : 'contained'}
                          color={hasAddress ? 'secondary' : 'primary'}
                          component={RouterLink}
                          to={USER_ADDRESS}
                          startIcon={<IconMapPin size={20} />}>
                          Mis Direcciones
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </div>
            </>
          )}

          {needPayment && (
            <div className={styles.actions}>
              <Button
                disableElevation
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<CreditCardIcon />}
                onClick={closeModal}>
                Pagar
              </Button>
            </div>
          )}
        </div>
        <div className={styles.footer}>
          {isRegister && (
            <Button
              disableElevation
              disabled={!Boolean(addressId) || savedisable}
              variant="contained"
              color="primary"
              onClick={handleSaveAddress}>
              Confirmar Dirección
            </Button>
          )}
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Cerrar
          </Button>
        </div>
      </div>
    </GuveryDialog>
  );
};

export default DialogShipmentDetail;
