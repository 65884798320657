import { useState, useEffect } from 'react';
import { Button, Container, Grid, IconButton, Tooltip } from '@material-ui/core';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import clsx from 'clsx';

import { userService } from '#root/services';
import { useNotification, useDialog } from '#root/hooks';
import { Notification } from '#root/components';
import { GuveryPageHeader, GuveryDialog } from '#root/ui-components';
import { GuveryPaper, GuveryEmpty } from '#root/ui-components/cards';
import { Coupons } from '#root/assets';
import { CopyIcon, TicketIcon } from '#root/icons';
import * as VARIABLES from '#root/constants/variables';
import styles from './userCoupons.module.scss';

const CouponStatus = {
  created: 'CREATED',
  consumed: 'CONSUMED',
  discounted: 'DISCOUNTED',
};

const CouponService = {
  'GUVERY REGULAR': 'regular',
  'GUVERY ONE': 'one',
};

const HelpDialog = (props) => {
  const { open = false, onClose } = props;
  return (
    <GuveryDialog
      open={open}
      onClose={onClose}
      title="¿Cómo funcionan los cupones?"
      closeText="Cerrar">
      <ol className={styles.dialogSteps} direction="vertical" size="middle">
        <li>
          <p className="guv-regular">
            Genera tu cupón desde{' '}
            <a
              className="guv-text-primary"
              href={VARIABLES.URL_PHP_PAGE + 'cupones'}
              target="_blank"
              rel="noopener noreferrer">
              www.guvery.com/cupones
            </a>{' '}
            y guarda el código generado.
          </p>
          <p className="guv-text-alt-2">
            También recibirás un correo con tu cupón y el código generado, y también puedes
            verlos en Mis Cupones
          </p>
        </li>
        <li>
          <p className="guv-regular">
            Ingresa a tu pedido o acuerdo de Guvery (según el cupón generado) y sigue el proceso
            como siempre. Al momento de pagar, verás un campo que dice "Aplicar Cupón". Ingresa
            el código de tu cupón y presiona el botón para validarlo y aplicarlo.
          </p>
          <p className="guv-text-alt-2">
            Revisa el servicio y las condiciones en el detalle del cupón. Recuerda que los
            cupones pueden vencer.
          </p>
        </li>
        <li>
          <p className="guv-regular">
            Al aplicar el cupón verás que el monto total se ajustará y solo deberás pagar el
            nuevo monto (con el cupón aplicado)
          </p>
          <p className="guv-text-alt-2">
            Los pedidos ONE se pagan al inicio. Los pedidos Regulares se pagan después de haber
            concretado un acuerdo
          </p>
        </li>
      </ol>
    </GuveryDialog>
  );
};

function UserCoupons() {
  const { notification, createMessage, closeMessage } = useNotification();
  const [loading, setLoading] = useState(true);
  const [couponsArray, setCouponsArray] = useState([]);
  const helpDialog = useDialog();

  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code);
    createMessage.success('Código copiado: ' + code);
  };

  const fetchUserCoupons = async () => {
    setLoading(true);
    const res = await userService.coupons();
    if (!res.error) {
      setCouponsArray(res.data.coupon_user);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserCoupons();
  }, []);

  const Content = (props) => {
    if (props.status === 'CREATED') {
      const qty = props.consumed_quantity === null ? 0 : props.consumed_quantity;
      const unlimitedCoupon = props.max_quantity === 0 ? true : false;
      const coupon_qty = parseInt(props.max_quantity, 10) - parseInt(qty, 10);
      const expDate = props.expiration_date
        ? props.expiration_date.replace(/ /g, 'T')
        : 'Fri Jul 02 2021 18:15:13 GMT-0500';

      return (
        <>
          <p className={styles.detail}>
            Valido hasta:{' '}
            <span className="guv-semibold guv-text-base">
              {format(new Date(expDate), "dd '/' MMM '/' yyyy", {
                locale: es,
              })}
            </span>
          </p>

          {!unlimitedCoupon && (
            <p
              className="guv-font-size-7 guv-text-alt-2"
              style={{ marginTop: props.description ? 8 : 24 }}>
              {coupon_qty > 0 ? (
                `Solo quedan ${coupon_qty} cupones en la comunidad. Utilízalo antes que se agote`
              ) : (
                <span className="uv-text-error">
                  Ya no puedes utilizar este cupón, lo sentimos...
                </span>
              )}
            </p>
          )}
        </>
      );
    } else if (props.status === 'CONSUMED') {
      return (
        <>
          <p className={styles.detail}>
            Cupón utilizado el:{' '}
            <span className="guv-semibold guv-text-base">
              {format(new Date(props.consumed_at.replace(/ /g, 'T')), "dd '/' MMM '/' yyyy", {
                locale: es,
              })}
            </span>
          </p>
        </>
      );
    } else {
      return (
        <p className={styles.detail}>
          Cupón aplicado el:{' '}
          <span className="guv-semibold guv-text-base">
            {format(new Date(props.discounted_at.replace(/ /g, 'T')), "dd '/' MMM '/' yyyy", {
              locale: es,
            })}
          </span>
        </p>
      );
    }
  };

  return (
    <div className="guv-main-container">
      <Container maxWidth="lg">
        <GuveryPageHeader
          title="Mis Cupones"
          extra={[
            <Button
              key="1"
              variant="outlined"
              color="secondary"
              onClick={helpDialog.openDialog}>
              ¿Como usar?
            </Button>,
          ]}
        />
        {loading && <h4>Cargando...</h4>}
        {!loading && (
          <Grid container spacing={3}>
            {couponsArray.map((item, index) => {
              const itemService = item?.coupon?.service;
              const serviceClass =
                CouponService[itemService] !== undefined
                  ? CouponService[itemService]
                  : 'regular';
              return (
                <Grid item key={index} xs={12} sm={10} md={6}>
                  <GuveryPaper
                    disableHover
                    disableElevation
                    className={clsx(
                      styles.card,
                      item.current_status !== CouponStatus.created && styles.cardDisable,
                    )}>
                    <div className={clsx(styles.badge, styles[serviceClass])}>
                      <span className={styles.badgeText}>{item?.coupon?.service}</span>
                    </div>
                    <div className={styles.content}>
                      <div className={styles.left}>
                        <h2>
                          {item?.coupon?.type_factor}
                          <b>{item?.coupon?.type === 'porcentaje' ? '%' : '$'}</b>
                        </h2>
                        <p>Descuento sobre</p>
                        <p>
                          {item.coupon?.type_field === 'acuerdo_comguv'
                            ? 'Comisión de Guvery '
                            : 'Importe del Envío'}
                        </p>
                        <span className={styles.max}>(máx. $ {item.coupon.type_limit})</span>
                      </div>
                      <div className={styles.right}>
                        <div className={styles.name}>
                          <TicketIcon />
                          <h6>{item.coupon?.name}</h6>
                        </div>
                        {item?.coupon?.description && (
                          <div className={styles.desc}>
                            <p>{item.coupon?.description}</p>
                          </div>
                        )}
                        {item?.coupon?.service_product && (
                          <p className={clsx(styles.detail, styles.space)}>
                            Aplica sólo para el producto:{' '}
                            <span className="guv-semibold guv-text-base">
                              {item.coupon?.service_product}
                            </span>
                          </p>
                        )}
                        <Content
                          status={item.current_status}
                          expiration_date={item.expiration_date}
                          code={item.code}
                          description={item.coupon?.description}
                          max_quantity={item.coupon?.max_discount_quantity}
                          consumed_quantity={item.coupon?.consumed_quantity}
                          consumed_at={item.consumed_at}
                          discounted_at={item.discounted_at}
                        />
                      </div>
                    </div>
                    {item.current_status === CouponStatus.created && (
                      <div className={styles.footer}>
                        <p className={styles.disc}>
                          Código:
                          <span className={styles.code}>{item.code}</span>
                        </p>
                        <Tooltip title="Copiar código">
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => copyToClipboard(item.code)}>
                            <CopyIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </GuveryPaper>
                </Grid>
              );
            })}
            {couponsArray.length === 0 && (
              <Grid item xs={12}>
                <GuveryEmpty description="No tienes ningún cupón" image={Coupons}>
                  <Button
                    disableElevation
                    color="secondary"
                    variant="outlined"
                    component="a"
                    href={VARIABLES.URL_PHP_PAGE + 'cupones'}>
                    Revisar cupones
                  </Button>
                </GuveryEmpty>
              </Grid>
            )}
          </Grid>
        )}
      </Container>
      <HelpDialog
        open={helpDialog.open}
        onClose={helpDialog.closeDialog}
        // onConfirm={openPaymentDialog}
      />
      {/* {notification.state && <Notification onClose={closeMessage} {...notification} />} */}
    </div>
  );
}

export default UserCoupons;
