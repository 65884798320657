import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Card, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { shopperService } from '#root/services';
import { useNotification, useDialog } from '#root/hooks';
import {
  Notification,
  OfferDrawer,
  GuveryExpressDialog,
  ExpressDetailsDialog,
  ExpirationDays,
  PaymentCheckoutDialog,
  TravelerReviewsDialog,
} from '#root/components';
import { DataSourceType } from '#root/interfaces/PaymentCheckoutDialog';
import { GuveryLayout, GuveryPageHeader, GuverySpace } from '#root/ui-components';
import { ServiceOneLogoSvg } from '#root/assets';
import { replacePathParams, roundDecimal } from '#root/utils';
import {
  USER_ORDERS,
  USER_ORDERS_BY_ID,
  USER_DEAL_DETAIL,
  USER_ORDERS_BY_ID_BY_OFFER,
} from '#root/constants/navigation';
import styles from './userOrderDetail.module.scss';
import LoadingLayout from './LoadingLayout';
import OrderInfo from './OrderInfo';
import Actions from './Actions';
import TimeLine from './Timeline';
import OfferItem from './OfferItem';
import ExtraActions from './ExtraActions';
import Observation from './Observation';
import { IconBolt } from '@tabler/icons';

import clsx from 'clsx';

export const OrderStatus = {
  creado: 'CREADO',
  aprobado: 'APROBADO',
  observado: 'OBSERVADO',
  publicado: 'PUBLICADO',
  rePublicado: 'REPUBLICADO',
  conOferta: 'CON_OFERTA',
  anuladoComprador: 'ANULADO_COMPRADOR',
} as const;

const UserOrderDetail = (props) => {
  const orderId = props.match.params.idp;
  const offerId = props.match.params.ido;
  const { notification, createMessage, closeMessage } = useNotification();
  const [isLoading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [offer, setOffer] = useState(null);
  const [waitingTime, setWaitingTime] = useState(null);
  const offerDrawer = useDialog();
  const guveryExpressDialog = useDialog();
  const expressDetailsDialog = useDialog();
  const paymentDialog = useDialog();
  const travelerReviewsDialog = useDialog();
  const [reviewTravelerData, setReviewTravelerData] = useState(null);
  const history = useHistory();

  const orderIdPublic = order?.id_public ? order.id_public : '';
  const imagePath = order?.img_path;
  const offers = order?.offers ? order?.offers : [];
  const quantityOffers = offers?.length;

  const isWaitingApproval =
    imagePath &&
    (order?.estado === OrderStatus.aprobado || order?.estado === OrderStatus.creado);
  const isPublished =
    order?.estado === OrderStatus.publicado || order?.estado === OrderStatus.conOferta;
  const isExpired = order?.estado === OrderStatus.anuladoComprador || order?.is_expired;
  const isCourier = order?.flag_envio;
  const isObserved = order?.admin_motivo && order?.estado === OrderStatus.observado;
  const orderExpress = order?.order_express;

  const isExpress = order?.flujo === 'EXPRESS';
  const orderExpressIsAvailable = orderExpress && order?.order_express?.is_available;
  const orderExpressIsPaying = orderExpress && order?.order_express?.is_paying;
  const orderExpressIsPaid = orderExpress && order?.order_express?.is_paid;
  const orderExpressIsRejected = orderExpress && order?.order_express?.is_rejected;

  const expressHideOffers = (orderExpressIsPaying || orderExpressIsPaid);

  const paymentDialogSource: DataSourceType = {
    title: 'Pagar Pedido Express',
    id: orderId,
    amount: orderExpress?.total_price && roundDecimal(orderExpress.total_price),
    amountHelper: 'Importe total del pedido:',
    currency: 'DOLARES',
    tca: null,
    disabledCurrencies: false,
    disabledPrice: false,
    allowConvert: true,
    allowCredits: true,
    allowZipago: true,
    showCredits: false,
    stepsArray: [
      'Selecciona el método y el banco.',
      'Escoge la moneda y el monto.',
      'Realiza el pago y confírmalo.',
    ],
    methodsHelper:
      '* Si haces un depósito en agencia o agente, considera que el banco podría cobrar una comisión. Las comisiones bancarias son asumidas por el usuario.',
    paymentType: 'ORDER',
  };

  const closePaymentDialog = () => {
    fetchOrderById();
    paymentDialog.closeDialog();
  };

  // Estados ONE
  const isOne = order?.flujo === 'ONE';
  const isCreatedOne = isOne && order?.estado === OrderStatus.creado;
  const isApprovedOne = isOne && order?.estado === OrderStatus.aprobado;
  // ONE: Mensaje de tiempo de asignación:
  let one_textDealTimeCustom =
    'Asignando tu pedido al mejor viajero disponible (2 días promedio).';
  if (isOne && order?.product_one) {
    if (order?.product_one?.deal_time_custom_message) {
      one_textDealTimeCustom = order.product_one.deal_time_custom_message;
    } else {
      if (order?.product_one?.deal_time_days) {
        one_textDealTimeCustom = `Asignando tu pedido al mejor viajero disponible (${order.product_one.deal_time_days} días promedio).`;
      }
    }
  }

  const handleOpenOfferDrawer = (offerObj) => {
    const offerDrawerPath = replacePathParams(USER_ORDERS_BY_ID_BY_OFFER, {
      idp: order?.idp,
      ido: offerObj?.ido,
    });
    history.push({
      pathname: offerDrawerPath,
    });
    setOffer(offerObj);
    offerDrawer.openDialog();
  };

  const handleCloseOfferDrawer = () => {
    const offerDrawerPath = replacePathParams(USER_ORDERS_BY_ID, { idp: orderId });
    history.push(offerDrawerPath);
    setOffer(null);
    offerDrawer.closeDialog();
  };

  const handleOfferFind = (order, offerId) => {
    const offerSelect = order?.offers?.find((offer) => Number(offer.ido) === Number(offerId));
    if (!offerSelect) {
      handleCloseOfferDrawer();
      return;
    }
    setOffer(offerSelect);
    offerDrawer.openDialog();
  };

  const fetchOrderById = async () => {
    setLoading(true);
    const res = await shopperService.orderById(orderId);
    if (res.status === 'redirect') {
      history.push(replacePathParams(USER_DEAL_DETAIL, { ida: res.data?.deal_id }));
      return;
    }
    if (!res.error) {
      setOrder(res.data?.order);
      setWaitingTime(res.data?.waiting_time);
      if (offerId) {
        handleOfferFind(res.data?.order, offerId);
      }
      setPaymentData(res.data?.payment_checkout_dialog);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOrderById();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const title = (
    <span className={styles.title}>
      <span>{`Pedido #${orderIdPublic}`}</span>
      {isOne && (
        <div className={styles.badgeOne}>
          <img src={ServiceOneLogoSvg} alt="One" />
        </div>
      )}
      {isCourier && (
        <div className={styles.courier}>
          <span>Envío Nacional</span>
        </div>
      )}
    </span>
  );

  if (isLoading) {
    return (
      <GuveryLayout>
        <GuveryPageHeader backButton title={title} onBack={() => history.push(USER_ORDERS)} />
        <LoadingLayout />
      </GuveryLayout>
    );
  }

  if (order === null) {
    return (
      <GuveryLayout>
        <GuveryPageHeader backButton title={title} onBack={() => history.push(USER_ORDERS)} />
        <h5>Error al obtener los datos del pedido</h5>
      </GuveryLayout>
    );
  }

  return (
    <GuveryLayout>
      <GuveryPageHeader backButton title={title} onBack={() => history.push(USER_ORDERS)} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <GuverySpace direction="vertical" size="large">
                {isObserved && <Observation reason={order.admin_motivo_details} />}
                <OrderInfo order={order} />
              </GuverySpace>
              {(isWaitingApproval || isPublished) &&
                !quantityOffers &&
                !isOne &&
                !isExpress && (
                  <>
                    <Card
                      elevation={0}
                      style={{ marginTop: 24 }}
                      className={styles.cardExpressFeeSection}>
                      <div className={styles.content}>
                        <div className={styles.icon}>
                          <IconBolt />
                        </div>
                        <h5 className={styles.text}>
                          ¿Necesitas agilizar la entrega? ⚡️ Podrías calificar para Guvery
                          Express.
                        </h5>
                      </div>
                      <div className={styles.action}>
                        <Button
                          variant="outlined"
                          color="inherit"
                          onClick={() => guveryExpressDialog.openDialog()}>
                          Revisar
                        </Button>
                      </div>
                    </Card>
                    <GuveryExpressDialog
                      open={guveryExpressDialog.open}
                      onClose={guveryExpressDialog.closeDialog}
                      order={order}
                    />
                  </>
                )}
            </Grid>
            <Grid item xs={12} lg={4}>
              <GuverySpace direction="vertical" size="large">
                {!imagePath && !isPublished && (
                  <Actions
                    orderId={orderId}
                    handleLoading={fetchOrderById}
                    createMessage={createMessage}
                  />
                )}

                {isWaitingApproval && isExpress && !orderExpress && (
                  <Card elevation={0} className={styles.cardExpressFeeSection}>
                    <div className={styles.content}>
                      <div className={styles.icon}>
                        <IconBolt />
                      </div>
                      <h5 className={styles.text}>
                        Esperando cotización express ó la aprobación de Guvery.
                      </h5>
                    </div>
                  </Card>
                )}

                {isExpress && orderExpress && orderExpressIsAvailable && (
                  <>
                    <Card elevation={0} className={styles.cardExpressFeeSection}>
                      <div className={styles.content}>
                        <div className={styles.icon}>
                          <IconBolt />
                        </div>
                        <h5 style={{ color: '#fff', marginRight: 6 }}>
                          Pedido Express listo! Págalo y recíbelo en el menor tiempo
                          garantizado.{' '}
                          <strong style={{ color: 'var(--color-express-fee)' }}>
                            Total: $ {orderExpress.total_price}
                          </strong>
                        </h5>
                      </div>
                      <Button className={styles.action} style={{padding: '8px 16px'}} variant="outlined" color="inherit" onClick={expressDetailsDialog.openDialog}>
                          Detalle
                        </Button>
                      <ExpressDetailsDialog
                        open={expressDetailsDialog.open}
                        onClose={expressDetailsDialog.closeDialog}
                        order={order}
                        orderExpress={orderExpress}
                      />
                    </Card>
                    <div>
                    <Button fullWidth variant="contained" color="primary" onClick={() => paymentDialog.openDialog()}>
                      Pagar Pedido Express
                    </Button>
                    <ExpirationDays expirationDays={orderExpress.expiration_days} style={{ marginTop: 4 }} />
                    </div>
                    <PaymentCheckoutDialog
                      open={paymentDialog.open}
                      onClose={closePaymentDialog}
                      dataSource={paymentDialogSource}
                      banks={paymentData?.banks}
                      currencies={paymentData?.currencies}
                      zipago={paymentData?.zipago}
                    />
                  </>
                )}

                {isWaitingApproval && !isOne && (!isExpress || orderExpressIsRejected) && (
                  <Alert severity="info">{`Esperando la aprobación de Guvery (${waitingTime} horas aprox.).`}</Alert>
                )}
                {(isCreatedOne || orderExpressIsPaying) && (
                  <Alert severity="info">Validando el pago por parte de Guvery...</Alert>
                )}
                {orderExpressIsPaid && (
                  <Alert severity="success">Asignando tu pedido al mejor viajero....</Alert>
                )}
                {isApprovedOne && <Alert severity="success">{one_textDealTimeCustom}</Alert>}
                {isExpired && <Alert severity="error">Pedido expirado</Alert>}
                <TimeLine order={order} isOne={isOne} isExpress={(isExpress && !orderExpressIsRejected)} isCanceled={isExpired} />
                <ExtraActions
                  orderId={orderId}
                  isExpired={isExpired}
                  isCourier={isCourier}
                  handleLoading={fetchOrderById}
                  createMessage={createMessage}
                />
              </GuverySpace>
            </Grid>
          </Grid>
        </Grid>
        {!expressHideOffers && (
          <Grid item xs={12}>
          <GuverySpace direction="vertical" size="large">
            {quantityOffers > 0 && (
              <>
                <div>
                  <h2>Ofertas ({quantityOffers})</h2>
                </div>
                <Grid container spacing={3}>
                  {quantityOffers < 3 && (
                    <Grid item xs={12}>
                      <div className={styles.hintOffers}>
                        <p>
                          <strong>Consejo:</strong>&nbsp; Los viajeros invierten su tiempo en
                          hacer ofertas. Mantén su motivación elevada aceptando rápido 1 oferta.
                        </p>
                      </div>
                    </Grid>
                  )}
                  {quantityOffers === 3 && (
                    <Grid item xs={12}>
                      <div className={styles.hintOffers}>
                        <p>
                          <strong>Consejo:</strong>&nbsp; Usualmente la oferta más atractiva se
                          encuentra dentro de las primeras 3 que ha recibido tu pedido. Acepta 1
                          oferta rápido.
                        </p>
                      </div>
                    </Grid>
                  )}
                  {quantityOffers === 4 && (
                    <Grid item xs={12}>
                      <div className={styles.hintOffers}>
                        <p>
                          <strong>Consejo:</strong>&nbsp; Los pedidos que reciben más de 4
                          ofertas, se vuelven menos atractivos. Acepta 1 oferta rápido.
                        </p>
                      </div>
                    </Grid>
                  )}
                  {quantityOffers === 5 && (
                    <Grid item xs={12}>
                      <div className={clsx(styles.hintOffers, styles.warning)}>
                        <p>
                          <strong>Importante:</strong>&nbsp; Tienes muchas ofertas, debes
                          aceptar 1 pronto pues algunas vencerán y los viajeros llenarán su
                          equipaje.
                          <br />
                          <span className={styles.small}>
                            Si tienes dudas, puedes conversar con el viajero usando el chat.
                          </span>
                        </p>
                      </div>
                    </Grid>
                  )}
                  {quantityOffers >= 6 && (
                    <Grid item xs={12}>
                      <div className={clsx(styles.hintOffers, styles.danger)}>
                        <p>
                          <strong>Acción requerida:</strong>&nbsp; Llegaste al máximo de ofertas
                          que puedes recibir. Acepta 1 o contáctate con Guvery para recibir
                          ayuda.
                          <br />
                          <span className={styles.small}>
                            Recibir muchas ofertas y no aceptar ninguna es una conducta inusual
                            y poco ética.
                          </span>
                        </p>
                      </div>
                    </Grid>
                  )}
                  {offers.map((offer, index) => (
                    <Grid key={index} item xs={12} sm={6} md={4}>
                      <OfferItem
                        orderImage={imagePath}
                        orderTitle={order?.tit}
                        orderExpire={isExpired}
                        orderUrl={order?.url}
                        offer={offer}
                        openOfferDrawer={handleOpenOfferDrawer}
                        createMessage={createMessage}
                        setReviewTravelerData={setReviewTravelerData}
                        travelerReviewsDialog={travelerReviewsDialog}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </GuverySpace>
          <TravelerReviewsDialog
        open={travelerReviewsDialog.open}
        onClose={travelerReviewsDialog.closeDialog}
        data={reviewTravelerData}
      />
        </Grid>
        )}
        
      </Grid>
      <OfferDrawer
        orderRoot={order}
        offerRoot={offer}
        open={offerDrawer.open}
        onClose={handleCloseOfferDrawer}
        handleLoading={fetchOrderById}
        createMessage={createMessage}
      />
      {/* {notification.state && <Notification onClose={closeMessage} {...notification} />} */}
    </GuveryLayout>
  );
};

export default UserOrderDetail;
