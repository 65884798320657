import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const ObsToolTip = withStyles((theme) => ({
  arrow: {
    color: 'var(--color-status-info)',
  },
  tooltip: {
    backgroundColor: 'var(--color-status-info)',
    color: 'var(--color-white)',
    boxShadow: theme.shadows[5],
    fontSize: 16,
  },
}))(Tooltip);

export default ObsToolTip;
