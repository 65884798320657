import {
  Avatar,
  Button,
  Divider,
  Dialog,
  List,
  ListItem,
  ListItemAvatar,
  Popover,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import * as ROUTES from '#root/constants/navigation';
import * as VARIABLES from '#root/constants/variables';
import { useResponsive, useAuth } from '#root/hooks';
import {
  PersonOutlineIcon,
  MessageSquareIcon,
  LifeBuoyIcon,
  WandIcon,
  ShoppingBagIcon,
  LogOutIcon,
  PlanenIcon,
} from '#root/icons';

import styles from './userDropdownMenu.module.scss';

interface UserDropdownMenuProps {
  anchorEl: any;
  open?: boolean;
  onClose: any /* func */;
  chatsCount?: number;
}

const UserDropdownMenu = (props: UserDropdownMenuProps) => {
  const { anchorEl, onClose, chatsCount } = props;
  const { usuario, global_is_viajero, removeAuth } = useAuth();
  const { isDesktop } = useResponsive();
  const open = Boolean(anchorEl);
  const id = open ? 'user-menu' : undefined;

  const handleLogout = () => {
    removeAuth();
    window.location.href = VARIABLES.URL_PHP_PAGE + 'usuario/logout';
  };

  const content = (
    <>
      <div className={styles.profileBox}>
        <h6>{usuario.nom}</h6>
        <p>{usuario.mai}</p>
      </div>
      <div className={styles.divider} />
      <div className={styles.content}>
        <RouterLink to={ROUTES.USER_PROFILE} className="guv-no-decoration guv-block">
          <div className={styles.menuItem}>
            <PersonOutlineIcon />
            Ver mi perfil
          </div>
        </RouterLink>
        <RouterLink to={ROUTES.USER_CHAT} className="guv-no-decoration guv-block">
          <div className={styles.menuItem}>
            <MessageSquareIcon />
            Mis chats
          </div>
        </RouterLink>
        {global_is_viajero && (
          <RouterLink to={ROUTES.TRAVELER_DASHBOARD} className="guv-no-decoration guv-block">
            <div className={styles.menuItem}>
              <PlanenIcon />
              Viajero
            </div>
          </RouterLink>
        )}
        <RouterLink to={ROUTES.USER_ORDERS} className="guv-no-decoration guv-block">
          <div className={styles.menuItem}>
            <ShoppingBagIcon />
            Mis Pedidos
          </div>
        </RouterLink>
      </div>
      <div className={styles.divider} />
      <div className={styles.content}>
        {global_is_viajero && (
          <a
            href="https://bit.ly/ViajerosGuvery"
            className="guv-no-decoration guv-block"
            target="_blank"
            rel="noopener noreferrer">
            <div className={styles.menuItem}>
              <WandIcon className={styles.wand} />
              Manual del Viajero
            </div>
          </a>
        )}
        <a
          href="https://bit.ly/Compradores-Guvery"
          className="guv-no-decoration guv-block"
          target="_blank"
          rel="noopener noreferrer">
          <div className={styles.menuItem}>
            <WandIcon className={styles.wand} />
            Manual del Comprador
          </div>
        </a>
        <a
          href="https://medium.com/guvery-help"
          className="guv-no-decoration guv-block"
          target="_blank"
          rel="noopener noreferrer">
          <div className={styles.menuItem}>
            <LifeBuoyIcon />
            Ayuda y Soporte
          </div>
        </a>
      </div>
      <div className={styles.divider} />
      <div className={styles.footer}>
        <Button
          disableElevation
          fullWidth
          color="secondary"
          className={styles.footerButton}
          onClick={handleLogout}
          startIcon={<LogOutIcon />}>
          Salir
        </Button>
      </div>
    </>
  );

  return (
    <>
      {isDesktop ? (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{ className: styles.userPopoverPaper }}>
          <span className={styles.arrow}></span>
          {content}
        </Popover>
      ) : (
        <Dialog fullWidth open={open} onClose={onClose}>
          <List>
            <ListItem button>
              <RouterLink to={ROUTES.USER_PROFILE} className={styles.itemLink}>
                <ListItemAvatar>
                  <Avatar
                    className="guv-background-base"
                    src={VARIABLES.IMAGE_PROFILE_PATH + usuario.pic + '.png'}
                    alt={usuario.nom}>
                    <PersonOutlineIcon className="guv-text-base" />
                  </Avatar>
                </ListItemAvatar>
                <span className="guv-font-size-5 guv-semibold">Ver mi perfil</span>
              </RouterLink>
            </ListItem>
            <ListItem button>
              <RouterLink to={ROUTES.USER_CHAT} className={styles.itemLink}>
                <ListItemAvatar>
                  <Avatar className="guv-background-base ">
                    <MessageSquareIcon className="guv-text-base" />
                  </Avatar>
                </ListItemAvatar>
                <span className="guv-font-size-5 guv-semibold">Mis chats</span>
                {chatsCount > 0 && <span className={styles.badge}>{chatsCount}</span>}
              </RouterLink>
            </ListItem>
            {global_is_viajero && (
              <ListItem button>
                <RouterLink to={ROUTES.TRAVELER_DASHBOARD} className={styles.itemLink}>
                  <ListItemAvatar>
                    <Avatar className="guv-background-base ">
                      <PlanenIcon className="guv-text-base" />
                    </Avatar>
                  </ListItemAvatar>
                  <span className="guv-font-size-5 guv-semibold">Viajero</span>
                </RouterLink>
              </ListItem>
            )}
            <ListItem button>
              <RouterLink to={ROUTES.USER_ORDERS} className={styles.itemLink}>
                <ListItemAvatar>
                  <Avatar className="guv-background-base ">
                    <ShoppingBagIcon className="guv-text-base" />
                  </Avatar>
                </ListItemAvatar>
                <span className="guv-font-size-5 guv-semibold">Mis Pedidos</span>
              </RouterLink>
            </ListItem>
            <Divider style={{ margin: '8px 0' }} />
            {global_is_viajero && (
              <ListItem button>
                <a
                  href="https://bit.ly/ViajerosGuvery"
                  className={styles.itemLink}
                  target="_blank"
                  rel="noopener noreferrer">
                  <ListItemAvatar>
                    <Avatar className="guv-background-base">
                      <WandIcon className={styles.wand} />
                    </Avatar>
                  </ListItemAvatar>
                  <span className="guv-font-size-5 guv-semibold">Manual del Viajero</span>
                </a>
              </ListItem>
            )}
            <ListItem button>
              <a
                href="https://bit.ly/Compradores-Guvery"
                className={styles.itemLink}
                target="_blank"
                rel="noopener noreferrer">
                <ListItemAvatar>
                  <Avatar className="guv-background-base guv-text-base">
                    <WandIcon className={styles.wand} />
                  </Avatar>
                </ListItemAvatar>
                <span className="guv-font-size-5 guv-semibold">Manual del Comprador</span>
              </a>
            </ListItem>
            <ListItem button>
              <a
                href="https://medium.com/guvery-help"
                className={styles.itemLink}
                target="_blank"
                rel="noopener noreferrer">
                <ListItemAvatar>
                  <Avatar className="guv-background-base ">
                    <LifeBuoyIcon className="guv-text-base" />
                  </Avatar>
                </ListItemAvatar>
                <span className="guv-font-size-5 guv-semibold">Ayuda y Soporte</span>
              </a>
            </ListItem>
            <Divider style={{ margin: '8px 0' }} />
            <ListItem button onClick={handleLogout}>
              <ListItemAvatar>
                <Avatar className="guv-background-base ">
                  <LogOutIcon className="guv-text-base" />
                </Avatar>
              </ListItemAvatar>
              <span className="guv-font-size-5 guv-semibold">Cerrar Sesión</span>
            </ListItem>
          </List>
        </Dialog>
      )}
    </>
  );
};

export default UserDropdownMenu;
