import { IconStar } from '@tabler/icons';
import clsx from 'clsx';

import { GuveryPaper } from '#root/ui-components/cards';
import styles from './rating.module.scss';

interface RatingProps {
  rating: any;
}

const Rating = (props: RatingProps) => {
  const { rating } = props;

  return (
    <GuveryPaper disableHover disableBorder disableElevation>
      <p className={clsx(styles.root, styles.rating)}>
        <span className={styles.text}>Calificaste al viajero con</span>
        <span className={styles.score}>
          {rating?.score} <IconStar />
        </span>
      </p>
    </GuveryPaper>
  );
};

export default Rating;
