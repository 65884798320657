import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Container } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';

import TripDTO from '#root/models/TripDTO';
import { tripService } from '#root/services';
import { useNotification, useDialog } from '#root/hooks';
import { GuveryPageHeader } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import { PlusIcon, PlanenIcon } from '#root/icons';
import { GlobeTrip } from '#root/assets';
import * as ROUTES from '#root/constants/navigation';
import TripCard from './TripCard';
import UpdateDialog from './UpdateDialog';
import styles from './travelerTrips.module.scss';

const TravelerTrips = () => {
  const updateDialog = useDialog();
  const { createMessage } = useNotification();
  const [trips, setTrips] = useState<TripDTO[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchTripsList = async () => {
    setLoading(true);
    const res = await tripService.list();
    // console.log(res);
    if (!res.error) {
      setTrips(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTripsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = (
    <>
      {trips.length !== 0 ? (
        <div className={styles.stack}>
          {trips.map((item, index) => (
            <TripCard
              key={index}
              trip={item}
              createMessage={createMessage}
              fetchTrips={fetchTripsList}
              openDialog={updateDialog.openDialog}
            />
          ))}
        </div>
      ) : (
        <div className={styles.noTrip}>
          <img src={GlobeTrip} alt="globe trip" />
          <h5>
            Crea tu primer viaje para poder hacer ofertas sobre los pedidos disponibles.
            Recuerda que sólo debes crear un viaje cuando tengas los pasajes comprados y las
            fechas confirmadas.
          </h5>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            startIcon={<PlanenIcon />}
            size="large"
            component={RouterLink}
            to={ROUTES.TRAVELER_TRIPS_NEW}>
            Crear mi viaje
          </Button>
        </div>
      )}
    </>
  );

  const btnNewTrip = (
    <Button
      variant="outlined"
      color="primary"
      startIcon={<PlusIcon />}
      component={RouterLink}
      to={ROUTES.TRAVELER_TRIPS_NEW}>
      Nuevo Viaje
    </Button>
  );

  return (
    <div className={clsx(styles.root, 'guv-main-container')}>
      <Container maxWidth="lg">
        <GuveryPageHeader title="Mis Viajes" extra={btnNewTrip} />
        {!loading ? (
          <>{content}</>
        ) : (
          <div className={styles.stack}>
            {Array.from(new Array(8)).map((_item, key) => (
              <GuveryPaper disableElevation disableHover key={key}>
                <Skeleton variant="rect" height={245} />
              </GuveryPaper>
            ))}
          </div>
        )}
      </Container>
      <UpdateDialog open={updateDialog.open} onClose={updateDialog.closeDialog} />
    </div>
  );
};

export default TravelerTrips;
