import { ReactNode } from 'react';
import clsx from 'clsx';

import styles from './radio.module.scss';

interface RadioProps {
  fullWidth?: boolean;
  center?: boolean;
  label?: string | number | ReactNode;
  option?: string | number | undefined;
  value?: string | number | undefined;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InternalRadio = (props: RadioProps) => {
  const { fullWidth = false, center = false, label, value, option, onChange } = props;

  return (
    <div className={fullWidth && styles.fullWidth}>
      <label className="radio-wrapper">
        <input
          type="radio"
          className={styles.input}
          value={value}
          checked={value === option}
          onChange={onChange}
        />
        <span className={styles.tile}>
          <span className={clsx(styles.label, center && styles.center)}>{label}</span>
        </span>
      </label>
    </div>
  );
};

export default InternalRadio;
