import SvgIcon from '@material-ui/core/SvgIcon';

const MessageSquareSvg = () => (
  <>
    <g data-name="Layer 2">
      <g data-name="message-square">
        <rect width={24} height={24} opacity={0} />
        <circle cx={12} cy={11} r={1} />
        <circle cx={16} cy={11} r={1} />
        <circle cx={8} cy={11} r={1} />
        <path d="M19 3H5a3 3 0 0 0-3 3v15a1 1 0 0 0 .51.87A1 1 0 0 0 3 22a1 1 0 0 0 .51-.14L8 19.14a1 1 0 0 1 .55-.14H19a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3zm1 13a1 1 0 0 1-1 1H8.55a3 3 0 0 0-1.55.43l-3 1.8V6a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1z" />
      </g>
    </g>
  </>
);

const MessageSquare = (props) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <MessageSquareSvg />
  </SvgIcon>
);

export default MessageSquare;
