import configService from './config';
// import fetchApi from './fetchApi';
import guveryFetchApi from './guveryFetchApi';
import * as METHODS from './methods';

const guveryApi = configService.apiGuvery;

const listByTraveler = async (offset = 0) => {
  const { response } = await guveryFetchApi({
    url: guveryApi + `/traveler/offers?offset=${offset}`,
    options: METHODS.GET(),
    message: {
      success: 'Ofertas obtenidas con exito.',
      error: 'No se pudo obtener las lista de ofertas.',
    },
  });

  return { ...response };
};

const getOfferById = async (offerId) => {
  const { response } = await guveryFetchApi({
    url: guveryApi + '/traveler/offer/' + offerId,
    options: METHODS.GET(),
    message: {
      success: 'Ofertas obtenidas con exito.',
      error: 'No se pudo obtener las lista de ofertas.',
    },
    avoidOverwriteData: true,
  });

  return { ...response };
};

const listBySearch = async (search: string | number, offset = 0) => {
  const { response } = await guveryFetchApi({
    url: guveryApi + `/traveler/offers?search=${search}&offset=${offset}`,
    options: METHODS.GET(),
    message: {
      success: 'Ofertas obtenidas con exito.',
      error: 'No se pudo obtener las lista de ofertas.',
    },
  });

  return { ...response };
};

const reject = async (offerId) => {
  const { response } = await guveryFetchApi({
    url: guveryApi + '/traveler/offer/reject',
    options: METHODS.POST({
      offer_id: offerId,
    }),
    message: {
      success: 'Ofertas obtenidas con exito.',
      error: 'No se pudo obtener las lista de ofertas.',
    },
  });

  return { ...response };
};

const extend = async (offerId) => {
  const { response } = await guveryFetchApi({
    url: guveryApi + '/traveler/offer/extend',
    options: METHODS.POST({
      offer_id: offerId,
    }),
    message: {
      success: 'Ofertas obtenidas con exito.',
      error: 'No se pudo obtener las lista de ofertas.',
    },
  });

  return { ...response };
};

const acceptByShopper = async (offerId) => {
  const { response } = await guveryFetchApi({
    url: guveryApi + '/offer/accept',
    options: METHODS.POST({
      offer_id: offerId,
    }),
    message: {
      success: 'Oferta aceptada con exito.',
      error: 'No se pudo obtener las lista de ofertas.',
    },
  });

  return { ...response };
};

const rejectByShopper = async (offerId) => {
  const { response } = await guveryFetchApi({
    url: guveryApi + '/offer/reject/shopper',
    options: METHODS.POST({
      offer_id: offerId,
    }),
    message: {
      success: 'Oferta rechazada con exito.',
      error: 'No se pudo obtener las lista de ofertas.',
    },
  });

  return { ...response };
};

const chatByShopper = async (offerId) => {
  const { response } = await guveryFetchApi({
    url: guveryApi + '/offer/chat',
    options: METHODS.POST({
      offer_id: offerId,
    }),
    message: {
      success: 'Ofertas obtenidas con exito.',
      error: 'No se pudo obtener las lista de ofertas.',
    },
  });

  return { ...response };
};

const offerService = {
  listByTraveler,
  getOfferById,
  listBySearch,
  reject,
  extend,
  acceptByShopper,
  rejectByShopper,
  chatByShopper,
};

export default offerService;
