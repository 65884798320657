import { MouseEvent } from 'react';
import { IconButton, Toolbar } from '@material-ui/core';

import { ArrowBackIcon, CloseIcon } from '#root/icons';
import styles from './styles.module.scss';

interface DrawerAppbarProps {
  isMobile?: boolean;
  title?: string;
  onClose: (e?: MouseEvent<HTMLElement>) => void;
}

const DrawerAppbar = (props: DrawerAppbarProps) => {
  const { isMobile, title, onClose } = props;

  return (
    <div className={styles.root}>
      <Toolbar>
        {isMobile && (
          <IconButton onClick={onClose}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <h6 className={styles.title}>{title}</h6>
        {!isMobile && (
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Toolbar>
    </div>
  );
};

export default DrawerAppbar;
