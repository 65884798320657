import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { IconInfoCircle, IconWallet } from '@tabler/icons';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import clsx from 'clsx';

import { useDialog, useAuth } from '#root/hooks';
import { GuverySpace } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import { USER_WALLET } from '#root/constants/navigation';
import RefundDialog from './RefundDialog';
import styles from './refund.module.scss';

const RefundStatus = {
  creado: 'CREATED',
  solicitado: 'REQUESTED',
  pagado: 'PAID',
} as const;

const formatTime = (date: string) => {
  const replaceDate = date?.replace(/ /g, 'T');
  const validDate = new Date(replaceDate);
  return format(validDate, "dd 'de' MMM',' YYY", { locale: es });
};

const Refund = (props) => {
  const { refund, banks, createMessage, handleLoading } = props;
  const { usuario } = useAuth();
  const refundDialog = useDialog();

  const state = refund?.current_status;
  const payDate = refund?.paid_at ? formatTime(refund?.paid_at) : '...';
  const validWallet = usuario.saldo_est === 1;
  const positiveBalance = validWallet && Number(usuario.saldo) > 0;

  const isCreated = state === RefundStatus.creado;
  const isRequested = state === RefundStatus.solicitado;
  const isPaid = state === RefundStatus.pagado;

  return (
    <GuveryPaper disableHover disableElevation disableBorder={!isCreated}>
      {isCreated && positiveBalance && (
        <div className={styles.root}>
          <GuverySpace direction="vertical" size="middle">
            <p className={styles.hint}>
              Puedes ver y utilizar tu saldo dentro de Guvery. No tiene vencimiento
            </p>
            <Button
              disableElevation
              fullWidth
              variant="contained"
              color="primary"
              onClick={refundDialog.openDialog}>
              Solicita tu reembolso
            </Button>
            <Button
              disableElevation
              fullWidth
              variant="outlined"
              color="secondary"
              component={RouterLink}
              to={USER_WALLET}
              startIcon={<IconWallet />}>
              Mi Billetera
            </Button>
          </GuverySpace>
          <RefundDialog
            open={refundDialog.open}
            onClose={refundDialog.closeDialog}
            refundId={refund?.id}
            banks={banks}
            createMessage={createMessage}
            handleLoading={handleLoading}
          />
        </div>
      )}
      {isRequested && (
        <div className={clsx(styles.root, styles.request)}>
          <IconInfoCircle size={22} />
          <p>El reembolso se está procesando...</p>
        </div>
      )}
      {isPaid && (
        <div className={clsx(styles.root, styles.paid)}>
          <IconInfoCircle size={22} />
          <p>El reembolso ha sido pagado el {payDate}.</p>
        </div>
      )}
    </GuveryPaper>
  );
};

export default Refund;
