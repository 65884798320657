import React from 'react';
import { List, ListItem } from '@material-ui/core';
import { IconCheck } from '@tabler/icons';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import clsx from 'clsx';

// import { useResponsive } from '#root/hooks';
// import { orderStatus } from '#root/interfaces/orderDTO';
import { GuveryMainCard } from '#root/ui-components/cards';
import {
  ShippingHandshake,
  ShippingBox,
  ShippingSearch,
  ShippingDelivery,
  ShippingPay,
  Bag,
} from '#root/assets';
import styles from './timeline.module.scss';

const formatTime = (date: string) => {
  let time: string | null;
  if (!date) {
    return;
  }
  try {
    time = format(new Date(date.replace(/ /g, 'T')), "dd 'de' MMM ',' YYY", { locale: es });
  } catch (error) {
    time = 'Error';
  }
  return time;
};

const Timeline = (props) => {
  const { order = null, isOne, isExpress, isCanceled } = props;
  const [activeStep, setActiveStep] = React.useState(1);
  const [steps, setSteps] = React.useState([]);

  const offers = order?.offers;
  const hasOffers = offers?.length > 0;
  const timeOffer = hasOffers ? offers[0]?.feccre : null;

  const offerAccepted = offers?.find((offer) => offer?.fecace);

  const getBasicSteps = [
    {
      title: 'Creado',
      time: formatTime(order?.feccre),
      icon: Bag,
    },
    {
      title: 'Publicado',
      time: formatTime(order?.fecpub),
      icon: ShippingBox,
    },
    {
      title: 'Ofertado',
      time: formatTime(timeOffer),
      icon: ShippingSearch,
    },
    {
      title: 'Oferta Aceptada',
      time: formatTime(offerAccepted?.fecace),
      icon: ShippingHandshake,
    },
  ];

  const getCanceledSteps = [
    {
      title: 'Creado',
      time: formatTime(order?.feccre),
      icon: Bag,
    },
    {
      title: 'Publicado',
      time: formatTime(order?.fecpub),
      icon: ShippingBox,
    },
    {
      title: 'Expirado',
      time: formatTime(order?.fecmax),
      icon: '',
    },
  ];

  const getOneSteps = [
    {
      title: 'Creado',
      time: formatTime(order?.feccre),
      icon: ShippingHandshake,
    },
    {
      title: 'Buscando Viajero',
      time: formatTime(order?.fecpub),
      icon: ShippingSearch,
    },
    {
      title: 'Viajero Asignado',
      time: formatTime(timeOffer),
      icon: ShippingDelivery,
    },
  ];

  const getExpressSteps = [
    {
      title: 'Creado',
      time: formatTime(order?.feccre),
      icon: ShippingHandshake,
    },
    {
      title: 'Pagado',
      time: formatTime(order?.fecpub),
      icon: ShippingPay,
    },
    {
      title: 'Viajero asignado',
      time: null,
      icon: ShippingSearch,
    },
  ];

  const handleBasicSteps = () => {
    setSteps(getBasicSteps);
    if (timeOffer) {
      setActiveStep(3);
      return;
    }
    if (order?.fecpub) {
      setActiveStep(2);
      return;
    }
  };

  const handleOneSteps = () => {
    setSteps(getOneSteps);
    if (timeOffer) {
      setActiveStep(3);
      return;
    }
    if (order?.fecpub) {
      setActiveStep(2);
      return;
    }
  };

  const handleExpressSteps = () => {
    setSteps(getExpressSteps);
    if (timeOffer) {
      setActiveStep(3);
      return;
    }
    if (order?.fecpub) {
      setActiveStep(2);
      return;
    }
  };

  const handleCanceledSteps = () => {
    setSteps(getCanceledSteps);
    if (order?.fecmax) {
      setActiveStep(3);
      return;
    }
    if (order?.fecpub) {
      setActiveStep(2);
      return;
    }
    if (order?.feccre) {
      setActiveStep(1);
      return;
    }
  };

  const handleSteps = () => {
    if (isCanceled) {
      handleCanceledSteps();
      return;
    }
    if (isOne) {
      handleOneSteps();
      return;
    }
    if (isExpress) {
      handleExpressSteps();
      return;
    }
    handleBasicSteps();
  };

  React.useEffect(() => {
    handleSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOne, isCanceled]);

  return (
    <GuveryMainCard className={styles.card} title="Historial del Pedido">
      <div className={styles.mobileTabs}>
        <List disablePadding className={styles.mobileList}>
          {steps.map((step, index) => {
            const active = index === activeStep ? true : false;
            const completed = index < activeStep ? true : false;
            return (
              <ListItem key={index} style={{ padding: '14px 16px' }}>
                <div className={styles.iconWrap}>
                  <div
                    className={clsx(styles.icon, styles.iconMobile, {
                      [styles.activeState]: active,
                    })}>
                    {step.icon && <img src={step.icon} alt="step" />}
                    {completed && (
                      <div className={styles.coverCompleted}>
                        <div className={styles.iconCompleted}>
                          <IconCheck size={18} stroke={3} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.mobileText}>
                  <h5
                    className={clsx(styles.status, {
                      [styles.statusComplete]: completed,
                    })}>
                    {step.title}
                  </h5>
                  {active && !step.time && <p className={styles.time}>Esperando...</p>}
                  {step.time && <p className={styles.time}>{step.time}</p>}
                </div>
              </ListItem>
            );
          })}
        </List>
        <div className={styles.mobileLine}></div>
      </div>
    </GuveryMainCard>
  );
};

export default Timeline;
