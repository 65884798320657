import { ChangeEvent, useState, useEffect } from 'react';
import { Button, Radio, RadioGroup, FormControlLabel, FormControl } from '@material-ui/core';
import { IconUsers, IconChecks } from '@tabler/icons';

import { GuveryDialog } from '#root/ui-components';
import styles from './partnersDialog.module.scss';

const PartnersDialog = (props) => {
  const { id, partners = [], open = false, onClose, onConfirm } = props;
  const [partnerId, setPartnerId] = useState('');
  const [disableBtn, setDisableBtn] = useState(true);

  const handlePartnerChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPartnerId((event.target as HTMLInputElement).value);
    setDisableBtn(false);
  };

  const handleSubmit = () => {
    setDisableBtn(true);
    onConfirm(partnerId, id);
  };

  useEffect(() => {
    if (open) {
      setPartnerId('');
      setDisableBtn(true);
    }
  }, [open]);

  return (
    <GuveryDialog open={open} onClose={onClose} title={`Acuerdo ${id}`} content={false}>
      <div className={styles.root}>
        <div className={styles.title}>
          <IconUsers size={22} />
          <h4>Asignar Socio</h4>
        </div>
        <div className={styles.content}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="partner"
              name="partner"
              value={partnerId}
              onChange={handlePartnerChange}>
              {partners.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item?.idvs?.toString()}
                  control={<Radio />}
                  label={item?.nombre}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
        <div className={styles.actions}>
          <Button
            disableElevation
            disabled={disableBtn}
            variant="contained"
            color="secondary"
            className={styles.confirm}
            startIcon={<IconChecks size={20} />}
            onClick={handleSubmit}>
            Confirmar Socio
          </Button>
        </div>
      </div>
    </GuveryDialog>
  );
};

export default PartnersDialog;
