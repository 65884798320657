import { useState, useEffect, ChangeEvent } from 'react';
import { Button, TextField, InputAdornment } from '@material-ui/core';
import { IconArrowRight } from '@tabler/icons';
import clsx from 'clsx';

import { shopperService } from '#root/services';
import { useAuth } from '#root/hooks';
import { changeCurrencySymbol } from '#root/utils';
import { roundDecimal } from '#root/utils';
import styles from './instructions.module.scss';

interface StepsList {
  [index: number]: string;
}

interface InstructionsProps {
  defaultAmount?: any;
  paymentData?: any;
  sourceCurrency?: any;
  sourceAmount?: any;
  allowCoupon?: boolean;
  setDefaultAmount?: any;
  setPaymentData?: any;
  id?: string | number;
  currency: any;
  amountHelper?: string;
  stepsArray?: StepsList[];
  showCredits?: boolean;
  activeNextStep: Function;
}

const StepLabel = ({ position, label }) => (
  <div className={styles.stepLabel}>
    <span className={styles.circle}>{position}</span>
    <span className={styles.label}>{label}</span>
  </div>
);

const Instructions = (props: InstructionsProps) => {
  const {
    sourceCurrency,
    sourceAmount,
    defaultAmount,
    paymentData,
    allowCoupon,
    setDefaultAmount,
    setPaymentData,
    id,
    currency,
    amountHelper,
    stepsArray = [],
    showCredits,
    activeNextStep,
  } = props;
  const { usuario } = useAuth();
  // Payment data
  // const amount = paymentData.amount;
  const hasCoupon = paymentData.coupon.active;
  const couponName = paymentData.coupon.name;
  const couponCode = paymentData.coupon.code;
  const couponHint = paymentData.coupon.hint;

  const walletState = Number(usuario.saldo) < 0 ? 'negative' : 'positive';
  const userMoney = usuario.saldo !== null ? usuario.saldo : 0;

  const [openCouponInput, setOpenCouponInput] = useState(hasCoupon);
  const [coupon, setCoupon] = useState({
    input: couponCode,
    state: hasCoupon && 'success',
  });

  const handleCouponChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCoupon({ ...coupon, input: event.target.value });
  };

  const handleActiveCoupon = async () => {
    const res = await shopperService.couponDeal(id, coupon.input);
    // console.log(res);
    if (!res.error) {
      const discount = roundDecimal(Number(res.data?.disccount));
      const oldAmount = Number(sourceAmount);
      const newAmount = oldAmount - discount;
      setCoupon({
        ...coupon,
        state: 'success',
      });
      setDefaultAmount(newAmount);
      setPaymentData({
        amount: newAmount,
        discount: discount,
        coupon: {
          active: true,
          name: res.data.name,
          code: coupon.input,
          hint: res.message,
        },
      });
    } else {
      setCoupon({
        ...coupon,
        state: 'error',
      });
      setDefaultAmount(sourceAmount);
      setPaymentData({
        amount: sourceAmount,
        discount: 0,
        coupon: {
          active: false,
          name: '',
          code: null,
          hint: res.message,
        },
      });
    }
  };

  useEffect(() => {
    activeNextStep(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.root}>
      <h3 className={styles.title}>Instrucciones</h3>
      <div className={styles.steps}>
        {stepsArray.map((item, index) => (
          <StepLabel key={index} position={index + 1} label={item} />
        ))}
      </div>
      {showCredits && usuario.saldo_est === 1 && (
        <div className={styles.amount}>
          <span className={styles.header}>Tu saldo actual es:</span>
          <span className={clsx(styles.total, styles[walletState])}>{userMoney + ' US$'}</span>
        </div>
      )}
      {!showCredits && (
        <div className={styles.amount}>
          <span className={styles.header}>{amountHelper}</span>
          <span>
            <span className={clsx(styles.total, hasCoupon && styles.totalOld)}>
              {changeCurrencySymbol(sourceCurrency, 'Symbol') +
                ' ' +
                roundDecimal(sourceAmount)}
            </span>
            {hasCoupon && (
              <>
                <span className={styles.amountDivider}>
                  <IconArrowRight size={20} />
                </span>
                <span className={styles.total}>
                  {changeCurrencySymbol(currency, 'Symbol') + ' ' + roundDecimal(defaultAmount)}
                </span>
              </>
            )}
          </span>
        </div>
      )}
      {coupon.state && (
        <div className={clsx(styles.couponHint, styles[coupon.state])}>
          <div>
            <p>
              {couponHint}
              {couponName && <span className={styles.italic}>, {couponName}</span>}
            </p>
          </div>
        </div>
      )}
      {allowCoupon && (
        <>
          {openCouponInput ? (
            <div className={styles.discount}>
              <TextField
                disabled={hasCoupon}
                id="amount"
                variant="outlined"
                color="primary"
                placeholder="Cupón"
                value={coupon.input}
                onChange={handleCouponChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Button disabled={hasCoupon} onClick={handleActiveCoupon}>
                        Aplicar
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          ) : (
            <div className={styles.helpCoupon}>
              <span onClick={() => setOpenCouponInput(!openCouponInput)}>
                ¿Tienes un Cupon?
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Instructions;
