import { RankingProgress } from '#root/components';
import { getMinMax } from '#root/utils';
import styles from './styles.module.scss';

const getMinMaxScoring = (arr = []) => {
  const max = Object.entries(arr).reduce((prev, curr) => {
    return prev[1] > curr[1] ? prev : curr;
  });

  const min = Object.entries(arr).reduce((prev, curr) => {
    return prev[1] < curr[1] ? prev : curr;
  });

  return [min, max];
};

const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

const ScoreCriteria = (props) => {
  const { criteria } = props;

  const [min, max] = getMinMaxScoring(criteria?.scoring);
  const scoringMinFilter = Object.entries(criteria?.scoring).filter(
    (e) => !isEmptyObject(e[0]),
  );
  const scoringMin = scoringMinFilter?.map((e) => e[1]);
  const scoringMinHeader = scoringMinFilter?.map((e) => e[0]);
  const criteriaMetricShowed =
    criteria?.is_empty && criteria?.suffix === ''
      ? '#'
      : (criteria?.traveler_criteria_metric ?? '') + (criteria?.suffix ?? '');

  return (
    <div className={styles.scoreCriteria}>
      <div className={styles.criteria}>
        <h4>{criteria?.title}:</h4>
        <p>{criteria?.description}</p>
      </div>
      <div>
        {criteria?.is_empty && criteria.is_empty ? (
          <>
            <div className={styles.criteriaMetricsEmpty}>
              <p>(Sin datos suficientes)</p>
              {criteria?.constraint && (
                <p className={styles.constraint}>{criteria?.constraint}</p>
              )}
              <h4>N/A</h4>
            </div>
            <div className={styles.criteriaProgressEmpty}>
              <RankingProgress
                noScore={true}
                score={null}
                metric={criteria?.traveler_criteria_metric}
                totalMin={min[1]}
                totalMax={max[1]}
                listMin={scoringMin}
                listMinHeader={scoringMinHeader}
                headerSufix={criteria?.suffix && criteria?.suffix}
                bufferOn={true}
              />
            </div>
          </>
        ) : (
          <>
            <div className={styles.criteriaMetrics}>
              <h4>
                {criteria?.traveler_criteria_metric ? criteria?.traveler_criteria_metric : '--'}
                {criteria?.suffix && criteria?.suffix}
              </h4>
              <h4>
                {criteria?.traveler_criteria_score ? criteria?.traveler_criteria_score : '0'}{' '}
                pts
              </h4>
            </div>
            <div className={styles.criteriaProgress}>
              <RankingProgress
                score={criteria?.traveler_criteria_score}
                metric={criteria?.traveler_criteria_metric}
                totalMin={min[1]}
                totalMax={max[1]}
                listMin={scoringMin}
                listMinHeader={scoringMinHeader}
                headerSufix={criteria?.suffix && criteria?.suffix}
                bufferOn={true}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ScoreCriteria;
