import { GuveryDialog } from '#root/ui-components';
import { Button } from '@material-ui/core';
import { WhatsappIcon } from '#root/icons';
import styles from './dialogHelp.module.scss';

interface DialogHelpProps {
  open: boolean;
  onClose: (e?: React.MouseEvent<HTMLElement>) => void;
}

const DialogHelp = (props: DialogHelpProps) => {
  const { open = false, onClose } = props;

  return (
    <GuveryDialog
      open={open}
      onClose={onClose}
      title="Proceso y costo de envío"
      closeText="Cerrar">
      <div className={styles.root}>
        <p className="guv-font-size-5">
          Hacemos envíos de Lunes a Viernes vía MBE (MailBoxes ETC), aliado de OLVA COURIER.
        </p>
        <h4 className="guv-text-darker">Proceso:</h4>
        <ul>
          <li>El viajero entrega tu pedido en la oficina de MBE.</li>
          <li><strong>Recibes una notificación para confirmar tu dirección.</strong></li>
          <li>MBE registrará y embalará tu pedido.</li>
          <li><strong>Realizas el pago cuando tu envío está apunto de salir.</strong></li>
          <li>OLVA recoge tu pedido e inicia su camino hacia tu dirección.</li>
        </ul>
        <h4 className="guv-text-darker">El costo del envío es pagado por el comprador.</h4>
        <p className="guv-font-size-5">Olva Courier nos brinda el costo, el cual depende de:</p>
        <ul>
          <li>La provincia destino.</li>
          <li>Seguro acorde a la Valorización*.</li>
          <li>Dimensiones y peso.</li>
        </ul>
        <p className="guv-font-size-6">
          * Los envíos valorados, viajan con una garantía de hasta el 95% sobre el valor
          declarado.
        </p>
        <br />
        <hr className={styles.divider} />
        <br />
        <div className={styles.actions}>
          <p className="guv-font-size-5">Para mas información contáctanos vía whatsapp</p>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            startIcon={<WhatsappIcon />}
            href="https://api.whatsapp.com/send?phone=51960684330&text=Hola!"
            target="_blank"
            rel="noopener noreferrer">
            +51 960684330
          </Button>
        </div>
      </div>
    </GuveryDialog>
  );
};

export default DialogHelp;
