import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { useAuth } from '#root/hooks';
import { roundDecimal } from '#root/utils';
import { ExpirationDays } from '#root/components';
import styles from './offerPrice.module.scss';

const commissionGuvery = (price, com, guv, guvTope, guvExtra) => {
  const sumPrices = Number(price) + Number(com);
  const getPercent = (sumPrices * Number(guv)) / 100;
  const getMaxPercent = getPercent > Number(guvTope) ? Number(guvTope) : getPercent;
  const commission = getMaxPercent + Number(guvExtra);

  return commission;
};

const OfferPrice = (props) => {
  const { offer, isOfferRejected } = props;
  const { negocio } = useAuth();

  const comGuveryPCT = Number(negocio?.com_porc);

  const priceReal = Number(offer?.prerea);
  const comTraveler = Number(offer?.comvia);
  const comGuvery = Number(
    commissionGuvery(
      priceReal,
      comTraveler,
      comGuveryPCT,
      negocio?.com_tope,
      negocio?.com_link,
    ),
  );
  const total = priceReal + comTraveler + comGuvery;

  return (
    <div className={styles.root}>
      <TableContainer>
        <Table className={styles.table}>
          <TableHead className={styles.header}>
            <TableRow>
              <TableCell>NOMBRE</TableCell>
              <TableCell>CANTIDAD</TableCell>
              <TableCell>TOTAL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={styles.body}>
            <TableRow>
              <TableCell>Precio Real *</TableCell>
              <TableCell>{offer?.can}</TableCell>
              <TableCell>$ {roundDecimal(priceReal)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Viajero</TableCell>
              <TableCell></TableCell>
              <TableCell>$ {roundDecimal(comTraveler)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Guvery (%)</TableCell>
              <TableCell></TableCell>
              <TableCell>$ {roundDecimal(comGuvery)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className={styles.total}>Total</span>
              </TableCell>
              <TableCell></TableCell>
              <TableCell>
                <span className={styles.total}>$ {roundDecimal(total)}</span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.asterisk}>
          <p>* El Precio Real incluye los impuestos de la ciudad donde está el viajero y todos los gatos asociados con la compra del pedido (gastos de envío, etc).</p>
      </div>
      {!isOfferRejected && offer?.fixed_expiration_days !== undefined && (
        <ExpirationDays expirationDays={offer.fixed_expiration_days} style={{ fontStyle: 'italic', marginTop: 2 }} />
      )}
      {isOfferRejected && <span className={styles.offerRejected}>Rechazado</span>}
    </div>
  );
};

export default OfferPrice;
