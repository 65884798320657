import { CSSProperties, useEffect, useState } from 'react';
import { IconClock } from '@tabler/icons';
import { format, compareDesc } from 'date-fns';
import { es } from 'date-fns/locale';
import clsx from 'clsx';

import { validFormatDistanceStrict } from '#root/utils';
import styles from './distanceExpire.module.scss';

interface DistanceExpireProps {
  className?: string;
  style?: CSSProperties;
  date: string;
  disableIcon?: boolean;
  prefix?: string;
  errorPrefix?: string;
}

const formatToday = () => {
  try {
    const time = format(new Date(), "YYY'-'MM'-'dd", { locale: es });
    return time;
  } catch (error) {
    return 'Error';
  }
};

const DistanceExpire = (props: DistanceExpireProps) => {
  const { className, style, date, disableIcon = false, prefix, errorPrefix } = props;
  const [dateText, setDateText] = useState('');
  const [state, setState] = useState({
    error_expired: true,
    error: false,
    warning: false,
    default: false,
  });
  const formatDate = new Date(date.replace(/ /g, 'T'));
  const today = new Date();

  const isValidExpire = compareDesc(formatDate, today) !== 1 ? true : false;
  // -1 first > second VIGENTE
  // 0 equals VIGENTE
  // 1 first < second EXPIRADO

  const handleState = () => {
    const startDateMS = today.getTime();
    const endDateMS = formatDate.getTime();
    const diff = endDateMS - startDateMS;
    const diffDays = diff / (1000 * 60 * 60 * 24);

    const isErrorExpired = diffDays < 0 ? true : false;
    const isError = diffDays < 3 ? true : false;
    const isWarning = diffDays >= 3 && diffDays < 10 ? true : false;
    const isDefault = diffDays >= 10 ? true : false;

    setState({
      error: isError,
      error_expired: isErrorExpired,
      warning: isWarning,
      default: isDefault,
    });
  };

  const handleDateText = () => {
    const textToday = formatToday();
    if (textToday === date) {
      if (prefix) {
        setDateText(prefix + ' vence hoy');
        return;
      }
      setDateText('Vence hoy');
      return;
    }
    if (isValidExpire) {
      const distanceToExpire = date ? validFormatDistanceStrict((date + 'T23:59:59')) : '---';
      if (prefix) {
        setDateText(prefix + ' vence en ' + distanceToExpire);
        return;
      }
      setDateText('Vence en ' + distanceToExpire);
      return;
    }
    if (errorPrefix) {
      setDateText(errorPrefix + ' venció');
      return;
    }
    setDateText('Venció');
  };

  useEffect(() => {
    if (isValidExpire && date) {
      handleState();
    }
    handleDateText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, isValidExpire]);

  const cn = clsx(
    styles.root,
    {
      [styles.error]: state.error,
      [styles.error_expired]: state.error_expired,
      [styles.warning]: state.warning,
      [styles.default]: state.default,
    },
    className,
  );

  return (
    <div className={cn} style={{ ...style }}>
      {!disableIcon && <IconClock size={14} />}
      <div className={styles.text}>{dateText}.</div>
    </div>
  );
};

export default DistanceExpire;
