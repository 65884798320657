// interface RefundDTO {
//     id: number
//     entity: string
//     entity_id: number
//     user_id: number
//     bank?: string | null
//     currency?: string | null
//     amount?: string | null
//     tca?: string | null
//     bank_account_number: string
//     bank_account_holder: string
//     current_status: string
//     requested_at?: string | null
//     paid_at?: string | null
//     closed_at?: string | null
//     created_at: string
//     updated_at?: string | null
//     deleted_at?: string | null
//   }

  const REFUND_STATUS = {
    'CREATED': 'Esperando',
    'REQUESTED': 'Procesando...',
    'PAID': 'Pagado',
    'CLOSED': 'Cerrado',
  }

  const refund_label = (current_status?: string | null) => {
    return REFUND_STATUS[current_status] ?? 'Indeterminado';
  }

  export default refund_label;
  