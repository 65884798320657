import RatingDTO from '#root/interfaces/RatingDTO';
import DeliverySetupDTO from './DeliverySetupDTO';
import DealChangelogDTO from './DealChangelogDTO';

export const DealStatus = {
  paying: 'PAGADO_COM',
  created: 'CREADO',
  paid: 'PAGADO_ADM',
  buyed: 'COMPRADO',
  recivedTraveler: 'RECIBIDO_VIA',
  arrived: 'POR_ENTREGAR',
  confirmedTraveler: 'ENTREGADO_VIA',
  confirmedShopper: 'RECIBIDO_COM',
  payedTraveler: 'PAGADO_VIA',
  rejectedTraveler: 'ANULADO_VIA',
  rejectedAdmin: 'ANULADO_ADM',
  rejectedShopper: 'ANULADO_COM',
  refunded: 'REEMBOLSO_COM',
  blocked: 'ELIMINADO',
} as const;

export type DealStatusType = typeof DealStatus[keyof typeof DealStatus];

export const IN_STATUS_CREATED: Array<DealStatusType> = [DealStatus.created, DealStatus.paying];

export const IN_STATUS_PAID: Array<DealStatusType> = [
  DealStatus.paid,
  DealStatus.buyed,
  DealStatus.recivedTraveler,
  DealStatus.arrived,
];

export const IN_STATUS_BUYED: Array<DealStatusType> = [
  DealStatus.buyed,
  DealStatus.recivedTraveler,
  DealStatus.arrived,
];

export const IN_STATUS_RECEIVED_TRAVELER: Array<DealStatusType> = [
  DealStatus.recivedTraveler,
  DealStatus.arrived,
];

export const IN_STATUS_DELIVERED: Array<DealStatusType> = [
  DealStatus.confirmedTraveler,
  DealStatus.confirmedShopper,
  DealStatus.payedTraveler,
];

export const IN_STATUS_REJECTED: Array<DealStatusType> = [
  DealStatus.rejectedTraveler,
  DealStatus.rejectedAdmin,
  DealStatus.rejectedShopper,
  DealStatus.refunded,
];

export const IN_STATUS_PAID_DELIVERED: Array<DealStatusType> = [
  DealStatus.paid,
  DealStatus.buyed,
  DealStatus.recivedTraveler,
  DealStatus.arrived,
  DealStatus.confirmedTraveler,
  DealStatus.confirmedShopper,
  DealStatus.payedTraveler,
];

export const PackageStatus = {
  sealed: 'sellado',
  openBox: 'caja abierta',
  noBox: 'sin caja',
} as const;

export type PackageStatusType = typeof PackageStatus[keyof typeof PackageStatus];

interface DealDTO {
  cantot?: number;
  guvery_fee?: string;
  guvery_express_fee?: string;
  guvery_match_fee?: string;
  comtot?: number;
  deal_orders?: any;
  dscto?: any;
  dscto_motivo?: any;
  empaque?: PackageStatusType;
  est_n: DealStatusType;
  feccre?: string | Date;
  fecent?: string | Date;
  fixed_delivery_date?: string;
  fecmax?: string | Date;
  fixed_expiration_days?: number | null;
  fecentcom?: string | Date;
  fecentvia?: string | Date;
  ida: number;
  ido: number;
  flujo?: any;
  lugent?: string;
  delivery_setup?: DeliverySetupDTO | null;
  unclosed_changelogs?: DealChangelogDTO | null;
  offer?: any;
  order?: any;
  pretot?: number;
  rating_to_traveler?: RatingDTO | null;
  rating_to_shopper?: RatingDTO | null;
  status_label?: string;
  current_status_date?: string;
  total?: number;
  total_soles?: number;
  urltot?: number;
  partnership_travelers?: any;
}

export default DealDTO;
