export const GET_EXTERNAL = (): RequestInit => {
  return {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
};

export const GET_EXTERNAL_2 = (): RequestInit => {
  return {
    method: 'GET',
  };
};

export const GET = (): RequestInit => {
  return {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    //mode: 'same-origin',
    // withCredentials: true
  };
};

export const POST = (body?): RequestInit => {
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(body),
  };
};

export const POST_FORM_DATA = (body): RequestInit => {
  return {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    credentials: 'include',
    body: body,
  };
};

export const POST_FORM_DATA_NODE = (body): RequestInit => {
  return {
    method: 'POST',
    // credentials: 'include',
    body: body,
  };
};

export const PUT = (body): RequestInit => {
  return {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(body),
  };
};

export const PUT_FORM_DATA = (body): RequestInit => {
  return {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
    },
    body: body,
  };
};

export const DELETE = (): RequestInit => {
  return {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  };
};
