import ItemModel from '#root/interfaces/Item';
import { Button, ListItem } from '@material-ui/core';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';

import { DoneAllIcon } from '#root/icons';

import './navItem.scss';

interface NavItemProps extends ItemModel {
  alert?: boolean;
  done?: boolean;
  redirect?: boolean;
}

const NavItem = ({
  alert = false,
  done = false,
  external,
  href,
  icon: Icon,
  redirect,
  title,
  ...rest
}: NavItemProps) => {
  const location = useLocation();

  const active = href
    ? !!matchPath(location.pathname, { path: href, exact: false /*, end: false*/ })
    : false;

  const routerProps = external ? { href: href } : { to: href };
  const redirectProps = redirect && { target: '_blank', rel: 'noopener noreferrer' };

  return (
    <ListItem disableGutters className="guvery-nav-item" {...rest}>
      <Button
        disableRipple={active}
        className={`guvery-nav-item__link ${active && 'link-active'}`}
        component={external ? 'a' : RouterLink}
        {...routerProps}
        {...redirectProps}>
        {Icon && <Icon />}
        <span>{title}</span>
        {alert && <span className="dot" />}
        {done && (
          <span className="done">
            <DoneAllIcon />
          </span>
        )}
      </Button>
    </ListItem>
  );
};

export default NavItem;
