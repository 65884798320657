import { ChangeEvent } from 'react';
import { TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { es } from 'date-fns/locale';

import { GuverySpace } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import styles from './dealForm.module.scss';

const DealForm = (props) => {
  const { isProvince, dealForm, setDealForm } = props;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDealForm({
      ...dealForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = (name: string) => (e) => {
    setDealForm({
      ...dealForm,
      [name]: e,
    });
  };

  return (
    <GuveryPaper disableHover disableElevation className={styles.root}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <span className={styles.headerTitle}>Detalles de la entrega</span>
        </div>
      </div>
      <hr className={styles.divider} />
      <div className={styles.body}>
        <GuverySpace direction="vertical" size="large">
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
            <KeyboardDatePicker
              disableToolbar
              inputVariant="outlined"
              format="dd/MM/yyyy"
              label="Fecha de entrega"
              value={dealForm.delivery_date}
              onChange={handleDateChange('delivery_date')}
              minDate={new Date()}
              minDateMessage="No puedes escoger una fecha anterior a hoy."
              InputAdornmentProps={{ position: 'start' }}
              invalidDateMessage="Formato de fecha no válido"
            />
          </MuiPickersUtilsProvider>
          <TextField
            disabled={isProvince}
            id="delivery_place"
            name="delivery_place"
            label="Lugar de entrega"
            variant="outlined"
            value={isProvince ? 'MailBoxes ETC' : dealForm.delivery_place}
            onChange={handleInputChange}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
            <KeyboardDatePicker
              disableToolbar
              inputVariant="outlined"
              format="dd/MM/yyyy"
              label="El acuerdo vence el"
              value={dealForm.expiration_date}
              onChange={handleDateChange('expiration_date')}
              minDate={new Date()}
              minDateMessage="No puedes escoger una fecha anterior a hoy."
              maxDate={dealForm.delivery_date}
              maxDateMessage="El vencimiento debe ser anterior a la fecha de entrega."
              InputAdornmentProps={{ position: 'start' }}
              invalidDateMessage="Formato de fecha no válido"
            />
          </MuiPickersUtilsProvider>
        </GuverySpace>
      </div>
    </GuveryPaper>
  );
};

export default DealForm;
