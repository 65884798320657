import { Button } from '@material-ui/core';

import { GuveryDialog } from '#root/ui-components';
import { WhatsappIcon } from '#root/icons';
import styles from './updateDialog.module.scss';

const UpdateDialog = (props) => {
  const { open = false, onClose } = props;

  return (
    <GuveryDialog open={open} onClose={onClose} title="Modificar Viaje" closeText="Cerrar">
      <div className={styles.root}>
        <p className={styles.desc}>
          Comunícate con Guvery para poder modificar la información de tu viaje.
        </p>
        <div className={styles.action}>
          <p className={styles.actionTitle}>Contáctanos vía whatsapp</p>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            startIcon={<WhatsappIcon />}
            href="https://api.whatsapp.com/send?phone=51960684330&text=Hola!"
            className="guv-no-decoration"
            target="_blank"
            rel="noopener noreferrer">
            +51 960684330
          </Button>
        </div>
      </div>
    </GuveryDialog>
  );
};

export default UpdateDialog;
