import { useState, useEffect } from 'react';
import { shopperService } from '#root/services';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { IconArrowRight } from '@tabler/icons';
import { TravelerCard } from '#root/components';
import styles from './styles.module.scss';
import clsx from 'clsx';

const travelerProfile_default = {
  deals_delivered_qty: 0,
  deals_delivered_percent: 0,
};

const levelBreaks_default = [
  {
    icon: '💎',
    title: 'Super Viajero',
    order: 3,
    selected: false,
  },
  {
    icon: '🎖️',
    title: 'Viajero Experimentado',
    order: 2,
    selected: false,
  },
  {
    icon: '',
    title: 'Viajero Regular',
    order: 1,
    selected: true,
  },
];

const TravelerReviewsDialog = (props) => {
  const { open, onClose, data } = props;
  const traveler = data?.traveler;
  const avoidReviews = data?.avoidReviews ?? false;

  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [totalReviewsQty, setTotalReviewsQty] = useState(0);
  const [travelerProfile, setTravelerProfile] = useState(travelerProfile_default);
  const [levelBreaks, setLevelBreaks] = useState(levelBreaks_default);

  const handleClose = () => {
    onClose();
  };

  const fetchReviews = async () => {
    if (!traveler) return;
    setLoading(true);
    if (avoidReviews) {
      setLoading(false);
      return;
    }
    const res = await shopperService.travelerReviews(traveler.idu);
    if (res.error) return setLoading(false);
    setReviews(res.data.reviews);
    setTravelerProfile(res.data.profile);
    const orderedBreaks = res.data.breaks.reverse();
    setLevelBreaks(orderedBreaks);
    setTotalReviewsQty(res.data.total_reviews_qty);
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      fetchReviews();
    } else {
      setReviews([]);
    }
  }, [open]);

  const Title = (props) => {
    const deliveredPercent = travelerProfile.deals_delivered_percent
      ? Math.round(travelerProfile.deals_delivered_percent * 100) + '%'
      : '0 %';
    return (
      <div className={styles.title}>
        <div className={styles.traveler}>
          <TravelerCard traveler={traveler} noClickable bigAvatar />
          {!avoidReviews && (
            <p className={styles.travelerCardExtra}>
              <strong>{travelerProfile.deals_delivered_qty}</strong> entregas • cumplimiento:{' '}
              <strong>{deliveredPercent}</strong>
            </p>
          )}
        </div>
        {!avoidReviews && (
          <div>
            <List component="nav" className={styles.levels}>
              {levelBreaks &&
                levelBreaks.map((level, index) => (
                  <ListItem divider={index < levelBreaks.length - 1} key={index}>
                    <ListItemIcon className={styles.check} key={'selected'}>
                      {level.selected ? <IconArrowRight /> : <span>&nbsp;</span>}
                    </ListItemIcon>
                    {level.icon && (
                      <ListItemIcon className={styles.icon} key={'icon'}>
                        {level.icon}
                      </ListItemIcon>
                    )}
                    <ListItemText
                      primary={level.title}
                      className={clsx(
                        !level.icon && styles.noIcon,
                        level.selected && styles.selected,
                      )}
                      inset={!level.icon}
                    />
                  </ListItem>
                ))}
            </List>
          </div>
        )}
      </div>
    );
  };

  const ReviewsSubtitle = () => {
    const variantText =
      reviews.length === totalReviewsQty
        ? `las ${reviews.length}`
        : `las últimas ${reviews.length} de ${totalReviewsQty}`;
    return <>Mostrando {variantText} calificaciones recibidas.</>;
  };

  const ReviewCard = (props) => {
    const { review, index } = props;
    const user = review.user_source;
    console.log(index);
    return (
      <div className={styles.reviewCard} key={index}>
        <Avatar className={styles.avatar} src={user.picture_path}>
          {user.one_firstname.charAt(0)}
        </Avatar>
        <div className={styles.userInfo}>
          <div className={clsx(styles.firstCol, !review.opinion && styles.emptyOpinion)}>
            <p className={styles.name}>{user.mask_fullname}</p>
            {review.opinion && <p className={styles.opinion}>{review.opinion}</p>}
          </div>
          <div className={styles.secondCol}>
            <Rating size="small" value={review.score} readOnly />
            <p className={styles.elapsedTime}>{review.elapsed_rated_at}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Dialog fullWidth maxWidth={'sm'} onClose={handleClose} open={open}>
      <DialogTitle>
        <div>{!traveler || loading ? <CircularProgress /> : <Title />}</div>
      </DialogTitle>
      <DialogContent dividers={true}>
        {loading ? (
          <CircularProgress />
        ) : avoidReviews ? (
          <div className={styles.avoidReviews}>
            <p>
              Solo puedes leer las reseñas del viajero cuando tienes ofertas o acuerdos activos
              con él o ella.
            </p>
          </div>
        ) : (
          <div className={styles.content}>
            <p className={styles.reviewsSubtitle}>
              <ReviewsSubtitle />
            </p>
            {reviews.length > 0 &&
              reviews.map((review, index) => <ReviewCard review={review} index={index} />)}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus fullWidth onClick={handleClose} color="primary" variant="contained">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TravelerReviewsDialog;
