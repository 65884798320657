import { Button } from '@material-ui/core';

import { MailIcon } from '#root/icons';
import styles from './emailAlert.module.scss';

function EmailAlert() {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.header}>
          <MailIcon />
          <h5>Activa tu cuenta</h5>
        </div>
        <p className={styles.text}>
          Has recibido un correo con el botón para activar tu cuenta. Revísalo (podría estar en
          tu bandeja de spam).
        </p>
      </div>
      <Button
        disableElevation
        variant="contained"
        color="secondary"
        href="https://api.whatsapp.com/send?phone=51960684330&text=Hola!"
        className="guv-no-decoration"
        target="_blank"
        rel="noopener noreferrer">
        ¿Tienes problemas?
      </Button>
    </div>
  );
}

export default EmailAlert;
