const getMinMax = (arr = []) => {
  if (!arr.length) {
    return [null, null];
  }
  let min = arr.reduce((a, b) => {
    return a < b ? a : b;
  });

  let max = arr.reduce((a, b) => {
    return a > b ? a : b;
  });

  return [min, max];
};

export default getMinMax;
