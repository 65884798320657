import { useState, useEffect } from 'react';
import { Button, Dialog, Stepper, Step, StepLabel } from '@material-ui/core';
import { IconX, IconArrowRight } from '@tabler/icons';
import clsx from 'clsx';

import { userService, shopperService } from '#root/services';
import { useResponsive } from '#root/hooks';
import { changeCurrencySymbol, roundDecimal } from '#root/utils';
import {
  AlertCircleIcon,
  ArrowBackIcon,
  ArrowForwardIcon,
  CheckmarkCircleIcon,
  DoneAllIcon,
} from '#root/icons';
import PaymentCheckoutDialogProps, {
  PaymentDataType,
} from '#root/interfaces/PaymentCheckoutDialog';
import Instructions from './Instructions';
import Methods from './Methods';
import Confirmation from './Confirmation';
import StepIcon from './StepIcon';
import Connector from './Connector';
import styles from './paymentCheckoutDialog.module.scss';

const getSteps = () => {
  return ['Instrucciones', 'Forma de Pago', 'Confirmación'];
};

const initialPaymentData: PaymentDataType = {
  id: '',
  bank: null,
  currency: '',
  amount: 0,
  discount: 0,
  useCredits: false,
  amountCredits: 0,
  coupon: {
    active: false,
    name: '',
    code: null,
    hint: '',
  },
  zipago: null,
};

const PaymentCheckoutDialog = (props: PaymentCheckoutDialogProps) => {
  const { open, onClose, dataSource, banks, currencies, zipago } = props;
  const { isMobile } = useResponsive();
  const steps = getSteps();
  const [activeStep, setActiveStep] = useState(0);
  const [activeNextBtn, setActiveNextBtn] = useState(false);
  const [defaultAmount, setDefaultAmount] = useState(0);
  const [paymentData, setPaymentData] = useState<PaymentDataType>(initialPaymentData);
  const [messajeData, setMessajeData] = useState({
    status: 'error',
    title: 'Error, no se ha realizado ningun pago.',
    icon: <AlertCircleIcon />,
    helper: '',
  });

  const handleActiveNextBtn = (state: boolean) => {
    setActiveNextBtn(state);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handlePaymentData = (obj) => {
    setPaymentData({
      ...paymentData,
      ...obj,
    });
  };

  const handleCreatePayment = async () => {
    if (dataSource.paymentType === 'SHIPPING') {
      fetchShipping();
      return;
    }
    if (dataSource.paymentType === 'WALLET') {
      fetchWallet();
      return;
    }
    if (dataSource.paymentType === 'DEAL') {
      fetchDeal();
      return;
    }
    if (dataSource.paymentType === 'ORDER') {
      fetchOrder();
      return;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fetchOrder = async () => {
    const res = await shopperService.addPaymentOrderExpress({
      id: paymentData.id,
      bank: (paymentData.bank?.nombre) ? paymentData.bank?.nombre : paymentData.bank,
      currency: paymentData.currency,
      amount: paymentData.amount,
      using_wallet: paymentData.useCredits ? 1 : 0,
      zipago_bank: paymentData.zipago?.name,
      zipago_fee: paymentData.zipago?.amount,
    });
    // console.log(res);
    if (!res.error) {
      setMessajeData({
        status: 'success',
        title: 'El pago se esta validando...',
        icon: <CheckmarkCircleIcon />,
        helper: 'Guvery validará tu pago en 60 minutos o menos y recibirás una notificación.',
      });
    } else {
      setMessajeData({
        status: 'error',
        title: 'El pedido no existe o no se puede pagar.',
        icon: <AlertCircleIcon />,
        helper: '',
      });
    }
    handleNext();
  }

  const fetchDeal = async () => {
    const res = await shopperService.dealPaying({
      deal_id: paymentData.id,
      bank: paymentData.bank?.nombre,
      currency: paymentData.currency,
      amount: paymentData.amount,
      coupon_code: paymentData.coupon?.code,
      using_wallet: paymentData.useCredits ? 1 : 0,
      zipago_bank: paymentData.zipago?.name,
      zipago_fee: paymentData.zipago?.amount,
    });
    // console.log(res);
    if (!res.error) {
      setMessajeData({
        status: 'success',
        title: res.message ? res.message : 'El pago se esta validando...',
        icon: <CheckmarkCircleIcon />,
        helper: '',
      });
    } else {
      setMessajeData({
        status: 'error',
        title: 'Error al realizar el pago.',
        icon: <AlertCircleIcon />,
        helper: '',
      });
    }
    handleNext();
  };

  const fetchShipping = async () => {
    const res = await userService.addPaymentShipping({
      id: paymentData.id,
      bank: paymentData.bank?.nombre,
      currency: paymentData.currency,
      amount: paymentData.amount,
    });
    // console.log(res);
    if (!res.error) {
      setMessajeData({
        status: 'success',
        title: 'El pago se esta validando...',
        icon: <CheckmarkCircleIcon />,
        helper: 'Guvery validará tu pago en 60 minutos o menos y recibirás una notificación.',
      });
    } else {
      setMessajeData({
        status: 'error',
        title: 'El envío no existe o no se puede pagar.',
        icon: <AlertCircleIcon />,
        helper: '',
      });
    }
    handleNext();
  };

  const fetchWallet = async () => {
    const res = await userService.addPaymentWallet({
      bank: paymentData.bank?.nombre,
      currency: paymentData.currency,
      amount: paymentData.amount,
      zipago_bank: paymentData.zipago?.name,
      zipago_fee: paymentData.zipago?.amount,
    });
    // console.log(res);
    if (!res.error) {
      setMessajeData({
        status: 'success',
        title: 'El pago se esta validando...',
        icon: <CheckmarkCircleIcon />,
        helper: 'Guvery validará tu pago en 60 minutos o menos y recibirás una notificación.',
      });
    } else {
      setMessajeData({
        status: 'error',
        title: 'Error al realizar el pago.',
        icon: <AlertCircleIcon />,
        helper: '',
      });
    }
    handleNext();
  };

  useEffect(() => {
    handleReset();
    setDefaultAmount(Number(dataSource.amount));
    setPaymentData({
      ...initialPaymentData,
      id: dataSource.id,
      currency: dataSource.currency,
      amount: Number(dataSource.amount),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Instructions
            sourceCurrency={dataSource.currency}
            sourceAmount={dataSource.amount}
            defaultAmount={defaultAmount}
            paymentData={paymentData}
            allowCoupon={dataSource.allowCoupon}
            setDefaultAmount={setDefaultAmount}
            setPaymentData={handlePaymentData}
            id={paymentData.id}
            currency={paymentData.currency}
            amountHelper={dataSource.amountHelper}
            stepsArray={dataSource.stepsArray}
            showCredits={dataSource.showCredits}
            activeNextStep={handleActiveNextBtn}
          />
        );
      case 1:
        return (
          <Methods
            allowZipago={dataSource.allowZipago}
            allowConvert={dataSource.allowConvert}
            allowCredits={dataSource.allowCredits}
            disabledCurrencies={dataSource.disabledCurrencies}
            disabledPrice={dataSource.disabledPrice}
            defaultAmount={defaultAmount}
            sourceTca={dataSource?.tca}
            amount={paymentData.amount}
            currency={paymentData.currency}
            useCredits={paymentData.useCredits}
            methodsHelper={dataSource.methodsHelper}
            banks={banks}
            currencies={currencies}
            zipago={zipago}
            showCredits={dataSource.showCredits}
            setPaymentData={handlePaymentData}
            activeNextStep={handleActiveNextBtn}
          />
        );
      case 2:
        return <Confirmation paymentData={paymentData} activeNextStep={handleActiveNextBtn} />;
      default:
        return 'Error';
    }
  };

  const content = (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.headerIcon} onClick={() => onClose()}>
          <IconX />
        </div>
        {paymentData.id ? (
          <h3 className={styles.headerTitle}>{dataSource.title + ' #' + paymentData.id}</h3>
        ) : (
          <h3 className={styles.headerTitle}>{dataSource.title}</h3>
        )}
        {Number(dataSource.amount) !== 0 && (
          <div className={styles.headerPrice}>
            <h4 className={clsx(styles.total, paymentData.coupon.active && styles.totalOld)}>
              {changeCurrencySymbol(dataSource.currency, 'Symbol') +
                ' ' +
                roundDecimal(dataSource.amount)}
            </h4>
            {paymentData.coupon.active && (
              <>
                <span className={styles.amountDivider}>
                  <IconArrowRight size={20} />
                </span>
                <span className={styles.total}>
                  {changeCurrencySymbol(dataSource.currency, 'Symbol') +
                    ' ' +
                    roundDecimal(defaultAmount)}
                </span>
              </>
            )}
          </div>
        )}
      </div>
      <div className={styles.container}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<Connector />}
          className={styles.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel className={styles.stepLabel} StepIconComponent={StepIcon}>
                <span>{label}</span>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <div className={styles.body}>
            <div className={clsx(styles.sectionValidate, styles[messajeData.status])}>
              <h3>{messajeData.title}</h3>
              <div className={styles.icon}>{messajeData.icon}</div>
              <p>{messajeData.helper}</p>
            </div>
          </div>
        ) : (
          <div className={styles.body}>{getStepContent(activeStep)}</div>
        )}
      </div>
      {activeStep === steps.length ? (
        <div className={styles.actions}>
          <Button
            disableElevation
            className={styles.primary}
            variant="contained"
            color="primary"
            onClick={() => onClose()}>
            Cerrar
          </Button>
        </div>
      ) : (
        <div className={styles.actions}>
          <Button disableElevation disabled={activeStep === 0} onClick={handleBack}>
            <ArrowBackIcon />
          </Button>
          <Button
            disabled={!activeNextBtn}
            className={styles.primary}
            disableElevation
            variant="contained"
            color="primary"
            onClick={activeStep === steps.length - 1 ? handleCreatePayment : handleNext}
            endIcon={activeStep === steps.length - 1 ? <DoneAllIcon /> : <ArrowForwardIcon />}>
            {activeStep === steps.length - 1 ? 'Confirmar Pago' : 'Continuar'}
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <>
      {isMobile ? (
        <Dialog fullScreen open={open} onClose={onClose}>
          {content}
        </Dialog>
      ) : (
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="sm"
          PaperProps={{
            style: {
              width: 'calc(100% - 16px)',
              margin: '16px',
            },
          }}>
          {content}
        </Dialog>
      )}
    </>
  );
};

export default PaymentCheckoutDialog;
