import { withStyles } from '@material-ui/core/styles';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Button,
} from '@material-ui/core';
import { IconCash, IconChevronDown, IconInfoCircle, IconCircleCheck } from '@tabler/icons';
import { SparksIcon } from '#root/icons';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import clsx from 'clsx';

import { GuverySpace } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import WithdrawalDTO from '#root/models/WithdrawalDTO';
import styles from './withdrawCard.module.scss';
import DealsTable from './DealsTable';

interface WithdrawCardProps {
  withdrawal: WithdrawalDTO;
  title?: string;
  subtitle?: string;
  processingTimes?: any;
  allowNewWithdraw?: boolean;
  allowDeliveryDeals?: boolean;
  openNewWithdrawDialog?: () => void;
}

const getDate = (date) => {
  let time;
  try {
    time = format(new Date(date.replace(/ /g, 'T')), 'dd', { locale: es });
  } catch (error) {
    time = '---';
  }
  return time;
};

const getMonthYear = (date) => {
  let time;
  try {
    time = format(new Date(date.replace(/ /g, 'T')), 'MMM YYY', { locale: es });
  } catch (error) {
    time = '---';
  }
  return time;
};

const getFullDate = (date) => {
  let time;
  try {
    time = format(new Date(date.replace(/ /g, 'T')), "dd 'de' MMM',' YYY", {
      locale: es,
    });
  } catch (error) {
    time = '---';
  }
  return time;
};

const Accordion = withStyles({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'var(--color-background-alt)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiAccordionDetails);

const reducerQty = (accumulator, current) => Number(accumulator) + Number(current?.can);
const reducerPrice = (accumulator, current) => Number(accumulator) + Number(current?.pre);
const reducerCom = (accumulator, current) => Number(accumulator) + Number(current?.com);

const WithdrawCard = (props: WithdrawCardProps) => {
  const {
    withdrawal,
    title,
    subtitle,
    allowNewWithdraw,
    allowDeliveryDeals,
    openNewWithdrawDialog,
    processingTimes,
  } = props;

  const date = withdrawal?.feccre && getDate(withdrawal?.feccre);
  const dateExtra = withdrawal?.feccre && getMonthYear(withdrawal?.feccre);
  const payDate = withdrawal?.fecpag && getFullDate(withdrawal.fecpag);

  const isRequested = (withdrawal?.is_requested);
  const isReviewing = (withdrawal?.is_reviewing);
  const isApproved = (withdrawal?.is_approved);
  const isPaid = (withdrawal?.is_paid);

  const hasBankAccount = withdrawal?.numcue ? true : false;

  let totalPrice = 0;
  let totalCom = 0;
  let totalMatch = 0;
  let totalQty = 0;

  withdrawal?.deals?.forEach((deal) => {
    const orders = deal?.deal_orders;

    const priceDeal = orders?.reduce(reducerPrice, 0);
    const comDeal = orders?.reduce(reducerCom, 0);
    const qtyDeal = orders?.reduce(reducerQty, 0);

    totalPrice = totalPrice + priceDeal;
    totalCom = totalCom + comDeal;
    if (deal?.guvery_match_fee) {
      totalMatch = totalMatch + Number(deal.guvery_match_fee);
    }
    totalQty = totalQty + qtyDeal;
  });

  return (
    <GuveryPaper disableHover disableElevation>
      <div className={styles.root}>
        <div className={styles.content}>
          <div className={styles.left}>
            {withdrawal?.feccre && (
              <div className={styles.time}>
                <h5>{date}</h5>
                <p>{dateExtra}</p>
              </div>
            )}
            <div className={styles.info}>
              <div>
                {withdrawal?.idr && <p className={styles.number}>GR #{withdrawal.idr}</p>}
                <h6 className={styles.title}>{title}</h6>
                <h6 className={styles.subtitle}>{subtitle ? subtitle : withdrawal.nom}</h6>
                {withdrawal?.deals?.length <= 1 ? (
                  <p>{withdrawal?.deals?.length} acuerdo</p>
                ) : (
                  <p>{withdrawal?.deals?.length} acuerdos</p>
                )}
                {isRequested && (
                  <p className={styles.extraInfo}>
                  <IconInfoCircle size={18} />
                  <span className={styles.text}>
                    Guvery revisará, aprobará y pagará tu retiro a la cuenta indicada.
                  </span>
                </p>
                )}
                {isReviewing && processingTimes.review_laboral_days  && (
                  <p className={styles.extraInfo}>
                  <IconInfoCircle size={18} />
                  <span className={styles.text}>
                    Guvery está revisando tu retiro, puede tardar hasta {processingTimes.review_laboral_days} días laborales.
                  </span>
                </p>
                )}
                {isApproved && processingTimes.paid_laboral_days  && (
                  <p className={styles.extraInfo}>
                  <IconCircleCheck size={18} />
                  <span className={styles.text}>
                    Guvery aprobó el retiro, el pago puede tardar hasta {processingTimes.paid_laboral_days} días laborales.
                  </span>
                </p>
                )}
                {isPaid && payDate && (
                  <p className={styles.extraInfo}>
                    <IconCircleCheck size={18} />
                    <span className={styles.text}>Pagado el {payDate}</span>
                  </p>
                )}
              </div>
              <GuverySpace className={styles.actions}>
                {allowDeliveryDeals && (
                  <div>
                    <p style={{ color: 'var(--color-gray)' }}>
                      Entrega estos acuerdos <br /> para retirar ganancias
                    </p>
                  </div>
                )}
                {allowNewWithdraw && (
                  <Button
                    onClick={openNewWithdrawDialog}
                    disableElevation
                    color="primary"
                    variant="contained"
                    startIcon={<IconCash />}>
                    Retirar Ganacias
                  </Button>
                )}
              </GuverySpace>
            </div>
          </div>
          {hasBankAccount && (
            <div className={styles.right}>
              <GuveryPaper disableHover className={styles.card}>
                <div className={styles.wrap}>
                  {withdrawal?.bank_colors?.path_logo && (
                    <div className={styles.cardImage}>
                      <img src={withdrawal?.bank_colors?.path_logo} alt={withdrawal.bco} />
                    </div>
                  )}
                  <div className={styles.cardContent}>
                    <p className={styles.bankType}>
                      <span className={styles.bankResponsive}>{withdrawal?.bco} - </span>
                      {withdrawal?.tipcue}
                    </p>
                    <p className={styles.bankUser}>{withdrawal?.titular}</p>
                    <p className={styles.bankNum}>{withdrawal?.numcue}</p>
                  </div>
                </div>
              </GuveryPaper>
            </div>
          )}
        </div>
      </div>
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<IconChevronDown />} aria-controls="panel-offer">
          <div className={styles.footer}>
            <div className={styles.price}>
              <h4 className={styles.text}>
                <span className={styles.totalPrice}>$ {totalPrice}</span>
                <span className={styles.totalHint}>Precio</span>
              </h4>
              <span className={styles.space}>+</span>
              <h4 className={styles.text}>
                <span className={styles.totalPrice}>$ {totalCom}</span>
                <span className={styles.totalHint}>Comisión</span>
              </h4>
              {totalMatch > 0 && (
                <>
                <span className={styles.space}>-</span>
                <h4 className={styles.text}>
                  <span className={styles.totalPrice}>$ {totalMatch}</span>
                  <span className={styles.totalHint}>Match <SparksIcon inlineDefault /></span>
                </h4>
                </>
              )}
              <span className={styles.space}>=</span>
              <h4 className={styles.text}>
                <span className={clsx(styles.totalPrice, styles.primary)}>
                  $ {totalPrice + totalCom - totalMatch}
                </span>
                <span className={clsx(styles.totalHint, styles.primary)}>Total</span>
              </h4>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <DealsTable deals={withdrawal?.deals} hasMatch={(totalMatch > 0)} />
        </AccordionDetails>
      </Accordion>
    </GuveryPaper>
  );
};

export default WithdrawCard;
