import configService from './config';
import fetchApi from './fetchApi';
import guveryFetchApi from './guveryFetchApi';
import * as METHODS from './methods';

const baseUrl = configService.apiUrl;
const guveryApi = configService.apiGuvery;

const nodeChatUrl = configService.apiUrlChatServer;

export interface ValidateProps {
  idu: number;
  gat?: string;
  ip?: string;
}

const validate = async (arg: ValidateProps) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/auth/gat/${arg.idu}/${arg.gat}/${arg.ip}`,
    options: METHODS.GET(),
    message: {
      success: 'Peticion exitosa.',
      error: 'Peticion No exitosa.',
    },
  });

  return { ...response };
};

const session = async (arg: ValidateProps) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/auth/session/${arg.idu}`,
    options: METHODS.GET(),
    message: {
      success: 'Peticion exitosa.',
      error: 'Peticion No exitosa.',
    },
  });

  return { ...response };
};

const authChatServer = async (arg) => {
  /*
  const formData = new FormData();
  formData.append('idu', arg.idu);
  formData.append('channel_id', arg.channel_id);
  formData.append('sid', arg.sid);
  */
  const { response } = await fetchApi({
    url: nodeChatUrl + '/secure',
    options: METHODS.POST({
      idu: arg.idu,
      channel_id: arg.channel_id,
      sid: arg.sid,
    }),
    dataType: {},
    message: {
      success: 'Auth exito',
      error: 'Auth fallido',
    },
  });

  return { ...response };
};

const authService = {
  validate,
  session,
  authChatServer,
};

export default authService;
