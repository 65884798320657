import { useMediaQuery } from 'react-responsive';

const useResponsive = () => {
  const isMobile = useMediaQuery({
    maxWidth: 768,
  });

  const isTablet = useMediaQuery({
    minWidth: 768,
    maxWidth: 1024,
  });

  const isDesktop = useMediaQuery({
    minWidth: 1024,
  });

  return {
    isDesktop: isDesktop,
    isTablet: isTablet,
    isMobile: isMobile,
  };
};

export default useResponsive;
