import { useState, useRef, useEffect } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';

import { Button } from '@material-ui/core';
import { IconPhoto } from '@tabler/icons';
import { CameraPlusIcon } from '#root/icons';

import styles from './styles.module.scss';

interface CroppedImageState {
  blob: Blob;
  fileUrl: string;
}

const UploadImageCrop = (props) => {
  const { setCroppedImage, counter, isOptional = false, setPendingCroppings = null } = props;

  const [imageRef, setImageRef] = useState<HTMLImageElement>(null);
  const [image, setImage] = useState({
    file: '',
    imagePreviewUrl: '',
  });
  const [crop, setCrop] = useState<Crop>({ unit: '%', width: 50, aspect: 1 });
  const [userCrop, setUserCrop] = useState(false);

  const handleInputFile = async (e) => {
    if (setPendingCroppings) {
      setPendingCroppings(true);
    }
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setImage({
        file: file,
        imagePreviewUrl: reader.result.toString(),
      });
    };
    if (file && file.type.match('image.*')) {
      await reader.readAsDataURL(file);
    }
  };

  const onImageLoaded = (imageRef: HTMLImageElement) => {
    setImageRef(imageRef);
  };

  const onCropChange = (crop: Crop) => {
    setCrop(crop);
  };

  const onCropComplete = (crop: Crop) => {
    makeClientCrop(crop);
  };

  const makeClientCrop = async (crop: Crop) => {
    if (imageRef && crop.width && crop.height) {
      setUserCrop(true);
      try {
        const crpImage = await getCroppedImg(imageRef, crop, `newFile_${counter}.jpeg`);
        if (crpImage) {
          setCroppedImage({
            blob: crpImage.blob,
            fileUrl: crpImage.fileUrl,
          });
          if (setPendingCroppings) {
            setPendingCroppings(false);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getCroppedImg = (image: HTMLImageElement, crop: Crop, fileName: string) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = 400;
    canvas.height = 400;
    const ctx = canvas.getContext('2d');
    let fileUrl: string;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      400,
      400,
    );

    return new Promise<CroppedImageState>((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          // console.error('Canvas is empty');
          return;
        }

        // @ts-ignore
        blob.name = fileName;

        window.URL.revokeObjectURL(fileUrl);
        fileUrl = window.URL.createObjectURL(blob);
        resolve({ fileUrl, blob });
      }, 'image/png');
    });
  };

  useEffect(() => {
    setCroppedImage({
      blob: null,
      fileUrl: '',
    });
    setImage({
      file: '',
      imagePreviewUrl: '',
    });
  }, []);

  return (
    <div className={styles.main}>
      {image.imagePreviewUrl ? (
        <div className={styles.main}>
          <input
            type="file"
            id={`upload-image-${counter}`}
            className={styles.inputHide}
            onChange={handleInputFile}
          />
          <Button
            fullWidth
            disableElevation
            variant="contained"
            color="secondary"
            component="label"
            htmlFor={`upload-image-${counter}`}
            startIcon={<IconPhoto size={22} />}>
            Reemplazar la Imagen {counter}
          </Button>
          {!userCrop && (
            <div className={styles.hintDanger}>
              <p>Ajusta el recortador</p>
            </div>
          )}
          <ReactCrop
            ruleOfThirds
            keepSelection
            src={image.imagePreviewUrl}
            crop={crop}
            onChange={onCropChange}
            onComplete={onCropComplete}
            onImageLoaded={onImageLoaded}
          />
        </div>
      ) : (
        <div>
          <div className={styles.image}>
            <input
              type="file"
              id={`upload-image-${counter}`}
              className={styles.inputHide}
              onChange={handleInputFile}
            />
            <label htmlFor={`upload-image-${counter}`} className={styles.holderLabel}>
              <figure className={styles.imageHolder} aria-label="Subir Imagen">
                <div className={styles.btnHolder}>
                  <CameraPlusIcon />
                  <span>Subir imagen {counter}</span>
                  {isOptional ? (
                    <div className={styles.hint}>
                      <p>Opcional</p>
                    </div>
                  ) : (
                    <div className={styles.hint}>
                      <p>Requerido</p>
                    </div>
                  )}
                </div>
              </figure>
            </label>
          </div>
          <div className={styles.hint}>
            <p>El formato debe ser JPEG o PNG y no puede superar los 2 MB.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadImageCrop;
