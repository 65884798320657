import { ActionTypes, ReducerInitialState } from '#root/Context';

import { SET_AUTH, REMOVE_AUTH, SET_NEGOCIO, SET_SESSION_DATA, SET_USUARIO } from './types';

type AuthReducerType = (state: ReducerInitialState, action: ActionTypes) => ReducerInitialState;

export const authReducer: AuthReducerType = (state, action) => {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        usuario: { ...action.usuarioObj.usuario },
        global_is_admin: action.usuarioObj.global_is_admin,
        global_is_viajero: action.usuarioObj.global_is_viajero,
        auth: true,
        last_updated: new Date(),
        session: action.guveryAuth,
        userRole: action.userRole,
      };
    case SET_SESSION_DATA:
      return {
        ...state,
        global_is_admin: action.sessionData.global_is_admin,
        global_is_viajero: action.sessionData.global_is_viajero,
        last_updated: new Date(),
        negocio: { ...action.sessionData.negocio },
        session: action.guveryAuth,
        usuario: { ...action.sessionData.usuario },
        userRole: action.userRole,
        traveler_trips_count: action.sessionData.traveler_trips_count,
        viajero_ultimo_retiro: { ...action.sessionData.viajero_ultimo_retiro },
      };
    case SET_USUARIO:
      return {
        ...state,
        usuario: { ...action.usuarioObj },
        last_updated: new Date(),
        session: action.guveryAuth,
      };
    case SET_NEGOCIO:
      return {
        ...state,
        negocio: { ...action.negocioObj },
        last_updated: new Date(),
        session: action.guveryAuth,
      };
    case REMOVE_AUTH:
      return {
        usuario: null,
        negocio: null,
        traveler_trips_count: null,
        viajero_ultimo_retiro: {},
        global_is_admin: false,
        global_is_viajero: false,
        auth: false,
        last_updated: new Date(),
        session: null,
        userRole: 'comprador',
      };
    default:
      return state;
  }
};
