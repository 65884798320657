import { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Skeleton } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

import { GuveryTextField } from '#root/components';
import { QuestionMarkCircleIcon, ArrowDownwardIcon } from '#root/icons';
import styles from './extraData.module.scss';

const ExtraData = (props) => {
  const { offer, flagEnvio, handleDateChange, handleInputChange, loading } = props;
  const [expanded, setExpanded] = useState(false);

  const dateDelivery = offer?.delivery_date
    ? format(new Date(offer.delivery_date), "dd'/'MMM", {
        locale: es,
      })
    : '---';

  const dateExpiration = offer?.expiration_date
    ? format(new Date(offer?.expiration_date), "dd'/'MMM", {
        locale: es,
      })
    : '---';

  const form = (
    <Grid container spacing={3} className={styles.form}>
      <Grid item xs={12} md={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <KeyboardDatePicker
            disableToolbar
            fullWidth
            label="Mi oferta vence el"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            value={offer?.expiration_date}
            onChange={handleDateChange('expiration_date')}
            minDate={new Date()}
            InputAdornmentProps={{ position: 'end' }}
            minDateMessage="La fecha no debe ser anterior a la fecha mínima"
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12} md={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <KeyboardDatePicker
            disableToolbar
            fullWidth
            label="Fecha de entrega"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            value={offer.delivery_date}
            onChange={handleDateChange('delivery_date')}
            minDate={new Date()}
            InputAdornmentProps={{ position: 'end' }}
            minDateMessage="La fecha no debe ser anterior a la fecha mínima"
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12}>
        {loading ? (
          <Skeleton variant="rect" width="100%" height={56} />
        ) : (
          <GuveryTextField
            id="delivery_places"
            disabled={flagEnvio}
            label="Lugares de entrega"
            value={offer.delivery_places}
            onChange={handleInputChange}
          />
        )}
        {flagEnvio && (
          <Tooltip
            arrow
            title="Guvery le enviará este pedido al comprador, porque se encuentra fuera de Lima. "
            placement="bottom">
            <div className={styles.formHelp}>
              <p>
                Debe entregarse en la oficina de MailBoxes (Miraflores, San Isidro ó Surco).
              </p>
              <QuestionMarkCircleIcon />
            </div>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );

  const Text = (
    <div className={styles.header}>
      {expanded ? (
        <h5>
          <strong>Datos de entrega</strong>
        </h5>
      ) : (
        <h5>
          Entregando el <strong>{dateDelivery}</strong> en [{' '}
          <strong>{offer.delivery_places}</strong> ]. Mi oferta vence el{' '}
          <strong>{dateExpiration}</strong>.
        </h5>
      )}
    </div>
  );

  return (
    <div className={styles.root}>
      <Accordion
        className={styles.acordion}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          expandIcon={<ArrowDownwardIcon />}
          IconButtonProps={{ size: 'small' }}>
          {Text}
        </AccordionSummary>
        <AccordionDetails>{form}</AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ExtraData;
