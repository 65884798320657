import SvgIcon from '@material-ui/core/SvgIcon';

const ArrowForwardSvg = () => (
  <>
    <g data-name="Layer 2">
      <path
        d="M5 13h11.86l-3.63 4.36a1 1 0 001.54 1.28l5-6a1.19 1.19 0 00.09-.15c0-.05.05-.08.07-.13A1 1 0 0020 12a1 1 0 00-.07-.36c0-.05-.05-.08-.07-.13a1.19 1.19 0 00-.09-.15l-5-6A1 1 0 0014 5a1 1 0 00-.64.23 1 1 0 00-.13 1.41L16.86 11H5a1 1 0 000 2z"
        data-name="arrow-forward"></path>
    </g>
  </>
);

const ArrowForward = (props) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <ArrowForwardSvg />
  </SvgIcon>
);

export default ArrowForward;
