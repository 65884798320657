import { createContext, useReducer } from 'react';
import { useCookies } from 'react-cookie';

import UserDTO from '#root/interfaces/UserDTO';
import UserResponseDTO from '#root/interfaces/UserResponseDTO';
import { AuthReducer } from '#root/reducers';
import {
  SET_AUTH,
  REMOVE_AUTH,
  SET_NEGOCIO,
  SET_SESSION_DATA,
  SET_USUARIO,
} from '#root/reducers/types';

import UserRole, { UserRoleType } from './interfaces/UserRole';
import SessionResponseDTO from './interfaces/SessionResponseDTO';
import BusinessDTO from './interfaces/BusinessDTO';
import { COOKIE_DOMAIN } from './constants/variables';

/**
 * Reducer state: properties
 */
export interface ReducerInitialState {
  usuario: UserDTO | null;
  negocio: BusinessDTO | null;
  traveler_trips_count?: number | null;
  viajero_ultimo_retiro?: any;
  global_is_admin?: boolean;
  global_is_viajero: boolean;
  auth: boolean;
  last_updated: Date;
  session: CookieType | null;
  userRole?: UserRoleType;
}

const initialState: ReducerInitialState = {
  usuario: null,
  negocio: null,
  traveler_trips_count: null,
  viajero_ultimo_retiro: {},
  global_is_admin: false,
  global_is_viajero: false,
  auth: false,
  last_updated: new Date(),
  session: null,
  userRole: 'comprador',
};

type CookieType = string | undefined;

/**
 * Reducer state: properties + cookies and actions
 */

interface AuthContext extends ReducerInitialState {
  GuveryTIP?: CookieType;
  GuveryAuth?: CookieType;
  GuveryRememberMe?: CookieType;
  GuverySession?: CookieType;
  setAuth?: (usuarioObj: UserResponseDTO) => void;
  setSessionData?: (sessionData: SessionResponseDTO) => void;
  setUsuario?: (usuarioObj: UserDTO) => void;
  setNegocio?: (negocioObj: BusinessDTO) => void;
  removeAuth?: () => void;
}

export const Context = createContext<AuthContext>(initialState);

/**
 * Context Actions Types
 */
interface SetAuthAction {
  type: typeof SET_AUTH;
  userRole: UserRoleType;
  usuarioObj: UserResponseDTO;
  guveryAuth: CookieType;
}

interface SetSessionDataAction {
  type: typeof SET_SESSION_DATA;
  guveryAuth: CookieType;
  sessionData: SessionResponseDTO;
  userRole: UserRoleType;
}

interface SetUsuarioAction {
  type: typeof SET_USUARIO;
  usuarioObj: UserDTO;
  guveryAuth: CookieType;
}

interface SetNegocioAction {
  type: typeof SET_NEGOCIO;
  negocioObj: BusinessDTO;
  guveryAuth: CookieType;
}

interface RemoveAuthAction {
  type: typeof REMOVE_AUTH;
}

export type ActionTypes =
  | SetAuthAction
  | SetSessionDataAction
  | SetUsuarioAction
  | SetNegocioAction
  | RemoveAuthAction;

/**
 * Context Provider
 */

const Provider = ({ children }) => {
  const [cookies /* setCookies */, , removeCookies] = useCookies([
    'GuveryTIP',
    'GuveryAuth',
    'GuveryRememberMe',
    'GuverySession',
  ]);

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const setAuth = (usuarioObj: UserResponseDTO) => {
    let userRole: UserRoleType = UserRole.comprador;
    if (usuarioObj.global_is_viajero && usuarioObj.usuario.pic) userRole = UserRole.viajero;
    if (usuarioObj.global_is_admin) userRole = UserRole.admin;

    dispatch({ type: SET_AUTH, userRole, usuarioObj, guveryAuth: cookies.GuveryAuth });
  };

  const setSessionData = (sessionData: SessionResponseDTO) => {
    let userRole: UserRoleType = UserRole.comprador;
    if (sessionData.global_is_viajero && sessionData.usuario.pic) userRole = UserRole.viajero;
    if (sessionData.global_is_admin) userRole = UserRole.admin;

    dispatch({ type: SET_SESSION_DATA, sessionData, guveryAuth: cookies.GuveryAuth, userRole });
  };

  const setUsuario = (usuarioObj: UserDTO) => {
    dispatch({ type: SET_USUARIO, usuarioObj, guveryAuth: cookies.GuveryAuth });
  };

  const setNegocio = (negocioObj: BusinessDTO) => {
    dispatch({ type: SET_NEGOCIO, negocioObj, guveryAuth: cookies.GuveryAuth });
  };

  const removeAuth = () => {
    dispatch({ type: REMOVE_AUTH });
    removeCookies('GuveryTIP', { path: '/', domain: COOKIE_DOMAIN });
    removeCookies('GuveryAuth', { path: '/', domain: COOKIE_DOMAIN });
    removeCookies('GuverySession', { path: '/', domain: COOKIE_DOMAIN });
    removeCookies('GuveryRememberMe', { path: '/', domain: COOKIE_DOMAIN });
  };

  /*
  const setAuthCookie = (token) => {
    setCookies('to', token, {
      path: '/',
      domain: 'localhost',
      sameSite: false,
      secure: false,
      httpOnly: false,
    });
  };
  */

  const GuveryTIP: CookieType = cookies.GuveryTIP;
  const GuveryAuth: CookieType = cookies.GuveryAuth;
  const GuveryRememberMe: CookieType = cookies.GuveryRememberMe;
  const GuverySession: CookieType = cookies.GuverySession;

  const value = {
    ...state,
    GuveryTIP,
    GuveryAuth,
    GuveryRememberMe,
    GuverySession,
    setAuth,
    setSessionData,
    setUsuario,
    setNegocio,
    removeAuth,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Provider,
  Consumer: Context.Consumer,
};
