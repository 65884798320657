import SvgIcon from '@material-ui/core/SvgIcon';

const ArrowCircleRightSvg = () => (
  <>
    <g data-name="Layer 2">
      <g data-name="arrow-circle-right">
        <path d="M17 12v-.09a.88.88 0 00-.06-.28.72.72 0 00-.11-.19 1 1 0 00-.09-.13l-2.86-3a1 1 0 00-1.45 1.38L13.66 11H8a1 1 0 000 2h5.59l-1.3 1.29a1 1 0 000 1.42 1 1 0 001.42 0l3-3a1 1 0 00.21-.32A1 1 0 0017 12z"></path>
        <path d="M12 2a10 10 0 1010 10A10 10 0 0012 2zm0 18a8 8 0 118-8 8 8 0 01-8 8z"></path>
      </g>
    </g>
  </>
);

const ArrowCircleRight = (props) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <ArrowCircleRightSvg />
  </SvgIcon>
);

export default ArrowCircleRight;
