import Resizer from 'react-image-file-resizer';

const resizeImage = (file, outputType = 'base64') =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      800,
      800,
      'JPEG',
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      outputType,
    );
  });

export default resizeImage;
