import SvgIcon from '@material-ui/core/SvgIcon';

const MoneyBagSvg = () => (
  <>
    <g>
      <g>
        <path
          d="M256,317c-8.271,0-15-6.729-15-15c0-6.042,3.595-11.468,9.159-13.825c6.717-2.844,14.755-0.417,22.059,6.659
			c5.95,5.764,15.446,5.614,21.21-0.335c5.765-5.95,5.614-15.446-0.335-21.21c-6.772-6.561-14.3-11.198-22.093-13.815V242
			c0-8.284-6.716-15-15-15s-15,6.716-15,15v17.571c-0.851,0.303-1.699,0.623-2.54,0.979C221.779,267.615,211,283.885,211,302
			c0,24.813,20.187,45,45,45c8.271,0,15,6.729,15,15c0,6.286-3.965,11.954-9.866,14.103c-7.705,2.805-16.674-1-24.606-10.445
			c-5.328-6.344-14.789-7.167-21.133-1.839s-7.167,14.79-1.839,21.133c8.109,9.655,17.548,16.276,27.445,19.608V422
			c0,8.284,6.716,15,15,15s15-6.716,15-15v-17.573c0.134-0.047,0.268-0.088,0.401-0.136C289.105,397.843,301,380.847,301,362
			C301,337.187,280.813,317,256,317z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M394.919,225.259c-17.828-21.933-38.208-39.407-60.58-51.973c3.664-4.335,6.555-9.418,8.422-15.083
			c7.326-22.309-3.375-48.809-30.688-57.442l38.289-66.765c4.831-8.426,0.491-19.158-8.824-21.866C313.854,4.081,285.076,0,256,0
			s-57.854,4.081-85.538,12.13c-9.326,2.711-13.649,13.45-8.824,21.866l38.289,66.766c-27.205,8.589-38.041,35.05-30.684,57.453
			c1.861,5.65,4.751,10.73,8.426,15.067c-22.375,12.566-42.757,30.042-60.587,51.977C81.441,269.106,61,324.413,61,377
			c0,109.091,87.047,135,195,135c108.029,0,195-25.953,195-135C451,324.413,430.559,269.106,394.919,225.259z M197.496,36.218
			C216.633,32.087,236.238,30,256,30c19.762,0,39.367,2.087,58.504,6.218L281.471,93.82c-16.919-2.362-34.018-2.361-50.941,0.001
			L197.496,36.218z M207.296,129.896c25.815-8.468,52.823-10.073,79.08-4.824c0.031,0.006,0.063,0.012,0.094,0.018
			c6.12,1.228,12.199,2.826,18.213,4.799c7.861,2.591,12.157,11.094,9.58,18.941c-1.873,5.683-6.818,9.597-12.635,10.231
			C286.672,154.378,271.417,152,256,152c-15.386,0-30.611,2.368-45.538,7.032c-5.907-0.608-10.864-4.55-12.721-10.188
			C195.16,140.983,199.456,132.479,207.296,129.896z M256,482.2c-117.196,0-165-30.62-165-105.2
			c0-45.142,18.453-94.793,49.36-132.818C172.955,204.083,214.023,182,256,182c46.206,0,86.621,26.482,115.64,62.182
			C402.547,282.207,421,331.858,421,377C421,451.58,373.196,482.2,256,482.2z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </>
);

const MoneyBag = (props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    style={{ enableBackground: 'new 0 0 512 512' }}
    {...props}>
    <MoneyBagSvg />
  </SvgIcon>
);

export default MoneyBag;
