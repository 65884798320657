import { Avatar, Grid, Button, Tooltip } from '@material-ui/core';
import { IconMessageCircle, IconStar } from '@tabler/icons';

import { useDialog } from '#root/hooks';
import { ChatDrawer } from '#root/components';
import { GuveryPaper } from '#root/ui-components/cards';
import styles from './dealInfo.module.scss';

const Item = ({ title, response }) => (
  <div className={styles.itemText}>
    <h6>{title}</h6>
    <div className={styles.response}>
      <p>{response}</p>
    </div>
  </div>
);

const DealInfo = (props) => {
  const { dealInfo } = props;
  const chatDrawer = useDialog();

  const shopper = dealInfo?.offer?.order?.user;
  const order = dealInfo?.offer?.order;

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <span className={styles.headerTitle}>Información del acuerdo</span>
        </div>
      </div>
      <hr className={styles.divider} />
      <div className={styles.body}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <GuveryPaper disableBorder disableHover>
              <div className={styles.traveler}>
                <div className={styles.wrap}>
                  <div className={styles.travelerCover}>
                    <Avatar className={styles.avatar} src={shopper?.picture_path}>
                      {shopper?.one_firstname?.charAt(0)}
                    </Avatar>
                  </div>
                  <div className={styles.travelerText}>
                    <h4 className="guv-ellipsis">{shopper?.mask_fullname}</h4>
                    {shopper?.calvia && (
                      <Tooltip arrow title="Puntaje del viajero" placement="bottom">
                        <div className={styles.score}>
                          <IconStar />
                          <span>{shopper.calvia}</span>
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div>
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={chatDrawer.openDialog}
                    startIcon={<IconMessageCircle />}>
                    Chat
                  </Button>
                </div>
              </div>
            </GuveryPaper>
          </Grid>
          <Grid item xs={12} md={4}>
            <GuveryPaper disableBorder disableHover className={styles.item}>
              <div className={styles.wrap}>
                {dealInfo?.empaque && <Item title="Empaque" response={dealInfo?.empaque} />}
              </div>
            </GuveryPaper>
          </Grid>
          {order?.obs && (
            <Grid item xs={12} md={4}>
              <GuveryPaper disableBorder disableHover className={styles.item}>
                <div className={styles.wrap}>
                  <Item
                    title="Observaciones"
                    response={
                      <Tooltip title={order?.obs} arrow placement="top">
                        <span className={styles.observation}>{order?.obs}</span>
                      </Tooltip>
                    }
                  />
                </div>
              </GuveryPaper>
            </Grid>
          )}
        </Grid>
      </div>
      {dealInfo?.ido && (
        <ChatDrawer
          extraTitle={`Pedido #${order?.id_public}`}
          offerId={dealInfo?.ido}
          open={chatDrawer.open}
          onClose={chatDrawer.closeDialog}
        />
      )}
    </div>
  );
};

export default DealInfo;
