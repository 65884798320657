import { IconButton } from '@material-ui/core';
import { MouseEventHandler, ReactNode } from 'react';

import { CloseIcon } from '#root/icons';
import styles from './dialogTitle.module.scss';

interface DialogTitleProps {
  children?: ReactNode;
  onClose: MouseEventHandler<HTMLButtonElement>;
}

const DialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <div className={styles.title} {...other}>
      <h4>{children}</h4>
      {onClose ? (
        <IconButton
          aria-label="close"
          size="small"
          className={styles.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </div>
  );
};

export default DialogTitle;
