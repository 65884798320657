import { GuveryDialog } from '#root/ui-components';
import styles from './expressDetailsDialog.module.scss';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
} from '@material-ui/core';

import { roundDecimal } from '#root/utils';

import {
  IconBolt,
  IconInfoCircle,
  IconClock,
  IconPackage,
  IconChevronDown,
} from '@tabler/icons';

interface propsInteface {
  open: boolean;
  onClose: () => void;
  title?: string;
  order?: any;
  orderExpress?: any;
  confirmAction?: any;
  textAction?: any;
}

const ExpressDetailsDialog = (props: propsInteface) => {
  const defaultTitle = (
    <p style={{ display: 'flex', alignItems: 'center', gap: 3, fontSize: 20 }}>
      <IconBolt style={{ color: 'var(--color-express-fee)' }} /> Detalle Express
    </p>
  );
  const {
    open = false,
    onClose,
    title = defaultTitle,
    order = null,
    orderExpress = null,
    confirmAction = null,
    textAction = '',
  } = props;

  const subtotal = orderExpress.real_price + orderExpress.traveler_fee;

  const bodyBenefits = (
    <>
      <ul className={styles.benefitsMore}>
        <li>Tu pedido llegará en la mejor fecha existente.</li>
        <li>Estás pagando la comisión más adecuada.</li>
        <li>El viajero comprará tu pedido de inmediato.</li>
      </ul>
      <p style={{ fontSize: 14 }}>También te ahorrarás las etapas de un proceso regular:</p>
      <ul className={styles.benefitsLess}>
        <li>Esperar aprobación.</li>
        <li>Esperar recibir ofertas.</li>
        <li>Comparar ofertas.</li>
        <li>Leer reseñas de los viajeros.</li>
        <li>Conversar con el viajero.</li>
        <li>Aceptar una oferta.</li>
      </ul>
    </>
  );
  return (
    <GuveryDialog
      open={open}
      content={false}
      onClose={onClose}
      onConfirm={confirmAction}
      confirmText={textAction}
      confirmIcon={<IconBolt />}
      title={title}
      closeText="Cerrar">
      <TableContainer>
      <Accordion style={{ boxShadow: 'none', borderTop: '1px solid var(--border-color-2)' }}>
        <AccordionSummary
          expandIcon={<IconChevronDown color='#fff' />}
          style={{ backgroundColor: '#333', color: 'var(--color-express-fee)' }}>
          Mira los beneficios de Guvery Express
        </AccordionSummary>
        <div className={styles.wrap} style={{ margin: '10px 16px 0px' }}>
          {bodyBenefits}
        </div>
      </Accordion>
        <Table className={styles.table}>
          <TableHead className={styles.header}>
            <TableRow>
              <TableCell>Producto</TableCell>
              <TableCell>Precio Real</TableCell>
              <TableCell>Viajero</TableCell>
              <TableCell>Suma</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <span className={styles.name}>
                  <span style={{marginLeft: 10}}>{`${orderExpress.quantity} x `}</span>
                  <a
                    className={styles.link}
                    href={order.url}
                    target="_blank"
                    rel="noopener noreferrer">
                    <span>{order.url_host}</span>
                  </a>
                </span>
                {orderExpress.remarks && (
                  <p className={styles.remarks}>
                    <IconInfoCircle size={20} />
                    {orderExpress.remarks}
                  </p>
            )}
              </TableCell>
              <TableCell>$ {roundDecimal(orderExpress.real_price)}</TableCell>
              <TableCell>$ {roundDecimal(orderExpress.traveler_fee)}</TableCell>
              <TableCell>$ {roundDecimal(subtotal)}</TableCell>
            </TableRow>
            {orderExpress.guvery_fee && (
              <TableRow>
                <TableCell>
                  <span style={{ paddingLeft: 24 }}>Guvery</span>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>$ {roundDecimal(orderExpress.guvery_fee)}</TableCell>
              </TableRow>
            )}
            {orderExpress.guvery_express_fee && (
              <TableRow>
                <TableCell>
                  <span style={{ paddingLeft: 24 }}>Express ⚡️</span>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>$ {roundDecimal(orderExpress.guvery_express_fee)}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <span className={styles.total}>Total</span>
              </TableCell>
              <TableCell>
                <span className={styles.total}>$ {roundDecimal(orderExpress.total_price)}</span>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={4} style={{ padding: 16, backgroundColor: '#333' }}>
                <div className={styles.extra}>
                <div>
                  <div style={{ display: 'flex', gap: 4, color: '#fff' }}>
                    <IconClock size={20} />
                    <h6>
                      Entrega: {orderExpress.delivery_days_min} -{' '}
                      {orderExpress.delivery_days_max} días *
                    </h6>
                  </div>
                </div>
                <div>
                  <div style={{ display: 'flex', gap: 4, color: '#fff' }}>
                    <IconPackage size={20} />
                    <h6>Empaque: {orderExpress.packaging_type}</h6>
                  </div>
                </div>
                </div>
                
                <div style={{ display: 'block', padding: '0px 16px 20px' }}>
                  <p
                    style={{
                      fontSize: 'var(--font-size-7)',
                      color: 'var(--color-express-fee)',
                      marginTop: 10,
                    }}>
                    * En Lima. Si solicitaste Envio Nacional, debes considerar unos días extra.
                  </p>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </GuveryDialog>
  );
};

export default ExpressDetailsDialog;
