import { Button } from '@material-ui/core';
import { IconBrandWhatsapp, IconAlertTriangle } from '@tabler/icons';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

import { GuveryMainCard } from '#root/ui-components/cards';
import styles from './observation.module.scss';

const formatTime = (date: string) => {
  if (!date) return;

  let time: string | null;

  try {
    time = format(new Date(date.replace(/ /g, 'T')), "dd 'de' MMM 'del' YYY", { locale: es });
  } catch (error) {
    time = '---';
  }
  return time;
};

const Observation = (props) => {
  const { reason } = props;

  const title = (
    <span className={styles.header}>
      <IconAlertTriangle />
      <span className={styles.text}>Pedido Observado</span>
    </span>
  );

  return (
    <GuveryMainCard title={title}>
      <>
        <h6 className={styles.title}>Motivo: {reason?.title}</h6>
        <p className={styles.info}>{reason?.message}</p>
        <p className={styles.expiration}>
          Si no tenemos respuesta hasta el {formatTime(reason?.expiration_date)}, tu pedido será
          expirado.
        </p>
        <div className={styles.solution}>
          <div className={styles.text}>
            <p>{reason?.solution}</p>
            <br />
            <p style={{ fontWeight: '400' }}>Código de la observación: {reason?.code}</p>
          </div>
          <div className={styles.action}>
            <Button
              fullWidth
              component="a"
              href={`https://api.whatsapp.com/send?phone=51960684330&text=Hola%20Guvery,%20${reason?.code}`}
              target="_blank"
              rel="noopener noreferrer"
              variant="outlined"
              color="inherit"
              startIcon={<IconBrandWhatsapp />}>
              Whatsapp Guvery
            </Button>
          </div>
        </div>
      </>
    </GuveryMainCard>
  );
};

export default Observation;
