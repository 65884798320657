import { GuveryDialog } from '#root/ui-components';
import styles from './styles.module.scss';
import { SparksIcon } from '#root/icons';

const MatchDialog = (props) => {
  const { open = false, onClose } = props;

  return (
    <GuveryDialog
      open={open}
      onClose={onClose}
      title={<p style={{display: 'flex', alignItems: 'center', gap: 3, fontSize: 20}}><SparksIcon inlineDefault /> Pedidos Match</p>}
      closeText="Cerrar">
      <div className={styles.root}>
        {/* <p className="guv-font-size-4 guv-semibold" style={{textAlign: 'center'}}>
          <span className="guv-text-primary guv-bold">¡Nuevo servicio para viajeros!</span>
        </p> */}
        <p className="guv-font-size-5 guv-text-primary guv-regular">
          Si eres un <strong>Viajero Confiable 🎓</strong>, haz realizado al menos <strong>10 ofertas</strong> y tienes al menos <strong>1 acuerdo comprado</strong> (en este viaje), puedes ser considerado para recibir Pedidos Match ✨.
        </p>
        <p className="guv-font-size-5 guv-regular guv-text-alt-2">
          Los <strong>Pedidos Match</strong> ya están pagados. Guvery cotizó el precio real exacto y se encargó de negociar la mejor comisión posible para el viajero.
        </p>
        <p className="guv-font-size-5 guv-regular guv-text-alt-2">
          A cambio de este pedido que se convertirá directamente en un Acuerdo Pagado, Guvery le cobra al viajero un Fee desde 1$ hasta el 15% de la comisión acordada al viajero (esto dependerá de la complejidad y tiempo invertido, por parte de Guvery).
        </p>
        <p className="guv-font-size-5 guv-regular" style={{color: 'var(--color-blue)'}}>
          Si quieres recibir Pedidos Match, adicionalmente de los pedidos regulares, <strong>escríbenos en tu grupo de Whatsapp de Viajero </strong> para considerarte en los pedidos que vamos consiguiendo.
        </p>
        {/* <p className="guv-font-size-6 guv-regular guv-text-alt-2 ">
          <strong>* Ya que estamos en una versión Beta</strong>, podemos hacer excepciones a los requisitos y nuestro Fee Match estará ajustado de forma atractiva para los viajeros que nos puedan dar retro alimentación sobre este nuevo servicio, y que sean cumplidos con sus entregas. Conversa con nosotros.
        </p> */}
      </div>
    </GuveryDialog>
  );
};

export default MatchDialog;
