import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { roundDecimal } from '#root/utils';
import styles from './dealsTable.module.scss';

import { SparksIcon } from '#root/icons';

const reducerQty = (accumulator, current) => Number(accumulator) + Number(current?.can);
const reducerPrice = (accumulator, current) => Number(accumulator) + Number(current?.pre);
const reducerCom = (accumulator, current) => Number(accumulator) + Number(current?.com);

const DealRow = (props) => {
  const { deal, hasMatch } = props;
  const orders = deal?.deal_orders;

  const qtyTotal = orders?.reduce(reducerQty, 0);
  const priceTotal = orders?.reduce(reducerPrice, 0);
  const comTotal = orders?.reduce(reducerCom, 0);

  const delivery_date_aprox = deal?.fixed_real_delivery_date
    ? deal?.fixed_real_delivery_date
    : deal?.fixed_delivery_date;

  return (
    <TableRow>
      <TableCell>#{deal?.ida}</TableCell>
      <TableCell>
        {deal?.order?.user?.mask_fullname ? deal?.order?.user?.mask_fullname : '---'}
      </TableCell>
      <TableCell>{delivery_date_aprox}</TableCell>
      <TableCell>x {qtyTotal}</TableCell>
      <TableCell>
        <p className={styles.total}>$ {roundDecimal(priceTotal)}</p>
      </TableCell>
      <TableCell>
        <p className={styles.total}>$ {roundDecimal(comTotal)}</p>
      </TableCell>
      {deal?.guvery_match_fee ? (
      <TableCell>
        <p className={styles.total}>- $ {Number(deal.guvery_match_fee)}</p>
      </TableCell>
      ) : hasMatch && (
          <TableCell></TableCell>
        )
      }
    </TableRow>
  );
};

interface DealsTableProps {
  deals?: any;
  hasMatch?: boolean;
}

const DealsTable = (props: DealsTableProps) => {
  const { deals = [], hasMatch = false } = props;

  return (
    <TableContainer>
      <Table className={styles.table}>
        <TableHead className={styles.header}>
          <TableRow>
            <TableCell>Acuerdo</TableCell>
            <TableCell>Comprador</TableCell>
            <TableCell>Entrega aprox.</TableCell>
            <TableCell>Pedidos</TableCell>
            <TableCell>Precio</TableCell>
            <TableCell>Comisión</TableCell>
            {hasMatch && (
              <TableCell>Match <SparksIcon inlineDefault /></TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {deals?.map((deal, index) => (
            <DealRow key={index} deal={deal} hasMatch={hasMatch} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DealsTable;
