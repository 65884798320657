import { MouseEventHandler, ReactNode } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { IconAlertCircle } from '@tabler/icons';
import clsx from 'clsx';

import { AlertTriangleIcon, CheckmarkCircle2Icon, InfoCircleIcon } from '#root/icons';
import styles from './alertDialog.module.scss';

const variantIcon = {
  success: CheckmarkCircle2Icon,
  warning: AlertTriangleIcon,
  error: IconAlertCircle,
  info: InfoCircleIcon,
};

interface AlertDialogProps {
  open: boolean;
  onClose: MouseEventHandler<HTMLButtonElement>;
  message?: string | ReactNode;
  title?: string;
  variant?: 'error' | 'info' | 'success' | 'warning';
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmText?: string | null;
}

const AlertDialog = (props: AlertDialogProps) => {
  const { open, onClose, message, title, variant = 'info', onConfirm, onCancel, confirmText = 'Confirmar' } = props;
  const Icon = variantIcon[variant];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <h2 className={styles.title}>
        <Icon className={clsx(styles.icon, styles[variant])} />
        <p className={styles.text}>{title}</p>
      </h2>
      <DialogContent className={styles.content}>
        <p className={styles.text}>{message}</p>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button onClick={onCancel ? onCancel : onClose} color="secondary">
          Cancelar
        </Button>
        <Button
          disableElevation
          variant="contained"
          color="primary"
          onClick={onConfirm}
          autoFocus>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
