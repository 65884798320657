import { Avatar } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { formatDistanceStrict } from 'date-fns';
import { es } from 'date-fns/locale';
import clsx from 'clsx';

import { useResponsive } from '#root/hooks';
import { DoneAllIcon } from '#root/icons';
import * as VARIABLES from '#root/constants/variables';
import ConversationMessageDTO, {
  ConversationMessageStatus,
} from '#root/interfaces/ConversationMessageDTO';
import ConversationUserDTO from '#root/interfaces/ConversationUserDTO';
import styles from './message.module.scss';

interface MessageProps {
  isLoading?: boolean;
  owner?: boolean;
  message?: ConversationMessageDTO;
  conversationUsers?: ConversationUserDTO[];
}

const urlify = (text) => {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    return (
      '<a style="text-decoration: underline" target="_blank" href="' + url + '">' + url + '</a>'
    );
  });
};

const Message = (props: MessageProps) => {
  const { isLoading, owner, message, conversationUsers = [] } = props;
  const { isMobile } = useResponsive();
  const time = message?.created_at ? message.created_at : '2021-06-25T18:44:35.000000Z';
  const messageTime = time.toString().replace(/ /g, 'T');

  const recordUser = conversationUsers.find((user) => {
    return user.user_id === message.user_id;
  });
  const userName = recordUser ? recordUser?.user?.first_name : 'Usuario';

  const messageView = message?.current_status === ConversationMessageStatus.seen ? true : false;
  const userActive = message?.receptor_is_active ? true : false;

  return (
    <div className={styles.root}>
      {!isLoading ? (
        <div className={clsx(styles.wrap, owner && styles.owner)}>
          {!owner && !isMobile && (
            <div className={styles.profile}>
              <Avatar
                alt={userName}
                src={VARIABLES.IMAGE_PROFILE_PATH + recordUser?.user.profile_picture + '.png'}>
                {userName.charAt(0)}
              </Avatar>
            </div>
          )}
          <div className={!isMobile ? styles.content : undefined}>
            <div className={clsx(styles.text, owner && styles.owner)}>
              <div
                className={styles.msg}
                dangerouslySetInnerHTML={{ __html: urlify(message?.content) }}
              />
              {/*<p className={styles.msg}>{urlify(message?.content)}</p>*/}
            </div>
            <span className={clsx(styles.time, owner && styles.owner)}>
              {formatDistanceStrict(new Date(messageTime), new Date(), { locale: es })} atras
              {owner && (
                <span
                  className={clsx(
                    styles.icon,
                    owner && styles.owner,
                    messageView && styles.active,
                    userActive && styles.active,
                  )}>
                  <DoneAllIcon />
                </span>
              )}
            </span>
          </div>
        </div>
      ) : (
        <div className={clsx(styles.wrap, owner && styles.owner)}>
          {!owner && !isMobile && (
            <div className={styles.profile}>
              <Skeleton variant="circle" width={32} height={32} />
            </div>
          )}
          <div className={!isMobile ? styles.content : undefined}>
            <Skeleton
              variant="rect"
              component="div"
              className={clsx(styles.text, owner && styles.owner)}
              width={240}
              height={36}
            />
            <Skeleton
              variant="rect"
              component="div"
              width="40%"
              height={10}
              style={{ marginTop: 4 }}
              className={clsx(styles.time, owner && styles.owner)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Message;
