import { ChangeEvent, useState, useEffect } from 'react';
import { Button, InputBase, IconButton } from '@material-ui/core';
import { IconSearch } from '@tabler/icons';

import { GuveryDialog } from '#root/ui-components';
import styles from './searchDialog.module.scss';

const SearchDialog = (props) => {
  const { open = false, onClose, onSearch = null, searchBy = '', setSearch = null, setOffset = null } = props;
  const [searchx, setSearchx] = useState('');
  const searchByText = (searchBy === '') ? "palabras" : searchBy;

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchx(event.target.value);
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      handleSend();
    }
  };

  const handleSend = () => {
    if (!searchx) {
      return;
    }
    if (setSearch) setSearch(searchx);
    if (onSearch) onSearch(searchx);
    if (setOffset) setOffset(0);
    onClose();
  };

  useEffect(() => {
    if (open) {
      setSearchx('');
    }
  }, [open]);

  return (
    <GuveryDialog
      content={false}
      disableActions
      open={open}
      onClose={onClose}
      className={styles.dialog}>
      <div className={`${styles.root} ${styles.noBottom}`}>
        <h5>
          <strong>Puedes buscar por:</strong> {searchByText}
        </h5>
      </div>
      <div className={styles.root}>
        <div className={styles.field}>
          <IconButton
            disableRipple
            disableFocusRipple
            className={styles.submitIcon}
            type="submit"
            aria-label="search"
            onClick={handleSend}>
            <IconSearch size={22} />
          </IconButton>
          <InputBase
            fullWidth
            className={styles.input}
            placeholder="ej. ebay"
            onKeyUp={handleKeyUp}
            value={searchx}
            onChange={handleChangeSearch}
            autoFocus
          />
        </div>
        <Button
          disableElevation
          className={styles.submit}
          variant="contained"
          color="primary"
          onClick={handleSend}>
          Buscar
        </Button>
      </div>
    </GuveryDialog>
  );
};

export default SearchDialog;
