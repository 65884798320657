import React, { Fragment, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { USER_WALLET_REFUNDS } from '#root/constants/navigation';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Tabs,
  Tab,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

import { userService } from '#root/services';
import { useDialog, useAuth } from '#root/hooks';
import { PaymentCheckoutDialog } from '#root/components';
import { GuveryLayout, GuveryPageHeader, GuveryDialog } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import { TrendingDown, TrendingUp } from '#root/icons';
import './userWallet.scss';

const GuveryTableCell = withStyles((theme) => ({
  head: {
    maxWidth: '380px',
    backgroundColor: 'rgba(34, 51, 84, 0.02)',
    borderTop: '1px solid #e8e8e8',
    fontWeight: 'var(--font-weight-semibold)',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const GuveryTabs = withStyles({
  root: {
    height: '38px',
    minHeight: '38px',
  },
  indicator: {
    height: '38px',
    minHeight: '38px',
    backgroundColor: 'var(--color-primary)',
    borderRadius: '6px',
  },
})((props) => <Tabs {...props} />);

const GuveryTab = withStyles((theme) => ({
  root: {
    minHeight: '38px',
    minWidth: 'auto',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '14px',
    marginRight: theme.spacing(1),
    transition: 'color 0.2s ease 0s',
    '&:hover': {
      opacity: 1,
    },
  },
  selected: {
    color: 'rgb(255, 255, 255)',
    zIndex: 5,
  },
}))((props) => <Tab disableRipple {...props} />);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wallte-tabpanel-${index}`}
      aria-labelledby={`wallet-tab-${index}`}
      {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `wallet-tab-${index}`,
    'aria-controls': `wallet-tabpanel-${index}`,
  };
};

const AmountText = (props) => {
  const { currency, amount, balance } = props;
  const variantCurrency = { SOLES: 'PEN', DOLARES: 'USD' };
  const variantClassName = { INGRESO: 'success', EGRESO: 'error' };
  const variantSuffix = { INGRESO: '+', EGRESO: '-' };

  const type = variantCurrency[currency] !== undefined && variantCurrency[currency];
  const className = variantClassName[balance] !== undefined && variantClassName[balance];
  const suffix = variantSuffix[balance] !== undefined && variantSuffix[balance];

  return (
    <span className={`amount-text amount-text--${className}`}>
      {`${suffix} ${amount} `}
      <span className="currency">{type}</span>
    </span>
  );
};

const WalletRow = (props) => {
  const { row } = props;
  let idx = '#' + row.idx;
  if (row.con === 'RECARGA SALDO' || row.con === 'REEMBOLSO COMPRADOR') {
    idx = '';
  }
  return (
    <TableRow hover>
      <TableCell>
        {format(new Date(row.feccre.replace(/ /g, 'T')), "dd 'de' MMMM 'del' yyyy", {
          locale: es,
        })}
      </TableCell>
      <TableCell>
        <div className="desc-wrap">
          <div
            className={`icon ${row.tip === 'INGRESO' && 'icon--success'} ${
              row.tip === 'EGRESO' && 'icon--error'
            }`}>
            {row.tip === 'INGRESO' && <TrendingUp />}
            {row.tip === 'EGRESO' && <TrendingDown />}
          </div>
          <div className="name">
            <span>{row.con} </span>
            {idx}
          </div>
        </div>
      </TableCell>
      <TableCell>{row.ban}</TableCell>
      <TableCell>
        <AmountText currency={row.mon} amount={row.imp} balance={row.tip} />
      </TableCell>
    </TableRow>
  );
};

const SkeletonRow = (
  <TableRow>
    <TableCell>
      <Skeleton width="100%" />
    </TableCell>
    {Array.from(new Array(3)).map((data, index) => (
      <TableCell key={index}>
        <Skeleton />
      </TableCell>
    ))}
  </TableRow>
);

const RechargeDialog = (props) => {
  const { open = false, onClose, onConfirm } = props;
  return (
    <GuveryDialog open={open} onClose={onClose} title="Recargar mi saldo" closeText="Cerrar">
      <div className="dialog-content-recharge">
        <p className="guv-font-size-5 guv-text-darker guv-regular">
          Recarga tu saldo para que puedas pagar tus acuerdos más rápido.
        </p>
        <div className="actions">
          <p className="guv-font-size-5">
            Puedes solicitar el retiro de tu saldo a favor cuando lo desees.
          </p>
          <Button
            disableElevation
            fullWidth
            variant="contained"
            color="primary"
            onClick={onConfirm}>
            Hacer una recarga
          </Button>
        </div>
      </div>
    </GuveryDialog>
  );
};

const RetireDialog = (props) => {
  const { open = false, onClose } = props;
  return (
    <GuveryDialog open={open} onClose={onClose} title="Retirar mi saldo" closeText="Cerrar">
      <div className="dialog-content-retire">
        <p className="guv-font-size-5 guv-regular">
          Antes de solicitar el retiro de tu saldo, recuerda que mantener un saldo a favor en
          Guvery te ayudará a pagar más rapido tus acuerdos.
        </p>
        <div className="actions">
          <p className="guv-font-size-5">
            Puedes solicitar el retiro de tu saldo cuando lo desees
          </p>
          <p className="guv-font-size-5">Escríbenos a nuestro Whatsapp:</p>
          <a
            className="guv-font-size-5 guv-no-decoration"
            href="https://api.whatsapp.com/send?phone=51960684330&text=Hola!"
            target="_blank"
            rel="noopener noreferrer">
            +51 960684330
          </a>
          <p className="guv-font-size-5" style={{ marginTop: 8 }}>
            Lunes a Viernes de 9am a 6pm.
          </p>
          <Button
            disableElevation
            fullWidth
            variant="contained"
            color="primary"
            onClick={onClose}
            className="guv-no-decoration">
            Entendido
          </Button>
        </div>
      </div>
    </GuveryDialog>
  );
};

function UserWallet() {
  const retireDialog = useDialog();
  const rechargeDialog = useDialog();
  const { usuario } = useAuth();
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(1);
  const [wallet, setWallet] = useState({
    transactions_created: [],
    transactions_invalid: [],
    transactions_valid: [],
  });
  // Payment vars
  const paymentDialog = useDialog();
  const [paymentData, setPaymentData] = useState(null);
  const [paymentDialogSource, setPaymentDialogSource] = useState({
    title: 'Recargar Saldo',
    id: null,
    amount: 0,
    amountHelper: 'Importe de la recarga:',
    currency: 'DOLARES',
    disabledCurrencies: false,
    disabledPrice: false,
    allowConvert: false,
    allowCredits: false,
    allowCoupon: false,
    allowZipago: true,
    showCredits: true,
    stepsArray: [
      'Selecciona el método y el banco.',
      'Escoge la moneda y el monto.',
      'Realiza el pago y confírmalo.',
    ],
    methodsHelper: '',
    paymentType: 'WALLET',
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openPaymentDialog = () => {
    // rechargeDialog.closeDialog();
    paymentDialog.openDialog();
  };

  const closePaymentDialog = () => {
    fetchData();
    paymentDialog.closeDialog();
  };

  const fetchData = async () => {
    setLoading(true);
    const res = await userService.wallet();
    // console.log(res);
    if (!res.error) {
      const data = res.data;
      if (data?.bank_transactions?.transactions_created.length > 0) {
        setValue(0);
      }
      setWallet(data.bank_transactions);
      setPaymentData(data.payment_checkout_dialog);
      setPaymentDialogSource({
        ...paymentDialogSource,
        allowZipago: data?.payment_checkout_dialog?.zipago ? true : false,
        showCredits: data?.payment_checkout_dialog?.show_credits,
      });
    } else {
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GuveryLayout className="user-wallet">
      <GuveryPageHeader
        title="Mi Billetera"
        extra={[
          <Button
            key="1"
            color="primary"
            variant="outlined"
            component={NavLink}
            to={USER_WALLET_REFUNDS}>
            Reembolsos
          </Button>,
          <Button key="1" color="primary" variant="contained" onClick={openPaymentDialog}>
            Recargar
          </Button>,
        ]}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <GuveryPaper disableElevation disableHover>
            <div className="card-wallet">
              {usuario.saldo_est === 1 ? (
                <>
                  <h6>
                    <span>{usuario.saldo}</span> US$
                  </h6>
                  {usuario.saldo < 0 && usuario.saldo_evento === 'ENVIO_ACTUALIZADO' && (
                    <p className="balanceEvent">(envío actualizado)</p>
                  )}
                  {usuario.saldo < 0 && usuario.saldo_evento === 'ACUERDO_MODIFICADO' && (
                    <p className="balanceEvent">(pedido actualizado)</p>
                  )}
                  {usuario.saldo > 0 && (
                    <>
                      <p className="balanceMessage positive">
                        El saldo a tu favor <strong>jamás expira</strong> y estará disponible
                        para ti <strong>por siempre</strong>.
                      </p>
                      <p className="balanceSubMessage positive">
                        Puedes usarlo para pagar ó puedes solicitar un reembolso en cualquier
                        momento.
                      </p>
                    </>
                  )}
                </>
              ) : (
                <h6>En proceso...</h6>
              )}
            </div>
          </GuveryPaper>
        </Grid>
        <Grid item xs={12} md={12}>
          <GuveryPaper disableHover disableElevation className="card-movements">
            <div className="header">
              <h4>Movimientos</h4>
              <GuveryTabs value={value} onChange={handleChange} aria-label="tabs wallet types">
                <GuveryTab label="En Proceso" {...a11yProps(0)} />
                <GuveryTab label="Válidos" {...a11yProps(1)} />
                <GuveryTab label="No Válidos" {...a11yProps(2)} />
              </GuveryTabs>
            </div>
            <TabPanel value={value} index={0}>
              <TableContainer>
                <Table style={{ minWidth: 650 }} aria-label="wallet table">
                  <TableHead>
                    <TableRow>
                      <GuveryTableCell>Fecha</GuveryTableCell>
                      <GuveryTableCell>Descripción</GuveryTableCell>
                      <GuveryTableCell>Banco</GuveryTableCell>
                      <GuveryTableCell>Importe</GuveryTableCell>
                    </TableRow>
                  </TableHead>
                  {!loading ? (
                    <TableBody>
                      {wallet.transactions_created.map((row, index) => (
                        <WalletRow row={row} key={index} />
                      ))}
                    </TableBody>
                  ) : (
                    <TableBody>
                      {Array.from(new Array(10)).map((data, index) => (
                        <React.Fragment key={index}>{SkeletonRow}</React.Fragment>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {wallet.transactions_created.length <= 0 && !loading && (
                <div className="no-content">
                  <p>No hay transacciones</p>
                </div>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TableContainer>
                <Table style={{ minWidth: 650 }} aria-label="wallet table">
                  <TableHead>
                    <TableRow>
                      <GuveryTableCell>Fecha</GuveryTableCell>
                      <GuveryTableCell>Descripción</GuveryTableCell>
                      <GuveryTableCell>Banco</GuveryTableCell>
                      <GuveryTableCell>Importe</GuveryTableCell>
                    </TableRow>
                  </TableHead>
                  {!loading ? (
                    <TableBody>
                      {wallet.transactions_valid.map((row, index) => (
                        <WalletRow row={row} key={index} />
                      ))}
                    </TableBody>
                  ) : (
                    <TableBody>
                      {Array.from(new Array(10)).map((data, index) => (
                        <React.Fragment key={index}>{SkeletonRow}</React.Fragment>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {wallet.transactions_valid.length <= 0 && !loading && (
                <div className="no-content">
                  <p>No hay transacciones</p>
                </div>
              )}
            </TabPanel>
            <TabPanel value={value} index={2}>
              <TableContainer>
                <Table style={{ minWidth: 650 }} aria-label="wallet table">
                  <TableHead>
                    <TableRow>
                      <GuveryTableCell>Fecha</GuveryTableCell>
                      <GuveryTableCell>Descripción</GuveryTableCell>
                      <GuveryTableCell>Banco</GuveryTableCell>
                      <GuveryTableCell>Importe</GuveryTableCell>
                    </TableRow>
                  </TableHead>
                  {!loading ? (
                    <TableBody>
                      {wallet.transactions_invalid.map((row, index) => (
                        <WalletRow row={row} key={index} />
                      ))}
                    </TableBody>
                  ) : (
                    <TableBody>
                      {Array.from(new Array(10)).map((data, index) => (
                        <React.Fragment key={index}>{SkeletonRow}</React.Fragment>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {wallet.transactions_invalid.length <= 0 && !loading && (
                <div className="no-content">
                  <p>No hay transacciones</p>
                </div>
              )}
            </TabPanel>
          </GuveryPaper>
        </Grid>
      </Grid>
      <RechargeDialog
        open={rechargeDialog.open}
        onClose={rechargeDialog.closeDialog}
        onConfirm={openPaymentDialog}
      />
      <RetireDialog open={retireDialog.open} onClose={retireDialog.closeDialog} />
      <PaymentCheckoutDialog
        open={paymentDialog.open}
        onClose={closePaymentDialog}
        dataSource={paymentDialogSource}
        banks={paymentData?.banks}
        currencies={paymentData?.currencies}
        zipago={paymentData?.zipago}
      />
    </GuveryLayout>
  );
}

export default UserWallet;
