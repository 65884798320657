import { SyntheticEvent, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton, Slide } from '@material-ui/core';
import {
  IconAlertTriangle,
  IconAlertCircle,
  IconInfoCircle,
  IconChecks,
  IconX,
} from '@tabler/icons';
import clsx from 'clsx';

import { RootState } from '#root/store/reducer';
import { NOTIFICATION_CLOSED } from '#root/store/actions';
import { NotificationClosedAction } from '#root/store/notificationReducer';
import useEventCallback from '#root/utils/useEventCallback';
import styles from './banner.module.scss';

const variantIcon = {
  success: IconChecks,
  warning: IconAlertTriangle,
  error: IconAlertCircle,
  info: IconInfoCircle,
};

const Banner = () => {
  const bannerInitial = useSelector((state: RootState) => state.notification);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const timerAutoHide = useRef<number>();
  const IconRoot = variantIcon[bannerInitial.severity];

  const closeNotification = () => {
    return dispatch<NotificationClosedAction>({
      type: NOTIFICATION_CLOSED,
    });
  };

  const handleClose = (event: SyntheticEvent | MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    closeNotification();
  };

  const setAutoHideTimer = useEventCallback((autoHideDurationParam: number) => {
    if (autoHideDurationParam == null) {
      return;
    }

    clearTimeout(timerAutoHide.current);
    timerAutoHide.current = window.setTimeout(() => {
      handleClose(null, 'timeout');
    }, autoHideDurationParam);
  });

  useEffect(() => {
    setOpen(bannerInitial.open);
  }, [bannerInitial.action, bannerInitial.open]);

  useEffect(() => {
    if (open) {
      setAutoHideTimer(bannerInitial.duration);
    }

    return () => {
      clearTimeout(timerAutoHide.current);
    };
  }, [open, bannerInitial.duration, setAutoHideTimer]);

  return (
    <>
      {/* alert banner */}
      {bannerInitial.variant === 'banner' && (
        <Slide appear={false} direction="down" in={open}>
          <div className={styles.root}>
            <div className={styles.paper}>
              <div className={clsx(styles.wrap, styles[bannerInitial.severity])}>
                <div className={styles.content}>
                  <div className={styles.icon}>
                    {bannerInitial.icon ? bannerInitial.icon : <IconRoot />}
                  </div>
                  <div className={styles.text}>{bannerInitial.message}</div>
                </div>
                <div className={styles.actions}>
                  <IconButton
                    className={styles.btn}
                    aria-label="close"
                    size="small"
                    onClick={handleClose}>
                    <IconX size={20} />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </Slide>
      )}
    </>
  );
};

export default Banner;
