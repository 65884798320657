import { GuveryDialog } from '#root/ui-components';
import { IconExternalLink } from '@tabler/icons';
import styles from './provinceDialog.module.scss';

const ProvinceDialog = (props) => {
  const { open = false, onClose } = props;
  return (
    <GuveryDialog
      open={open}
      closeText='Entendido'
      onClose={onClose}
      title="Acuerdos con Envío Nacional"
      content={false}>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <p>
            Los acuerdos marcados como <strong>Envío Nacional</strong>
            &nbsp;se deben entregar en una oficina de&nbsp;
            <strong>MailBoxes ETC</strong>. <span className={styles.advice}>&nbsp; No se deben embalar &nbsp;</span>
          </p>
          <ul>
            <li>MBE Miraflores:&nbsp;<a href="https://goo.gl/maps/ehbT1nqisAz4gzTd9" target='_blank'><span>Calle Berlín 219, Miraflores</span><IconExternalLink size={16} /></a></li>
            <li>MBE San Isidro:&nbsp;<a href="https://goo.gl/maps/Bvy8W4c2LU82HF3f8" target='_blank'><span>Las Begonias 774, San Isidro</span><IconExternalLink size={16} /></a></li>
            <li>MBE Surco:&nbsp;<a href="https://goo.gl/maps/LexVPchqLSyFqwvr5" target='_blank'><span>Av. Primavera 1314, Santiago de Surco</span><IconExternalLink size={16} /></a></li>
          </ul>
          <p>
            <strong>⏰ Horarios de atención:</strong>
          </p>
          <ul style={{marginTop: 0}}>
            <li>Lunes a Viernes: <strong style={{color: 'var(--color-status-success)'}}>9am a 6pm</strong></li>
            <li>Sábados: <strong style={{color: 'var(--color-status-success)'}}>9am a 1pm</strong></li>
            <li>Domingos y feriados: <span style={{color: 'var(--color-status-error)'}}>cerrado</span>.</li>
          </ul>
          <p>
            <strong>✅ Indicaciones de entrega:</strong>
          </p>
          <ul style={{marginTop: 0}}>
            <li>🙋‍♂️ "Buen día, vengo a dejar pedidos de Guvery"</li>
            <li>▶︎ Coloca los pedidos sobre el mostrador y toma unas fotos del conjunto.</li>
            <li>▶︎ Envíalas por tu grupo de whatsapp de viajero (a Guvery).</li>
            <li>▶︎ Toma una <strong  style={{color: 'var(--color-status-success)'}}>*</strong>foto<strong  style={{color: 'var(--color-status-success)'}}>*</strong> de cada pedido y súbela al Acuerdo de Guvery para confirmar la entrega.</li>
            <li style={{color: 'var(--color-status-success)', fontSize: '0.8em'}}><strong>*</strong> Asegúrate que en la foto se pueda ver claramente el estado del pedido entregado.</li>
            <li style={{color: 'var(--color-status-success)', fontSize: '0.8em'}}><strong>**</strong> Si el pedido es un iPhone, iPad o Macbook: envíanos una foto por el grupo del modelo y número de serie (aparecen en el reverso del empaque).</li>
          </ul>
        </div>
      </div>
    </GuveryDialog>
  );
};

export default ProvinceDialog;
