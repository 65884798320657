import { useRef, useState, useEffect } from 'react';
import { IconButton, Input, InputAdornment } from '@material-ui/core';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

import { useResponsive, useAuth } from '#root/hooks';
import { SendIcon, SmilingFaceIcon } from '#root/icons';
import ConversationMessageDTO from '#root/interfaces/ConversationMessageDTO';
import styles from './messageInput.module.scss';

interface EmojiProps {
  colons?: string;
  emoticons?: any[];
  id?: string;
  name?: string;
  native?: string;
  short_names?: any[];
  skin?: any | null;
  unified?: string;
}

interface EmojiPickerProps {
  disabled: boolean;
  value: string;
  setValue: (event: string) => void;
}

const EmojiPicker = (props: EmojiPickerProps) => {
  const { disabled, value, setValue } = props;
  const { isMobile } = useResponsive();
  const [isOpen, setOpen] = useState<boolean>(false);
  const pickerRef = useRef(null);
  const buttonRef = useRef(null);

  const stylesProps = isMobile && { style: { width: '272px' } };

  const handlePicker = (emoji: EmojiProps) => {
    setValue(value + emoji.native);
  };

  const handleClickOutside = (event) => {
    const pickerNode = pickerRef.current;
    const buttonNode = buttonRef.current;
    if (buttonNode && buttonNode.contains(event.target)) {
      return;
    }
    if (pickerNode && !pickerNode.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleOpenPicker = () => {
    setOpen(!isOpen);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className={styles.emojiPicker}>
      <div className={styles.picker} ref={pickerRef}>
        {isOpen && (
          <Picker
            // native
            color="var(--color-primary)"
            showPreview={!isMobile}
            showSkinTones={!isMobile}
            title="Elige tu emoji…"
            emoji="point_up"
            // perLine={10}
            onSelect={handlePicker}
            {...stylesProps}
          />
        )}
      </div>
      <IconButton size="small" disabled={disabled} ref={buttonRef} onClick={handleOpenPicker}>
        <SmilingFaceIcon fontSize="small" />
      </IconButton>
    </div>
  );
};

interface MessageInputProps {
  token: string | number | null;
  conversationId: string | number | null;
  onSend: (event: ConversationMessageDTO) => void;
  disabled: boolean;
  disableText: any;
}

const MessageInput = (props: MessageInputProps) => {
  const { token, conversationId, onSend, disabled, disableText } = props;
  const { usuario } = useAuth();
  const [message, setMessage] = useState('');

  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      handleSend();
    }
  };

  const handleSend = () => {
    if (!message) {
      return '';
    }
    if (onSend) {
      onSend({
        channel_id: conversationId,
        user_id: usuario.idu,
        token_node: token,
        content: message,
      });
    }
    return setMessage('');
  };

  const emoji = (
    <>
      {!disabled && (
        <InputAdornment position="start">
          <EmojiPicker disabled={disabled} value={message} setValue={setMessage} />
        </InputAdornment>
      )}
    </>
  );

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <Input
          fullWidth
          multiline
          disableUnderline
          autoComplete="off"
          disabled={disabled}
          onKeyUp={handleKeyUp}
          value={message}
          onChange={handleChangeMessage}
          placeholder={disabled ? disableText : 'Escribe un mensaje'}
          style={{ height: '100%' }}
          startAdornment={emoji}
        />
      </div>
      <div className={styles.divider} />
      <div className={styles.action}>
        <IconButton color="primary" disabled={!message} onClick={handleSend}>
          <SendIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default MessageInput;
