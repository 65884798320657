import { MouseEvent } from 'react';
import { Avatar, Button, Tooltip } from '@material-ui/core';
import { IconStar, IconExternalLink } from '@tabler/icons';
import { formatDistanceStrict } from 'date-fns';
import { es } from 'date-fns/locale';

import { useDialog } from '#root/hooks';
import { ProvinceDialog } from '#root/components';
import { GuveryObsToolTip, GuveryShippingBadge, GuverySpace } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import { PersonIcon, InboxIcon } from '#root/icons';
import { ImageHorizontal } from '#root/assets';
import * as VARIABLES from '#root/constants/variables';
import styles from './orderCard.module.scss';

const getTime = (orderDate) => {
  let time;
  try {
    time = orderDate
      ? formatDistanceStrict(new Date(orderDate.replace(/ /g, 'T')), new Date(), { locale: es })
      : '---';
  } catch (error) {
    time = 'error';
  }
  return time;
};

interface OrderCardProps {
  xref?: any;
  order: any;
  disableOffer?: boolean;
  onActionClick: (e?: MouseEvent<HTMLElement>) => void;
}

const OrderCard = (props: OrderCardProps) => {
  const { xref, order, onActionClick, disableOffer } = props;
  const provinceDialog = useDialog();

  const orderDate = order?.fecpub ? order?.fecpub : order?.feccre;
  const formatDate = getTime(orderDate);

  return (
    <GuveryPaper disableBorder disableHover className={styles.root}>
      <div ref={xref} className={styles.cover}>
        <a
          className="guv-no-decoration"
          href={order.url}
          target="_blank"
          rel="noopener noreferrer">
          {order?.img_path ? (
            <img src={order.img_path} alt="Pedido" />
          ) : (
            <img className={styles.placeholder} src={ImageHorizontal} alt="Pedido" />
          )}
        </a>
      </div>
      <div className={styles.content}>
        <div className={styles.contentWrap}>
          
          <div className={styles.orderInfo}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 6}}>
            <span className={styles.date}>Hace {formatDate}</span>
            {order.flag_envio === 1 && (
            <GuveryShippingBadge
              onClick={provinceDialog.openDialog}
            />
          )}
            </div>
            
            <a
              className="guv-no-decoration guv-block"
              href={order?.url}
              target="_blank"
              rel="noopener noreferrer">
              <h4 className={styles.name}>{order?.tit}</h4>
            </a>
            <a
              className={styles.linkUrl}
              href={order?.url}
              target="_blank"
              rel="noopener noreferrer">
              <div className={styles.text}>{order?.url_host}</div>
            </a>
            {order?.obs && (
              <GuveryObsToolTip arrow title={order.obs} placement="bottom">
                <div className={styles.obs}>
                  <p>{order.obs}</p>
                </div>
              </GuveryObsToolTip>
            )}
            <GuverySpace wrap>
              {order?.can && order?.can > 1 && (
                <div className={styles.chip}>
                  <div className={styles.link}>
                    <div className={styles.text}>
                      <strong>x {order?.can}</strong>
                    </div>
                  </div>
                </div>
              )}
              {order?.condicion && (
                <div className={styles.chip}>
                  <div className={styles.link}>
                    <div className={styles.text}>condición: {order?.condicion}</div>
                  </div>
                </div>
              )}

              {order?.empaque && (
                <div className={styles.chip}>
                  <div className={styles.link}>
                    <div className={styles.text}>{order?.empaque}</div>
                  </div>
                </div>
              )}
            </GuverySpace>
          </div>
          {order?.user && (
            <div className={styles.userInfo}>
              <div className={styles.avatar}>
                <Avatar
                  alt={order.user?.mask_fullname}
                  src={VARIABLES.IMAGE_PROFILE_PATH + order.user?.pic + '.png'}
                  sizes="24">
                  <PersonIcon />
                </Avatar>
                <div>
                  <span className={styles.userName}>{order.user?.mask_fullname}</span>
                  {order?.user?.calcom && (
                    <Tooltip arrow title="Puntaje del comprador" placement="bottom">
                      <div className={styles.userScore}>
                        <IconStar />
                        <span>{order.user.calcom}</span>
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.actions}>
        <div className={styles.actionsWrap}>
          <div className={styles.price}>
            <h5>
              <strong className="guv-text-alt-2">{order?.pre} $</strong>
            </h5>
            <div>
              <p>Precio</p>
              <p>Referencial</p>
            </div>
          </div>
          <div className={styles.price}>
            <h5>
              <strong>+ {order?.com} $</strong>
            </h5>
            <div>
              <p>Comisión propuesta</p>
              <p>por el comprador</p>
            </div>
          </div>
          <Button
            fullWidth
            disableElevation
            size="large"
            disabled={disableOffer}
            variant="contained"
            color="primary"
            startIcon={<InboxIcon />}
            onClick={() => onActionClick(order)}>
            Hacer Oferta
          </Button>
        </div>
      </div>
      <ProvinceDialog
        open={provinceDialog.open}
        onClose={provinceDialog.closeDialog}
        title="Pedidos con Envío Nacional"
        content={
          <>
            <p>
              Los pedidos marcados como <strong>Envío Nacional</strong>
              &nbsp;se deben entregar en una oficina de&nbsp;
              <strong>MailBoxes ETC</strong>&nbsp;-- (sin embalar)
            </p>
            <ul>
              <li><strong>MBE Miraflores:</strong>&nbsp;Cerca al parque Kennedy</li>
              <li><strong>MBE San Isidro:</strong>&nbsp;Frente a Tottus de Las Begonias</li>
              <li><strong>MBE Surco:</strong>&nbsp;Cruce de Av. Primavera con Av. El Polo</li>
            </ul>
          </>
        }
      />
    </GuveryPaper>
  );
};

export default OrderCard;
