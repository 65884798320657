import { useState } from 'react';
import { Avatar, Button, IconButton, Tooltip } from '@material-ui/core';
import { IconStar, IconQuestionMark, IconMessageCircle } from '@tabler/icons';
import clsx from 'clsx';

import { useAuth, useDialog } from '#root/hooks';
import { ExpirationDays, ChatDrawer, TravelerCard } from '#root/components';
import { GuveryPaper } from '#root/ui-components/cards';
import styles from './offerItem.module.scss';
import PriceDialog from '../PriceDialog';

export const OfferStatus = {
  creado: '0',
  negociando: '1',
  aceptado: '2',
  rechazado: '4',
  rechazado_viajero: '5',
  rechazado_comprador: '6',
  rechazado_admin: '7',
} as const;

const commissionGuvery = (price, com, guv, guvTope, guvExtra) => {
  const sumPrices = Number(price) + Number(com);
  const getPercent = (sumPrices * Number(guv)) / 100;
  const getMaxPercent = getPercent > Number(guvTope) ? Number(guvTope) : getPercent;
  const commission = getMaxPercent + Number(guvExtra);

  return commission;
};

const OfferItem = (props) => {
  const { orderImage, orderTitle, orderUrl, orderExpire, offer, openOfferDrawer, setReviewTravelerData, travelerReviewsDialog } = props;
  const { negocio } = useAuth();
  const priceDialog = useDialog();
  const [priceData, setPriceData] = useState({
    image: '',
    name: '',
    link: '',
    can: null,
    price: null,
    comTraveler: null,
    comGuvery: null,
  });

  const comGuveryPCT = Number(negocio?.com_porc);
  const priceOffer = Number(offer.prerea);
  const commisonOffer = Number(offer?.comvia);
  const sumPrice = priceOffer + commisonOffer;
  const commission = commissionGuvery(
    priceOffer,
    commisonOffer,
    comGuveryPCT,
    negocio?.com_tope,
    negocio?.com_link,
  );
  const price = sumPrice + commission;
  const isOfferNegotiating = offer.est === OfferStatus.negociando ? true : false;
  const isOfferAccepted = offer.est === OfferStatus.aceptado ? true : false;
  const isOfferRejected =
    offer.est === OfferStatus.rechazado ||
    offer.est === OfferStatus.rechazado_viajero ||
    offer.est === OfferStatus.rechazado_comprador ||
    offer.est === OfferStatus.rechazado_admin
      ? true
      : false;
  const isOfferExpired =
    offer?.fixed_expiration_days !== undefined && offer.fixed_expiration_days < 0;

  const chatDrawer = useDialog();

  const handleOpenOfferDrawer = () => {
    openOfferDrawer(offer);
  };

  const handlePriceDialogOpen = (can, priceOffer, comTraveler, comGuvery) => {
    setPriceData({
      image: orderImage,
      name: orderTitle,
      link: orderUrl,
      can: can,
      price: priceOffer,
      comTraveler: comTraveler,
      comGuvery: comGuvery,
    });
    priceDialog.openDialog();
  };

  return (
    <GuveryPaper disableHover disableElevation className={styles.root}>
      <div className={styles.header}>
        <TravelerCard traveler={offer?.traveler} setReviewTravelerData={setReviewTravelerData} travelerReviewsDialog={travelerReviewsDialog} />

        {false && (
          <div className={styles.user}>
          <a
            href={offer?.traveler?.url_traveler_public_profile}
            className="guv-no-decoration"
            target="_blank"
            rel="noopener noreferrer">
            <Avatar className={styles.avatar} src={offer.traveler.picture_path}>
              {offer.traveler.one_firstname.charAt(0)}
            </Avatar>
          </a>
          <div className={styles.userInfo}>
            <a
              href={offer?.traveler?.url_traveler_public_profile}
              className="guv-no-decoration"
              target="_blank"
              rel="noopener noreferrer">
              <h6 className={clsx(styles.name, 'guv-ellipsis')}>
                {offer.traveler.mask_fullname}
              </h6>
            </a>

            {offer?.traveler?.calvia && (
              <Tooltip arrow title="Puntaje del viajero" placement="top">
                <div className={styles.userScore}>
                  <IconStar />
                  <span>{offer.traveler.calvia}</span>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        )}

        
        {(offer.est === OfferStatus.negociando || offer.est === OfferStatus.aceptado) && (
          <>
            <Tooltip arrow title="Chatear" placement="top">
              <IconButton
                size="small"
                color={isOfferExpired ? 'secondary' : 'primary'}
                disabled={isOfferExpired ? true : false}
                className={isOfferExpired ? null : 'iconMessageCircleContained'}
                onClick={chatDrawer.openDialog}>
                <IconMessageCircle />
              </IconButton>
            </Tooltip>
            <ChatDrawer
              extraTitle={`Oferta #${offer?.id_public}`}
              offerId={offer?.ido}
              open={chatDrawer.open}
              onClose={chatDrawer.closeDialog}
            />
          </>
        )}
      </div>
      <div className={styles.content}>
        {(isOfferNegotiating || isOfferAccepted) && (
          <div className={styles.statusLabel}>
            <span>{offer?.status_label}</span>
          </div>
        )}
        <div className={styles.offerInfo}>
          <h6>
            Entrega: <strong>{offer.fixed_delivery_date} </strong>
          </h6>
          <h6>
            En: <strong>{offer.lugent}</strong>
          </h6>
        </div>
        <div
          className={styles.offerPrice}
          onClick={() =>
            handlePriceDialogOpen(offer?.can, priceOffer, commisonOffer, commission)
          }>
          <div className={styles.priceInfo}>
            <h3>
              <span className={styles.currency}>$</span>
              <span className={styles.priceTotal}>{price}</span>
              <span className={styles.pricetext}> Total a pagar</span>
              <span className={styles.offerQty}> &nbsp;x {offer?.can ? offer.can : '1'}</span>
            </h3>
          </div>
          <Tooltip arrow title="Detalle del total" placement="top">
            <IconButton size="small" color="primary" className={styles.link}>
              <IconQuestionMark />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className={styles.offerAction}>
        {!isOfferRejected && !orderExpire && (
          <Button
            fullWidth
            disableElevation
            variant={isOfferExpired ? 'outlined' : 'contained'}
            color={isOfferExpired ? 'secondary' : 'primary'}
            onClick={handleOpenOfferDrawer}
            startIcon={
              isOfferExpired || offer.est === OfferStatus.negociando ? null : (
                <IconMessageCircle />
              )
            }>
            {isOfferExpired || isOfferAccepted ? 'Ver oferta' : 'Ver / Aceptar oferta'}
          </Button>
        )}
        <div className={styles.afterAction}>
          {!isOfferRejected && offer?.fixed_expiration_days !== undefined && (
            <div className={styles.expirationStatus}>
              <ExpirationDays
                expirationDays={offer?.fixed_expiration_days}
                style={{ fontStyle: 'italic' }}
              />
            </div>
          )}
          {isOfferRejected && <span className={styles.offerRejected}>Rechazado</span>}
        </div>
      </div>
      <PriceDialog
        open={priceDialog.open}
        onClose={priceDialog.closeDialog}
        priceData={priceData}
      />
    </GuveryPaper>
  );
};

export default OfferItem;
