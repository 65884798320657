import { GuveryDialog } from '#root/ui-components';
import { IconMapPins } from '@tabler/icons';
import styles from './styles.module.scss';

const DeliverySetupDialog = (props) => {
    const { open = false, onClose } = props;

  return (
    <GuveryDialog
      open={open}
      onClose={onClose}
      title={<p style={{display: 'flex', alignItems: 'center', gap: 3, fontSize: 20}}><IconMapPins /> Configurar Entregas</p>}
      closeText="Cerrar">
      <div className={styles.root}>
        <p className="guv-font-size-5 guv-regular guv-text-alt-2">
          <span style={{marginTop: 2}}>1️⃣</span>
          <span>Crea una configuración de entrega con la información necesaria. (Puedes usar los textos predefinidos).</span>
        </p>
        <p className="guv-font-size-5 guv-regular guv-text-alt-2">
          <span style={{marginTop: 2}}>2️⃣</span>
          <span>
            Al momento que marques un acuerdo como <strong>Listo para entregar</strong> podrás asociar esta información para que lo vea el comprador.
          </span>
        </p>
        <p className="guv-font-size-5 guv-regular guv-text-alt-2">
          <span style={{marginTop: 2}}>3️⃣</span>
          <span>Puedes usar la misma configuración de entrega para coordinar con uno, varios o todos los compradores que quieras. Tú decides a quién le das acceso.</span>
        </p>
        <hr />
        <p className="guv-font-size-6 guv-regular guv-text-alt-2">
          <span style={{marginTop: 2}}>🧠</span>
          <span>Al crear una configuración, ponle un nombre de etiqueta como "Entrega en mi casa" ó "Entrega en casa de Sofia" para que puedas identificarlos rápidamente luego.</span>
        </p>
        <p className="guv-font-size-6 guv-regular guv-text-alt-2">
          <span style={{marginTop: 2}}>🧠</span>
          <span>Por lo general, si siempre eres tu mismo quien viaja y entregas de la misma manera, no necesitas crear mas que 1 configuración. Si es necesario, la puedes editar.</span>
        </p>
        <p className="guv-font-size-6 guv-regular guv-text-alt-2">
          <span style={{marginTop: 2}}>🧠</span>
          <span>Si has tenido una mala experiencia con un comprador y no quieres mostrarle ninguna configuración de entrega, simplemente no selecciones ninguna al momento de marcar su acuerdo como Listo para entregar, y contáctalo directamente para que coordinen otra alternativa.</span>
        </p>
      </div>
    </GuveryDialog>
  );
}

export default DeliverySetupDialog;