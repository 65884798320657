import { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Avatar, Button, Chip, Grid, Slide, useScrollTrigger } from '@material-ui/core';
import { IconSearch, IconRefresh, IconCheck } from '@tabler/icons';

import { offerService } from '#root/services';
import { useDialog, useNotification } from '#root/hooks';
import { GuveryLayout, GuveryPageHeader } from '#root/ui-components';
import { GuveryEmpty } from '#root/ui-components/cards';
import { Notification, SearchDialog } from '#root/components';
import { replacePathParams } from '#root/utils';
import { TRAVELER_OFFERS, TRAVELER_OFFERS_BY_ID } from '#root/constants/navigation';
import { ShippingSearch } from '#root/assets';
import OfferItem from './OfferItem';
import styles from './travelerOffers.module.scss';

type SearchType = string | number;

function ElevationScroll(props) {
  const { children, search } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 60,
  });

  return (
    <Slide appear={false} direction="down" in={trigger && search}>
      {children}
    </Slide>
  );
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const TravelerOffers = (props) => {
  let query = useQuery();
  let queryParamSearch: SearchType = query.get('search');
  const offerId = props.match.params.id;
  const [loading, setLoading] = useState(true);
  const [offers, setOffers] = useState([]);
  const ITEMS_BY_PAGE = 7;
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [paginable, setPaginable] = useState(true);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [search, setSearch] = useState<SearchType>(queryParamSearch);
  const { notification, createMessage, closeMessage } = useNotification();
  const searchDialog = useDialog();
  const history = useHistory();

  const handleReset = () => {
    history.push({
      pathname: TRAVELER_OFFERS,
    });
    window.scrollTo(0, 0);
    setSearch('');
    if (offset === 0) {
      return fetchOffers();
    }
    setOffset(0);
  };

  const fetchOffers = async () => {
    if (offset > 0) setLoadingPagination(true);
    setLoading(true);
    setPaginable(true);
    if (search) {
      history.push({
        pathname: TRAVELER_OFFERS,
        search: `?search=${search}`,
      });
    } else {
      history.push({
        pathname: TRAVELER_OFFERS,
      });
    }
    const res = (!search)
      ? await offerService.listByTraveler(offset)
      : await offerService.listBySearch(search, offset);
    setLoadingPagination(false);
    setLoading(false);
    if (res.error) return;
    if (offset > 0) {
      setOffers(prevOffers => [...prevOffers, ...res.data]);
    } else {
      setOffers(res.data);
    }
    setHasMore(res.data.length > (ITEMS_BY_PAGE - 1));
  };

  const fetchOfferById = async (id) => {
    setLoading(true);
    setPaginable(false);
    const res = await offerService.getOfferById(id);
    if (!res.error) {
      if (res.data) {
        setOffers([res.data]);
      }
    }
    setLoading(false);
  };

  const fetchOfferReject = async (id) => {
    const res = await offerService.reject(id);
    if (!res.error) {
      createMessage.success('Oferta Rechazada...');
      if (offerId) {
        fetchOfferById(offerId);
        return;
      }
      return history.push(replacePathParams(TRAVELER_OFFERS_BY_ID, { id: id }));
    }
    createMessage.error('No se pudo completar la acción');
  };

  const fetchOfferExtend = async (id) => {
    const res = await offerService.extend(id);
    if (!res.error) {
      createMessage.success('Se extendió la vigencia.');
      if (offerId) {
        fetchOfferById(offerId);
        return;
      }
      return history.push(replacePathParams(TRAVELER_OFFERS_BY_ID, { id: id }));
    }
    createMessage.error('No se pudo completar la acción');
  };

  const observer = useRef() as any;
  const lastMapElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (!paginable) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setOffset((offset) => offset + ITEMS_BY_PAGE);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  useEffect(() => {
    if (offerId) {
      fetchOfferById(offerId);
      return;
    }
    fetchOffers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId, offset, search]);

  const headerExtra = [
    <Fragment key="0">
      {!search && !offerId && (
        <Button
          disableFocusRipple
          disableElevation
          color="primary"
          variant="outlined"
          onClick={searchDialog.openDialog}
          endIcon={<IconSearch size={20} />}>
          Buscar
        </Button>
      )}
    </Fragment>,
    <Fragment key="1">
      {search && (
        <Chip
          avatar={
            <Avatar>
              <IconSearch size={16} />
            </Avatar>
          }
          label={search}
          onDelete={handleReset}
        />
      )}
    </Fragment>,
    <Fragment key="2">
      {offerId && (
        <Chip
          avatar={
            <Avatar>
              <IconSearch size={16} />
            </Avatar>
          }
          label={`Oferta: #${offerId}`}
          onDelete={handleReset}
        />
      )}
    </Fragment>,
  ];

  const offersList = (
    <>
      {offers.map((offer, index) => {
        if (offers.length === index + 1) {
          return (
            <Grid item xs={12} key={index}>
              <OfferItem
                xref={lastMapElementRef}
                offer={offer}
                fetchOfferReject={fetchOfferReject}
                fetchOfferExtend={fetchOfferExtend}
              />
            </Grid>
          );
        } else {
          return (
            <Grid item xs={12} key={index}>
              <OfferItem
                offer={offer}
                fetchOfferReject={fetchOfferReject}
                fetchOfferExtend={fetchOfferExtend}
              />
            </Grid>
          );
        }
      })}

      {paginable && loadingPagination && (
        <Grid item xs={12}>
          <h3 className="guv-text-alt-2" style={{ textAlign: 'center', marginBottom: 30 }}>
            <IconRefresh size={18} />
            {'  '}Cargando...
          </h3>
        </Grid>
      )}

      {paginable && !loadingPagination && !hasMore && (
        <Grid item xs={12}>
          <h3 className="guv-text-alt-2" style={{ textAlign: 'center', marginBottom: 30 }}>
            <IconCheck size={18} />
            {'  '}Revisaste todas tus ofertas...
          </h3>
        </Grid>
      )}

      {offers.length === 0 && (
        <Grid item xs={12}>
          <GuveryEmpty description="No se encontraron ofertas..." image={ShippingSearch}>
            {search && (
              <Button
                disableElevation
                color="secondary"
                variant="outlined"
                onClick={handleReset}>
                Ver todos
              </Button>
            )}
          </GuveryEmpty>
        </Grid>
      )}
      {offerId && (
        <Grid item xs={12}>
          <Button
            fullWidth
            size="large"
            color="secondary"
            variant="outlined"
            onClick={handleReset}>
            Ver todas las ofertas
          </Button>
        </Grid>
      )}
    </>
  );

  return (
    <GuveryLayout>
      <ElevationScroll search={search}>
        <div className={styles.searchTopbar}>
          <div className={styles.wrap}>
            <div className={styles.flex}>
              <h4 className={styles.title}>Buscando:</h4>
              <Chip
                avatar={
                  <Avatar>
                    <IconSearch size={16} />
                  </Avatar>
                }
                label={search}
                onDelete={handleReset}
              />
            </div>
          </div>
        </div>
      </ElevationScroll>
      <GuveryPageHeader title="Mis Ofertas" extra={headerExtra} />
      <Grid container spacing={3}>
        {loading && !loadingPagination ? (
          <Grid item xs={12}>
            <h4>Cargando...</h4>
          </Grid>
        ) : (
          offersList
        )}
      </Grid>
      <SearchDialog
        open={searchDialog.open}
        onClose={searchDialog.closeDialog}
        searchBy="comprador, título, link ó categoría"
        setSearch={setSearch}
        setOffset={setOffset}
      />
      {/* {notification.state && <Notification onClose={closeMessage} {...notification} />} */}
    </GuveryLayout>
  );
};

export default TravelerOffers;
