import { useRef, useEffect, useState } from 'react';
import socketClient from 'socket.io-client';
import clsx from 'clsx';

import { useAuth } from '#root/hooks';
import configService from '#root/services/config';
import { chatService, authService } from '#root/services';
import { InfoCircleIcon } from '#root/icons';
import Message from '../Message';
import MessageInput from '../MessageInput';
import MessageDetail from '../MessageDetail';
import ConversationMessageDTO, {
  ConversationMessageStatus,
} from '#root/interfaces/ConversationMessageDTO';
import styles from './messageList.module.scss';

const SERVER = configService.apiUrlChatServer;

const multiplo = (numero) => {
  if (numero % 3 === 0) {
    return true;
  } else {
    return false;
  }
};

const MessageList = (props) => {
  const {
    openDetail,
    onDetailClose,
    activeConversationId,
    setChats,
    setChannelUsers,
    setReceptorActive,
    isLoading,
    setLoading,
  } = props;
  const { usuario } = useAuth();
  const scrollRef = useRef(null);
  const [inRoom, setInRoom] = useState(false);
  const [rootSocket, setRootSocket] = useState(null);
  const [token, setToken] = useState(null);
  const [hasError, setError] = useState(false);
  const [disableText, setDisableText] = useState('Ha ocurrido un error con el chat');
  const [chanel, setChanel] = useState({
    channel: null,
    channel_allow_write: false,
    channel_token_write_sid: null,
    channel_subheader: null,
    channel_entity: {},
    channel_messages: [],
    channel_users: [],
  });

  const foundTraveler = chanel.channel_users?.find((e) => e.role === 'viajero');
  const isTraveler = foundTraveler?.user_id === usuario.idu ? true : false;

  const handleSendMessage = async (value) => {
    try {
      sendMessageSocketServer(value);
    } catch (error) {
      console.error(error);
    }
  };

  const configureSocket = () => {
    const socket = socketClient(SERVER, { transports: ['websocket'] });
    socket.on('connect', () => {
      // console.log(`Connected with the Node Chat`);
      socket.emit('log_user', { user_id: usuario.idu, channel_id: activeConversationId });
    });
    setRootSocket(socket);
    setInRoom(true);
  };

  const watchSocketServer = (conversationId: string | number) => {
    rootSocket.on(conversationId.toString(), (message: ConversationMessageDTO) => {
      // console.log('Watch Socket Server');
      // console.log(message);
      if (message.hasError) {
        setError(true);
        setDisableText('Ha ocurrido un error con el chat');
        return;
      }
      if (message.receptor_is_active) {
        setReceptorActive(true);
      } else {
        setReceptorActive(false);
      }
      const conversationMessages = chanel.channel_messages;
      const newMessage = {
        ...message,
        id: conversationMessages.length + 1,
      };
      setChanel({
        ...chanel,
        channel_messages: [...conversationMessages, newMessage],
      });
    });
  };

  const disconnectSocket = () => {
    // console.log('Disconnecting socket...');
    if (rootSocket) rootSocket.disconnect();
    setInRoom(false);
  };

  const sendMessageSocketServer = (payload: ConversationMessageDTO) => {
    // console.log('Send Message Socket Server');
    rootSocket.emit('new-message', payload);
  };

  const handleSeenConversation = (conversation) => {
    const { channel_messages } = conversation;
    const messageLength = channel_messages?.length;
    const messageStatus =
      channel_messages[messageLength - 1]?.current_status ===
        ConversationMessageStatus.created ||
      channel_messages[messageLength - 1]?.current_status ===
        ConversationMessageStatus.notified;
    if (messageLength > 0 && messageStatus) {
      fetchSeenChanel();
    }
  };

  const fetchChatRoom = async () => {
    setLoading(true);
    const res = await chatService.getChanel(activeConversationId);
    if (!res.info.error) {
      setChanel(res.data);
      setChannelUsers(res.data.channel_users);
      handleSeenConversation(res.data);
      if (res.data.channel_token_write_sid) {
        setError(false);
        fetchAuthChatServer(res.data.channel?.id, res.data.channel_token_write_sid);
      } else {
        setError(true);
        setDisableText('La conversación ha finalizado.');
      }
    } else {
      setError(true);
      setDisableText('Error de conexión con el servidor de chat.');
    }
    setLoading(false);
  };

  const fetchSeenChanel = async () => {
    const res = await chatService.seenChanel(activeConversationId);
    // console.log(res);
    if (!res.info.error) {
      const res = await chatService.listByUser();
      if (!res.info.error) {
        const chats_ordered = res.data.chat_channels.sort(
          (a, b) =>
            new Date(a.last_channel_message?.created_at.replace(/ /g, 'T')).getTime() -
            new Date(b.last_channel_message?.created_at.replace(/ /g, 'T')).getTime(),
        );
        setChats(chats_ordered.reverse());
      }
    }
  };

  const fetchAuthChatServer = async (id, token) => {
    const arg = {
      idu: usuario.idu,
      channel_id: id,
      sid: token,
    };
    try {
      const res = await authService.authChatServer(arg);
      if (!res.info.error) {
        setToken(res.data.token);
      } else {
        // window.location.reload();
      }
    } catch (error) {
      // window.location.reload();
    }
  };

  useEffect(() => {
    if (inRoom) {
      setRootSocket(null);
    }
    configureSocket();
    if (activeConversationId) {
      fetchChatRoom();
    }
    return () => {
      setReceptorActive(false);
      disconnectSocket();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConversationId]);

  useEffect(() => {
    if (rootSocket) {
      watchSocketServer(activeConversationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chanel]);

  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollMessagesToBottom();
  }, [chanel.channel_messages, isLoading]);

  return (
    <div className={styles.body}>
      <div className={styles.main}>
        <div
          className={clsx(styles.chat, chanel.channel_subheader && styles.subHeader)}
          ref={scrollRef}>
          <div className={styles.content}>
            {!isLoading ? (
              <>
                {chanel.channel_messages.map((message) => (
                  <Message
                    key={message.id}
                    owner={usuario.idu === message.user_id}
                    message={message}
                    conversationUsers={chanel.channel_users}
                  />
                ))}
              </>
            ) : (
              <>
                {Array.from(new Array(8)).map((data, index) => (
                  <Message key={index} isLoading owner={!multiplo(index)} />
                ))}
              </>
            )}
          </div>
        </div>
        {chanel.channel_subheader && (
          <div className={styles.help}>
            <div className={styles.helpWrap}>
              <InfoCircleIcon />
              <p>{chanel.channel_subheader}</p>
            </div>
          </div>
        )}
        <hr className={styles.divider} />
        <MessageInput
          token={token}
          conversationId={activeConversationId}
          onSend={handleSendMessage}
          disabled={hasError}
          disableText={disableText}
        />
      </div>
      <MessageDetail
        isLoading={isLoading}
        openDetail={openDetail}
        onDetailClose={onDetailClose}
        entityData={chanel.channel_entity}
        isTraveler={isTraveler}
      />
    </div>
  );
};

export default MessageList;
