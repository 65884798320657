import SvgIcon from '@material-ui/core/SvgIcon';

const CloseSvg = () => (
  <>
    <line x1={18} y1={6} x2={6} y2={18} />
    <line x1={6} y1={6} x2={18} y2={18} />
  </>
);

const Close = (props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    viewBox="0 0 24 24"
    {...props}>
    <CloseSvg />
  </SvgIcon>
);

/*
fill="none"
stroke="currentColor"
strokeWidth={2}
strokeLinecap="round"
strokeLinejoin="round"
*/

export default Close;
