import { useState, useEffect } from 'react';
import { travelerService } from '#root/services';
import DeliverySetupDTO from '#root/models/DeliverySetupDTO';
import { Link as RouterLink } from 'react-router-dom';
import * as ROUTES from '#root/constants/navigation';
import {
  Link,
  Button,
  Drawer,
  Avatar,
  Tooltip,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import {
  IconStar,
  IconMapPins,
} from '@tabler/icons';

import { useResponsive } from '#root/hooks';

import DrawerAppbar from '../DrawerAppbar';

import styles from './styles.module.scss';
import clsx from 'clsx';

const DealDeliverySetupDrawer = (props) => {
  const { open, onClose, handleLoading, deal } = props;
  const { isDesktop } = useResponsive();
  const [loading, setLoading] = useState(true);
  const [deliverySetups, setDeliverySetups] = useState<DeliverySetupDTO[]>([]);
  const [radioValue, setRadioValue] = useState(null);
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  const shopper = deal?.offer?.order?.user ?? null;

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    const delivery_setup_id = (radioValue === `default`) ? null : parseInt(radioValue);
    handleLoading('readyForDelivery', delivery_setup_id);
  };

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
    setDisabledSubmit(false);
  };

  const fetchDeliverySetups = async () => {
    setLoading(true);
    const res = await travelerService.getDeliverySetups();
    if (!res.error) {
      setDeliverySetups(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      fetchDeliverySetups();
      setRadioValue(null);
      setDisabledSubmit(true);
    }
  }, [open]);

  const content = (
    <div className={styles.main}>
      <p className={styles.head}>
        Ya puedes notificarle al comprador que su acuerdo está Listo para Entregar e incluso
        usar una <strong>Configuración de Entrega</strong> predefinida para que sepa cómo
        contactarte.
      </p>
      <hr />
      <div className={styles.dealDetails}>
        {/*  */}
        <div className={styles.user}>
          <Avatar className={styles.avatar} src={shopper?.picture_path}>
            {shopper?.one_firstname?.charAt(0)}
          </Avatar>
          <div className={styles.userInfo}>
            <h6 className={styles.name}>{shopper?.mask_fullname}</h6>
            {shopper?.calcom && (
              <Tooltip arrow title="Puntaje del comprador" placement="top">
                <div className={styles.userScore}>
                  <IconStar />
                  <span>{shopper?.calcom}</span>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        {/*  */}
        <div className={styles.deliveryDate}>
          <span className={styles.textHelper}>Fecha pactada:</span>
          <span className={styles.text}>{deal?.fixed_delivery_date}</span>
        </div>
      </div>
      <hr />
      <h4 className="guv-text-primary">¿Cómo quieres coordinar la entrega?</h4>
      <div className={styles.radioOptions}>
        {loading ? (
          <p>Cargando...</p>
        ) : (
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="Configurar Entrega"
              name="delivery_setup"
              value={radioValue}
              onChange={handleRadioChange}>
              <FormControlLabel
                className={styles.option}
                value={`default`}
                control={<Radio />}
                label={
                  <div className={styles.label}>
                    <div>
                      <p className={styles.title}>Yo mismo lo contactaré</p>
                      <p
                        className={styles.subtitle}
                        style={
                          radioValue === `default` ? { color: 'var(--color-status-info)' } : {}
                        }>
                        no se compartirá ninguna información
                      </p>
                    </div>
                  </div>
                }
              />
              {(!!deliverySetups.length) &&
                deliverySetups.map((item, index) => {
                  const deliveryOption = (
                    <div className={styles.label}>
                      <IconMapPins />
                      <div>
                        <p className={styles.title}>{item.label}</p>
                        <p
                          className={styles.subtitle}
                          style={
                            radioValue === `${item.id}`
                              ? { color: 'var(--color-status-info)' }
                              : {}
                          }>
                          <span>
                            {item.pickup_contact}
                            {item.phone_number && (
                              <>
                                <br />
                                {item.phone_number}
                              </>
                            )}
                            {item.whatsapp_number && (
                              <>
                                <br />
                                {item.whatsapp_number}
                              </>
                            )}
                          </span>
                          {item.pickup_address && <span>{item.pickup_address}</span>}
                        </p>
                      </div>
                    </div>
                  );
                  return (
                    <FormControlLabel
                      key={index}
                      className={styles.option}
                      value={`${item.id}`}
                      control={<Radio />}
                      label={deliveryOption}
                    />
                  );
                })}
            </RadioGroup>
          </FormControl>
        )}
      </div>
      <div className={clsx(styles.editLink)}>
        <Link className={clsx(!deliverySetups.length && styles.super)} component={RouterLink} to={ROUTES.TRAVELER_DELIVERY_SETUP} href="#">Agregar o editar configuraciones de entrega</Link>
      </div>
      <div className={styles.actions}>
        <p>El comprador recibirá una notificación indicando que su acuerdo está Listo para ser Entregado / Recogido.</p>
        <Button fullWidth variant="contained" color="primary" onClick={handleSubmit} disabled={disabledSubmit}>
            Listo para Entregar
        </Button>
      </div>
    </div>
  );

  return (
    <>
      {isDesktop ? (
        <Drawer
          anchor="right"
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            style: {
              background: 'var(--color-background-base)',
              // borderRight: 'none',
              width: 400,
              height: '100%',
            },
          }}>
          <DrawerAppbar title={'Acuerdo #' + deal.ida} onClose={handleClose} />
          {content}
        </Drawer>
      ) : (
        <Drawer
          anchor="right"
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '100%',
              background: 'var(--color-background-base)',
            },
          }}
          style={{
            zIndex: 1300,
          }}>
          <DrawerAppbar isMobile title={'Acuerdo #' + deal.ida} onClose={handleClose} />
          {content}
        </Drawer>
      )}
    </>
  );
};

export default DealDeliverySetupDrawer;
