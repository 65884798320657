import { forwardRef } from 'react';
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Slide,
  Popover,
  Tooltip,
  Toolbar,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import clsx from 'clsx';
import { formatDistanceStrict } from 'date-fns';
import { es } from 'date-fns/locale';

import * as VARIABLES from '#root/constants/variables';
import { useResponsive, useDialog, useNotification } from '#root/hooks';
import { userService } from '#root/services';
import { AlertDialog } from '#root/ui-components';
import { ClockIcon, DoneAllIcon, ArrowBackIcon } from '#root/icons';

import styles from './notificationPopover.module.scss';

const Transition = forwardRef<TransitionProps, { children?: React.ReactElement<any, any> }>(
  (props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);

interface NotificationPopoverProps {
  anchorEl: any;
  data: any[];
  onClose: () => void;
  fetchLoading: any;
}

const NotificationPopover = (props: NotificationPopoverProps) => {
  const { data = [], anchorEl, onClose, fetchLoading } = props;
  const { createMessage } = useNotification();
  const { isMobile } = useResponsive();
  const alertDialog = useDialog();
  const open = Boolean(anchorEl);
  const id = open ? 'notification-popover' : undefined;
  const notiViewArray = data.filter((noti) => noti.est === 0 || noti.est === 1);

  const handleSeenClick = () => {
    alertDialog.openDialog();
    onClose();
  };

  const handleSeenNoti = () => {
    fetchNotiClose();
    fetchLoading();
    alertDialog.closeDialog();
  };

  const fetchNotiClose = async () => {
    const res = await userService.notificationsClose();
    if (!res.error) {
      createMessage.success('Todas tus notificaciones se marcaron como leídas');
      return;
    }
    createMessage.error('No se pudo completar la acción');
  };

  const content = (
    <>
      <div className={styles.title}>
        <div className={styles.titleText}>
          <h6>Notificaciones</h6>
          <p>Tienes {notiViewArray.length} notificaciones sin leer</p>
        </div>
        <div className="guv-effect-grow">
          <Tooltip title="Marcar todo como leído">
            <IconButton
              disabled={notiViewArray.length <= 0 && true}
              color="primary"
              onClick={handleSeenClick}>
              <DoneAllIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className={styles.divider} />
      {data.length > 0 ? (
        <div className={styles.content}>
          {data.map((item, index) => {
            const notiActive = item.est === 0 || item.est === 1 ? true : false;
            const time = formatDistanceStrict(
              new Date(item.feccre.replace(/ /g, 'T')),
              new Date(),
              {
                locale: es,
              },
            );
            return (
              <a
                key={index}
                className={clsx([styles.link, notiActive && styles.linkActive])}
                href={VARIABLES.URL_PHP_PAGE + `notificacion/click/${item.idn}/${item.tkn}`}>
                <h6>
                  <strong>{item.acc} · </strong>
                  {item.men}
                </h6>
                <span className={styles.linkTime}>
                  <ClockIcon />
                  Hace {time}
                </span>
              </a>
            );
          })}
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.link}>
            <h6>No tienes notificaciones.</h6>
          </div>
        </div>
      )}
      <div className={styles.divider} />
      <div className={styles.actions}>
        <Button
          disableElevation
          fullWidth
          color="primary"
          className={styles.actionsButton}
          href={VARIABLES.URL_PHP_PAGE + 'usuario/notificaciones'}>
          Ver Todas
        </Button>
      </div>
    </>
  );

  return (
    <>
      {!isMobile ? (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{ className: styles.notificationPaper }}>
          <span className={styles.arrow}></span>
          {content}
        </Popover>
      ) : (
        <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
          <AppBar className={styles.appbar}>
            <Toolbar>
              <IconButton onClick={onClose}>
                <ArrowBackIcon />
              </IconButton>
              <h6 className={styles.appbarTitle}>Volver</h6>
            </Toolbar>
          </AppBar>
          {content}
        </Dialog>
      )}
      <AlertDialog
        variant="warning"
        open={alertDialog.open}
        onClose={alertDialog.closeDialog}
        title="Marcar notificaciones como leídas"
        message={
          <span>
            <span style={{ display: 'inline-block' }}>
              ¿Está seguro de que deseas marcar todas tus notificaciones como leídas?
            </span>
            <span style={{ display: 'inline-block' }}>Esta acción no se puede deshacer.</span>
          </span>
        }
        onConfirm={handleSeenNoti}
      />
      {/* {notification.state && <Notification onClose={closeMessage} {...notification} />} */}
    </>
  );
};

export default NotificationPopover;
