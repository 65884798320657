import { formatDistanceStrict } from 'date-fns';
import { es } from 'date-fns/locale';

const validFormatDistanceStrict = (date: string | null) => {
  const formatDate = date?.replace(/ /g, 'T');
  const validDate = new Date(formatDate);
  let distance: string | null;
  try {
    distance = formatDistanceStrict(validDate, new Date(), { locale: es });
  } catch (error) {
    distance = '---';
  }
  return distance;
};

export default validFormatDistanceStrict;
