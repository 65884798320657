import { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import UserRole from '#root/interfaces/UserRole';
import LocationState from '#root/interfaces/LocationState';
import { CanguroGuvery } from '#root/assets';
import { URL_PHP_PAGE } from '#root/constants/variables';
import { useAuth, useGlobalNotification } from '#root/hooks';
import { authService, ipifyService } from '#root/services';
import { ValidateProps } from '#root/services/auth';
import { decodeGAT } from '#root/utils';
import {
  USER_TRAVELER_SETTINGS,
  TRAVELER_DASHBOARD,
  USER_WALLET,
} from '#root/constants/navigation';

import './authValidate.scss';

const AuthValidate = () => {
  const { auth, GuveryAuth, GuveryTIP, removeAuth, session, setAuth, userRole } = useAuth();
  const { state } = useLocation<LocationState>();
  const globalNotification = useGlobalNotification();

  // const fetchIp = async (): Promise<string | undefined> => {
  //   if (process.env.NODE_ENV !== 'production') {
  //     return '127.0.0.1';
  //   }
  //   const res = await ipifyService.getIp();
  //   if (!res.info.error) {
  //     return res.data.ip;
  //   }
  //   return undefined;
  // };

  const fetchIpGuvery = async (): Promise<string | undefined> => {
    // console.log(GuveryTIP);
    // if (process.env.NODE_ENV !== 'production') {
    //   return '127.0.0.1';
    // }
    if (!GuveryTIP) {
      globalNotification.alert('No se puede leer la cookie.', 'error');
      setTimeout(() => {
        window.location.href = URL_PHP_PAGE + 'usuario/try_auth/700';
      }, 1500);
      return;
    }
    const res = await ipifyService.getIpGuvery(GuveryTIP);
    if (!res.info.error) {
      return res.data.ip;
    }
    return undefined;
  };

  const fetchAuthValidate = async (arg: ValidateProps, attempt: number) => {
    const res = await authService.validate(arg);
    if (res.error) {
      if (attempt === 0) {
        globalNotification.alert('...Reintentando conexión.', 'error');
        const secondIp = await fetchIpGuvery();
        handleAuth(secondIp, attempt + 1);
        return;
      }
      globalNotification.alert('...No se pudo obtener acceso.', 'error');
      setTimeout(() => {
        removeAuth();
        window.location.href = URL_PHP_PAGE + 'usuario/try_auth/703';
      }, 1500);
      return;
    }
    setAuth(res.data);
  };

  const handleAuth = async (ip: any, attempt: number) => {
    if (!ip) {
      globalNotification.alert('No se pudo obtener la dirección IP.', 'error');
      setTimeout(() => {
        window.location.href = URL_PHP_PAGE + 'usuario/try_auth/700';
      }, 1500);
      return;
    }
    if (!GuveryAuth) {
      globalNotification.alert('No se pudo obtener los datos del usuario.', 'error');
      setTimeout(() => {
        window.location.href = URL_PHP_PAGE + 'usuario/try_auth/701';
      }, 1500);
      return;
    }

    // En caso el IP y el GAT no sean undefined, entonces se intenta el decode:
    const idu = decodeGAT(GuveryAuth, ip);
    if (!idu) {
      globalNotification.alert('Error al intentar autenticar al usuario.', 'error');
      setTimeout(() => {
        window.location.href = URL_PHP_PAGE + 'usuario/try_auth/702';
      }, 2000);
      return;
    }

    const arg = { idu: parseInt(idu), gat: GuveryAuth, ip: ip };
    fetchAuthValidate(arg, attempt);
  };

  const handleIpFetch = async () => {
    const ip = await fetchIpGuvery();
    handleAuth(ip, 0);
  };

  useEffect(() => {
    handleIpFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let redirectLocationTraveler: Location | string = TRAVELER_DASHBOARD;
  let redirectLocation: Location | string = USER_WALLET;

  const isSessionActive = auth && session === GuveryAuth;
  let userHasRequiredRole = true;

  // If was redirected
  if (state) {
    redirectLocation = state.from;
    redirectLocationTraveler = state.from;
    userHasRequiredRole = state.allowedRoles.includes(userRole) || userRole === UserRole.admin;
  }

  if (isSessionActive && userHasRequiredRole) {
    if (userRole === 'comprador') return <Redirect to={redirectLocation} />;
    if (userRole === 'viajero') return <Redirect to={redirectLocationTraveler} />;
    if (userRole === 'admin') return <Redirect to={redirectLocationTraveler} />;
  }

  if (isSessionActive) {
    return <Redirect to={USER_TRAVELER_SETTINGS} />;
  }

  return (
    <div className="guv-background-alt auth-validate">
      <div className="auth-validate__logo">
        <img src={CanguroGuvery} alt="Canguro Guvery" />
      </div>
    </div>
  );
};

export default AuthValidate;
