import { RatingStatus } from '#root/interfaces/RatingDTO';
import { GuveryPaper } from '#root/ui-components/cards';
import Summary from './Summary';
import TravelerInfo from './TravelerInfo';
import styles from './dealCard.module.scss';

export const OrderLabel = {
  creado: 'creado',
  entregado: 'entregado',
  vencido: 'vencido',
} as const;

const DealCard = (props) => {
  const {
    state,
    deal,
    handleLoading,
    noTraveler = false,
    banks,
    createMessage,
    travelerReviewsDialog,
    setReviewTravelerData,
    avoidReviews = false,
  } = props;

  const traveler = deal?.offer?.traveler;
  const rating = deal?.rating_to_traveler;
  const hasRating = rating?.current_status === RatingStatus.rated;

  const isDelivered = deal?.status_label === OrderLabel.entregado;
  const isProvincia = deal?.offer?.order.flag_envio === 1;

  return (
    <GuveryPaper disableHover disableElevation className={styles.root}>
      <Summary
        deal={deal}
        state={state}
        handleLoading={handleLoading}
        noTraveler={noTraveler}
        banks={banks}
        createMessage={createMessage}
      />
      {!noTraveler && (
        <TravelerInfo
          dealId={deal?.ida}
          dealIdo={deal?.ido}
          traveler={traveler}
          isDelivered={isDelivered}
          hasRating={hasRating}
          rating={rating}
          deliveryPlace={isProvincia ? 'Envío Nacional' : deal?.lugent}
          deliveryDate={isProvincia ? deal?.fixed_delivery_date : deal?.fixed_delivery_date}
          packingType={deal?.empaque}
          setReviewTravelerData={setReviewTravelerData}
          travelerReviewsDialog={travelerReviewsDialog}
          avoidReviews={avoidReviews}
        />
      )}
    </GuveryPaper>
  );
};

export default DealCard;
