import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import {
  IconCreditCard,
  IconShieldCheck,
  IconCircleCheck,
  IconStar,
  IconReceipt,
  IconMapPins,
} from '@tabler/icons';
import { USER_DEAL_DETAIL_RATING } from '#root/constants/navigation';

import { useDialog } from '#root/hooks';
import { roundDecimal, replacePathParams } from '#root/utils';
import { shopperService } from '#root/services';
import { DataSourceType } from '#root/interfaces/PaymentCheckoutDialog';
import { PaymentCheckoutDialog, RatingDialog, ExpirationDays, ShopperDeliverySetupDialog } from '#root/components';
import { GuveryPaper } from '#root/ui-components/cards';
import { AlertDialog } from '#root/ui-components';
import * as VARIABLES from '#root/constants/variables';
import styles from './payment.module.scss';

const Payment = (props) => {
  const {
    isRatingOpen = false,
    isDelivered,
    isTransit,
    deal,
    deliverySetup = null,
    isConcretized,
    isExpire,
    isUserPay,
    isDeliveredTraveler,
    hasRating,
    ratingId,
    allowCoupon,
    dealIda,
    dealPrice,
    fecentcom,
    fecentvia,
    tca,
    paymentData,
    travelerData,
    expirationDays,
    handleLoading,
    createMessage,
  } = props;
  const history = useHistory();
  const alertDialog = useDialog();
  const ratingDialog = useDialog();
  const shopperDeliverySetupDialog = useDialog();
  const paymentDialog = useDialog();
  const paymentDialogSource: DataSourceType = {
    title: 'Pagar Acuerdo',
    id: dealIda,
    amount: dealPrice && roundDecimal(dealPrice),
    amountHelper: 'Importe del acuerdo:',
    currency: 'DOLARES',
    tca: tca,
    disabledCurrencies: false,
    disabledPrice: false,
    allowConvert: true,
    allowCredits: true,
    allowCoupon: allowCoupon,
    allowZipago: true,
    showCredits: false,
    stepsArray: [
      'Selecciona el método y el banco.',
      'Escoge la moneda y el monto.',
      'Realiza el pago y confírmalo.',
    ],
    methodsHelper:
      '* Si haces un depósito en agencia o agente, considera que el banco podría cobrar una comisión. Las comisiones bancarias son asumidas por el usuario.',
    paymentType: 'DEAL',
  };

  const allowPay = isConcretized && !isExpire;

  const handleOpenPaymentDialog = () => {
    paymentDialog.openDialog();
  };

  const closePaymentDialog = () => {
    handleLoading();
    paymentDialog.closeDialog();
  };

  const handleConfirmDelivered = () => {
    fetchReceiving();
    handleLoading();
    alertDialog.closeDialog();
  };

  const fetchReceiving = async () => {
    const res = await shopperService.dealReciving(dealIda);
    if (res.error) {
      createMessage.error('No se pudo completar la acción');
      return;
    }
    createMessage.success('Acuerdo recibido...');
    setTimeout(() => {
      history.push(replacePathParams(USER_DEAL_DETAIL_RATING, { ida: dealIda }));
    }, 1000);
  };

  useEffect(() => {
    if (isRatingOpen) {
      ratingDialog.openDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRatingOpen]);

  return (
    <GuveryPaper disableHover disableElevation>
      <div className={styles.body}>
        {(isTransit || allowPay) && (
          <p className={styles.shield}>
            <IconShieldCheck size={26} />
            <span className={styles.text}>
              <span>Reembolso 100% garantizado.</span>
              <span>Guvery custodia tu dinero hasta que recibas tu pedido.</span>
            </span>
          </p>
        )}
        {isDelivered && !hasRating && (
          <p className={styles.qualify}>
            <span className={styles.text}>
              {!isDeliveredTraveler ? (
                <span>
                  Ayúdanos a mantener una comunidad de viajeros confiables. Califica a tu
                  viajero de forma anónima.
                </span>
              ) : (
                <span>
                  El viajero ha confirmado la entrega. Confirma la recepción y/o califica al
                  viajero.
                </span>
              )}
            </span>
          </p>
        )}
        {isUserPay && (
          <span className={styles.validating}>
            <span className={styles.text}>
              <IconReceipt size={20} strokeWidth={2} />
              El pago se está validando...
            </span>
          </span>
        )}
        {isTransit && deliverySetup && (
          <>
          <div className={styles.deliverySetup}>
            <Button
              disableElevation
              variant="contained"
              size="large"
              color="inherit"
              startIcon={<IconMapPins />}
              onClick={shopperDeliverySetupDialog.openDialog}
              >
              Indicaciones para recoger
            </Button>
          </div>
          <ShopperDeliverySetupDialog
          open={shopperDeliverySetupDialog.open}
          onClose={shopperDeliverySetupDialog.closeDialog}
          deal={deal}
        />
        </>
        )}
        {(isTransit || isDeliveredTraveler) && (
          <Button
            fullWidth
            disableElevation
            size="large"
            variant="contained"
            color="primary"
            style={{ marginTop: '16px' }}
            startIcon={<IconCircleCheck />}
            onClick={alertDialog.openDialog}>
            Ya lo recibí
          </Button>
        )}
        
        {isDelivered && !hasRating && (
          <Button
            fullWidth
            disableElevation
            onClick={ratingDialog.openDialog}
            variant="outlined"
            color="primary"
            style={{ marginTop: '16px' }}
            startIcon={<IconStar />}>
            Calificar
          </Button>
        )}
        {allowPay && (
          <>
            <Button
              fullWidth
              disableElevation
              size="large"
              variant="contained"
              color="primary"
              style={{ marginTop: '16px' }}
              startIcon={<IconCreditCard />}
              onClick={handleOpenPaymentDialog}>
              Iniciar / Completar pago
            </Button>
            <ExpirationDays expirationDays={expirationDays} style={{ marginTop: 6 }} />
          </>
        )}
      </div>
      <PaymentCheckoutDialog
        open={paymentDialog.open}
        onClose={closePaymentDialog}
        dataSource={paymentDialogSource}
        banks={paymentData?.banks}
        currencies={paymentData?.currencies}
        zipago={paymentData?.zipago}
      />
      <AlertDialog
        variant="success"
        open={alertDialog.open}
        onClose={alertDialog.closeDialog}
        message={
          <span>
            <span style={{ display: 'inline-block' }}>
              ¿Está seguro de que desea marcar este acuerdo como entregado?
            </span>
            <span style={{ display: 'inline-block' }}>Esta acción no se puede deshacer.</span>
          </span>
        }
        title="Marcar el acuerdo como entregado"
        onConfirm={handleConfirmDelivered}
      />
      <RatingDialog
        id={dealIda}
        ratingId={ratingId}
        open={ratingDialog.open}
        onClose={ratingDialog.closeDialog}
        title="Estas calificando a tu viajero"
        date1={fecentcom}
        date2={fecentvia}
        hint="Esta es una calificación anónima. El viajero no será notificado ni podrá saber su calificación."
        userData={travelerData}
        handleLoading={handleLoading}
        createMessage={createMessage}
        confirmText={(isTransit || isDeliveredTraveler) && 'Confirmar recepción y calificar'}
      />
    </GuveryPaper>
  );
};

export default Payment;
