import { ReactNode } from 'react';
import { SnackbarOrigin } from '@material-ui/core/Snackbar';

import NotificationReducer, {
  NotificationVariant,
  NotificationSeverity,
  NotificationTransition,
} from '#root/interfaces/NotificationReducer';
// State management
import * as actionTypes from './actions';

const initialState: NotificationReducer = {
  action: false,
  open: false,
  message: 'Note archived',
  icon: null,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'banner',
  severity: 'success',
  transition: 'SlideDown',
  duration: 4500,
  close: true,
  actionButton: false,
};

export interface NotificationOpenAction {
  type: typeof actionTypes.NOTIFICATION_OPEN;
  open: boolean;
  message: ReactNode | string;
  icon?: ReactNode;
  anchorOrigin?: SnackbarOrigin;
  variant?: NotificationVariant;
  severity?: NotificationSeverity;
  transition?: NotificationTransition;
  duration?: number;
  close?: boolean;
  actionButton?: boolean;
}

export interface NotificationClosedAction {
  type: typeof actionTypes.NOTIFICATION_CLOSED;
}

export type ActionTypes = NotificationOpenAction | NotificationClosedAction;

// ===========================|| SNACKBAR REDUCER ||=========================== //

type NotificationReducerType = (
  state: NotificationReducer,
  action: ActionTypes,
) => NotificationReducer;

const notificationReducer: NotificationReducerType = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION_OPEN:
      return {
        ...state,
        action: !state.action,
        open: action.open ? action.open : initialState.open,
        message: action.message ? action.message : initialState.message,
        icon: action.icon ? action.icon : initialState.icon,
        anchorOrigin: action.anchorOrigin ? action.anchorOrigin : initialState.anchorOrigin,
        variant: action.variant ? action.variant : initialState.variant,
        severity: action.severity ? action.severity : initialState.severity,
        transition: action.transition ? action.transition : initialState.transition,
        duration: action.duration ? action.duration : initialState.duration,
        close: action.close === false ? action.close : initialState.close,
        actionButton: action.actionButton ? action.actionButton : initialState.actionButton,
      };
    case actionTypes.NOTIFICATION_CLOSED:
      return {
        ...state,
        action: false,
        open: false,
      };
    default:
      return state;
  }
};

export default notificationReducer;
