import React from 'react';

import { GuveryPaper } from '#root/ui-components/cards';
import Summary from './Summary';
import ShopperInfo from './ShopperInfo';
import { useAuth } from '#root/hooks';

const DealCard = (props) => {
  const { deal, carriers, partners, handleLoading, createMessage } = props;
  const { usuario } = useAuth();
  // eslint-disable-next-line eqeqeq
  const imOwner = usuario.idu == deal?.offer?.idu ? true : false;

  return (
    <GuveryPaper disableHover disableElevation>
      <Summary
        deal={deal}
        imOwner={imOwner}
        carriers={carriers}
        partners={partners}
        handleLoading={handleLoading}
        createMessage={createMessage}
      />
      <ShopperInfo deal={deal} imOwner={imOwner} />
    </GuveryPaper>
  );
};

export default DealCard;
