import SvgIcon from '@material-ui/core/SvgIcon';

const TicketSvg = () => (
  <>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 010-4V7a2 2 0 00-2-2H5z"></path>
  </>
);

const Ticket = (props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    style={{ fill: 'none' }}
    {...props}>
    <TicketSvg />
  </SvgIcon>
);

export default Ticket;
