import RatingDTO from './RatingDTO';

export const DealStatus = {
  paying: 'PAGADO_COM',
  created: 'CREADO',
  paid: 'PAGADO_ADM',
  buyed: 'COMPRADO',
  recivedTraveler: 'RECIBIDO_VIA',
  arrived: 'POR_ENTREGAR',
  confirmedTraveler: 'ENTREGADO_VIA',
  confirmedShopper: 'RECIBIDO_COM',
  payedTraveler: 'PAGADO_VIA',
  rejectedTraveler: 'ANULADO_VIA',
  rejectedAdmin: 'ANULADO_ADM',
  rejectedShopper: 'ANULADO_COM',
  refunded: 'REEMBOLSO_COM',
  blocked: 'ELIMINADO',
} as const;

export const InStatusOfDeal = {
  CREATED: [DealStatus.created, DealStatus.paying],
  PAID: [DealStatus.paid, DealStatus.buyed, DealStatus.recivedTraveler, DealStatus.arrived],
  BUYED: [DealStatus.buyed, DealStatus.recivedTraveler, DealStatus.arrived],
  RECEIVED_TRAVELER: [DealStatus.recivedTraveler, DealStatus.arrived],
  DELIVERED: [],
  REJECTED: [
    DealStatus.rejectedTraveler,
    DealStatus.rejectedAdmin,
    DealStatus.rejectedShopper,
    DealStatus.refunded,
  ],
  PAID_DELIVERED: [
    DealStatus.paid,
    DealStatus.buyed,
    DealStatus.recivedTraveler,
    DealStatus.arrived,
    DealStatus.confirmedTraveler,
    DealStatus.confirmedShopper,
    DealStatus.payedTraveler,
  ],
} as const;

export const PackageStatus = {
  sealed: 'sellado',
  openBox: 'caja abierta',
  noBox: 'sin caja',
} as const;

export type DealStatusType = typeof DealStatus[keyof typeof DealStatus];

export type PackageStatusType = typeof PackageStatus[keyof typeof PackageStatus];

interface DealDTO {
  cantot?: number;
  guvery_fee?: string;
  comtot?: number;
  deal_orders?: any;
  dscto?: any;
  dscto_motivo?: any;
  empaque?: PackageStatusType;
  est_n: DealStatusType;
  feccre?: string | Date;
  fecent?: string | Date;
  fecmax?: string | Date;
  fixed_expiration_days?: number | null;
  ida: number;
  ido: number;
  lugent?: string;
  offer?: any;
  pretot?: number;
  rating_to_traveler?: RatingDTO | null;
  status_label?: string;
  total?: number;
  total_soles?: number;
  urltot?: number;
}

export default DealDTO;
