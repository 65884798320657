import { useEffect, useState } from 'react';
import { Button, Container, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { userService } from '#root/services';
import { useNotification, useAuth } from '#root/hooks';
import { Notification } from '#root/components';
import { GuveryPageHeader } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import { PinIcon, PlusIcon } from '#root/icons';
import { LogoGuverySM } from '#root/assets';
import DialogNewAddress from './DialogNewAddress';
import './userAddress.scss';

const AddressCard = (props) => {
  const { item } = props;
  const { usuario } = useAuth();
  const fullName =
    item.flag_idu !== 1
      ? item.first_name + ' ' + item.last_name
      : usuario.nom + ' ' + usuario.ape;

  return (
    <GuveryPaper disableHover disableElevation className="address-card">
      {item.is_primary_address && (
        <div className="address-card__header">
          <span>Principal: </span>
          <div
            className="guvery-logo"
            style={{ background: `url(${LogoGuverySM}) left center no-repeat` }}
          />
        </div>
      )}
      <div className="address-card__content">
        <div className="content-icon">
          <PinIcon />
        </div>
        <div className="content-body">
          <h6 className="guv-bold guv-ellipsis">{fullName}</h6>
          <p>{item.address_1}</p>
          <p>{item.address_2}</p>
          <p>
            {item.district}, {item.province}, {item.department}
          </p>
        </div>
      </div>
    </GuveryPaper>
  );
};

function UserAddress() {
  const { notification, createMessage, closeMessage } = useNotification();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [departmentArray, setDepartmentArray] = useState([]);
  const [addressArray, setAddressArray] = useState([]);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const fetchUserAddress = async () => {
    setLoading(true);
    const res = await userService.address();
    if (!res.error) {
      setAddressArray(res.data.addresses_list);
      setDepartmentArray(res.data.departments_list);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="user-address guv-main-container">
      <Container maxWidth="lg">
        <GuveryPageHeader title="Mis Direcciones" />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Button fullWidth className="add-address-card" onClick={handleOpenDialog}>
              <PlusIcon />
              <span className="add-text">Agregar dirección</span>
            </Button>
          </Grid>
          {!loading ? (
            <>
              {addressArray.map((item, index) => (
                <Grid item key={index} xs={12} sm={6} md={3}>
                  <AddressCard item={item} />
                </Grid>
              ))}
            </>
          ) : (
            <>
              {Array.from(new Array(3)).map((item, index) => (
                <Grid item key={index} xs={12} sm={6} md={3}>
                  <GuveryPaper disableHover disableElevation className="address-card">
                    <Skeleton variant="rect" height="100%" />
                  </GuveryPaper>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Container>
      <DialogNewAddress
        createMessage={createMessage}
        fetchUserAddress={fetchUserAddress}
        open={dialogOpen}
        onClose={handleCloseDialog}
        departmentArray={departmentArray}
      />
      {/* {notification.state && <Notification onClose={closeMessage} {...notification} />} */}
    </div>
  );
}

export default UserAddress;
