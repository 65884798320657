import { useEffect, useState } from 'react';
import { Drawer } from '@material-ui/core';
import { IconInfoCircle } from '@tabler/icons';

import { useResponsive } from '#root/hooks';
import styles from './offerDrawer.module.scss';
import Appbar from './Appbar';
import OrderInfo from './OrderInfo';
import TravelerInfo from './TravelerInfo';
import OfferInfo from './OfferInfo';
import OfferPrice from './OfferPrice';
import OfferActions from './OfferActions';

export const OfferStatus = {
  creado: '0',
  negociando: '1',
  aceptado: '2',
  rechazado: '4',
  rechazado_viajero: '5',
  rechazado_comprador: '6',
  rechazado_admin: '7',
} as const;

const OfferDrawer = (props) => {
  const {
    open,
    onClose,
    orderRoot = null,
    offerRoot = null,
    handleLoading,
    createMessage,
  } = props;
  const { isDesktop } = useResponsive();
  const [order, setOrder] = useState(orderRoot);
  const [offer, setOffer] = useState(offerRoot);

  const idPublic = order?.id_public ? order.id_public : '';

  const isOfferRejected =
    offer?.est === OfferStatus.rechazado ||
    offer?.est === OfferStatus.rechazado_viajero ||
    offer?.est === OfferStatus.rechazado_comprador ||
    offer?.est === OfferStatus.rechazado_admin;
  const isOfferExpired = offer?.fixed_expiration_days !== undefined && offer.fixed_expiration_days < 0;
  const isOrderExpire = order?.is_expired;
  const isCreated = offer?.est === OfferStatus.creado;
  const isNegotiating = offer?.est === OfferStatus.negociando;
  const isAccepted = offer?.est === OfferStatus.aceptado;

  useEffect(() => {
    if (!open) {
      setOrder(null);
      setOffer(null);
      return;
    }
    setOrder(orderRoot);
    setOffer(offerRoot);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const content = (
    <>
      <Appbar title={`Pedido #${idPublic}`} onClose={onClose} />
      <div className={styles.content}>
        <OrderInfo order={order} />
        <div className={styles.subTitle}>
          <h3 className={styles.text}>Oferta #{offer?.id_public}</h3>
          {(isNegotiating || isAccepted) && (
              <div className={styles.badge}>
                <span>{offer?.status_label}</span>
              </div>
          )}
        </div>

        {isNegotiating && (
        <div className={styles.subTitle}>
        <div className={styles.statusDescriptionSecondary}>
          <p>
          <IconInfoCircle />&nbsp;
          Si estás conforme con lo conversando por el chat, acepta la oferta para que el viajero pueda crear un acuerdo personalizado.
          </p>
        </div>
        </div>
        )}
        {isAccepted && (
        <div className={styles.subTitle}>
        <div className={styles.statusDescriptionPrimary}>
          <p>
          <IconInfoCircle />&nbsp;
          Espera a que el viajero cree el Acuerdo. Si aún no lo hace, puedes escribirle.
          </p>
        </div>
        </div>
        )}

        <TravelerInfo
          traveler={offer?.traveler}
          travelerMessage={offer?.traveler_message}
          offerId={offer?.ido}
          offerIdPublic={offer?.id_public}
          isAccepted={isAccepted}
          isNegotiating={isNegotiating}
          isOfferExpired={isOfferExpired}
          isOfferRejected={isOfferRejected}
          createMessage={createMessage}
        />
        <OfferInfo offer={offer} />
        <OfferPrice offer={offer} isOfferRejected={isOfferRejected} />
      </div>
      {!isOrderExpire && (
        <OfferActions
          orderId={order?.idp}
          offerId={offer?.ido}
          isCreated={isCreated}
          isOfferRejected={isOfferRejected}
          isOfferExpired={isOfferExpired}
          isNegotiating={isNegotiating}
          handleLoading={handleLoading}
          createMessage={createMessage}
        />
      )}
    </>
  );

  return (
    <>
      {isDesktop ? (
        <Drawer
          anchor="right"
          open={open}
          onClose={onClose}
          PaperProps={{
            elevation: 0,
            style: {
              width: 576,
              height: '100%',
              background: 'var(--color-background-base)',
            },
          }}>
          {content}
        </Drawer>
      ) : (
        <Drawer
          anchor="right"
          open={open}
          onClose={onClose}
          PaperProps={{
            style: {
              width: '100%',
              background: 'var(--color-background-base)',
            },
          }}
          style={{ zIndex: 1300 }}>
          {content}
        </Drawer>
      )}
    </>
  );
};

export default OfferDrawer;
