import SvgIcon from '@material-ui/core/SvgIcon';

const DollarSignSvg = () => (
  <>
    <path d="M12 1L12 23"></path>
    <path d="M17 5H9.5a3.5 3.5 0 000 7h5a3.5 3.5 0 010 7H6"></path>
  </>
);

const DollarSign = (props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="guv-fill-none"
    {...props}>
    <DollarSignSvg />
  </SvgIcon>
);

export default DollarSign;
