import configService from './config';
import guveryFetchApi from './guveryFetchApi';
import * as METHODS from './methods';

const guveryApi = configService.apiGuvery;

export type WithdrawServicesTypes = 'requested' | 'paid' | 'coming';

const getAll = async () => {
  const { response } = await guveryFetchApi({
    url: guveryApi + `/traveler/withdraws`,
    options: METHODS.GET(),
    message: {
      success: 'Retiros obtenidos con exito.',
      error: 'No se pudo obtener las lista de retiros.',
    },
  });

  return { ...response };
};

const getAllByType = async (type: WithdrawServicesTypes) => {
  const { response } = await guveryFetchApi({
    url: guveryApi + `/traveler/withdraws/${type}`,
    options: METHODS.GET(),
    message: {
      success: 'Retiros obtenidos con exito.',
      error: 'No se pudo obtener las lista de retiros.',
    },
  });

  return { ...response };
};

const saveNew = async (arg) => {
  const { response } = await guveryFetchApi({
    url: guveryApi + `/traveler/withdraw`,
    options: METHODS.POST({ ...arg }),
    message: {
      success: 'Retiro solicitado',
      error: 'No se pudo crear el retiro.',
    },
  });
  return { ...response };
};


const withdrawService = {
  getAll,
  getAllByType,
  saveNew,
};

export default withdrawService;
