import { CSSProperties, ReactNode, useMemo } from 'react';
import clsx from 'clsx';

export type SizeType = 'small' | 'middle' | 'large' | undefined;

export type SpaceSize = SizeType | number;

interface SpaceProps {
  className?: string;
  style?: CSSProperties;
  size?: SpaceSize | [SpaceSize, SpaceSize];
  children: ReactNode;
  direction?: 'horizontal' | 'vertical';
  align?: 'start' | 'end' | 'center' | 'baseline';
  wrap?: boolean;
}

const prefixCls = 'guv-space';

const spaceSize = {
  small: 8,
  middle: 16,
  large: 24,
};

function getNumberSize(size: SpaceSize) {
  return typeof size === 'string' ? spaceSize[size] : size || 0;
}

const Space = (props: SpaceProps) => {
  const {
    size = 'small',
    align,
    className,
    children,
    direction = 'horizontal',
    style,
    wrap = false,
  } = props;

  const [horizontalSize, verticalSize] = useMemo(
    () =>
      ((Array.isArray(size) ? size : [size, size]) as [SpaceSize, SpaceSize]).map((item) =>
        getNumberSize(item),
      ),
    [size],
  );

  const mergedAlign = align === undefined && direction === 'horizontal' ? 'center' : align;
  const cn = clsx(
    prefixCls,
    `${prefixCls}-${direction}`,
    {
      [`${prefixCls}-align-${mergedAlign}`]: mergedAlign,
    },
    className,
  );

  const gapStyle: React.CSSProperties = {};

  if (wrap) {
    gapStyle.flexWrap = 'wrap';
  }

  gapStyle.columnGap = horizontalSize;
  gapStyle.rowGap = verticalSize;

  return (
    <div
      className={cn}
      style={{
        ...gapStyle,
        ...style,
      }}>
      {children}
    </div>
  );
};

export default Space;
