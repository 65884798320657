import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Button,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
} from '@material-ui/core';
import {
  IconUser,
  IconChecks,
  IconMessageCircle,
  IconCalendarEvent,
  IconMapPin,
  IconStar,
  IconPackage,
  IconDotsVertical,
  IconTrash,
  IconClock,
} from '@tabler/icons';
import { formatDistanceStrict } from 'date-fns';
import { es } from 'date-fns/locale';
import clsx from 'clsx';

import { useDialog } from '#root/hooks';
import { roundDecimal, replacePathParams } from '#root/utils';
import { ExpirationDays, ChatDrawer, ProvinceDialog } from '#root/components';
import {
  AlertDialog,
  GuveryObsToolTip,
  GuveryShippingBadge,
  GuverySpace,
} from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import { CanguroGuvery } from '#root/assets';
import { TRAVELER_DEAL_NEW } from '#root/constants/navigation';
import * as VARIABLES from '#root/constants/variables';
import styles from './offerItem.module.scss';

const getTime = (date) => {
  try {
    const validDate = new Date(date.replace(/ /g, 'T'));
    const stringDate = validDate
      ? formatDistanceStrict(validDate, new Date(), { locale: es })
      : '---';
    return stringDate;
  } catch (error) {
    return '---';
  }
};

const BadgeStatus = ({ label }) => {
  if (!label) {
    return null;
  }
  return <div className={clsx(styles.statusBadge)}>{label}</div>;
};

interface OfferItemProps {
  xref?: any;
  offer: any;
  fetchOfferReject?: (id) => void;
  fetchOfferExtend?: (id) => void;
}

const OfferItem = (props: OfferItemProps) => {
  const { xref, offer, fetchOfferReject, fetchOfferExtend } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const rejectDialog = useDialog();
  const extendDialog = useDialog();
  const chatDrawer = useDialog();
  const provinceDialog = useDialog();

  const order = offer.order;
  const shopper = offer.order.user;
  const offerCreateDate = getTime(offer.feccre);
  const isExpired = offer.is_expired === true;

  const isCreated = offer.est === '0';
  const isProcessing = offer.est === '1';
  const isAccepted = offer.est === '2';
  const isRejected =
    offer.est === '4' || offer.est === '5' || offer.est === '6' || offer.est === '7';

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openRejectAlert = () => {
    handleMenuClose();
    rejectDialog.openDialog();
  };
  const openExtendAlert = () => {
    handleMenuClose();
    extendDialog.openDialog();
  };

  const handleRejecteOffer = () => {
    fetchOfferReject(offer.ido);
  };

  const handleExtendOffer = () => {
    fetchOfferExtend(offer.ido);
  };

  return (
    <GuveryPaper disableBorder disableHover>
      <div ref={xref} className={styles.root}>
        <div className={styles.cover}>
          {order?.img_path ? (
            <Avatar src={order.img_path} alt="Oferta">
              <img className={styles.placeholder} src={CanguroGuvery} alt="Pedido" />
            </Avatar>
          ) : (
            <img className={styles.placeholder} src={CanguroGuvery} alt="Pedido" />
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.flex}>
            <div className={styles.orderInfo}>
              <span className={styles.date}>Hace {offerCreateDate}</span>
              <div className={styles.title}>
                <h4 className={styles.id}>Pedido #{offer.order.id_public}</h4>
                {order.flag_envio === 1 && (
                  <GuveryShippingBadge
                    className={styles.shipping}
                    onClick={provinceDialog.openDialog}
                  />
                )}
              </div>

              {!isCreated && <BadgeStatus label={offer.status_label} />}
              <Tooltip arrow title={order.tit}>
                <a
                  className={styles.nameLink}
                  href={order.url}
                  target="_blank"
                  rel="noopener noreferrer">
                  <h4 className={styles.name}>{order.tit}</h4>
                </a>
              </Tooltip>
              {order?.obs && (
                <GuveryObsToolTip arrow title={order.obs} placement="bottom">
                  <div className={styles.obs}>
                    <p>{order.obs}</p>
                  </div>
                </GuveryObsToolTip>
              )}
              <GuverySpace wrap>
                <div className={styles.product}>
                  <h5 className={styles.text}>
                    <a
                      className={styles.link}
                      href={order.url}
                      target="_blank"
                      rel="noopener noreferrer">
                      {order.url_host}
                    </a>
                  </h5>
                </div>
                {order.empaque && (
                  <div className={styles.product}>
                    <span className={styles.text}>{order.empaque}</span>
                  </div>
                )}
              </GuverySpace>
            </div>
            <div className={styles.priceInfo}>
              <div className={styles.price}>
                <div className={styles.hint}>
                  <p>Cantidad:</p>
                </div>
                <h5 className={styles.number}>
                  <strong>x {offer?.can}</strong>
                </h5>
              </div>
              <div className={styles.price}>
                <div className={styles.hint}>
                  <p>Precio Real:</p>
                </div>
                <h5 className={styles.number}>
                  <strong>$ {roundDecimal(offer?.prerea)}</strong>
                </h5>
              </div>
              <div className={styles.price}>
                <div className={styles.hint}>
                  <p>Viajero:</p>
                </div>
                <h5 className={styles.number}>
                  <strong>$ {roundDecimal(offer?.comvia)}</strong>
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.actions}>
          <div className={styles.flex}>
            <div className={styles.offerInfo}>
              {!isRejected && (
                <div className={styles.tools}>
                  <IconButton
                    color="secondary"
                    onClick={handleMenuClick}
                    className={styles.toolsButton}>
                    <IconDotsVertical size={22} />
                  </IconButton>
                  <Menu
                    id="tools-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}>
                    {offer?.fixed_expiration_days !== undefined && offer?.fixed_expiration_days < 1 &&
                      (
                        <MenuItem onClick={openExtendAlert}>
                          <ListItemIcon style={{ minWidth: 32 }}>
                            <IconClock size={20} color="var(--color-status-success)" />
                          </ListItemIcon>
                          <h6>Extender vigencia</h6>
                        </MenuItem>
                      )
                    }
                    <MenuItem onClick={openRejectAlert}>
                      <ListItemIcon style={{ minWidth: 32 }}>
                        <IconTrash size={20} color="var(--color-status-error)" />
                      </ListItemIcon>
                      <h6>Anular oferta</h6>
                    </MenuItem>
                    
                    
                    {(isProcessing || isAccepted) && (
                      <MenuItem
                        component="a"
                        href={VARIABLES.URL_PHP_PAGE + 'chat/oferta/' + offer.ido}>
                        <ListItemIcon style={{ minWidth: 32 }}>
                          <IconMessageCircle size={20} color="var(--color-graphite)" />
                        </ListItemIcon>
                        <h6>Ver Chat</h6>
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              )}
              <div className={styles.items}>
                <IconCalendarEvent size={20} />
                <div className={styles.info}>
                  <h6>{offer.fixed_delivery_date}</h6>
                </div>
              </div>
              <div className={styles.items}>
                <IconMapPin size={20} />
                <div className={styles.info}>
                  <h6>{offer.lugent}</h6>
                </div>
              </div>
              {offer.empaque === 'sellado' && (
                <div className={styles.items}>
                  <IconPackage size={20} />
                  <div className={styles.info}>
                    <h6>Acepté llevarlo sellado</h6>
                  </div>
                </div>
              )}
            </div>
            <GuverySpace direction="vertical">
              {isCreated && !isExpired && (
                <div className={clsx(styles.alert, styles.created)}>
                  <h6>Esperando respuesta del comprador...</h6>
                </div>
              )}
              {isProcessing && !isExpired && (
                <div className={clsx(styles.alert, styles.processing)}>
                  <h6>
                    Si el comprador acepta la oferta, podrás crear un acuerdo respetando lo
                    conversado.
                  </h6>
                </div>
              )}
              {isAccepted && !isExpired && (
                <div className={clsx(styles.alert)}>
                  <h6>Oferta aceptada. Debes crear el Acuerdo.</h6>
                </div>
              )}
              {isAccepted && isExpired && (
                <div className={clsx(styles.alert)}>
                  <h6>Extiende la vigencia para crear el acuerdo.</h6>
                </div>
              )}
              {isAccepted && !isExpired && (
                <Button
                  fullWidth
                  disableElevation
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={replacePathParams(TRAVELER_DEAL_NEW, { id: offer?.ido })}
                  startIcon={<IconChecks size={22} />}>
                  Crear Acuerdo
                </Button>
              )}
              {!isRejected && (offer?.fixed_expiration_days !== undefined) && <ExpirationDays expirationDays={offer?.fixed_expiration_days} />}
            </GuverySpace>
          </div>
        </div>
      </div>
      <div
        className={clsx(
          styles.footer,
          isCreated && styles.created,
          isProcessing && styles.processing,
          isRejected && styles.reject,
        )}>
        <div className={styles.userInfo}>
          <div className={styles.badge}>
            <Avatar alt={shopper?.mask_fullname} src={shopper?.picture_path}>
              <IconUser size={20} />
            </Avatar>
            <div className={styles.data}>
              <h6 className={clsx(styles.name, 'guv-ellipsis')}>{shopper?.mask_fullname}</h6>
              {shopper?.calcom && (
                <Tooltip arrow title="Puntaje del viajero" placement="top">
                  <div className={styles.userScore}>
                    <IconStar />
                    <span>{shopper.calcom}</span>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        <div style={{ padding: '0 16px' }}>
          {(isProcessing || isAccepted) && (
            <Button
              disableElevation
              variant="outlined"
              color="secondary"
              component="a"
              onClick={chatDrawer.openDialog}
              startIcon={<IconMessageCircle size={22} />}>
              Ver Chat
            </Button>
          )}
        </div>
        {offer?.ido && (
          <ChatDrawer
            extraTitle={`Pedido #${offer?.order?.id_public}`}
            offerId={offer?.ido}
            open={chatDrawer.open}
            onClose={chatDrawer.closeDialog}
          />
        )}
      </div>
      <AlertDialog
        variant="error"
        open={rejectDialog.open}
        onClose={rejectDialog.closeDialog}
        title="Anular la oferta"
        message={
          <span>
            <span style={{ display: 'inline-block' }}>
              ¿Está seguro de que desea anular esta oferta?
            </span>
            <span style={{ display: 'inline-block' }}>Esta acción no se puede deshacer.</span>
          </span>
        }
        onConfirm={handleRejecteOffer}
      />
      <AlertDialog
        variant="info"
        open={extendDialog.open}
        onClose={extendDialog.closeDialog}
        title="Extender la vigencia"
        message={
          <span>
            <span style={{ display: 'inline-block' }}>
              ¿Quieres extender la vigencia de esta oferta hasta mañana?
            </span>
          </span>
        }
        onConfirm={handleExtendOffer}
      />
      <ProvinceDialog
        open={provinceDialog.open}
        onClose={provinceDialog.closeDialog}
        title="Pedidos con Envío Nacional"
        content={
          <>
            <p>
              Los pedidos marcados como <strong>Envío Nacional</strong>
              &nbsp;se deben entregar en una oficina de&nbsp;
              <strong>MailBoxes ETC</strong>&nbsp;-- (sin embalar)
            </p>
            <ul>
              <li><strong>MBE Miraflores:</strong>&nbsp;Cerca al parque Kennedy</li>
              <li><strong>MBE San Isidro:</strong>&nbsp;Frente a Tottus de Las Begonias</li>
              <li><strong>MBE Surco:</strong>&nbsp;Cruce de Av. Primavera con Av. El Polo</li>
            </ul>
          </>
        }
      />
    </GuveryPaper>
  );
};

export default OfferItem;
