import { CSSProperties, ReactNode, forwardRef } from 'react';
import clsx from 'clsx';

const rootPaper = 'guv-paper';
const shadowPaper = 'guv-paper--shadow';
const borderPaper = 'guv-paper--border';
const hoverPaper = 'guv-paper--hover';
const roundedPaper = {
  none: '',
  small: 'guv-paper--rounded-small',
  medium: 'guv-paper--rounded-medium',
  large: 'guv-paper--rounded-large',
};

interface PaperProps {
  children: ReactNode;
  component?: ReactNode | any;
  className?: string;
  style?: CSSProperties;
  rounded?: 'none' | 'small' | 'medium' | 'large';
  disableElevation?: boolean;
  disableBorder?: boolean;
  disableHover?: boolean;
}

const Paper = forwardRef<HTMLDivElement, PaperProps>((props, ref) => {
  const {
    children,
    component: Component = 'div',
    className,
    rounded = 'medium',
    disableElevation = false,
    disableBorder = false,
    disableHover = false,
    ...other
  } = props;

  const cn = clsx(
    rootPaper,
    roundedPaper[rounded],
    !disableElevation && shadowPaper,
    !disableBorder && borderPaper,
    !disableHover && hoverPaper,
    className,
  );

  return (
    <Component ref={ref} className={cn} {...other}>
      {children}
    </Component>
  );
});

export default Paper;
