interface PathParamsOptions {
  id?: string | number;
  ida?: string | number;
  idp?: string | number;
  ido?: string | number;
  slug?: string | number;
}

const replacePathParams = (path: string, pathParams: PathParamsOptions) => {
  // console.log("path        -> "  + path)
  // console.log("pathParams  -> " + pathParams)
  let pathToGo = path;
  if (pathParams) {
    Object.keys(pathParams).forEach((param) => {
      // console.log("param  -> " + param)
      pathToGo = pathToGo.replace(`:${param}`, pathParams[param]);
    });
  }
  return pathToGo;
};

export default replacePathParams;
