import { Avatar, IconButton } from '@material-ui/core';
import { GuveryObsToolTip } from '#root/ui-components';
import { IconChevronRight } from '@tabler/icons';

import { CanguroGuvery } from '#root/assets';
import styles from './orderInfo.module.scss';

const OrderInfo = (props) => {
  const { order } = props;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.cover}>
          <a href={order?.url} target="_blank" rel="noopener noreferrer">
            <Avatar className={styles.image} src={order?.img_path} alt="Pedido">
              <img className={styles.placeholder} src={CanguroGuvery} alt="Pedido" />
            </Avatar>
          </a>
        </div>
        <div className={styles.content}>
          <div className={styles.sheath}>
            <div>
              <a
                className={styles.name}
                href={order?.url}
                target="_blank"
                rel="noopener noreferrer">
                <h4>{order?.tit}</h4>
              </a>
              {order?.obs && (
              <GuveryObsToolTip arrow title={order.obs} placement="bottom">
              <div className={styles.obs}>
                <p>{order.obs}</p>
              </div>
              </GuveryObsToolTip>
              )}
            </div>
            <a
              className="guv-no-decoration guv-block"
              href={order?.url}
              target="_blank"
              rel="noopener noreferrer">
              <div className={styles.shop}>
                <h5 className={styles.shopTitle}>
                  <span>{order?.url_host}</span>
                </h5>
                <div className={styles.shopInfo}>
                  <IconButton size="small" color="primary" className={styles.link}>
                    <IconChevronRight />
                  </IconButton>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderInfo;
