import { ChangeEvent, useState } from 'react';
import {
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
} from '@material-ui/core';
import { IconTrash } from '@tabler/icons';

import { GuveryDialog } from '#root/ui-components';
import styles from './rejectedDialog.module.scss';

const RejectedDialog = (props) => {
  const { id, open = false, onClose, onConfirm } = props;
  const [motive, setMotive] = useState('');
  const [text, setText] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);

  const handleMotiveChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMotive((event.target as HTMLInputElement).value);
    if (event.target.value === 'Otro') {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
      setText('');
    }
  };

  const handleChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
    if (event.target.value.length > 4) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  };

  const handleSubmit = () => {
    onConfirm(motive, text, id);
  };

  return (
    <GuveryDialog open={open} onClose={onClose} title={`Acuerdo ${id}`} content={false}>
      <div className={styles.root}>
        <div className={styles.title}>
          <h4>¿Motivo para anular?</h4>
        </div>
        <div className={styles.content}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="motive"
              name="motive1"
              value={motive}
              onChange={handleMotiveChange}>
              <FormControlLabel
                value="La tienda demorará mucho en entregármelo."
                control={<Radio />}
                label="La tienda demorará mucho en entregármelo."
              />
              <FormControlLabel
                value="Mi fecha de viaje se ha postergado/cancelado."
                control={<Radio />}
                label="Mi fecha de viaje se ha postergado/cancelado."
              />
              <FormControlLabel
                value="Mi equipaje se ha llenado."
                control={<Radio />}
                label="Mi equipaje se ha llenado."
              />
              <FormControlLabel
                value="El pedido era más grande/pesado de lo previsto."
                control={<Radio />}
                label="El pedido era más grande/pesado de lo previsto."
              />
              <FormControlLabel value="Otro" control={<Radio />} label="Otro:" />
            </RadioGroup>
          </FormControl>
        </div>
        {motive === 'Otro' && (
          <div className={styles.input}>
            <TextField
              fullWidth
              id="text"
              placeholder="Escribe tu motivo"
              variant="outlined"
              multiline
              rows={3}
              value={text}
              onChange={handleChangeText}
            />
          </div>
        )}
        {motive && (
          <div className={styles.actions}>
            <Button
              disableElevation
              disabled={disableBtn}
              variant="contained"
              color="secondary"
              className={styles.confirm}
              startIcon={<IconTrash size={20} />}
              onClick={handleSubmit}>
              Confirmar Anulación
            </Button>
          </div>
        )}
      </div>
    </GuveryDialog>
  );
};

export default RejectedDialog;
