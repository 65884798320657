import { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { IconStar } from '@tabler/icons';
import clsx from 'clsx';

import { useDialog } from '#root/hooks';
import { RatingDialog } from '#root/components';
import { GuveryPaper } from '#root/ui-components/cards';
import styles from './rating.module.scss';

interface RatingProps {
  isOpenDialog?: boolean;
  deal?: any;
  dealIda?: any;
  isReady?: boolean;
  handleLoading?: () => void;
  createMessage?: any;
}

const Rating = (props: RatingProps) => {
  const {
    isOpenDialog = false,
    deal,
    dealIda,
    isReady,
    handleLoading,
    createMessage,
  } = props;
  const ratingDialog = useDialog();
  const ratingToShopper = deal?.rating_to_shopper;

  useEffect(() => {
    if (isOpenDialog) {
      ratingDialog.openDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenDialog]);

  return (
    <GuveryPaper
      disableHover
      disableBorder={ratingToShopper?.score ? true : false}
      disableElevation>
      {ratingToShopper?.score && (
        <p className={clsx(styles.root, styles.rating)}>
          <span className={styles.text}>Calificaste al comprador con</span>
          <span className={styles.score}>
            {ratingToShopper?.score} <IconStar />
          </span>
        </p>
      )}
      {!ratingToShopper?.score && (
        <div className={styles.root}>
          <p className={styles.qualify}>
            <span className={styles.text}>
              {!isReady ? (
                <span>
                  Ayúdanos a mantener una comunidad confiable. Califica a tu comprador de forma
                  anónima.
                </span>
              ) : (
                <span>Confirma la entrega y/o califica al comprador.</span>
              )}
            </span>
          </p>
          <Button
            fullWidth
            disableElevation
            onClick={ratingDialog.openDialog}
            variant="outlined"
            color="primary"
            style={{ marginTop: '16px' }}
            startIcon={<IconStar />}>
            Calificar
          </Button>
        </div>
      )}
      <RatingDialog
        id={dealIda}
        ratingId={ratingToShopper?.id}
        open={ratingDialog.open}
        onClose={ratingDialog.closeDialog}
        ratingType="traveler"
        title="Estas calificando a tu comprador"
        date1={deal?.fecentcom}
        date2={deal?.fecentvia}
        hint="Esta es una calificación anónima. El comprador no será notificado ni podrá saber su calificación."
        userData={deal?.offer?.shopper}
        handleLoading={handleLoading}
        createMessage={createMessage}
        confirmText={isReady && 'Confirmar entrega y calificar'}
      />
    </GuveryPaper>
  );
};

export default Rating;
