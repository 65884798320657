import {
  Button,
  Dialog,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';

import { CloseIcon } from '#root/icons';

import styles from './imageCropModal.module.scss';

const DialogTitle = (props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <div className={styles.title} {...other}>
      <h4>{children}</h4>
      {onClose ? (
        <IconButton
          aria-label="close"
          size="small"
          className={styles.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </div>
  );
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

interface ImageCropModalProps {
  open?: boolean;
  onCancel: () => void /* func */;
  onConfirm: (blobImage, croppedImageUrl) => void /* func */;
  src: string | ArrayBuffer;
  title: string;
}

interface CroppedImageState {
  blob: Blob;
  fileUrl: string;
}

const ImageCropModal = (props: ImageCropModalProps) => {
  const { open = false, onCancel, onConfirm, src, title } = props;
  const [imageRef, setImageRef] = useState<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>({ unit: '%', width: 50, aspect: 1 });
  const [croppedImage, setCroppedImage] = useState<CroppedImageState>({
    blob: null,
    fileUrl: '',
  });

  let srcImage: string = null;

  if (typeof src === 'string') {
    srcImage = src;
  } else {
    srcImage = src.toString();
  }

  const onOk = () => {
    onConfirm(croppedImage.blob, croppedImage.fileUrl);
  };

  const onImageLoaded = (image: HTMLImageElement) => {
    setImageRef(image);
  };

  const onCropChange = (newCrop: Crop) => {
    setCrop(newCrop);
  };

  const onCropComplete = (crop: Crop) => {
    makeClientCrop(crop);
  };

  const getCroppedImg = (image: HTMLImageElement, crop: Crop, fileName: string) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = 400;
    canvas.height = 400;
    const ctx = canvas.getContext('2d');
    let fileUrl: string;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      400,
      400,
    );

    return new Promise<CroppedImageState>((resolve, _reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }

        // @ts-ignore
        blob.name = fileName;

        window.URL.revokeObjectURL(fileUrl);
        fileUrl = window.URL.createObjectURL(blob);
        resolve({ fileUrl, blob });
      }, 'image/png');
    });
  };

  const makeClientCrop = async (crop: Crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImg(imageRef, crop, 'newFile.jpeg');
      setCroppedImage({
        blob: croppedImage.blob,
        fileUrl: croppedImage.fileUrl,
      });
    }
  };

  return (
    <Dialog open={open} onClose={onCancel} aria-labelledby="image-crop-dialog">
      <DialogTitle id="image-crop-dialog-title" onClose={onCancel}>
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <div className={styles.content}>
          <ReactCrop
            ruleOfThirds
            keepSelection
            src={srcImage}
            crop={crop}
            onChange={onCropChange}
            onComplete={onCropComplete}
            onImageLoaded={onImageLoaded}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button disableElevation onClick={onCancel} color="secondary">
          Cancelar
        </Button>
        <Button disableElevation onClick={onOk} variant="contained" color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageCropModal;
