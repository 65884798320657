import { useState, useEffect, ChangeEvent } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Drawer,
  Grid,
  InputAdornment,
  Slider,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { format, compareAsc } from 'date-fns';
import { IconCheckbox } from '@tabler/icons';
// import clsx from 'clsx';

import { validateDecimalNumber } from '#root/utils';
import { travelerService, tripService } from '#root/services';
import { useResponsive, useNotification } from '#root/hooks';
import { GuveryTextField } from '#root/components';
import { ArrowDownwardIcon, InboxIcon, DollarSignIcon, ClockIcon } from '#root/icons';
import DrawerAppbar from '../DrawerAppbar';
import OrderInfo from './OrderInfo';
import TripBox from './TripBox';
import ExtraData from './ExtraData';
import styles from './createOfferDrawer.module.scss';

const marks = [
  {
    value: 0,
    label: 'Sin caja',
  },
  {
    value: 50,
    label: 'Caja abierta',
  },
  {
    value: 100,
    label: 'Sellado',
  },
];

const initialOffer = {
  order_id: null,
  quantity: 1,
  real_price: '',
  traveler_fee: '',
  delivery_places: '',
  delivery_date: new Date(),
  expiration_date: new Date(),
  offer_message: '',
  packaging_type: '',
};

function valueLabelFormat(value) {
  return marks.findIndex((mark) => mark.value === value) + 1;
}

const CreateOfferDrawer = (props) => {
  const { open, onClose, order, removeOrder } = props;
  const { isDesktop } = useResponsive();
  const { createMessage } = useNotification();
  const [offer, setOffer] = useState(initialOffer);
  const [trip, setTrip] = useState(null);
  const [tripError, setTripError] = useState(true);
  const [loadingTrip, setLoadingTrip] = useState(true);
  const [isOfferDisable, setOfferDisable] = useState(true);
  const [offerOptions, setOfferOptions] = useState({
    range_traveler_fee: {
      min: 0,
      max: 0,
    },
    current_offers: {
      quantity: 0,
      avg_fee: 0,
    },
  });
  const [loadingOfferOptions, setLoadingOfferOptions] = useState(false);
  const [slider, setSlider] = useState(50);
  const [errorQuantity, setErrorQuantity] = useState<Object>({ error: false, text: '' });

  const handleClose = () => {
    const deliveryDate = trip?.fecha_entrega
      ? new Date(trip.fecha_entrega + `T09:00:00`)
      : new Date();
    const expirationDate = trip?.offer_expiration_date
      ? new Date(trip.offer_expiration_date + `T09:00:00`)
      : new Date();

    const deliveryPlace = order?.flag_envio === 1 ? 'Oficina Mailboxes' : trip?.lugar_entrega;

    

    setOffer({
      ...offer,
      order_id: '',
      quantity: 1,
      real_price: '',
      traveler_fee: '',
      offer_message: '',
      packaging_type: '',
      delivery_date: deliveryDate,
      expiration_date: expirationDate,
      delivery_places: deliveryPlace,
    });
    onClose();
  };

  const handleSliderChange = (event, newValue) => {
    setSlider(newValue);
  };

  const handleInputChange = (e) => {
    setOffer({
      ...offer,
      [e.target.id]: e.target.value,
    });
  };

  const handleInputNumberChange = (e) => {
    if (e.target.value === '' || validateDecimalNumber(e.target.value)) {
      setOffer({
        ...offer,
        [e.target.id]: e.target.value,
      });
    }
  };

  const handleDateChange = (name: string) => (e) => {
    setOffer({
      ...offer,
      [name]: e,
    });
  };

  const handleOfferQuantity = (e: ChangeEvent<HTMLInputElement>) => {
    if (validateDecimalNumber(e.target.value)) {
      const offer_quantity_fixed =
        parseFloat(e.target.value) < 0 ? 0 : Math.ceil(parseFloat(e.target.value));
      if (offer_quantity_fixed === 0) setErrorQuantity({ error: true, text: 'Mínimo 1' });
      if (offer_quantity_fixed > order.can) setErrorQuantity({ error: true, text: `Máximo ${order.can}` });
      if (offer_quantity_fixed >= 1 && offer_quantity_fixed <= order.can)
        setErrorQuantity({ error: false, text: '' });
      setOffer({
        ...offer,
        [e.target.id]: offer_quantity_fixed,
        traveler_fee: '',
      });
    } else if (e.target.value === '') {
      setErrorQuantity({ error: true, text: `Número entre 1 y ${order.can}` });
      setOffer({
        ...offer,
        [e.target.id]: e.target.value,
        traveler_fee: '',
      });
    }
  };

  const handleInputRound = (name, value) => {
    setOffer({
      ...offer,
      [name]: Math.ceil(Number(value)),
    });
  };

  const setOrderDataToOffer = () => {
    setOffer({
      ...offer,
      order_id: order?.idp,
      quantity: order?.can,
      packaging_type: order?.empaque,
    });
    if (order.empaque === 'sin caja') {
      setSlider(0);
    }
    if (order.empaque === 'caja abierta') {
      setSlider(50);
    }
    if (order.empaque === 'sellado') {
      setSlider(100);
    }
    setTripDataToOffer();
  };

  const validateOfferObj = () => {
    const { real_price, traveler_fee } = offer;
    if (!real_price || !traveler_fee || errorQuantity['error']) {
      setOfferDisable(true);
      return;
    }
    setOfferDisable(false);
  };

  const setTripDataToOffer = () => {
    const deliveryDate = trip?.fecha_entrega
      ? new Date(trip.fecha_entrega + `T09:00:00`)
      : new Date();
    const expirationDate = trip?.offer_expiration_date
      ? new Date(trip.offer_expiration_date + `T09:00:00`)
      : new Date();

    const deliveryPlace = order?.flag_envio === 1 ? 'Oficina Mailboxes' : trip?.lugar_entrega;

    setOffer({
      ...offer,
      delivery_date: deliveryDate,
      expiration_date: expirationDate,
      delivery_places: deliveryPlace,
    });
  };

  const handleCreateOffer = () => {
    let packagingType = '';
    if (slider === 0) {
      packagingType = 'sin caja';
    }
    if (slider === 50) {
      packagingType = 'caja abierta';
    }
    if (slider === 100) {
      packagingType = 'sellado';
    }

    const arg = {
      ...offer,
      order_id: order.idp,
      delivery_date: format(new Date(offer?.delivery_date), 'yyyy-MM-dd'),
      expiration_date: format(new Date(offer?.expiration_date), 'yyyy-MM-dd'),
      packaging_type: packagingType,
    };
    fetchCreateOffer(arg);
  };

  const fetchCreateOffer = async (arg) => {
    setOfferDisable(true);
    const res = await travelerService.createOffer(arg);
    if (!res.error) {
      createMessage.success(res.message);
      removeOrder(order);
      handleClose();
    } else {
      createMessage.error(res.message);
    }
    setOfferDisable(false);
  };

  const fetchGetCurrentTrip = async () => {
    setLoadingTrip(true);
    const res = await tripService.getCurrent();
    if (res.info.error) {
      setTripError(true);
      setLoadingTrip(false);
      return;
    }
    setTrip(res.data);
    const deliveryDate = res.data?.fecha_entrega
      ? new Date(res.data.fecha_entrega + `T09:00:00`)
      : new Date();
    const expirationDate = res.data?.offer_expiration_date
      ? new Date(res.data.offer_expiration_date + `T09:00:00`)
      : new Date();

    const compareDelivery = compareAsc(deliveryDate, new Date());
    const compareExpiration = compareAsc(expirationDate, new Date());

    if (compareDelivery === 1 && compareExpiration === 1) {
      setTripError(false);
    }
    setLoadingTrip(false);
  };

  const resetOfferOptions = () => {
    setLoadingOfferOptions(true);
    setOfferOptions({
      range_traveler_fee: {
        min: 0,
        max: 0,
      },
      current_offers: {
        quantity: 0,
        avg_fee: 0,
      },
    });
    setLoadingOfferOptions(false);
  };

  const fetchOfferOptions = async () => {
    setLoadingOfferOptions(true);
    const res = await travelerService.getOrderOfferOptions(order.idp);
    if (res.error) return setLoadingOfferOptions(false);
    if (!res.data) return setLoadingOfferOptions(false);
    setOfferOptions(res.data);
    setLoadingOfferOptions(false);
  };

  useEffect(() => {
    fetchGetCurrentTrip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    resetOfferOptions();    
    setOrderDataToOffer();
    fetchOfferOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    if (trip) {
      setTripDataToOffer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trip]);

  useEffect(() => {
    validateOfferObj();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer]);

  const content = (
    <div className={styles.root}>
      <OrderInfo order={order} />

      {!tripError && offerOptions && (
        <div className={styles.offerOptions}>
          {loadingOfferOptions ? (
            <p>cargando...</p>
          ) : (
            <>
              <ClockIcon />
              <p>
                {offerOptions?.range_traveler_fee?.min &&
                  offerOptions?.range_traveler_fee?.max && (
                    <p>
                      Comisión sugerida por Guvery: ${' '}
                      {Number(offerOptions.range_traveler_fee.min)} a{' '}
                      {Number(offerOptions.range_traveler_fee.max)}
                      {order.can > 1 && <span>&nbsp;(x unidad)</span>}.
                    </p>
                )}
                {!offerOptions.current_offers?.quantity ? (
                  <p>Sé el primero en hacer una oferta</p>
                ) : (
                  <p>
                    {offerOptions.current_offers.quantity > 1 ? (
                      <>Tiene {offerOptions.current_offers.quantity} ofertas por $ {offerOptions.current_offers.avg_fee} en promedio</>
                    ) : (
                      <>Tiene 1 oferta por $ {offerOptions.current_offers.avg_fee}</>
                    )}
                    {order.can > 1 && <span>&nbsp;(x unidad)</span>}.
                  </p>
                )}
              </p>
            </>
          )}
        </div>
      )}

      <div className={styles.subTitle}>
        <h4>Mi oferta es:</h4>
      </div>
      <TripBox
        createMessage={createMessage}
        trip={trip}
        tripError={tripError}
        fetchTrips={fetchGetCurrentTrip}
        loading={loadingTrip}
      />

      {loadingTrip ? (
        <div className={styles.offerForm}>
        <Alert severity="info">
          Cargando viaje vigente...
        </Alert>
      </div>
      ) : (
        <>
        {!tripError ? (
        <>
          <div className={styles.offerForm}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={styles.slider}>
                  <Slider
                    valueLabelFormat={valueLabelFormat}
                    aria-labelledby="discrete-slider-restrict"
                    step={null}
                    // valueLabelDisplay="auto"
                    marks={marks}
                    value={slider}
                    onChange={handleSliderChange}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <GuveryTextField
                  id="quantity"
                  type="tel"
                  inputMode="numeric"
                  label="Cantidad"
                  value={offer.quantity}
                  onChange={handleOfferQuantity}
                  onBlur={() => handleInputRound('quantity', offer.quantity)}
                  error={errorQuantity['error']}
                  helperText={errorQuantity['text']}
                  disabled={(order.can === 1)}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <GuveryTextField
                  id="real_price"
                  type="tel"
                  inputMode="decimal"
                  label="Precio real total"
                  value={offer.real_price}
                  onChange={handleInputNumberChange}
                  onBlur={() => handleInputRound('real_price', offer.real_price)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DollarSignIcon style={{ fontSize: 16 }} color="action" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <GuveryTextField
                  id="traveler_fee"
                  type="tel"
                  inputMode="decimal"
                  label={(offer.quantity > 1) ? "Tu comisión total" : "Tu comisión"}
                  value={offer.traveler_fee}
                  onChange={handleInputNumberChange}
                  onBlur={() => handleInputRound('traveler_fee', offer.traveler_fee)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DollarSignIcon style={{ fontSize: 16 }} color="action" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <ExtraData
            offer={offer}
            loading={loadingTrip}
            flagEnvio={order.flag_envio === 1 ? true : false}
            handleDateChange={handleDateChange}
            handleInputChange={handleInputChange}
          />
          <div className={styles.offerForm}>
            <Accordion className={styles.acordion}>
              <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <div className={styles.header}>
                  <h5>
                    Mensaje opcional: <strong>{offer.offer_message}</strong>
                  </h5>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <GuveryTextField
                  multiline
                  rows={4}
                  id="offer_message"
                  value={offer.offer_message}
                  onChange={handleInputChange}
                />
              </AccordionDetails>
            </Accordion>
          </div>
          <div className={styles.offerHelp}>
            <IconCheckbox />
            <span>Simulé la compra para incluir el tax/shipping en el Precio Real.</span>
          </div>
          <div className={styles.action}>
            <Button
              fullWidth
              disableElevation
              color="primary"
              variant="contained"
              size="large"
              startIcon={<InboxIcon />}
              disabled={isOfferDisable}
              onClick={handleCreateOffer}
              style={{ height: 48 }}>
              Enviar Oferta
            </Button>
          </div>
        </>
      ) : (
        <div className={styles.offerForm}>
          <Alert severity="error">
            Selecciona un viaje vigente, y podrás realizar la oferta.
          </Alert>
        </div>
      )}
        </>
      )}

      
    </div>
  );

  return (
    <>
      {isDesktop ? (
        <Drawer
          anchor="right"
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            style: {
              background: 'var(--color-background-base)',
              // borderRight: 'none',
              width: 576,
              height: '100%',
            },
          }}>
          <DrawerAppbar title={'Pedido #' + order.id_public} onClose={handleClose} />
          {content}
        </Drawer>
      ) : (
        <Drawer
          anchor="right"
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '100%',
              background: 'var(--color-background-base)',
            },
          }}
          style={{
            zIndex: 1300,
          }}>
          <DrawerAppbar isMobile title={'Pedido #' + order.id_public} onClose={handleClose} />
          {content}
        </Drawer>
      )}
      {/* {notification.state && <Notification onClose={closeMessage} {...notification} />} */}
    </>
  );
};

export default CreateOfferDrawer;
