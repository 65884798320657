import { Grid, TextField, MenuItem, Avatar } from '@material-ui/core';

import { GuveryPaper } from '#root/ui-components/cards';
import { URL_PHP_PAGE } from '#root/constants/variables';
import countriesArray from '#root/constants/countries.json';
import styles from './travelerInformation.module.scss';

const TravelerInformation = (props) => {
  const { userObj } = props;

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <GuveryPaper disableHover disableElevation className={styles.box}>
          <div className={styles.header}>
            <div>
              <h4>Información adicional:</h4>
              <p>Se requiere esta información solo para los viajeros</p>
            </div>
            <span className={styles.link}>
              <a
                className="guv-no-decoration"
                href="https://m.me/guveryoficial"
                target="_blank"
                rel="noopener noreferrer">
                ¿Tienes problemas?
              </a>
            </span>
          </div>
          <form>
            <div className={styles.content}>
              <TextField
                select
                fullWidth
                disabled
                variant="outlined"
                id="pais_residencia"
                name="pais_residencia"
                label="Pais"
                type="text"
                value={userObj.paidoc}>
                {countriesArray.map((option, index) => (
                  <MenuItem key={index} value={option.name}>
                    <div className={styles.iconWrap}>
                      <Avatar className={styles.icon} src={option.file_url} alt={option.name} />
                      {option.name}
                    </div>
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                disabled
                variant="outlined"
                id="tipo_documento"
                name="tipo_documento"
                label="Tipo de documento"
                type="text"
                value={userObj.tipdoc}
              />
              <TextField
                fullWidth
                disabled
                variant="outlined"
                id="numero_documento"
                label="Número de identidad"
                type="text"
                value={userObj.numdoc}
              />
            </div>
            {/*
            <div className={styles.submit}>
              <Button
                disableElevation
                disabled
                variant="contained"
                color="primary"
                type="submit">
                Guardar cambios
              </Button>
            </div>
            */}
          </form>
        </GuveryPaper>
      </Grid>
      <span className={styles.terms}>
        * Tu información nunca será brindada a terceros y está totalmente protegida. Lee nuestra{' '}
        <a href={URL_PHP_PAGE + 'politica-privacidad'} target="_blank" rel="noopener noreferrer">
          política de privacidad
        </a>
        .
      </span>
    </Grid>
  );
};

export default TravelerInformation;
