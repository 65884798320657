import SvgIcon from '@material-ui/core/SvgIcon';

const InfoCircleSvg = () => (
  <>
    <g data-name="Layer 2">
      <g data-name="info">
        <rect width={24} height={24} transform="rotate(180 12 12)" opacity={0} />
        <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z" />
        <circle cx={12} cy={8} r={1} />
        <path d="M12 10a1 1 0 0 0-1 1v5a1 1 0 0 0 2 0v-5a1 1 0 0 0-1-1z" />
      </g>
    </g>
  </>
);

const InfoCircle = (props) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <InfoCircleSvg />
  </SvgIcon>
);

export default InfoCircle;
