import { Avatar, IconButton, Tooltip } from '@material-ui/core';
import { IconChevronRight, IconStar } from '@tabler/icons';
import { formatDistanceStrict } from 'date-fns';
import { es } from 'date-fns/locale';
import clsx from 'clsx';

import { GuveryShippingBadge } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import { PersonIcon } from '#root/icons';
import { ImageHorizontal } from '#root/assets';
import * as VARIABLES from '#root/constants/variables';
import styles from './orderInfo.module.scss';

const getTime = (orderDate) => {
  let time;
  try {
    time = orderDate
      ? formatDistanceStrict(new Date(orderDate.replace(/ /g, 'T')), new Date(), { locale: es })
      : '---';
  } catch (error) {
    time = 'error';
  }
  return time;
};

const OrderInfo = (props) => {
  const { order } = props;
  const orderDate = order?.fecpub ? order?.fecpub : order?.feccre;
  const formatDate = getTime(orderDate);

  return (
    <GuveryPaper
      disableBorder
      disableElevation
      disableHover
      rounded="none"
      className={styles.root}>
      <div className={styles.top}>
        <div className={styles.cover}>
          <a
            className="guv-no-decoration"
            href={order?.url}
            target="_blank"
            rel="noopener noreferrer">
            {order?.img_path ? (
              <img className={clsx(styles.image)} src={order.img_path} alt="Pedido" />
            ) : (
              <img
                className={clsx(styles.image, styles.placeholder)}
                src={ImageHorizontal}
                alt="Pedido"
              />
            )}
          </a>
          {order.flag_envio === 1 && (
            <GuveryShippingBadge background className={styles.badge} />
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.contentWrap}>
            <div>
              <span className={styles.date}>Hace {formatDate}</span>
              <a
                className="guv-no-decoration guv-block"
                href={order?.url}
                target="_blank"
                rel="noopener noreferrer">
                <h4 className={styles.name}>{order?.tit}</h4>
              </a>
              {order?.obs && (
                <div className={styles.obs}>
                  <p>{order.obs}</p>
                </div>
              )}
              <div className={styles.detail}>
                <p>
                  Empaque: <span>{order?.empaque}</span>
                </p>
              </div>
              <div className={styles.detail}>
                <p>
                  Cantidad: <span>{order?.can}</span>
                </p>
              </div>
            </div>
            <a
              className="guv-no-decoration guv-block"
              href={order?.url}
              target="_blank"
              rel="noopener noreferrer">
              <div className={styles.shop}>
                <h5 className={styles.shopTitle}>
                  <span>{order?.url_host}</span>
                </h5>
                <div className={styles.shopInfo}>
                  <div className={styles.price}>
                    <h6>
                      <strong>{order?.pre} $</strong>
                    </h6>
                    <p>Precio Referencial</p>
                  </div>
                  <IconButton size="small" color="primary" className={styles.link}>
                    <IconChevronRight />
                  </IconButton>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <div className={styles.actionsWrap}>
          {order?.user && (
            <div className={styles.avatar}>
              <Avatar
                alt={order.user?.mask_fullname}
                src={VARIABLES.IMAGE_PROFILE_PATH + order.user?.pic + '.png'}
                sizes="24">
                <PersonIcon />
              </Avatar>
              <div>
                <span className={styles.name}>{order.user?.mask_fullname}</span>
                {order?.user?.calcom && (
                  <Tooltip arrow title="Puntaje del comprador" placement="bottom">
                    <div className={styles.userScore}>
                      <IconStar />
                      <span>{order.user.calcom}</span>
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          )}
          <div className={styles.commission}>
            <h6 className={styles.price}>
              {order?.com} $<span className={styles.help}> Comisión</span>
            </h6>
            <p className={styles.hint}>Propuesto por el comprador</p>
          </div>
        </div>
      </div>
      
    </GuveryPaper>
  );
};

export default OrderInfo;
