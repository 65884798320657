import SvgIcon from '@material-ui/core/SvgIcon';

const CameraPlusSvg = () => (
  <>
    <circle cx={11} cy={14} r="2.5" fill="currentColor" />
    <path
      d="M21 4V3c0-.55-.45-1-1-1s-1 .45-1 1v1h-1c-.55 0-1 .45-1 1s.45 1 1 1h1v1c0 .55.45 1 1 1s1-.45 1-1V6h1c.55 0 1-.45 1-1s-.45-1-1-1h-1zm-4 4h-1c-.55 0-1-.45-1-1V4H8.88c-.56 0-1.1.24-1.48.65L6.17 6H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V10h-3c-.55 0-1-.45-1-1V8zm-6 10.5c-2.48 0-4.5-2.02-4.5-4.5S8.52 9.5 11 9.5s4.5 2.02 4.5 4.5s-2.02 4.5-4.5 4.5z"
      fill="currentColor"
    />
  </>
);

const CameraPlus = (props) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <CameraPlusSvg />
  </SvgIcon>
);

export default CameraPlus;
