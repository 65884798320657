import { ChangeEvent } from 'react';
import { TextField, MenuItem, FormControlLabel, Switch } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { es } from 'date-fns/locale';

import { GuverySpace } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import styles from './dealDelivery.module.scss';

const DealDelivery = (props) => {
  const { dealForm, setDealForm, trips } = props;

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDealForm({ ...dealForm, [event.target.name]: event.target.value });
  };

  const handleCheckedChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDealForm({ ...dealForm, [event.target.name]: event.target.checked });
  };

  const handleDateChange = (name: string) => (event) => {
    setDealForm({ ...dealForm, [name]: event });
  };

  const handleTripChange = (event: ChangeEvent<HTMLInputElement>) => {
    const tripId = event.target.value;
    const tripSelect = trips.find((trip) => trip?.id === tripId);
    const deliveryDate = tripSelect?.fecha_entrega
      ? new Date(tripSelect.fecha_entrega + `T09:00:00`)
      : new Date();
    const expirationDate = tripSelect?.offer_expiration_date
      ? new Date(tripSelect.offer_expiration_date + `T09:00:00`)
      : new Date();
    setDealForm({
      ...dealForm,
      tripId: tripId,
      delivery_date: deliveryDate,
      expiration_date: expirationDate,
      zipcode: tripSelect?.zipcode,
      delivery_place: tripSelect?.lugar_entrega,
    });
  };

  return (
    <GuveryPaper disableHover disableElevation className={styles.root}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <span className={styles.headerTitle}>Detalles de la entrega</span>
        </div>
      </div>
      <hr className={styles.divider} />
      <div className={styles.body}>
        <GuverySpace direction="vertical" size="large">
          <TextField
            select
            id="tripId"
            name="tripId"
            label="Viaje"
            variant="outlined"
            value={dealForm.tripId}
            onChange={handleTripChange}>
            {trips.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.nombre} - {option.zipcode}
              </MenuItem>
            ))}
          </TextField>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
            <KeyboardDatePicker
              disableToolbar
              inputVariant="outlined"
              format="dd/MM/yyyy"
              label="Fecha de entrega"
              value={dealForm.delivery_date}
              onChange={handleDateChange('delivery_date')}
              minDate={new Date()}
              InputAdornmentProps={{ position: 'start' }}
              minDateMessage="La fecha de entrega no debe ser anterior al dia de hoy"
              invalidDateMessage="Formato de fecha no válido"
            />
          </MuiPickersUtilsProvider>
          {/*
          <TextField
            disabled
            id="zipcode"
            name="zipcode"
            label="Zipcode"
            variant="outlined"
            value={dealForm.zipcode}
            onChange={handleInputChange}
          />
          */}
          {dealForm.shipping ? (
            <div>
              <TextField
                disabled
                fullWidth
                label="Lugar de entrega"
                variant="outlined"
                value="Oficina Mailboxes"
              />
              <span className={styles.helperPlace}>Miraflores, San Isidro ó Surco</span>
            </div>
          ) : (
            <TextField
              id="delivery_place"
              name="delivery_place"
              label="Lugar de entrega"
              variant="outlined"
              value={dealForm.delivery_place}
              onChange={handleInputChange}
            />
          )}
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
            <KeyboardDatePicker
              disableToolbar
              inputVariant="outlined"
              format="dd/MM/yyyy"
              label="El acuerdo vence el"
              value={dealForm.expiration_date}
              onChange={handleDateChange('expiration_date')}
              minDate={new Date()}
              InputAdornmentProps={{ position: 'start' }}
              minDateMessage="El acuerdo venció. (Si deseas, puedes extender la vigencia)"
              invalidDateMessage="Formato de fecha no válido"
            />
          </MuiPickersUtilsProvider>
          <FormControlLabel
            control={
              <Switch
                checked={dealForm.shipping}
                onChange={handleCheckedChange}
                name="shipping"
                color="primary"
              />
            }
            label="Envío Nacional"
          />
        </GuverySpace>
      </div>
    </GuveryPaper>
  );
};

export default DealDelivery;
