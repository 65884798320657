import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton, Button } from '@material-ui/core';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Skeleton } from '@material-ui/lab';
import { IconMailbox, IconCalendarEvent, IconMap } from '@tabler/icons';

import * as ROUTES from '#root/constants/navigation';
import { useDialog } from '#root/hooks';
import { GuveryRadio, GuverySpace, GuveryDialog } from '#root/ui-components';
import { tripService } from '#root/services';
import { EditIcon, PlanenIcon } from '#root/icons';
import styles from './tripBox.module.scss';

const getTime = (date) => {
  let time: string;
  try {
    time = date
      ? format(new Date((date + ' 23:59:59').replace(/ /g, 'T')), "dd'/'MMM", { locale: es })
      : '---';
  } catch (error) {
    time = 'error';
  }
  return time;
};

const SelectTripDialog = (props) => {
  const { open = false, onClose, onConfirm } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [trips, setTrips] = useState([]);
  const [tripId, setTripId] = useState(null);

  const handleChange = (event) => {
    setTripId(event.target.value);
  };

  const fetchTrips = async () => {
    setIsLoading(true);
    const res = await tripService.list();
    if (!res.error) {
      const newOrderArray = [...res.data?.filter((item) => item.estado === 'CREADO')];
      setTrips(newOrderArray);
      const selectTrip = newOrderArray.find((e) => e.is_seleccionado === 1);
      const tripId = selectTrip ? selectTrip.id : null;
      setTripId(tripId);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTrips();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GuveryDialog
      open={open}
      onClose={onClose}
      onConfirm={() => onConfirm(tripId)}
      title="Cambiar de Viaje"
      onConfirmDisable={trips.length <= 0 ? true : false}
      closeText="Cerrar">
      <div className={styles.dialogBox}>
        {!isLoading ? (
          <>
            {trips.map((item, index) => (
              <GuveryRadio
                fullWidth
                key={index}
                center
                value={item?.id}
                option={Number(tripId)}
                onChange={handleChange}
                label={
                  <span className={styles.tripItem}>
                    <span className={styles.name}>{item?.nombre} </span>
                    <GuverySpace wrap size="middle">
                      <span className={styles.element}>
                        <IconMap />
                        Origen:
                        <span className={styles.label}>{item?.city}</span>
                      </span>
                      <span className={styles.element}>
                        <IconMailbox />
                        Zipcode:<span className={styles.label}>{item?.zipcode}</span>
                      </span>
                      <span className={styles.element}>
                        <IconCalendarEvent />
                        Entrega:
                        <span className={styles.label}>{getTime(item?.fecha_entrega)}</span>
                      </span>
                    </GuverySpace>
                  </span>
                }
              />
            ))}
          </>
        ) : (
          <>
            {Array.from(new Array(3)).map((data, index) => (
              <Skeleton
                key={index}
                variant="rect"
                width="100%"
                height={72}
                className={styles.radioLoading}
              />
            ))}
          </>
        )}
        {!isLoading && trips.length <= 0 && (
          <div className={styles.noTripList}>
            <h5>
              No tienes ningún viaje activo/válido, crea un nuevo viaje para poder
              seleccionarlo...
            </h5>
            <Button
              disableElevation
              fullWidth
              component={RouterLink}
              to={ROUTES.TRAVELER_TRIPS}
              size="large"
              variant="contained"
              color="primary"
              startIcon={<PlanenIcon />}>
              Mis Viajes
            </Button>
          </div>
        )}
      </div>
    </GuveryDialog>
  );
};

const TripBox = (props) => {
  const { createMessage, trip, fetchTrips, loading } = props;
  const selectTripDialog = useDialog();

  const handleChooseTrip = (tripId) => {
    fetchSelectTrip(tripId);
    selectTripDialog.closeDialog();
  };

  const fetchSelectTrip = async (tripId) => {
    const res = await tripService.select(tripId);
    if (!res.error) {
      createMessage.success('Viaje seleccionado');
      fetchTrips();
    } else {
      createMessage.error(res.message);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = (
    <>
      {trip ? (
        <div className={styles.block}>
          <div className={styles.wrap}>
            <div className={styles.content}>
              <span className={styles.subtitle}>Viaje Seleccionado</span>
              <div className={styles.info}>
                <h4 className={styles.title}>{trip.nombre}</h4>
                <div className={styles.zipcode}>
                  <h6 className="guv-ellipsis">
                    (Zipcode: <span>{trip.zipcode}</span>)
                  </h6>
                </div>
              </div>
            </div>
            <IconButton size="small" color="secondary" onClick={selectTripDialog.openDialog}>
              <EditIcon />
            </IconButton>
          </div>
        </div>
      ) : (
        <div className={styles.block}>
          <div className={styles.noTrip}>
            <h5>No tiene un viaje seleccionado</h5>
            <IconButton size="small" color="secondary" onClick={selectTripDialog.openDialog}>
              <EditIcon />
            </IconButton>
          </div>
        </div>
      )}
      {selectTripDialog.open && (
        <SelectTripDialog
          open={selectTripDialog.open}
          onClose={selectTripDialog.closeDialog}
          onConfirm={handleChooseTrip}
        />
      )}
    </>
  );

  return (
    <>
      {!loading ? (
        <div className={styles.root}>{content}</div>
      ) : (
        <div className={styles.root}>
          <Skeleton variant="rect" width="100%" height={59} className={styles.tripLoading} />
        </div>
      )}
    </>
  );
};

export default TripBox;
