import { TypographyOptions } from '@material-ui/core/styles/createTypography';

const fontFamily = "'Lato', Helvetica, Arial, sans-serif";
// const fontFamily = "'Be Vietnam', sans-serif";

const typography = {
  fontFamily: fontFamily,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 700,
  fontWeightBold: 900,
  h1: {
    // color: palette.text.primary,
    fontWeight: 900,
    fontSize: '36px',
    fontFamily: fontFamily,
    letterSpacing: '-0.24px',
    lineHeight: '40px',
  },
  h2: {
    // color: palette.text.primary,
    fontWeight: 900,
    fontSize: '29px',
    fontFamily: fontFamily,
    letterSpacing: '-0.24px',
    lineHeight: '32px',
  },
  h3: {
    // color: palette.text.primary,
    fontWeight: 700,
    fontSize: '23px',
    fontFamily: fontFamily,
    letterSpacing: '-0.06px',
    lineHeight: '28px',
  },
  h4: {
    // color: palette.text.primary,
    fontWeight: 700,
    fontSize: '18px',
    fontFamily: fontFamily,
    letterSpacing: '-0.06px',
    lineHeight: '24px',
  },
  h5: {
    // color: palette.text.primary,
    fontWeight: 400,
    fontSize: '16px',
    fontFamily: fontFamily,
    letterSpacing: '-0.05px',
    lineHeight: '20px',
  },
  h6: {
    // color: palette.text.primary,
    fontWeight: 400,
    fontSize: '13px',
    fontFamily: fontFamily,
    letterSpacing: '-0.05px',
    lineHeight: '20px',
  },
  body1: {
    fontWeight: 700,
    fontSize: '16px',
    fontFamily: fontFamily,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
  },
  body2: {
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: fontFamily,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
  },
  button: {
    color: '#2b353b',
    fontWeight: 700,
    fontFamily: fontFamily,
    fontSize: '14px',
    textTransform: 'none',
  },
} as TypographyOptions;

export default typography;
