import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { IconMessageCircle, IconCheck, IconX } from '@tabler/icons';

import { useDialog } from '#root/hooks';
import { ChatDrawer, TravelerCard, TravelerReviewsDialog } from '#root/components';
import { AlertDialog } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import { replacePathParams } from '#root/utils';
import { offerService } from '#root/services';
import { USER_CHAT_ROOM } from '#root/constants/navigation';
import styles from './travelerInfo.module.scss';

interface TravelerInfoProps {
  traveler: any;
  travelerMessage?: string;
  offerId: string | number;
  offerIdPublic: string | number;
  isNegotiating?: boolean;
  isOfferExpired?: boolean;
  isOfferRejected?: boolean;
  isAccepted?: boolean;
  createMessage: any;
}

const TravelerInfo = (props: TravelerInfoProps) => {
  const {
    traveler,
    travelerMessage,
    offerId,
    offerIdPublic,
    isNegotiating,
    isOfferExpired,
    isOfferRejected,
    isAccepted,
    createMessage,
  } = props;
  const history = useHistory();

  const hasChat = isNegotiating || isAccepted;
  const chatText = hasChat ? 'Ver chat' : 'Iniciar chat';

  const dialogFirstChat = useDialog();
  const chatDrawer = useDialog();
  const travelerReviewsDialog = useDialog();

  const handleChatDrawer = () => {
    chatDrawer.openDialog();
  };

  const handleChatClick = () => {
    fetchActiveChat();
    dialogFirstChat.closeDialog();
  };

  const fetchActiveChat = async () => {
    const res = await offerService.chatByShopper(offerId);
    if (!res.error) {
      createMessage.info('Redireccionando al chat');
      if (!res.data?.chat_channel_id) return;
      const chatPath = replacePathParams(USER_CHAT_ROOM, { id: res.data?.chat_channel_id });
      history.push(chatPath);
    } else {
      createMessage.error('No se pudo actualizar el acuerdo');
    }
  };

  return (
    <div className={styles.root}>
      <GuveryPaper disableHover className={styles.wrap}>
        <div className={styles.content}>
        {traveler && (
          <>
        <TravelerCard traveler={traveler} travelerReviewsDialog={travelerReviewsDialog} />
        <TravelerReviewsDialog
        open={travelerReviewsDialog.open}
        onClose={travelerReviewsDialog.closeDialog}
        data={{traveler: traveler}}
      />
        </>
        )}
          {!isOfferExpired && !isOfferRejected && (
            <>
              <Button
                variant="outlined"
                color={isOfferExpired ? 'secondary' : 'primary'}
                startIcon={<IconMessageCircle />}
                onClick={!hasChat ? dialogFirstChat.openDialog : handleChatDrawer}>
                {chatText}
              </Button>
              <ChatDrawer
                extraTitle={`Oferta #${offerIdPublic}`}
                offerId={offerId}
                open={chatDrawer.open}
                onClose={chatDrawer.closeDialog}
              />
              <AlertDialog
                variant="info"
                open={dialogFirstChat.open}
                onClose={dialogFirstChat.closeDialog}
                message={
                  <>
                    <div>
                      <p style={{ fontSize: '18px' }}>Puedes:</p>
                      <p style={{ fontSize: '16px' }}>
                        <IconCheck size={16} color={'var(--color-green)'} />
                        &nbsp;Proponer una contra-oferta.
                      </p>
                      <p style={{ fontSize: '16px' }}>
                        <IconCheck size={16} color={'var(--color-green)'} />
                        &nbsp;Preguntar por más productos o corregir el link.
                      </p>
                      <p style={{ fontSize: '16px' }}>
                        <IconCheck size={16} color={'var(--color-green)'} />
                        &nbsp;Hacer preguntas sobre la oferta.
                      </p>
                    </div>
                    <br />
                    <div>
                      <p style={{ fontSize: '18px' }}>NO Debes:</p>
                      <p style={{ fontSize: '16px' }}>
                        <IconX size={16} color={'var(--color-red)'} />
                        &nbsp;Intercambiar información de contacto (teléfono, correo, etc).
                      </p>
                      <p style={{ fontSize: '16px' }}>
                        <IconX size={16} color={'var(--color-red)'} />
                        &nbsp;Hacer o sugerir tratos por fuera de Guvery.
                      </p>
                      <p style={{ fontSize: '16px' }}>
                        <IconX size={16} color={'var(--color-red)'} />
                        &nbsp;Hacer u ofrecer un pago directo al viajero.
                      </p>
                    </div>
                    <br />
                    <div>
                      <p style={{ fontSize: '14px' }}>
                        El incumplimiento de las normas del chat o de los términos y condiciones
                        de la plataforma, conllevará a una suspensión temporal o definitiva de
                        tu cuenta.
                      </p>
                    </div>
                  </>
                }
                title="Normas del chat de Guvery:"
                onConfirm={handleChatClick}
                confirmText="Acepto las normas"
              />
            </>
          )}
        </div>
        {!isNegotiating && !isAccepted && travelerMessage && (
          <p className={styles.message}>{travelerMessage}</p>
        )}
      </GuveryPaper>
    </div>
  );
};

export default TravelerInfo;
