import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@material-ui/core';
import clsx from 'clsx';

import {
  AlertCircleIcon,
  AlertTriangleIcon,
  CheckmarkCircle2Icon,
  InfoCircleIcon,
} from '#root/icons';
import styles from './alert.module.scss';

const variantIcon = {
  success: CheckmarkCircle2Icon,
  warning: AlertTriangleIcon,
  error: AlertCircleIcon,
  info: InfoCircleIcon,
};

interface AlertProps {
  actionText?: string;
  message: string;
  severity?: 'error' | 'info' | 'success' | 'warning';
  icon?: ReactNode;
  external?: boolean;
  href?: string;
}

const Alert = (props: AlertProps) => {
  const {
    actionText = 'Mas Detalles',
    message,
    severity = 'info',
    icon,
    external,
    href,
  } = props;
  const IconRoot = variantIcon[severity];
  const routerProps = external ? { href: href } : { to: href };

  return (
    <div className={styles.root}>
      <div className={clsx(styles.wrap, styles[severity])}>
        <div className={styles.content}>
          {icon ? icon : <IconRoot />}
          <div className={styles.text}>{message}</div>
        </div>
        {actionText && (
          <div className={styles.actions}>
            <Button
              className={styles.btn}
              variant="outlined"
              component={external ? 'a' : RouterLink}
              {...routerProps}>
              {actionText}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Alert;
