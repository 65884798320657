import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import { format, compareAsc } from 'date-fns';

import { validateURL } from '#root/utils';
import { useGlobalNotification } from '#root/hooks';
import { tripService, expertTravelerService } from '#root/services';
import { GuveryPageHeader, GuveryLayout, GuverySpace } from '#root/ui-components';
import { TRAVELER_DEALS_CREATED } from '#root/constants/navigation';
import styles from './travelerDealAuto.module.scss';
import DealShopper from './DealShopper';
import DealOrders from './DealOrders';
import DealDelivery from './DealDelivery';

type baseDataDTO = {
  shopper: any;
  tripId: string;
  packaging_type: string;
  delivery_date: Date | string;
  zipcode: string;
  delivery_place: string;
  expiration_date: Date | string;
  shipping: boolean;
  title: string;
  observation: string;
  deal_orders: any;
};

const baseData: baseDataDTO = {
  shopper: null,
  tripId: '',
  packaging_type: '',
  delivery_date: new Date(),
  zipcode: '',
  delivery_place: '',
  expiration_date: new Date(),
  shipping: false,
  title: '',
  observation: '',
  deal_orders: [
    {
      url: '',
      can: 1,
      pre: 0,
      com: 0,
    },
  ],
};

const reducerQty = (accumulator, current) => Number(accumulator) + Number(current?.can);
const reducerPrice = (accumulator, current) => Number(accumulator) + Number(current?.pre);
const reducerCom = (accumulator, current) => Number(accumulator) + Number(current?.com);

const TravelerDealAuto = () => {
  const history = useHistory();
  const globalNotification = useGlobalNotification();
  const [sendDisabled, setSendDisabled] = useState(false);
  const [dealForm, setDealForm] = useState(baseData);
  const [trips, setTrips] = useState([]);

  const qtyTotal = dealForm?.deal_orders?.reduce(reducerQty, 0);
  const priceTotal = dealForm?.deal_orders?.reduce(reducerPrice, 0);
  const comTotal = dealForm?.deal_orders?.reduce(reducerCom, 0);

  const goBack = () => history.push(TRAVELER_DEALS_CREATED);

  const handleValidate = () => {
    if (dealForm?.deal_orders?.length > 0) {
      const found = dealForm?.deal_orders.find((e) => !validateURL(e?.url));
      if (found) {
        setSendDisabled(true);
        return;
      }
    }
    if (!dealForm?.shopper) {
      setSendDisabled(true);
      return;
    }
    if (!dealForm?.title) {
      setSendDisabled(true);
      return;
    }
    if (!dealForm?.delivery_date) {
      setSendDisabled(true);
      return;
    }
    if (!dealForm?.delivery_place) {
      setSendDisabled(true);
      return;
    }
    if (!dealForm?.expiration_date) {
      setSendDisabled(true);
      return;
    }
    if (!dealForm?.packaging_type) {
      setSendDisabled(true);
      return;
    }
    if (qtyTotal <= 0) {
      setSendDisabled(true);
      return;
    }
    if (priceTotal <= 0) {
      setSendDisabled(true);
      return;
    }
    if (comTotal <= 0) {
      setSendDisabled(true);
      return;
    }
    setSendDisabled(false);
  };

  const handleSend = () => {
    fetchNewAutoDeal();
  };

  const fetchGetCurrentTrip = async () => {
    const res = await tripService.getCurrent();
    if (!res.info.error) {
      const deliveryDate = res.data?.fecha_entrega
        ? new Date(res.data.fecha_entrega + `T09:00:00`)
        : new Date();
      const expirationDate = res.data?.offer_expiration_date
        ? new Date(res.data.offer_expiration_date + `T09:00:00`)
        : new Date();

      const compareDelivery = compareAsc(deliveryDate, new Date());
      const compareExpiration = compareAsc(expirationDate, new Date());

      if (compareDelivery !== 1 || compareExpiration !== 1) {
        return;
      }
      setDealForm({
        ...dealForm,
        tripId: res.data?.id,
        delivery_date: deliveryDate,
        expiration_date: expirationDate,
        delivery_place: res.data?.lugar_entrega,
        zipcode: res.data?.zipcode,
      });
    }
  };

  const fetchTrips = async () => {
    const res = await tripService.list();
    if (!res.error) {
      const newOrderArray = [...res.data?.filter((item) => item.estado === 'CREADO')];
      setTrips(newOrderArray);
    }
  };

  const fetchNewAutoDeal = async () => {
    setSendDisabled(true);
    globalNotification.alert('Enviando datos...', 'info');
    const arg = {
      shopper_idu: dealForm.shopper?.idu,
      delivery_date: format(new Date(dealForm.delivery_date), 'yyyy-MM-dd'),
      delivery_place: dealForm.delivery_place,
      observation: dealForm.observation,
      title: dealForm.title,
      flag_province: dealForm.shipping ? 1 : 0,
      packing: dealForm.packaging_type,
      trip_id: dealForm.tripId,
      expiration_date: format(new Date(dealForm.expiration_date), 'yyyy-MM-dd'),
      deal_orders: dealForm.deal_orders,
    };
    const res = await expertTravelerService.createAutoDeal(arg);
    if (!res.error) {
      globalNotification.alert('Acuerdo creado con éxito.', 'success');
      history.push(TRAVELER_DEALS_CREATED);
      return;
    }
    globalNotification.alert('Ocurrió un error al crear el acuerdo.', 'error');
    setSendDisabled(false);
  };

  useEffect(() => {
    fetchGetCurrentTrip();
    fetchTrips();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleValidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qtyTotal, priceTotal, comTotal, dealForm]);

  const title = (
    <span className={styles.title}>
      <span>Creando Auto Acuerdo</span>
    </span>
  );

  return (
    <GuveryLayout>
      <GuveryPageHeader backButton title={title} onBack={goBack} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <GuverySpace direction="vertical" size="large">
            <DealShopper dealForm={dealForm} setDealForm={setDealForm} />
            <DealOrders
              dealForm={dealForm}
              setDealForm={setDealForm}
              setSendDisabled={setSendDisabled}
            />
          </GuverySpace>
        </Grid>
        <Grid item xs={12} md={4}>
          <GuverySpace direction="vertical" size="large">
            <DealDelivery dealForm={dealForm} setDealForm={setDealForm} trips={trips} />
            <Button
              fullWidth
              disableElevation
              disabled={sendDisabled}
              variant="contained"
              color="primary"
              onClick={handleSend}>
              Guardar y Enviar Acuerdo
            </Button>
          </GuverySpace>
        </Grid>
      </Grid>
    </GuveryLayout>
  );
};

export default TravelerDealAuto;
