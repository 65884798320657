import { GuveryDialog } from '#root/ui-components';
import { Link, Button, Avatar, Tooltip } from '@material-ui/core';
import { IconStar, IconBrandWhatsapp, IconMapPin, IconPhoneCall, IconUser, IconMapPins } from '@tabler/icons';

import styles from './styles.module.scss';
import clsx from 'clsx';



const ShopperDeliverySetupDialog = (props) => {
  const { open = false, onClose, deal } = props;
  const traveler = deal?.offer?.traveler;

  return (
    <GuveryDialog
      open={open}
      onClose={onClose}
      title={
        <p style={{ display: 'flex', alignItems: 'center', gap: 3, fontSize: 20 }}>
          <IconMapPins /> Indicaciones para la Entrega
        </p>
      }
      closeText="Cerrar">
      <div className={styles.root}>
        <p className="guv-font-size-5 guv-regular guv-text-alt-2">
          El viajero ha compartido contigo datos e indicaciones para que lo contactes.
        </p>
        <p className="guv-font-size-6 guv-regular guv-text-alt-2">
          Puede que recién haya llegado de viaje, por favor contáctalo con respeto y paciencia.
        </p>
        <hr />
        <div className={styles.dealDetails}>
          {/*  */}
          <div className={styles.user}>
            <Avatar className={styles.avatar} src={traveler?.picture_path}>
              {traveler?.one_firstname?.charAt(0)}
            </Avatar>
            <div className={styles.userInfo}>
              <h6 className={styles.name}>{traveler?.mask_fullname}</h6>
              {traveler?.calvia && (
                <Tooltip arrow title="Puntaje del comprador" placement="top">
                  <div className={styles.userScore}>
                    <IconStar />
                    <span>{traveler?.calvia}</span>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          {/*  */}
          <div className={styles.deliveryDate}>
            <span className={styles.textHelper}>Fecha pactada:</span>
            <span className={styles.text}>{deal?.fixed_delivery_date}</span>
          </div>
        </div>
        <div className={styles.deliverySetup}>
          <p>{deal.delivery_setup.custom_message}</p>
          <div className={clsx(styles.body)}>
            <div className={clsx(styles.col)}>
              {deal.delivery_setup.pickup_contact && (
                <p>
                  <IconUser size={14} /> {deal.delivery_setup.pickup_contact}
                </p>
              )}
              {deal.delivery_setup.phone_number && (
                <p>
                  <IconPhoneCall size={14} /> {deal.delivery_setup.phone_number}
                </p>
              )}
              {deal.delivery_setup.whatsapp_number && (
                <p>
                  <IconBrandWhatsapp size={14} /> {deal.delivery_setup.whatsapp_number}
                </p>
              )}
            </div>
            <div className={clsx(styles.col)}>
              {deal.delivery_setup.pickup_address && (
                <p>
                  <IconMapPin size={14} /> {deal.delivery_setup.pickup_address}
                </p>
              )}
            </div>
          </div>
          {deal.delivery_setup.pickup_details && (
            <p className={clsx(styles.details)}>{deal.delivery_setup.pickup_details}</p>
          )}
          
        </div>
      </div>
    </GuveryDialog>
  );
};

export default ShopperDeliverySetupDialog;
