export const ShippingStatus = {
  created: 'CREADO',
  registered: 'REGISTRADO',
  for_pickup: 'POR_RECOGER',
  picked: 'RECOGIDO',
  confirmed: 'CONFIRMADO',
  processing: 'PROCESANDO',
  ready: 'READY',
  shipped: 'DESPACHADO',
  paying: 'PAGADO_COMPRADOR',
  paid: 'PAGADO_ADMIN',
  paid_order: 'PAGADO_ORDEN',
  closed: 'CERRADO',
  blocked: 'BLOQUEADO',
} as const;

export type ShippingStatusType = typeof ShippingStatus[keyof typeof ShippingStatus];

interface ShippingDTO {
  carrier: {
    idu: 18238;
    empresa_nombre: 'Mailboxes ETC';
    empresa_alias: 'OLVA';
    empresa_web: null;
  };
  delivery_address: any;
  dir_id: null;
  current_status: ShippingStatusType;
  carrier_tracking_code: null;
  fecblq: null;
  feccre: '2021-08-26 14:19:21';
  fecpagadm: null;
  paying_at: null;
  id: number;
  idu: number;
  imp: null;
  is_affected: null;
  is_closed_debited: boolean | null;
  registered_at?: Date | string | null;
  picked_at?: Date | string | null;
  confirmed_at?: Date | string | null;
  processing_at?: Date | string | null;
  ready_at?: Date | string | null;
  shipped_at: Date | string | null;
  currency: 'SOLES' | 'DOLARES';
  description: string;
  shipping_details: [];
  tca: '4.10';
  total: number | null;
  carrier_cost: null;
  carrier_id: number;
  created_at: Date | string | null;
  updated_at?: Date | string | null;
  deleted_at?: Date | string | null;
}

export default ShippingDTO;
