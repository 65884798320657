import { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { IconBuildingStore } from '@tabler/icons';

import { shopperService } from '#root/services';
import { GuveryLayout, GuveryPageHeader } from '#root/ui-components';
import { GuveryPaper, GuveryEmpty } from '#root/ui-components/cards';
import { OrderStoreCard } from '#root/components';
import * as VARIABLES from '#root/constants/variables';

const UserOrdersStore = () => {
  const [isLoading, setLoading] = useState(true);
  const [ordersStore, setOrdersStore] = useState([]);

  const fetchOrdersStore = async () => {
    setLoading(true);
    const res = await shopperService.ordersStore();
    if (!res.error) {
      setOrdersStore(res.data?.orders_tienda?.reverse());
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOrdersStore();
  }, []);

  return (
    <GuveryLayout>
      <GuveryPageHeader title="Mis Pedidos Tienda" />
      <Grid container spacing={3}>
        {!isLoading ? (
          <>
            {ordersStore.map((order, index) => (
              <Grid item xs={12} key={index}>
                <OrderStoreCard order={order} />
              </Grid>
            ))}
            {ordersStore.length <= 0 && (
              <Grid item xs={12}>
                <GuveryEmpty description="No tienes pedidos">
                  <Button
                    component="a"
                    href={VARIABLES.URL_PHP_PAGE + 'tienda'}
                    variant="outlined"
                    color="primary"
                    startIcon={<IconBuildingStore />}>
                    Ir a la Tienda
                  </Button>
                </GuveryEmpty>
              </Grid>
            )}
          </>
        ) : (
          <>
            {Array.from(new Array(4)).map((item, index) => (
              <Grid item xs={12} key={index}>
                <GuveryPaper disableHover disableElevation>
                  <Skeleton variant="rect" height="299px" />
                </GuveryPaper>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </GuveryLayout>
  );
};

export default UserOrdersStore;
