import { useState } from 'react';

const useAnchorEl = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const onChange = (evt: any) => setAnchorEl(evt.currentTarget);
  const onClose = () => setAnchorEl(null);

  return { anchorEl, onChange, onClose };
};

export default useAnchorEl;
