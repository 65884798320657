import { SliderClassKey, StyleRules } from '@material-ui/core';

const MuiSlider: Partial<StyleRules<SliderClassKey, {}>> = {
  root: {
    color: '#5b7086',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 0,
  },
  mark: {
    // backgroundColor: '#bfbfbf',
    height: 14,
    width: 2,
    marginTop: -3,
    opacity: 0.64,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
  markLabel: {
    marginTop: 6,
  },
};

export default MuiSlider;
