import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { GuverySpace } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';

const LoadingLayout = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <GuveryPaper disableHover disableElevation>
              <Skeleton variant="rect" height="456px" />
            </GuveryPaper>
          </Grid>
          <Grid item xs={12} lg={4}>
            <GuverySpace direction="vertical" size="large">
              <GuveryPaper disableHover disableElevation>
                <Skeleton variant="rect" height="70px" />
              </GuveryPaper>
              <GuveryPaper disableHover disableElevation>
                <Skeleton variant="rect" height="411px" />
              </GuveryPaper>
            </GuverySpace>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <GuverySpace direction="vertical" size="large">
          <Skeleton variant="rect" height="32px" width="40%" />
          <Grid container spacing={3}>
            {Array.from(new Array(3)).map((data, index) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <GuveryPaper disableHover disableElevation>
                  <Skeleton variant="rect" height="242px" />
                </GuveryPaper>
              </Grid>
            ))}
          </Grid>
        </GuverySpace>
      </Grid>
    </Grid>
  );
};

export default LoadingLayout;
