import { withStyles, styled } from '@material-ui/core/styles';
import { Button as MuiButton } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { LinkProps as RouterLinkProps } from 'react-router-dom';

const AltButton = styled(MuiButton)<ButtonProps | RouterLinkProps>(({ theme }) => ({
  borderColor: 'rgba(0, 0, 0, 0.12)',
}));

const WhatsAppButton = withStyles(() => ({
  root: {
    color: '#f7f7f7',
    backgroundColor: '#25D366',
    '&:hover': {
      backgroundColor: '#25D366',
    },
  },
}))(MuiButton);

const PrimaryBackwardButton = withStyles(() => ({
  root: {
    color: '#002f27',
    backgroundColor: '#92ddd2',
    '&:hover': {
      backgroundColor: '#87d3c7',
    },
  },
}))(MuiButton);

const Button = () => {};

Button.AltButton = AltButton;
Button.WhatsApp = WhatsAppButton;
Button.PrimaryBackward = PrimaryBackwardButton;

export default Button;
