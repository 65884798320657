import SvgIcon from '@material-ui/core/SvgIcon';

const ClockSvg = () => (
  <>
    <g data-name="Layer 2">
      <g data-name="clock">
        <rect width={24} height={24} transform="rotate(180 12 12)" opacity={0} />
        <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm4 11h-4a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v3h3a1 1 0 0 1 0 2z" />
      </g>
    </g>
  </>
);

const Clock = (props) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <ClockSvg />
  </SvgIcon>
);

export default Clock;
