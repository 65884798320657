import { useState } from 'react';

const useDialog = () => {
  const [dialog, setDialog] = useState({
    loading: false,
    open: false,
  });

  const openDialog = () => {
    setDialog({ ...dialog, open: true });
  };

  const closeDialog = () => {
    setDialog({ ...dialog, open: false });
  };

  const handleLoading = (value: boolean) => {
    setDialog({ ...dialog, loading: value });
  };

  return { ...dialog, openDialog, closeDialog, handleLoading };
};

export default useDialog;
