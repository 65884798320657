import configService from './config';
import guveryFetchApi from './guveryFetchApi';
import * as METHODS from './methods';

const guveryApi = configService.apiGuvery;

const saveAddress = async (shippingId, addressId) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/user/shipping/confirmed`,
    options: METHODS.POST({
      shipping_id: shippingId,
      delivery_address_id: addressId,
    }),
    message: {
      success: 'Dirección guardada',
      error: 'No se pudo completar la acción.',
    },
  });

  return { ...response };
};

const shippingService = {
  saveAddress,
};

export default shippingService;
