export const questOne = [
  {
    id: 'one',
    text: 'Mucha experiencia',
    value: 'high',
  },
  {
    id: 'one',
    text: 'Regular experiencia',
    value: 'regular',
  },
  {
    id: 'one',
    text: 'Poca experiencia',
    value: 'low',
  },
  {
    id: 'one',
    text: 'Ninguna experiencia',
    value: 'none',
  },
];

export const questTwo = [
  {
    id: 'two',
    text: 'Sí',
    value: 'amazon',
  },
  {
    id: 'two',
    text: 'Sí y tengo Prime',
    value: 'prime',
  },
  {
    id: 'two',
    text: 'Tuve pero ya no',
    value: 'had_amazon',
  },
  {
    id: 'two',
    text: 'No, nunca he tenido',
    value: 'none',
  },
];

export const questThree = [
  {
    id: 'three',
    text: 'Paypal',
    value: 'paypal',
  },
  {
    id: 'three',
    text: 'Tarjeta de Crédito de EE.UU.',
    value: 'credit_card_us',
  },
  {
    id: 'three',
    text: 'Tarjeta de Crédito de Perú.',
    value: 'credit_card_pe',
  },
];

export const questFour = [
  {
    id: 'four',
    text: 'Me dedico al negocio de traer encargos o maletas.',
    value: 'carrier',
  },
  {
    id: 'four',
    text: 'A veces le traigo a mis contactos, amigos o familiares.',
    value: 'friends_family',
  },
];

export const questFive = [
  {
    id: 'five',
    text: 'Más de 4 veces por año',
    value: '4_times_yearly',
  },
  {
    id: 'five',
    text: 'De 2-3 veces al año',
    value: '2-3_times_yearly',
  },
  {
    id: 'five',
    text: '1 vez al año',
    value: '1_time_yearly',
  },
  {
    id: 'five',
    text: 'Es mi primer viaje',
    value: 'first_time',
  },
];

export const questSix = [
  {
    id: 'six',
    text: 'Resido en EE.UU.',
    value: 'us_resident',
  },
  {
    id: 'six',
    text: 'Trabajo en una aerolínea.',
    value: 'cabin_crew',
  },
  {
    id: 'six',
    text: 'Familia / Pareja',
    value: 'family_couple',
  },
  {
    id: 'six',
    text: 'Turismo / Vacaciones',
    value: 'tourism_vacations',
  },
  {
    id: 'six',
    text: 'Trabajo / Negocios',
    value: 'work_business',
  },
];
