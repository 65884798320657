import configService from './config';
import guveryFetchApi from './guveryFetchApi';
import * as METHODS from './methods';

const guveryApi = configService.apiGuvery;

type DealFilter_values = 'created' | 'paid' | 'delivered' | 'rejected';

const getQuantities = async () => {
  const { response } = await guveryFetchApi({
    url: guveryApi + `/traveler/deals`,
    options: METHODS.GET(),
    message: {
      success: 'Cantidades de Acuerdos obtenidas con exito.',
      error: 'No se pudo obtener las cantidades de acuerdos.',
    },
  });

  return { ...response };
}

const getExtras = async () => {
  const { response } = await guveryFetchApi({
    url: guveryApi + `/traveler/deals/extra_options`,
    options: METHODS.GET(),
    message: {
      success: 'Extra obtenidas con exito.',
      error: 'No se pudo obtener extra.',
    },
  });

  return { ...response };
}

const list_by_filter = async (filter: DealFilter_values, search = null, offset = 0) => {
  const urlFull = (!search) 
  ? guveryApi + `/traveler/deals/${filter}?offset=${offset}`
  : guveryApi + `/traveler/deals/${filter}?search=${search}&offset=${offset}`;
  const { response } = await guveryFetchApi({
    url: urlFull,
    options: METHODS.GET(),
    message: {
      success: 'Acuerdos obtenidas con exito.',
      error: 'No se pudo obtener las lista de acuerdos.',
    },
  });

  return { ...response };
};

const purchasedByTraveler = async (arg) => {
  const { response } = await guveryFetchApi({
    url: guveryApi + `/traveler/deal/purchased`,
    options: METHODS.POST({ ...arg }),
    message: {
      success: 'Acuerdo actualizado con éxito.',
      error: 'No se pudo actualizar el acuerdo.',
    },
  });

  return { ...response };
};

const rejectedByTraveler = async (arg) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/traveler/deal/rejected`,
    options: METHODS.POST({
      reason: arg.reason,
      reason_other: arg.reasonOther,
      deal_id: arg.dealId,
    }),
    message: {
      success: 'Acuerdo actualizado con éxito.',
      error: 'No se pudo actualizar el acuerdo.',
    },
  });

  return { ...response };
};

const receivedByTraveler = async (arg) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/traveler/deal/received`,
    options: METHODS.POST({ ...arg }),
    message: {
      success: 'Acuerdo actualizado con éxito.',
      error: 'No se pudo actualizar el acuerdo.',
    },
  });

  return { ...response };
};

const readyByTraveler = async (arg) => {
  const { response } = await guveryFetchApi({
    url: guveryApi + `/traveler/deal/ready`,
    options: METHODS.POST({ ...arg }),
    message: {
      success: 'Acuerdo actualizado con éxito.',
      error: 'No se pudo actualizar el acuerdo.',
    },
  });

  return { ...response };
};

const deliveredByTraveler = async (arg) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/traveler/deal/delivered`,
    options: METHODS.POST({ ...arg }),
    message: {
      success: 'Acuerdo actualizado con éxito.',
      error: 'No se pudo actualizar el acuerdo.',
    },
  });

  return { ...response };
};

const deliveredCarrierByTraveler = async (arg) => {
  const formData = new FormData();
  formData.append('photo_1', arg.photo_1);
  formData.append('photo_2', arg.photo_2);
  formData.append('carrier_id', arg.carrier_id);
  formData.append('message', arg.message);
  formData.append('deal_id', arg.deal_id);

  const { response } = await guveryFetchApi({
    url: `${guveryApi}/traveler/deal/delivered_carrier`,
    options: METHODS.POST_FORM_DATA(formData),
    message: {
      success: 'Acuerdo actualizado con éxito.',
      error: 'No se pudo actualizar el acuerdo.',
    },
  });

  return { ...response };
};

const travelerDealInfo = async (ida) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/traveler/deal/${ida}`,
    options: METHODS.GET(),
    message: {
      success: 'Acuerdo obtenido con exito',
      error: 'No se puedo obtener el acuerdo.',
    },
  });

  return { ...response };
};

const expiredByTraveler = async (arg) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/traveler/deal/expired`,
    options: METHODS.POST({
      deal_id: arg.dealId,
    }),
    message: {
      success: 'Acuerdo actualizado con éxito.',
      error: 'No se pudo actualizar el acuerdo.',
    },
  });

  return { ...response };
};

const addPartnerByTraveler = async (arg) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/expert_traveler/deal/associate`,
    options: METHODS.POST({
      deal_id: arg.dealId,
      idvs: arg.partnerId,
    }),
    message: {
      success: 'Acuerdo actualizado con éxito.',
      error: 'No se pudo actualizar el acuerdo.',
    },
  });

  return { ...response };
};

const createByTraveler = async (arg) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/traveler/deal/new`,
    options: METHODS.POST({
      ...arg,
    }),
    message: {
      success: 'Acuerdo creado con éxito.',
      error: 'No se pudo crear el acuerdo.',
    },
  });

  return { ...response };
};

const editByTraveler = async (arg) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/traveler/deal/edit`,
    options: METHODS.POST({
      ...arg,
    }),
    message: {
      success: 'Acuerdo actualizado con éxito.',
      error: 'No se pudo actualizar el acuerdo.',
    },
  });

  return { ...response };
};

const dealService = {
  getQuantities,
  getExtras,
  list_by_filter,
  purchasedByTraveler,
  rejectedByTraveler,
  receivedByTraveler,
  readyByTraveler,
  deliveredByTraveler,
  deliveredCarrierByTraveler,
  travelerDealInfo,
  expiredByTraveler,
  addPartnerByTraveler,
  createByTraveler,
  editByTraveler,
};

export default dealService;
