import { CSSProperties } from 'react';
import { IconQuestionMark } from '@tabler/icons';
import clsx from 'clsx';

import styles from './shippingBadge.module.scss';

interface ShippingBadgeProps {
  className?: string;
  style?: CSSProperties;
  text?: string;
  onClick?: () => void;
  background?: boolean;
}

const ShippingBadge = (props: ShippingBadgeProps) => {
  const { className, style, background, text = 'Envío Nacional', onClick } = props;

  const cn = clsx(
    styles.root,
    {
      [styles.clickable]: onClick,
      [styles.background]: background,
    },
    className,
  );

  return (
    <div className={cn} style={{ ...style }} onClick={onClick}>
      <span className={styles.text}>{text}</span>
      {onClick && <IconQuestionMark size={20} style={{ marginLeft: 4 }} />}
    </div>
  );
};

export default ShippingBadge;
