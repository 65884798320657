import { StyleRules, TooltipClassKey } from '@material-ui/core';

const MuiTooltip: Partial<StyleRules<TooltipClassKey, {}>> = {
  tooltip: {
    fontSize: '14px',
    color: '#fff',
    backgroundColor: '#333',
    padding: '8px 16px',
    boxShadow: '0 12px 32px rgba(0, 0, 0, 0.2), 0 0 8px rgba(0, 0, 0, 0.05)',
  },
  arrow: {
    color: '#333',
  },
};

export default MuiTooltip;
