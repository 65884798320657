import { ChangeEvent, useState, useEffect } from 'react';
import { Button, TextField, MenuItem } from '@material-ui/core';

import { GuverySpace, GuveryDialog } from '#root/ui-components';
import styles from './deliveredOfficeDialog.module.scss';
import { GuveryTextField, UploadImageCrop } from '#root/components';

interface CroppedImageState {
  blob: Blob;
  fileUrl: string;
}

const DeliveredOfficeDialog = (props) => {
  const { id, carriers, open = false, onClose, onConfirm } = props;
  const [carrier, setCarrier] = useState('');
  const [croppedImage1, setCroppedImage1] = useState<CroppedImageState>({
    blob: null,
    fileUrl: '',
  });
  const [croppedImage2, setCroppedImage2] = useState<CroppedImageState>({
    blob: null,
    fileUrl: '',
  });
  const [pendingCroppings, setPendingCroppings] = useState(true);
  const [message, setMessage] = useState('');
  const [disableAction, setDisableAction] = useState(true);

  const handleCarrierChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCarrier(event.target.value);
  };

  const handleMessageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleConfirm = async () => {
    setDisableAction(true);
    if (croppedImage1.blob) {
      if (croppedImage2.blob) {
        onConfirm(croppedImage1.blob, croppedImage2.blob, carrier, message, id);  
      } else {
        onConfirm(croppedImage1.blob, null, carrier, message, id);
      }
    }
    setDisableAction(false);
  };

  useEffect(() => {
    if (open) {
      setCroppedImage1({
        blob: null,
        fileUrl: '',
      });
      setCroppedImage2({
        blob: null,
        fileUrl: '',
      });
      setCarrier('');
      setDisableAction(true);
    }
  }, [open]);

  useEffect(() => {
    if (carrier && croppedImage1.blob && !pendingCroppings) {
      setDisableAction(false);
    } else {
      setDisableAction(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppedImage1, croppedImage2, carrier, pendingCroppings]);

  return (
    <GuveryDialog open={open} onClose={onClose} title={`Acuerdo ${id}`} content={false}>
      <div className={styles.root}>
        <GuverySpace direction="vertical" size="middle">
          <div className={styles.title}>
            <h4>Entregando en oficina</h4>
            <p style={{marginTop: 4, fontWeight: 600}}>Selecciona la oficina y luego sube 1 o 2 fotos.</p>
            <p style={{marginTop: 2, fontSize: 12}}>Opcionalmente puedes agregar un mensaje al final.</p>
          </div>
          <TextField
            fullWidth
            variant="outlined"
            id="standard-select-currency"
            select
            label="Oficina"
            value={carrier}
            onChange={handleCarrierChange}>
            {carriers?.map((option) => (
              <MenuItem key={option.user_id} value={option.user_id}>
                {option.company_name}
              </MenuItem>
            ))}
          </TextField>
          {!carrier ? (
            <div className={styles.hint}>
            <p style={{marginTop: '-16px', marginLeft: 10}}>Selecciona una oficina para continuar.</p>
          </div>
          ) : (
            <>
            <UploadImageCrop setCroppedImage={setCroppedImage1} counter={1} setPendingCroppings={setPendingCroppings} />
            {croppedImage1.blob && (
              <UploadImageCrop setCroppedImage={setCroppedImage2} counter={2} setPendingCroppings={setPendingCroppings} isOptional />
            )}
            </>
          )}

          {croppedImage1.blob && (
          <GuveryTextField 
          id="message" 
          label="Mensaje opcional sobre las fotos" 
          required={false} 
          onChange={handleMessageChange}
          />
          )}
          
          <Button
            fullWidth
            disableElevation
            variant="contained"
            color="primary"
            disabled={disableAction}
            onClick={handleConfirm}>
            Confirmar Entrega
          </Button>
        </GuverySpace>
      </div>
    </GuveryDialog>
  );
};

export default DeliveredOfficeDialog;
