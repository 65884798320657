import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { GuveryDialog } from '#root/ui-components';
import { CanguroGuvery } from '#root/assets';
import styles from './priceDialog.module.scss';

const getDecimal = (num: number) => {
  const intNum = Number(num);
  return Math.round(intNum * 100) / 100;
};

const PriceDialog = (props) => {
  const { open = false, onClose, priceData } = props;
  const priceReal = Number(priceData.price);
  const comTraveler = Number(priceData.comTraveler);
  const comGuvery = Number(priceData.comGuvery);
  const total = priceReal + comTraveler + comGuvery;

  return (
    <GuveryDialog
      open={open}
      onClose={onClose}
      content={false}
      title="Detalle del total"
      closeText="Cerrar">
      <div className={styles.root}>
        <div className={styles.product}>
          <div className={styles.cover}>
            {priceData.image ? (
              <img className={styles.img} src={priceData.image} alt="producto" />
            ) : (
              <img className={styles.holder} src={CanguroGuvery} alt="producto" />
            )}
          </div>
          <div className={styles.info}>
            <p className={styles.name}>{priceData.name}</p>
            <a href={priceData.link} target="_blank" rel="noopener noreferrer">
              <p className={styles.link}>{priceData.link}</p>
            </a>
          </div>
        </div>
        <TableContainer>
          <Table className={styles.table}>
            <TableHead className={styles.header}>
              <TableRow>
                <TableCell>NOMBRE</TableCell>
                <TableCell>CANTIDAD</TableCell>
                <TableCell>TOTAL</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Precio Real</TableCell>
                <TableCell>{priceData.can}</TableCell>
                <TableCell>$ {getDecimal(priceReal)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Comisión</TableCell>
                <TableCell></TableCell>
                <TableCell>$ {getDecimal(comTraveler)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Guvery (%)</TableCell>
                <TableCell></TableCell>
                <TableCell>$ {getDecimal(comGuvery)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <span className={styles.total}>Total</span>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <span className={styles.total}>$ {getDecimal(total)}</span>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </GuveryDialog>
  );
};

export default PriceDialog;
