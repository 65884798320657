import { useState, useEffect } from 'react';
import {
  Button,
  Drawer,
  Avatar,
  Tooltip,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { IconStar } from '@tabler/icons';

import { useResponsive } from '#root/hooks';

import DrawerAppbar from '../DrawerAppbar';

import styles from './styles.module.scss';

const defaultData = {
  received: {
    header:
      'Estas son las indicaciones y las responsabilidades que asumes cada vez que recibes en EE.UU. los pedidos de un Acuerdo de Guvery:',
    instructions: [
      'Recibir todos los pedidos de este Acuerdo.',
      'Revisar que cada pedido que compraste, cumpla con las características o especificaciones de la compra que hiciste.',
      'Si el producto es electrónico, conversa con el comprador si es posible hacer una prueba de encendido (especialmente si es un producto usado o refurbished).',
      'Si el producto tiene fecha de expiración y le queda menos de 1 año para expirar, comunícale de inmediato al comprador.',
      'Si el producto o el empaque muestran signos de que han sido dañados por el transportista, comunícale al comprador.',
      // 'Sube fotos del (los) producto(s) tan pronto como los hayas recibido.'
    ],
    question: '¿Has cumplido con tus responsabilidades como viajero al recibir estos pedidos?',
    checkbox_label: 'Si, he cumplido.',
    action_label: 'He recibido todos los pedidos',
    // action_extra_text: 'Después de marcar este Acuerdo como Recibido, podrás subir fotos de cómo recibiste los pedidos.',
  },
  purchased: {
    header:
      'Sigues estas indicaciones para comprar los pedidos de un Acuerdo de Guvery:',
    instructions: [
      'Lee y sigue las observaciones escritas por el comprador (talla, color, modelo, capacidad, características, etc) para hacer la compra.',
      'Compra el (los) pedido(s) exactamente en el mismo link provisto por el comprador.',
      'De preferencia, utiliza una cuenta de usuario en la tienda donde comprarás el pedido (evitar hacer compras como invitado).',
      'Toma una captura de pantalla y guarda las evidencias de que has comprado el (los) pedido(s) correctamente.',
      // 'Sube fotos del (los) producto(s) tan pronto como los hayas recibido.'
    ],
    question: '¿Has seguido todas las indicaciones para comprar estos pedidos?',
    checkbox_label: 'Si, las he seguido.',
    action_label: 'He comprado todos los pedidos',
    // action_extra_text: 'Después de marcar este Acuerdo como Recibido, podrás subir fotos de cómo recibiste los pedidos.',
  },
};

const DealInstructionsDrawer = (props) => {
  const { open, onClose, handleLoading, deal, nextAction } = props;
  const { isDesktop } = useResponsive();
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [data, setData] = useState({
    header: '',
    instructions: [],
    question: '',
    checkbox_label: '',
    action_label: '',
    action_extra_text: '',
  });

  const shopper = deal?.offer?.order?.user ?? null;

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    handleLoading(nextAction);
  };

  useEffect(() => {
    if (open) {
      setData(defaultData[nextAction]);
      setDisabledSubmit(true);
    }
  }, [open]);

  const content = (
    <div className={styles.main}>
      <div className={styles.dealDetails}>
        {/*  */}
        <div className={styles.user}>
          <Avatar className={styles.avatar} src={shopper?.picture_path}>
            {shopper?.one_firstname?.charAt(0)}
          </Avatar>
          <div className={styles.userInfo}>
            <h6 className={styles.name}>{shopper?.mask_fullname}</h6>
            {shopper?.calcom && (
              <Tooltip arrow title="Puntaje del comprador" placement="top">
                <div className={styles.userScore}>
                  <IconStar />
                  <span>{shopper?.calcom}</span>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        {/*  */}
        <div className={styles.deliveryDate}>
          <span className={styles.textHelper}>Fecha pactada:</span>
          <span className={styles.text}>{deal?.fixed_delivery_date}</span>
        </div>
      </div>
      <div className={styles.dealOrders}>
        <p className={styles.remarks}>{deal?.offer?.order?.obs}</p>
        {deal.deal_orders.map((order, index) => (
          <div className={styles.product} key={index}>
            <h5 className={styles.text}>
              <span className={styles.amount}>{order.can} x</span>
              <a
                className={styles.link}
                href={order.url}
                target="_blank"
                rel="noopener noreferrer">
                {order.fixed_url}
              </a>
            </h5>
          </div>
        ))}
      </div>
      <hr />
      <p className={styles.headInstructions}>
        {data.header}
      </p>
      <div className={styles.divInstructions}>
        <ul>
        {data.instructions.map((instruction, index) => (
            <li key={index}>
            {instruction}
          </li>
        ))}
        </ul>
      </div>
      <hr />
      {data.question && (
        <h4 className="guv-text-primary">
       {data.question}
      </h4>
      )}
      
      {data.checkbox_label && (
        <div className={styles.sectionCheck}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!disabledSubmit}
              onChange={() => setDisabledSubmit(!disabledSubmit)}
              name="checkedB"
              color="primary"
            />
          }
          label={data.checkbox_label}
        />
      </div>
      )}
      
      {data.action_label && (
      <div className={styles.actions}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={disabledSubmit}>
          {data.action_label}
        </Button>
        {data.action_extra_text && (
        <p>
          {data.action_extra_text}
        </p>
        )}
      </div>
      )}
    </div>
  );

  return (
    <>
      {isDesktop ? (
        <Drawer
          anchor="right"
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            style: {
              background: 'var(--color-background-base)',
              // borderRight: 'none',
              width: 400,
              height: '100%',
            },
          }}>
          <DrawerAppbar title={'Acuerdo #' + deal.ida} onClose={handleClose} />
          {content}
        </Drawer>
      ) : (
        <Drawer
          anchor="right"
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '100%',
              background: 'var(--color-background-base)',
            },
          }}
          style={{
            zIndex: 1300,
          }}>
          <DrawerAppbar isMobile title={'Acuerdo #' + deal.ida} onClose={handleClose} />
          {content}
        </Drawer>
      )}
    </>
  );
};

export default DealInstructionsDrawer;
