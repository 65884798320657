import { ReactNode } from 'react';
import { Button } from '@material-ui/core';
import { IconAlertCircle, IconBolt, IconClock } from '@tabler/icons';
import clsx from 'clsx';

import { AlertTriangleIcon, InfoCircleIcon } from '#root/icons';
import styles from './basicAlert.module.scss';

const variantIcon = {
  success: IconClock,
  warning: AlertTriangleIcon,
  error: IconAlertCircle,
  info: InfoCircleIcon,
  express: IconBolt,
};

interface BasicAlertProps {
  text?: string | ReactNode;
  actionText?: string;
  actionClick?: any;
  variant?: 'error' | 'info' | 'success' | 'warning' | 'express';
}

const BasicAlert = (props: BasicAlertProps) => {
  const { text, variant, actionText, actionClick } = props;
  const Icon = variant ? variantIcon[variant] : IconAlertCircle;

  return (
    <div className={clsx(styles.root, styles[variant])}>
      <div className={styles.content}>
        <div className={styles.icon}>
          <Icon />
        </div>
        <h5 className={styles.text}>{text}</h5>
      </div>
      {actionText && (
        <div className={styles.action}>
          <Button variant="outlined" color="inherit" onClick={actionClick}>
            {actionText}
          </Button>
        </div>
      )}
    </div>
  );
};

export default BasicAlert;
