import { useState, useEffect } from 'react';
import { Chip, Grid, InputAdornment, FormControlLabel, Switch } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import { IconAlertTriangle } from '@tabler/icons';

import { useAuth } from '#root/hooks';
import { roundDecimal } from '#root/utils';
import { GuveryTextField } from '#root/components';
import { NuevoSolIcon, DolarIcon } from '#root/icons';
import styles from './methods.module.scss';

const currencyIcon = { SOLES: <NuevoSolIcon />, DOLARES: <DolarIcon /> };

const currencySymbol = { SOLES: 'S/.', DOLARES: 'US$' };

const RadioBankCheck = ({ disabled, item, value, onChange }) => {
  const itemName = item.qr ? item.qr.nombre : item.nombre;
  const itemLogo = item.qr ? item.qr.img_logo : item.img_logo;
  return (
    <div className="radio">
      <label className="radio-wrapper">
        <input
          disabled={disabled}
          type="radio"
          className={styles.radioInput}
          value={itemName}
          checked={value === itemName}
          onChange={onChange(item)}
        />
        <span className={clsx(styles.radioTile, disabled && styles.disabled)}>
          <span className={styles.radioIcon}>
            <img src={itemLogo} alt={itemName} />
          </span>
          <span className={styles.radioLabel}>{itemName}</span>
        </span>
      </label>
    </div>
  );
};

const RadioCurrencyCheck = ({ disabled, small, item, value, onChange }) => {
  return (
    <div className="radio">
      <label className="radio-wrapper">
        <input
          disabled={disabled}
          type="radio"
          className={styles.radioInput}
          value={item.currency}
          checked={value === item.currency}
          onChange={onChange(item.currency)}
        />
        <span
          className={clsx(
            styles.radioTile,
            disabled && styles.disabled,
            small && styles.small,
          )}>
          <span className={styles.radioIcon}>{currencyIcon[item.currency]}</span>
          <span className={styles.radioLabel}>{item.currency}</span>
        </span>
      </label>
    </div>
  );
};

const RadioPaymentType = (props) => {
  const { disabled, name, hint, checked, value, onChange } = props;
  return (
    <div className="radio">
      <label className="radio-wrapper">
        <input
          disabled={disabled}
          type="radio"
          className={styles.radioInput}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        <span className={clsx(styles.radioTile, disabled && styles.disabled)}>
          <span className={styles.radioIcon}>{name}</span>
          <span className={styles.radioLabel}>{hint}</span>
        </span>
      </label>
    </div>
  );
};

const RadioBox = (props) => {
  const { disabled, name, hint, checked, value, onChange } = props;
  return (
    <div className="radio">
      <label className="radio-wrapper">
        <input
          disabled={disabled}
          type="radio"
          className={styles.radioInput}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        <span className={clsx(styles.radioTile, disabled && styles.disabled)}>
          <span className={styles.radioIcon}>{name}</span>
          <span className={styles.radioLabel}>{hint}</span>
        </span>
      </label>
    </div>
  );
};

const Methods = (props) => {
  const {
    allowZipago = false,
    allowConvert,
    allowCredits,
    disabledCurrencies,
    disabledPrice,
    defaultAmount,
    sourceTca,
    amount,
    currency,
    // useCredits,
    methodsHelper,
    banks,
    currencies,
    zipago,
    showCredits,
    setPaymentData,
    activeNextStep,
  } = props;
  const { usuario, negocio } = useAuth();
  const userBalance = showCredits ? Math.abs(Number(usuario.saldo)) : Number(usuario.saldo);
  const validBalance = usuario.saldo_est === 1;
  const isNegativeBalance = (validBalance && Number(usuario.saldo) < 0);
  const finalAmount = (showCredits && isNegativeBalance) ? userBalance : amount;
  const finalCurrency = showCredits && validBalance ? 'DOLARES' : currency;
  const exchangeRate: number = sourceTca ? Number(sourceTca) : Number(negocio?.tca_venta);

  const [isFirstRender, setFirstRender] = useState(true);
  // Radio Button && Check Box && Switch
  const [useCredits, setUseCredits] = useState(false);
  const [paymentType, setPaymentType] = useState(allowZipago ? '' : '0');
  const [bank, setBank] = useState('');
  const [zipagoName, setZipagoName] = useState('');
  const [checked, setChecked] = useState(finalCurrency);
  //
  const [zipagoData, setZipagoData] = useState(null);
  const [zipagoHint, setZipagoHint] = useState(null);
  const [zipagoHint2, setZipagoHint2] = useState(null);
  const [amountInput, setAmountInput] = useState(Number(finalAmount));
  const currenciesFilter = currencies.filter((item) => item.is_active === true);
  const [creditSpend, setCreditSpend] = useState(0);
  const [lacksCredit, setLacksCredit] = useState(false);
  const [disableSelection, setDisableSelection] = useState({
    bank: false,
    currency: false,
    amount: false,
    zipago: false,
  });

  // Detects if the user balance is negative
  const isWalletNegative = Math.sign(Number(usuario.saldo)) === -1 ? true : false;

  const handleUserCredits = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseCredits(event.target.checked);
  };

  const handlePaymentTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentType(event.target.value);
    setZipagoHint2(null);
  };

  const handleZiPagoChange = (event: React.ChangeEvent<HTMLInputElement>, data) => {
    setZipagoName(event.target.value);
    setZipagoData(data);
  };

  const handleReset = () => {
    setPaymentType(allowZipago ? '' : '0');
    setBank('');
    setZipagoName('');
    setZipagoData(null);
    setZipagoHint(null);
    setChecked(finalCurrency);
    setAmountInput(Number(finalAmount));
    setPaymentData({
      currency: finalCurrency,
      amount: Number(finalAmount),
      bank: null,
      zipago: null,
    });
  };

  const handleBankChange = (item) => (e) => {
    setBank(e.target.value);
    setPaymentData({ bank: item });
  };

  const handleCurrencyChange = (item) => (e) => {
    setChecked(e.target.value);
    setPaymentData({ currency: item });
  };

  const handleAmountChange = (e) => {
    setAmountInput(e.target.value);
    setPaymentData({ amount: e.target.value });
  };

  const validateData = () => {
    if (useCredits && !lacksCredit) {
      activeNextStep(true);
      return;
    }
    if (!amountInput) {
      activeNextStep(false);
      return;
    }
    if (!zipagoName && !bank) {
      activeNextStep(false);
      return;
    }
    if (amountInput < 1) {
      activeNextStep(false);
      return;
    }
    activeNextStep(true);
  };

  const handleCurrencyExchange = () => {
    if (isWalletNegative && showCredits && checked === 'DOLARES') {
      const newAmount = (Number(amountInput) / exchangeRate).toFixed(2);
      setAmountInput(Number(newAmount));
      setPaymentData({ amount: newAmount });
    }
    if (isWalletNegative && showCredits && checked === 'SOLES') {
      const newAmount = (Number(amountInput) * exchangeRate).toFixed(2);
      setAmountInput(Number(newAmount));
      setPaymentData({ amount: newAmount });
    }
  };

  const handlePriceExchange = () => {
    if (checked === 'DOLARES') {
      const newAmount = (Number(amountInput) / exchangeRate).toFixed(2);
      setAmountInput(Number(newAmount));
      setPaymentData({ amount: newAmount });
    }
    if (checked === 'SOLES') {
      const newAmount = (Number(amountInput) * exchangeRate).toFixed(2);
      setAmountInput(Number(newAmount));
      setPaymentData({ amount: newAmount });
    }
  };

  const managePriceWithCredits = () => {
    if (isFirstRender) {
      return;
    }
    if (!useCredits) {
      setDisableSelection({
        bank: false,
        currency: false,
        amount: false,
        zipago: false,
      });
      setLacksCredit(false);
      setCreditSpend(0);
      setAmountInput(defaultAmount);
      setPaymentData({ amount: defaultAmount, useCredits: false, amountCredits: 0 });
      return;
    }
    if (userBalance >= defaultAmount) {
      setDisableSelection({
        bank: true,
        currency: true,
        amount: true,
        zipago: true,
      });
      setCreditSpend(defaultAmount);
      setBank('');
      setAmountInput(0);
      setChecked('DOLARES');
      setPaymentData({
        currency: 'DOLARES',
        amount: 0,
        bank: '',
        useCredits: true,
        amountCredits: defaultAmount,
      });
    }
    if (userBalance < defaultAmount) {
      const newAmount = defaultAmount - userBalance;
      const validAmount = roundDecimal(newAmount);
      setLacksCredit(true);
      setDisableSelection({
        bank: false,
        currency: false,
        amount: false,
        zipago: false,
      });
      setCreditSpend(userBalance);
      setAmountInput(validAmount);
      setPaymentData({ amount: validAmount, useCredits: true, amountCredits: userBalance });
    }
  };

  const manageZipago = () => {
    const oldAmount = amountInput;
    const feeRate = zipagoData.fee_rates;
    let feeAmount = 0;
    let feeMin = 0;
    let feeMax = 0;
    if (useCredits && userBalance >= defaultAmount) {
      setZipagoHint(null);
      return;
    }
    if (oldAmount < zipagoData.rates.min || oldAmount > zipagoData.rates.max) {
      setZipagoHint(<Alert severity="error">No se puede realizar el pago por ZiPago</Alert>);
      setZipagoHint2(null);
      activeNextStep(false);
      setPaymentData({ zipago: null });
      return;
    }
    feeRate.forEach(function (item, index) {
      if (oldAmount > item.min && oldAmount <= item.max) {
        feeAmount = item.fee;
      }
      if (item.fee > feeMax) {
        feeMax = item.fee;
      }
      if (feeMin === 0) {
        feeMin = item.fee;
      }
      if (item.fee < feeMin) {
        feeMin = item.fee;
      }
    });
    if (feeAmount === 0) {
      setZipagoHint(<Alert severity="error">No se puede realizar el pago por ZiPago</Alert>);
      setZipagoHint2(null);
      setPaymentData({ zipago: null });
      activeNextStep(false);
      return;
    }
    setZipagoHint(
      <span className={styles.helpText} style={{ opacity: useCredits ? 0.4 : 1 }}>
        * Pagos en dólares (de ${zipagoData?.rates?.min} a ${zipagoData?.rates?.max} max) con
        una comisión de ${feeMin} a ${feeMax}.<br />
        ** La comisión ZiPago es asumida por el usuario y NO es reembolsable.
      </span>,
    );
    setZipagoHint2(
      <span style={{ display: 'block', textAlign: 'end', marginRight: 16 }}>
        comisión ZiPago: <span className="guv-semibold">${feeAmount}</span>
      </span>,
    );
    setPaymentData({
      zipago: {
        active: true,
        amount: feeAmount,
        name: zipagoData?.name,
        instructions: zipagoData?.details?.instructions,
      },
    });
  };

  useEffect(() => {
    validateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountInput, bank, zipagoName]);

  useEffect(() => {
    activeNextStep(false);
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFirstRender(false);
    if (!isFirstRender) {
      handleCurrencyExchange();
      if (allowConvert) {
        handlePriceExchange();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => {
    if (zipagoName) {
      manageZipago();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipagoData, amountInput, bank]);

  useEffect(() => {
    if (allowCredits) {
      managePriceWithCredits();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCredits]);

  return (
    <div className={styles.root}>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <h3 className="guv-bold">¿Cómo vas a pagar?</h3>
        </Grid>
        {allowCredits && userBalance > 0 && (
          <>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch color="primary" checked={useCredits} onChange={handleUserCredits} />
                }
                label={
                  <span>
                    <span>Usar mi saldo </span>
                    <span>($ {userBalance})</span>
                  </span>
                }
              />
            </Grid>
            {useCredits && (
              <Grid item xs={12}>
                <div className={clsx(styles.creditHint, styles.creditSummary)}>
                  <div>
                    <p>
                      Se descontará{' '}
                      <strong className={styles.amount}>$ {roundDecimal(creditSpend)}</strong>{' '}
                      de la billetera.
                    </p>
                    <p>
                      {lacksCredit && (
                        <span> Elige un metodo para agregar el importe adicional.</span>
                      )}
                    </p>
                  </div>
                </div>
              </Grid>
            )}
            {!validBalance && (
              <Grid item xs={12}>
                <div className={clsx(styles.creditHint, styles.creditWarning)}>
                  <IconAlertTriangle />
                  <span className={styles.text}>
                    Saldo en proceso de validación, no disponible temporalmente.
                  </span>
                </div>
              </Grid>
            )}
          </>
        )}
        {!paymentType && (
          <>
            <Grid item xs={12} md={6}>
              <RadioPaymentType
                disabled={disableSelection.bank}
                name={
                  <span className="guv-font-size-5 guv-semibold">Transferencia bancaria</span>
                }
                checked={paymentType === '0'}
                value={'0'}
                onChange={handlePaymentTypeChange}
              />
            </Grid>
            {allowZipago && (
              <Grid item xs={12} md={6}>
                <RadioPaymentType
                  disabled={disableSelection.zipago}
                  name={<span className="guv-font-size-5 guv-semibold">ZiPago 💳<br />(Tarjeta de Crédito)</span>}
                  checked={paymentType === '1'}
                  value={'1'}
                  onChange={handlePaymentTypeChange}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
        {paymentType === '0' && (
          <>
            <Grid item xs={12}>
              <div
                className="guv-semibold"
                style={{ opacity: disableSelection.bank ? 0.4 : 1 }}>
                <Chip
                  label={<h5 className="guv-semibold">Transferencia</h5>}
                  onDelete={handleReset}
                />
              </div>
            </Grid>
            {banks.map((item, index) => (
              <Grid key={index} item xs={6} sm={4} md={3} className={styles.bankGroup}>
                <RadioBankCheck
                  disabled={disableSelection.bank}
                  item={item}
                  value={bank}
                  onChange={handleBankChange}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <span className={styles.helpText} style={{ opacity: useCredits ? 0.4 : 1 }}>
                {methodsHelper}
              </span>
            </Grid>
          </>
        )}
        {paymentType === '1' && (
          <>
            <Grid item xs={12}>
              <div
                className="guv-semibold"
                style={{ opacity: disableSelection.zipago ? 0.4 : 1 }}>
                <Chip label={<h5 className="guv-semibold">ZiPago (Tarjeta de crédito)</h5>} onDelete={handleReset} />
              </div>
            </Grid>
            {zipago?.banks.map((item, index) => (
              <Grid key={index} item xs={6} sm={4} md={3} className={styles.bankGroup}>
                <RadioBox
                  disabled={disableSelection.zipago}
                  name={<img src={item?.details?.img_logo} alt={item?.name} />}
                  hint={item?.name}
                  checked={zipagoName === item?.name}
                  value={item?.name}
                  onChange={(e) => handleZiPagoChange(e, item)}
                />
              </Grid>
            ))}
            {zipagoHint && (
              <Grid item xs={12}>
                <span
                  className={styles.helpText}
                  style={{ opacity: disableSelection.zipago ? 0.4 : 1 }}>
                  {zipagoHint}
                </span>
              </Grid>
            )}
          </>
        )}
      </Grid>
      {(bank || zipagoName) && (
        <>
          <div className={styles.amountTitle}>
            <h3 style={{ opacity: disableSelection.amount ? 0.4 : 1 }}>Monto y moneda</h3>
          </div>
          <div className={clsx(styles.groupPrice, currenciesFilter.length === 1 && styles.one)}>
            <div className={styles.currency}>
              {currenciesFilter.map((item, index) => (
                <RadioCurrencyCheck
                  key={index}
                  small
                  disabled={disabledCurrencies || disableSelection.currency || zipagoName}
                  item={item}
                  value={checked}
                  onChange={handleCurrencyChange}
                />
              ))}
            </div>
            <div className={styles.input}>
              <GuveryTextField
                disabled={disabledPrice || disableSelection.amount}
                id="amount"
                type="number"
                label="Importe"
                value={amountInput}
                onChange={handleAmountChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currencySymbol[checked]}</InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          {checked === 'SOLES' && (
            <div className={styles.cambio}>
              <span className={styles.bold}>
                US$ {roundDecimal(amountInput / exchangeRate)}{' '}
              </span>
              <span>(TC{roundDecimal(exchangeRate)})</span>
            </div>
          )}
          {zipagoHint2 && (
            <Grid item xs={12}>
              <span
                className={styles.helpText}
                style={{ opacity: disableSelection.zipago ? 0.4 : 1 }}>
                {zipagoHint2}
              </span>
            </Grid>
          )}
        </>
      )}
    </div>
  );
};

export default Methods;
