import { Button, TextField, Grid, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { shopperService } from '#root/services';
import { GuveryDialog } from '#root/ui-components';
import styles from './refund.module.scss';

interface RefundDialogProps {
  open: boolean;
  onClose: () => void;
  refundId?: any;
  banks?: any;
  createMessage?: any;
  handleLoading?: any;
}

const currencyType = [
  {
    value: 'SOLES',
    label: 'Soles',
  },
  {
    value: 'DOLARES',
    label: 'Dolares',
  },
];

const RefundDialog = (props: RefundDialogProps) => {
  const { open = false, onClose, refundId, banks, createMessage, handleLoading } = props;

  const formik = useFormik({
    initialValues: {
      bank: '',
      currency: '',
      account: '',
      name: '',
    },
    validationSchema: Yup.object().shape({
      bank: Yup.string().required('Campo requerido'),
      currency: Yup.string().required('Campo requerido'),
      account: Yup.string().min(8, '¡Demasiado corto!').required('Campo requerido'),
      name: Yup.string().min(4, '¡Demasiado corto!').required('Campo requerido'),
    }),
    onSubmit: (values) => {
      // console.log(values);
      fetchTrading(values);
      formik.setSubmitting(false);
    },
  });

  const fetchTrading = async (arg) => {
    const body = {
      refund_id: refundId,
      bank: arg.bank,
      currency: arg.currency,
      account_number: arg.account.replace(/-/g, '').replace(/ /g, ''),
      account_holder: arg.name,
    };
    const res = await shopperService.dealRefund(body);
    if (res.error) {
      createMessage.error('No se pudo completar la acción');
      return;
    }
    const message = res.message ? res.message : 'No se pudo completar la acción';
    createMessage.success(message);
    handleLoading();
    onClose();
  };

  return (
    <GuveryDialog
      open={open}
      onClose={onClose}
      disableActions
      title="Solicitar Reembolso"
      closeText="Cerrar">
      <div className={styles.dialogContent}>
        <p className={styles.text}>
          Antes de solicitar el reembolso de tu saldo, recuerda que mantener un saldo a favor en
          Guvery te ayudará a pagar más rápido tus acuerdos.
        </p>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Banco"
                select
                id="bank"
                name="bank"
                value={formik.values.bank}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.bank && formik.errors.bank)}
                helperText={formik.errors.bank}>
                {banks?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Moneda de la cuenta"
                select
                id="currency"
                name="currency"
                value={formik.values.currency}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.currency && formik.errors.currency)}
                helperText={formik.errors.currency}>
                {currencyType?.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Numero de cuenta"
                id="account"
                value={formik.values.account}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.account && formik.errors.account)}
                helperText={formik.errors.account}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Titular de la cuenta"
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.name && formik.errors.name)}
                helperText={formik.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                disableElevation
                disabled={formik.isSubmitting}
                type="submit"
                variant="contained"
                color="primary">
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </GuveryDialog>
  );
};

export default RefundDialog;
