import { IconPackage, IconCalendarEvent, IconBasket, IconMapPin } from '@tabler/icons';

import { GuverySpace } from '#root/ui-components';
import styles from './offerInfo.module.scss';

const OfferInfo = (props) => {
  const { offer } = props;
  return (
    <div className={styles.root}>
      <GuverySpace size={16} className={styles.list}>
        <div className={styles.item}>
          <div className={styles.icon}>
            <IconPackage size={32} stroke={1.5} />
          </div>
          <div className={styles.info}>
            <p>Empaque</p>
            <h6>{offer?.empaque}</h6>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.icon}>
            <IconCalendarEvent size={32} stroke={1.5} />
          </div>
          <div className={styles.info}>
            <p>Entrega</p>
            <h6>{offer?.fixed_delivery_date}</h6>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.icon}>
            <IconBasket size={32} stroke={1.5} />
          </div>
          <div className={styles.info}>
            <p>Cantidad</p>
            <h6>{offer?.can}</h6>
          </div>
        </div>
      </GuverySpace>
      <div className={styles.place}>
        <div className={styles.item}>
          <div className={styles.icon}>
            <IconMapPin size={32} stroke={1.5} />
          </div>
          <div className={styles.info}>
            <p>Lugares de entrega</p>
            <h6>{offer?.lugent}</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferInfo;
