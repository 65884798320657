import { combineReducers } from 'redux';

// reducer import
// import customizationReducer from './customizationReducer';
import notificationReducer from './notificationReducer';

const reducer = combineReducers({
  // customization: customizationReducer,
  notification: notificationReducer,
});

export type RootState = ReturnType<typeof reducer>;

export default reducer;
