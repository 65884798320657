import { useState } from 'react';
import { GuveryPaper } from '#root/ui-components/cards';
import { RankingProgress } from '#root/components';
import { getMinMax } from '#root/utils';
import { RatingCriteriaDialog } from '#root/components/dialogs';
import './styles.scss';

const DashboardSummary = (props) => {
  const { travelerScore } = props;
  const arrOrderNum = travelerScore?.score_level_breaks?.map(({ order }) => order);
  const [min, max] = getMinMax(arrOrderNum);
  const orderMin = travelerScore?.score_level_breaks?.find(
    ({ order }) => order === min,
  )?.break_min;
  const orderMax = travelerScore?.score_level_breaks?.find(
    ({ order }) => order === max,
  )?.break_max;
  const travelerLevel = travelerScore?.score_level_breaks?.find(
    ({ order }) => order === travelerScore?.current_score_level_details?.order,
  );
  const levelsMinPoints = travelerScore?.score_level_breaks?.map(({ break_min }) => break_min);

  const [openDialog, setOpenDialog] = useState(false);

  return (
    <GuveryPaper
      disableElevation
      disableHover
      style={{ borderColor: 'rgba(130, 127, 251, 0.5)', borderWidth: 2 }}>
      <div className="main">
        <div className="content">
          <div className="announce">
            <strong style={{ color: 'var(--color-blue)' }}>- Nuevo: Score y Nivel -</strong>
            &nbsp; En Guvery nos esforzamos por mantener una comunidad de viajeros
            comprometidos. Medimos el desempeño de forma objetiva para determinar tu nivel como
            viajero.
          </div>
          <div>
            <div className="scoring">
              <h2>{travelerScore?.current_score} pts</h2>
              <h4>
                {travelerScore?.current_score_level_details?.title}{' '}
                {travelerScore?.current_score_level_details?.icon}
              </h4>
            </div>
            <div className="progress">
              <RankingProgress
                score={travelerScore?.current_score}
                totalMin={orderMin}
                totalMax={orderMax}
                levelMin={travelerLevel?.break_min}
                levelMax={travelerLevel?.break_max}
                listMin={levelsMinPoints}
                hideExtraText
                minHeight={30}
              />
            </div>
          </div>
        </div>
        <div className="footer">
          <p>
            <a onClick={() => setOpenDialog(true)}>Revisar todos los niveles y sus beneficios.</a>
            <RatingCriteriaDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        currentScore={travelerScore?.current_score}
        levelsInfo={travelerScore?.score_level_breaks}
        travelerOrder={travelerScore?.current_score_level_details?.order}
      />
          </p>
        </div>
      </div>
    </GuveryPaper>
  );
};

export default DashboardSummary;
