import configService from './config';
import fetchApi from './fetchApi';
import guveryFetchApi from './guveryFetchApi';
import * as METHODS from './methods';
import TripDTO from '#root/models/TripDTO';

const guveryApi = configService.apiGuvery;

const list = async () => {
  const { response } = await guveryFetchApi({
    url: guveryApi + '/trips',
    options: METHODS.GET(),
    message: {
      success: 'Viajes obtenidos con exito.',
      error: 'No se puedo obtener los viajes.',
    },
  });

  return { ...response };
};

const select = async (tripId: string | number) => {
  const { response } = await guveryFetchApi({
    url: guveryApi + '/trips/select',
    options: METHODS.POST({
      trip_id: tripId,
    }),
    message: {
      success: 'Viaje seleccionado correctamente.',
      error: 'El viaje no existe o debe seleccionar un viaje vigente.',
    },
  });

  return { ...response };
};

const getCurrent = async () => {
  const { response } = await fetchApi({
    url: guveryApi + '/trips/current',
    options: METHODS.GET(),
    dataType: {},
    message: {
      success: 'Viaje obtenido con exito.',
      error: 'No se puedo obtener el viaje.',
    },
  });

  return { ...response };
};

const create = async (arg: TripDTO) => {
  const { response } = await fetchApi({
    url: guveryApi + '/trips/create',
    options: METHODS.POST({
      name: arg.nombre,
      departure_country: arg.departure_country,
      destination_country: arg.destination_country,
      city: arg.city,
      zipcode: arg.zipcode,
      accommodation_type: arg.accommodation_type,
      adults_qty: arg.adults_qty,
      arrive_date: arg.arrive_date,
      max_reception_date: arg.max_reception_date,
      flag_receive: arg.flag_receive,
      flag_pickup: arg.flag_pickup,
      flag_no_travel: arg.flag_no_travel,
      delivery_place: arg.lugar_entrega,
      delivery_date: arg.fecha_entrega,
    }),
    dataType: {},
    message: {
      success: 'Viaje creado.',
      error: 'No se pudo crear el viaje.',
    },
  });

  return { ...response };
};

const tripService = {
  list,
  select,
  getCurrent,
  create,
};

export default tripService;
