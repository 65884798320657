import { useState } from 'react';

import { withStyles } from '@material-ui/core/styles';

import { useDialog } from '#root/hooks';
import { ChatDrawer } from '#root/components';
import {
  Tooltip,
  Avatar,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Button
} from '@material-ui/core';
import {
  IconPackage,
  IconMessageCircle,
  IconCalendarEvent,
  IconMapPin,
  IconArrowDown,
  IconStar,
  IconBrandWhatsapp,
} from '@tabler/icons';

import DealDTO, { DealStatus } from '#root/models/DealDTO';
import styles from './shopperInfo.module.scss';

const Accordion = withStyles({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgb(243, 244, 247)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 54,
    '&$expanded': {
      minHeight: 54,
    },
  },
  content: {
    margin: '8px 0',
    '&$expanded': {
      margin: '8px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 16,
  },
}))(MuiAccordionDetails);

interface ShopperInfoProps {
  deal: DealDTO;
  imOwner?: boolean;
}

const ShopperInfo = (props: ShopperInfoProps) => {
  const { deal, imOwner } = props;

  const shopper = deal?.offer?.order?.user;

  const isCreated = deal?.est_n === DealStatus.created;
  const isPaidCom = deal?.est_n === DealStatus.paying;
  const isPaid = deal?.est_n === DealStatus.paid;
  const isBuyed = deal?.est_n === DealStatus.buyed;
  const isRecivedTraveler = deal?.est_n === DealStatus.recivedTraveler;
  const isArrived = deal?.est_n === DealStatus.arrived;

  const isProvincia = deal?.offer?.order?.flag_envio === 1 ? true : false;

  const allowChat =
    isCreated || isPaidCom || isPaid || isBuyed || isRecivedTraveler || isArrived;

  const chatDrawer = useDialog();

  const inTransit = (isPaid || isBuyed || isRecivedTraveler || isArrived) ? true : false;
  const hasWhatsapp = (deal?.offer.order.user.wsp) ? true : false;
  const whatsappNumber = deal?.offer.order.user.wsp;

  const [expanded, setExpanded] = useState('');

  const CustomExpandIcon = () => {
    return <Button variant='outlined'>Detalles y Contacto</Button>;
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion expanded={expanded === `panel_${deal.ida}`} onChange={handleChange(`panel_${deal.ida}`)}>
      <AccordionSummary expandIcon={expanded === `panel_${deal.ida}` ? <IconArrowDown /> : <CustomExpandIcon />} aria-controls="panel-offer">
        <div className={styles.dealInfo}>
          <div className={styles.user}>
            <Avatar className={styles.avatar} src={shopper?.picture_path}>
              {shopper?.one_firstname?.charAt(0)}
            </Avatar>
            <div className={styles.userInfo}>
              <h6 className={styles.name}>{shopper?.mask_fullname}</h6>
              {shopper?.calcom && (
                <Tooltip arrow title="Puntaje del comprador" placement="top">
                  <div className={styles.userScore}>
                    <IconStar />
                    <span>{shopper?.calcom}</span>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.dealExtra}>
          <div className={styles.list}>
            <div className={styles.stats}>
              <div className={styles.name}>
                <IconMapPin size={22} />
                <p>Lugar</p>
              </div>
              <h5 className={styles.value}>{isProvincia ? 'MailBoxes ETC' : deal?.lugent}</h5>
            </div>
            <div className={styles.stats}>
              <div className={styles.name}>
                <IconCalendarEvent size={22} />
                <p>Entrega</p>
              </div>
              <h5 className={styles.value}>{deal?.fixed_delivery_date}</h5>
            </div>
            <div className={styles.stats}>
              <div className={styles.name}>
                <IconPackage size={22} />
                <p>Empaque</p>
              </div>
              <h5 className={styles.value}>{deal?.empaque}</h5>
            </div>
          </div>
          <div className={styles.actions}>
            {imOwner && allowChat && (
              <>
                <Button
                  fullWidth
                  component="a"
                  onClick={chatDrawer.openDialog}
                  variant="contained"
                  color="secondary"
                  disableElevation
                  startIcon={<IconMessageCircle />}>
                  Ver chat
                </Button>
                <ChatDrawer
                  extraTitle={`Acuerdo #${deal?.ida}`}
                  offerId={deal?.ido}
                  open={chatDrawer.open}
                  onClose={chatDrawer.closeDialog}
                />
                {imOwner && inTransit && hasWhatsapp && (
                  <Button
                    fullWidth
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://api.whatsapp.com/send?phone=${whatsappNumber}&text=Hola!`}
                    variant="outlined"
                    color="secondary"
                    startIcon={<IconBrandWhatsapp />}>
                    Whatsapp
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default ShopperInfo;
