import { TextField } from '@material-ui/core';

const validateErrors = (element, _index, _array) => {
  if (element.required) {
    return element === '' ? true : false;
  }

  return false;
};

interface GuveryTextFieldProps {
  id: string;
  label?: any;
  value?: any;
  onChange?: any;
  onBlur?: any;
  rules?: any;
  error?: boolean;
  children?: any;
  type?: string;
  inputMode?: any;
  required?: boolean;
  disabled?: boolean;
  InputProps?: any;
  inputProps?: any;
  select?: boolean;
  helperText?: string;
  multiline?: boolean;
  rows?: number;
  autoComplete?: boolean,
}

const GuveryTextField = (props: GuveryTextFieldProps) => {
  const {
    id,
    label,
    value,
    onChange,
    onBlur,
    rules,
    error,
    children,
    type = 'text',
    inputMode,
    required = false,
    disabled = false,
    InputProps,
    inputProps,
    select = false,
    helperText,
    multiline,
    rows,
    autoComplete = false,
  } = props;

  const isError = rules && rules.some(validateErrors);

  return (
    <TextField
      fullWidth
      multiline={multiline}
      required={required}
      disabled={disabled}
      error={error ? error : isError}
      type={type}
      inputMode={inputMode}
      id={id}
      name={id}
      label={label}
      variant="outlined"
      size="medium"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      rows={rows}
      autoComplete={(!autoComplete) ? "off" : ""}
      InputProps={InputProps}
      inputProps={inputProps}
      select={select}
      helperText={helperText}>
      {children}
    </TextField>
  );
};

export default GuveryTextField;
