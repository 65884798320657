import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { IconPlane } from '@tabler/icons';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

import styles from './arrivalTime.module.scss';

const formatTimeNoYear = (date: string | Date) => {
  let time: string | null;
  time = date ? format(new Date(date), "dd 'de' MMM", { locale: es }) : '---';

  return time;
};

interface ArrivalTimeProps {
  dateCreate: string | null;
  dateExpire: string | null;
  originalRemainingDays: number | null;
  remainingDays: number | null;
}

const ArrivalTime = (props: ArrivalTimeProps) => {
  const { dateCreate, dateExpire, originalRemainingDays, remainingDays } = props;
  console.log(originalRemainingDays, remainingDays);
  const createDate = dateCreate
    ? new Date(dateCreate.replace(/ /g, 'T'))
    : new Date(2015, 0, 1);
  const expireDate = dateExpire
    ? new Date(dateExpire.replace(/ /g, 'T'))
    : new Date(2016, 0, 1);
  
  const daysAdvancedInt = (remainingDays && remainingDays > 0) ? remainingDays : originalRemainingDays;
  const daysLeftInt = (daysAdvancedInt === originalRemainingDays) ? originalRemainingDays : originalRemainingDays - daysAdvancedInt;

  const normalise = (Number(daysLeftInt) * 100) / Number(originalRemainingDays);

  const fixedTimeLeftText = (remainingDays && remainingDays > 0) ? `Falta ${remainingDays} días` : null;

  const topPlane = (fixedTimeLeftText) ? '61%' : '96%';

  return (
    <div className={styles.root}>
      <div className={styles.dates}>
        <span>{formatTimeNoYear(createDate)}</span>
        <span>{formatTimeNoYear(expireDate)}</span>
      </div>
      <LinearProgress variant="determinate" value={normalise} color="primary" />
      {fixedTimeLeftText && (
        <div className={styles.hint}>
        <span>{fixedTimeLeftText}</span>
      </div>
      )}
      <div className={styles.plane} style={{left: `${normalise - 5}%`, top: topPlane}}>
        <IconPlane size={30} />
      </div>
    </div>
  );
};

export default ArrivalTime;
