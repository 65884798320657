import { GuveryLayout } from '#root/ui-components';

const NotFound = () => {
  return (
    <GuveryLayout>
      <div style={{ textAlign: 'center' }}>
        <h1 style={{ fontSize: '64px' }}>404</h1>
        <h4>Página no encontrada</h4>
      </div>
    </GuveryLayout>
  );
};

export default NotFound;
