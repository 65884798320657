import { MouseEvent, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, TextField, MenuItem, Grid } from '@material-ui/core';

import { useAuth } from '#root/hooks';
import { userService } from '#root/services';
import { GuveryTextField } from '#root/components';
import { GuveryDialog } from '#root/ui-components';

interface DialogNewAddressProps {
  createMessage: any;
  fetchUserAddress?: Function;
  open: boolean;
  onClose: (e?: MouseEvent<HTMLElement>) => void;
  departmentArray?: any[];
}

function DialogNewAddress(props: DialogNewAddressProps) {
  const {
    createMessage,
    fetchUserAddress,
    open = false,
    onClose,
    departmentArray = [],
  } = props;
  const { usuario } = useAuth();
  const [btnDisable, setBtnDisable] = useState(false);
  const initialNewAdress = {
    name: usuario.nom,
    lastName: usuario.ape,
    phone: '',
    address: '',
    address2: '',
    district: '',
    province: '',
    department: '',
  };
  const [newAddress, setNewAddress] = useState(initialNewAdress);
  const [isPrimary, setIsPrimary] = useState(false);

  const handleInputChange = (e) => {
    setNewAddress({
      ...newAddress,
      [e.target.id]: e.target.value,
    });
  };

  const handleSelectChange = (e) => {
    setNewAddress({
      ...newAddress,
      department: e.target.value,
    });
  };

  const handleCheckboxChange = (e) => {
    setIsPrimary(e.target.checked);
  };

  const validateNewAddress = () => {
    const { name, lastName, phone, address, district, province, department } = newAddress;
    if (!name || !lastName || !phone || !address || !district || !province || !department) {
      setBtnDisable(true);
      return;
    }
    setBtnDisable(false);
  };

  const handleNewAddress = () => {
    const body = {
      name: newAddress.name,
      lastName: newAddress.lastName,
      phone: newAddress.phone,
      address: newAddress.address,
      address2: newAddress.address2,
      district: newAddress.district,
      province: newAddress.province,
      department: newAddress.department,
      isPrimary: isPrimary ? '1' : '0',
    };
    fetchNewAddress(body);
  };

  const fetchNewAddress = async (arg) => {
    setBtnDisable(true);
    const res = await userService.addAddress(arg);
    if (!res.info.error) {
      if (res.data === 1) {
        fetchUserAddress();
        createMessage.success(res.info.message);
      } else {
        createMessage.error(res.info.message);
      }
    } else {
      createMessage.error(res.info.message);
    }
    setNewAddress(initialNewAdress);
    setIsPrimary(false);
    setBtnDisable(false);
    onClose();
  };

  useEffect(() => {
    validateNewAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAddress]);

  return (
    <GuveryDialog
      open={open}
      onClose={onClose}
      onConfirm={handleNewAddress}
      onConfirmDisable={btnDisable}
      title="Agregar una nueva dirección">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <GuveryTextField
            required
            id="name"
            label="Nombre"
            type="text"
            value={newAddress.name}
            onChange={handleInputChange}
            rules={[{ required: true, message: 'Por favor ingresa tu nombre!' }]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <GuveryTextField
            required
            id="lastName"
            label="Apellido"
            type="text"
            value={newAddress.lastName}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <GuveryTextField
            required
            id="phone"
            label="Teléfono"
            type="text"
            value={newAddress.phone}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <GuveryTextField
            required
            id="address"
            label="Dirección 1"
            type="text"
            value={newAddress.address}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <GuveryTextField
            id="address2"
            label="Dirección 2 (opcional)"
            type="text"
            value={newAddress.address2}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <GuveryTextField
            required
            id="district"
            label="Distrito"
            type="text"
            value={newAddress.district}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <GuveryTextField
            required
            id="province"
            label="Provincia"
            type="text"
            value={newAddress.province}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            select
            fullWidth
            variant="outlined"
            id="department"
            label="Departamento"
            type="text"
            value={newAddress.department}
            onChange={handleSelectChange}>
            {departmentArray.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={isPrimary} onChange={handleCheckboxChange} />}
            label=" Marcar como dirección principal."
          />
        </Grid>
      </Grid>
    </GuveryDialog>
  );
}

export default DialogNewAddress;
