import { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ChatDrawer, TravelerCard } from '#root/components';
import { useDialog } from '#root/hooks';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Grid,
  Button,
} from '@material-ui/core';
import {
  IconPackage,
  IconMessageCircle,
  IconCalendarEvent,
  IconMapPin,
  IconArrowDown,
} from '@tabler/icons';

import styles from './travelerInfo.module.scss';

const Accordion = withStyles({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgb(243, 244, 247)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiAccordionDetails);

const TravelerInfo = (props) => {
  const {
    dealId,
    dealIdo,
    traveler,
    isDelivered = false,
    deliveryPlace,
    deliveryDate,
    packingType,
    setReviewTravelerData, 
    travelerReviewsDialog,
    avoidReviews = false
  } = props;
  const chatDrawer = useDialog();

  const [expanded, setExpanded] = useState('');

  const CustomExpandIcon = () => {
    return <Button variant='outlined'>Detalles y Contacto</Button>;
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion expanded={expanded === `panel_${dealId}`} onChange={handleChange(`panel_${dealId}`)}>
      <AccordionSummary expandIcon={expanded === `panel_${dealId}` ? <IconArrowDown /> : <CustomExpandIcon />} aria-controls="panel-offer">
        <div className={styles.offerSumary}>
        {traveler && <TravelerCard traveler={traveler} setReviewTravelerData={setReviewTravelerData} travelerReviewsDialog={travelerReviewsDialog} avoidReviews={avoidReviews} />}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={styles.userOffer}>
              <div className={styles.content}>
                <div className={styles.statsGroup}>
                  <div className={styles.stats}>
                    <div className={styles.nameKey}>
                      <IconMapPin />
                      <p>Lugar</p>
                    </div>
                    <h5>{deliveryPlace}</h5>
                  </div>
                  <div className={styles.stats}>
                    <div className={styles.nameKey}>
                      <IconCalendarEvent />
                      <p>Entrega</p>
                    </div>
                    <h5>{deliveryDate}</h5>
                  </div>
                  {packingType && (
                    <div className={styles.stats}>
                      <div className={styles.nameKey}>
                        <IconPackage />
                        <p>Empaque</p>
                      </div>
                      <h5 style={{ textTransform: 'capitalize' }}>{packingType}</h5>
                    </div>
                  )}
                </div>
              </div>
              {!isDelivered && (
                <>
                  <div className={styles.offerAction}>
                    <Button
                      fullWidth
                      component="a"
                      onClick={chatDrawer.openDialog}
                      variant="contained"
                      color="secondary"
                      startIcon={<IconMessageCircle />}>
                      Ver chat
                    </Button>
                  </div>
                  <ChatDrawer
                    extraTitle={`Acuerdo #${dealId}`}
                    offerId={dealIdo}
                    open={chatDrawer.open}
                    onClose={chatDrawer.closeDialog}
                  />
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default TravelerInfo;
