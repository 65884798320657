import { FormLabelClassKey, StyleRules } from '@material-ui/core';

const MuiFormLabel: Partial<StyleRules<FormLabelClassKey, {}>> = {
  root: {
    fontWeight: 400,
    fontSize: '1rem',
    '&.Mui-disabled': {
      color: 'rgb(145, 158, 171)',
    },
  },
};

export default MuiFormLabel;
