import { ReactNode, useState } from 'react';

import { useAuth } from '#root/hooks';
import { TravelerNavbar, TravelerSidebar } from '#root/components';
import { GuveryAlert } from '#root/ui-components';
import { Banner } from '#root/ui-components/extended';
import { USER_PROFILE } from '#root/constants/navigation';

import './traveler.scss';

interface TravelerProps {
  children: ReactNode;
}

const Traveler = (props: TravelerProps) => {
  const { children } = props;
  const { usuario } = useAuth();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const activeAlert = usuario.is_active !== 1 || usuario.wsp === null;
  const textAlert = !usuario.wsp
    ? 'Ingresa tu Whatsapp para activar la cuenta.'
    : 'Verifica tu correo para activar tu cuenta.';

  return (
    <div className="traveler-layout">
      <TravelerNavbar
        openMobile={isMobileNavOpen}
        onMobileNavChange={() => setMobileNavOpen(!isMobileNavOpen)}
      />
      <TravelerSidebar
        onMobileClose={() => setMobileNavOpen(!isMobileNavOpen)}
        openMobile={isMobileNavOpen}
        viewOrdersPre={usuario?.traveler_view_orders_pre}
      />
      <div className="traveler-layout__wrapper">
        <div className="traveler-layout__container">
          <main className="traveler-content">
            <Banner />
            {activeAlert && (
              <GuveryAlert
                severity="warning"
                message={textAlert}
                actionText="Activar Cuenta"
                href={USER_PROFILE}
              />
            )}
            {children}
          </main>
        </div>
      </div>
    </div>
  );
};

export default Traveler;
