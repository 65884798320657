import { GuveryDialog } from '#root/ui-components';
import styles from './guveryExpressDialog.module.scss';
import * as VARIABLES from '#root/constants/variables';
import { Button } from '@material-ui/core';
import { IconBrandWhatsapp, IconBolt } from '@tabler/icons';

interface propsInteface {
    open: boolean;
    onClose: () => void;
    title?: string;
    order?: any;
  }

const GuveryExpressDialog = (props: propsInteface) => {
  const defaultTitle = (<p style={{display: 'flex', alignItems: 'center', gap: 3, fontSize: 20}}><IconBolt style={{color: 'var(--color-express-fee)'}} /> Guvery Express</p>);
  const { open = false, onClose, title = defaultTitle, order = null } = props;

  const reloadAfterSubmit = async () => {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  const body = (
    <>
      <p>
        Solicita al equipo de Guvery una cotización exacta de tu pedido para brindarte el <strong>total a pagar y la mejor fecha de entrega disponible</strong>.
      </p>
      <p style={{color: 'var(--color-text-alt-2)'}}>
        Para lograrlo, usamos la información de todos los viajeros activos, sus fechas de entrega, el tiempo de entrega de la tienda y la comisión más justa (basada en miles de pedidos similares procesados).
      </p>
      <p>
        🔒 Siendo Guvery la plataforma más segura y eficiente para traer compras con viajeros, nuestro servicio ⚡️ Express acorta todas las etapas de un proceso regular y te garantiza que obtendrás la mejor fecha de entrega existente.
      </p>
      <p style={{color: 'var(--color-text-alt-3)', fontSize: 'var(--font-size-6)'}}>
      * Solicita una cotización sin compromiso.
      </p>
      <form action={VARIABLES.URL_PHP_PAGE + "comprador/solicitar_express" } method='post' target='_blank'>
        <input type="hidden" name="order_id" value={order.idp} />
        <Button startIcon={<IconBrandWhatsapp />} component="button" type="submit" onClick={reloadAfterSubmit} variant="contained" color="primary" fullWidth>
          Cotizar
        </Button>
      </form>
    </>
  )

  return (
      <GuveryDialog open={open} onClose={onClose} title={title} closeText='Cerrar'>
      <div className={styles.root}>
        <div className={styles.wrap}>{body}</div>
      </div>
    </GuveryDialog>
    )
  ;
};

export default GuveryExpressDialog;
