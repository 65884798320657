import { useEffect } from 'react';
import { Button, TextField, Grid, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { withdrawService } from '#root/services';
import { GuveryDialog } from '#root/ui-components';
import styles from './earningsNewDialog.module.scss';

interface EarningsNewDialogProps {
  open: boolean;
  onClose: () => void;
  earnings?: any;
  nextWithdrawSequence?: number;
  createMessage?: any;
  handleLoading?: any;
}

const banks = [
  {
    value: 'INTERBANK',
    label: 'Interbank',
  },
  {
    value: 'BCP',
    label: 'BCP',
  },
  // {
  //   value: 'SCOTIABANK',
  //   label: 'Scotiabank',
  // },
  {
    value: 'BBVA',
    label: 'BBVA',
  },
];

const bankAccountTypes = [
  {
    value: 'Cuenta Bancaria',
    label: 'Cuenta de ahorro en dólares',
  },
  // {
  //   value: 'Tarjeta de Crédito',
  //   label: 'Tarjeta de Credito',
  // },
];

const EarningsNewDialog = (props: EarningsNewDialogProps) => {
  const { open = false, onClose, nextWithdrawSequence, createMessage, handleLoading } = props;

  const formik = useFormik({
    initialValues: {
      name: '',
      bank: '',
      bank_account_type: '',
      bank_account_number: '',
      bank_account_holder: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().min(4, 'Al menos 4 letras o dejar vacío.'),
      bank: Yup.string().required('Campo requerido'),
      bank_account_type: Yup.string().required('Campo requerido'),
      bank_account_number: Yup.string().min(8, '¡Demasiado corto!').required('Campo requerido'),
      bank_account_holder: Yup.string().required('Campo requerido'),
    }),
    onSubmit: (values) => {
      fetchSaveNewEarnings(values);
      formik.setSubmitting(false);
    },
  });

  const handleClean = () => {
    formik.resetForm({
      values: {
        name: '',
        bank: '',
        bank_account_type: '',
        bank_account_number: '',
        bank_account_holder: '',
      },
    });
  };

  const fetchSaveNewEarnings = async (arg) => {
    if (!arg.bank || !arg.bank_account_number) return;

    let fixed_bank_account_number = arg.bank_account_number;
    if (fixed_bank_account_number) {
        fixed_bank_account_number = fixed_bank_account_number.replace(/-/g, '').replace(/ /g, '');
    }
    const body = {
      name: arg.name,
      bank: arg.bank,
      bank_account_type: arg.bank_account_type,
      bank_account_number: fixed_bank_account_number,
      bank_account_holder: arg.bank_account_holder,
    };

    const res = await withdrawService.saveNew(body);
    if (res.error) {
      createMessage.error(res.message);
      return;
    }
    createMessage.success(res.message);
    handleLoading();
    onClose();
  };

  useEffect(() => {
    if (!open) {
      handleClean();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <GuveryDialog
      open={open}
      onClose={onClose}
      disableActions
      title="Retirar Ganancias"
      closeText="Cerrar">
      <div className={styles.dialogContent}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <p>
                <strong>Importante: </strong>
                Tu cuenta bancaria debe haber sido abierta en Lima Metropolitana, ya que sino, el banco cobrará una comisión por transferencia interplaza y será asumida por el viajero.
              </p>
            </Grid>
            <Grid item xs={12}>
              <h2>Mi Retiro #{nextWithdrawSequence}</h2>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Descripción opcional"
                type="text"
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="* Banco"
                select
                id="bank"
                name="bank"
                value={formik.values.bank}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.bank && formik.errors.bank)}
                helperText={formik.touched.bank && formik.errors.bank}>
                {banks?.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="* Tipo de Cuenta"
                select
                id="bank_account_type"
                name="bank_account_type"
                value={formik.values.bank_account_type}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.bank_account_type && formik.errors.bank_account_type,
                )}
                helperText={
                  formik.touched.bank_account_type && formik.errors.bank_account_type
                }>
                {bankAccountTypes?.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="* Numero de cuenta"
                id="bank_account_number"
                value={formik.values.bank_account_number}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.bank_account_number && formik.errors.bank_account_number,
                )}
                helperText={
                  formik.touched.bank_account_number && formik.errors.bank_account_number
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="* Titular de la cuenta"
                type="text"
                id="bank_account_holder"
                value={formik.values.bank_account_holder}
                onChange={formik.handleChange}
                error={
                  formik.touched.bank_account_holder &&
                  Boolean(formik.errors.bank_account_holder)
                }
                helperText={
                  formik.touched.bank_account_holder && formik.errors.bank_account_holder
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                disableElevation
                // disabled={formik.isSubmitting}
                onClick={fetchSaveNewEarnings}
                type="submit"
                variant="contained"
                color="primary">
                Solicitar Retiro
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </GuveryDialog>
  );
};

export default EarningsNewDialog;
