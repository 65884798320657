export const ConversationMessageStatus = {
  created: 'CREATED',
  notified: 'NOTIFIED',
  seen: 'SEEN',
  hidden: 'HIDDEN',
} as const;

export type ConversationMessageStatusType =
  typeof ConversationMessageStatus[keyof typeof ConversationMessageStatus];

interface ConversationMessageDTO {
  id?: string | number;
  chat_channel_id?: number;
  channel_id?: number | string;
  content?: string | null;
  created_at?: Date | string | null;
  updated_at?: Date | string | null;
  notified_at?: Date | string | null;
  seen_at?: Date | string | null;
  deleted_at?: Date | string | null;
  current_status?: ConversationMessageStatusType;
  url?: string | null;
  mimetype?: any | null;
  user_id: string | number;
  token_node?: number | string | null;
  hasError?: boolean;
  receptor_is_active?: boolean;
}

export default ConversationMessageDTO;
