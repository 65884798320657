import configService from './config';
import fetchApi from './fetchApi';
import guveryFetchApi from './guveryFetchApi';
import * as METHODS from './methods';

const baseUrl = configService.apiUrl;
const guveryApi = configService.apiGuvery;

// const NodeChatUrl = configService.apiUrlChatServer;

const list = async (type: 'open' | 'closed' | 'all') => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/user/chats/${type}`,
    options: METHODS.GET(),
    message: {
      success: 'Lista de chat obtenida con exito.',
      error: 'No se puedo obtener la lista de chats.',
    },
  });

  return { ...response };
};

const listByUser = async () => {
  const { response } = await fetchApi({
    url: baseUrl + '/chat_mischats',
    options: METHODS.GET(),
    dataType: {},
    message: {
      success: 'Lista de chat obtenida con exito.',
      error: 'No se puedo obtener la lista de chats.',
    },
  });

  return { ...response };
};

const getChanel = async (channelId: string | number) => {
  const { response } = await fetchApi({
    url: baseUrl + '/chat_channel/' + channelId,
    options: METHODS.GET(),
    dataType: {},
    message: {
      success: 'Canal obtenido con exito.',
      error: 'No se puedo obtener el canal.',
    },
  });

  return { ...response };
};

const seenChanel = async (channelId: string | number) => {
  const { response } = await fetchApi({
    url: baseUrl + '/post_chat_channel_seen/' + channelId,
    options: METHODS.GET(),
    dataType: {},
    message: {
      success: 'Canal actualizado con exito.',
      error: 'No se puede cambiar el estado del canal.',
    },
  });

  return { ...response };
};

const getChannelByOffer = async (offerId: string | number) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/user/chat/offer/${offerId}`,
    options: METHODS.GET(),
    message: {
      success: 'Chat obtenido con exito.',
      error: 'No se puedo obtener el chat.',
    },
  });

  return { ...response };
};

const chatService = {
  list,
  listByUser,
  getChanel,
  seenChanel,
  getChannelByOffer,
};

export default chatService;
