import { ChangeEvent, useState, useEffect } from 'react';
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  MenuItem,
  Divider,
} from '@material-ui/core';
import { IconTrash, IconPlus } from '@tabler/icons';

import { validateURL, validateDecimalNumber } from '#root/utils';
import { GuverySpace } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import styles from './dealOrders.module.scss';

const packageList = [
  {
    value: 'sin caja',
    label: 'Sin caja',
  },
  {
    value: 'caja abierta',
    label: 'Caja abierta',
  },
  {
    value: 'sellado',
    label: 'Sellado',
  },
];

const reducerQty = (accumulator, current) => Number(accumulator) + Number(current?.can);
const reducerPrice = (accumulator, current) => Number(accumulator) + Number(current?.pre);
const reducerCom = (accumulator, current) => Number(accumulator) + Number(current?.com);

const DealOrders = (props) => {
  const { dealForm, setDealForm } = props;
  const [qtyAlert, setQtyAlert] = useState<boolean>(false);
  const [priceAlert, setPriceAlert] = useState<boolean>(false);
  const [comAlert, setComAlert] = useState<boolean>(false);

  const qtyTotal = dealForm?.deal_orders?.reduce(reducerQty, 0);
  const priceTotal = dealForm?.deal_orders?.reduce(reducerPrice, 0);
  const comTotal = dealForm?.deal_orders?.reduce(reducerCom, 0);

  const handleAlerts = () => {
    qtyTotal <= 0 ? setQtyAlert(true) : setQtyAlert(false);
    priceTotal <= 0 ? setPriceAlert(true) : setPriceAlert(false);
    comTotal <= 0 ? setComAlert(true) : setComAlert(false);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDealForm({ ...dealForm, [event.target.name]: event.target.value });
  };

  const handleNewDealOrder = () => {
    const newArray = dealForm.deal_orders;
    newArray.push({
      url: '',
      can: 1,
      pre: 0,
      com: 0,
    });
    setDealForm({
      ...dealForm,
      deal_orders: [...newArray],
    });
  };

  const handleOrderInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const strName = e.target.name.split('-');
    const key = parseInt(strName[0], 10);
    const name = strName[1];
    const newArray = dealForm.deal_orders;

    newArray[key] = {
      ...newArray[key],
      [name]: e.target.value,
    };
    setDealForm({
      ...dealForm,
      deal_orders: [...newArray],
    });
  };

  const handleOrderInputNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const strName = e.target.name.split('-');
    const key = parseInt(strName[0], 10);
    const name = strName[1];
    const newArray = dealForm.deal_orders;

    if (e.target.value === '' || validateDecimalNumber(e.target.value)) {
      newArray[key] = {
        ...newArray[key],
        [name]: e.target.value,
      };
    }

    setDealForm({
      ...dealForm,
      deal_orders: [...newArray],
    });
  };

  const handleOrderInputNumberRound = (groupName, value) => {
    const strName = groupName.split('-');
    const key = parseInt(strName[0], 10);
    const name = strName[1];
    const newArray = dealForm.deal_orders;

    if (value === '' || validateDecimalNumber(value)) {
      newArray[key] = {
        ...newArray[key],
        [name]: Math.ceil(Number(value)),
      };
    }

    setDealForm({
      ...dealForm,
      deal_orders: [...newArray],
    });
  };

  const handleDeleteOrder = (index) => {
    const newArray = [...dealForm.deal_orders];
    newArray.splice(index, 1);
    setDealForm({
      ...dealForm,
      deal_orders: [...newArray],
    });
  };

  useEffect(() => {
    handleAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qtyTotal, priceTotal, comTotal]);

  return (
    <GuveryPaper disableHover disableElevation className={styles.root}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <span className={styles.headerTitle}>Pedido</span>
        </div>
      </div>
      <hr className={styles.divider} />
      <div className={styles.body}>
        <GuverySpace direction="vertical" size="large">
          <TextField
            required
            id="title"
            name="title"
            label="Titulo"
            variant="outlined"
            value={dealForm.title}
            onChange={handleInputChange}
          />
          <TextField
            multiline
            minRows={3}
            id="observation"
            name="observation"
            label="Observaciones"
            variant="outlined"
            value={dealForm.observation}
            onChange={handleInputChange}
          />
          <TextField
            select
            id="packaging_type"
            name="packaging_type"
            label="Tipo de empaque"
            variant="outlined"
            value={dealForm.packaging_type}
            onChange={handleInputChange}>
            {packageList.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {dealForm.deal_orders.map((item, index) => {
            const isValidUrl = validateURL(item.url);
            return (
              <div key={index}>
                {index === 1 && (
                  <>
                    <Divider />
                    <h4 className={styles.subHeader}>Pedidos adicionales</h4>
                  </>
                )}
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id={index + '-url'}
                      name={index + '-url'}
                      label="Link del producto"
                      margin="none"
                      variant="outlined"
                      value={item.url}
                      onChange={handleOrderInputChange}
                      error={!isValidUrl}
                      helperText={!isValidUrl && 'Debes ingresar una url válida'}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <GuverySpace direction="horizontal">
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            type="tel"
                            inputMode="numeric"
                            inputProps={{ min: 1 }}
                            id={index + '-can'}
                            name={index + '-can'}
                            variant="outlined"
                            label="Cantidad"
                            value={item.can}
                            onChange={handleOrderInputNumberChange}
                            onBlur={() => handleOrderInputNumberRound(index + '-can', item.can)}
                            error={qtyAlert}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            type="tel"
                            inputMode="decimal"
                            inputProps={{ min: 1 }}
                            id={index + '-pre'}
                            name={index + '-pre'}
                            variant="outlined"
                            label="Precio total"
                            value={item.pre}
                            onChange={handleOrderInputNumberChange}
                            onBlur={() => handleOrderInputNumberRound(index + '-pre', item.pre)}
                            error={priceAlert}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            type="tel"
                            inputMode="decimal"
                            id={index + '-com'}
                            name={index + '-com'}
                            variant="outlined"
                            label="Comisión total"
                            value={item.com}
                            onChange={handleOrderInputNumberChange}
                            onBlur={() => handleOrderInputNumberRound(index + '-com', item.com)}
                            error={comAlert}
                          />
                        </Grid>
                      </Grid>
                      {index !== 0 && (
                        <div className={styles.removeOrder}>
                          <Tooltip arrow title="Borrar" placement="top">
                            <IconButton size="small" onClick={() => handleDeleteOrder(index)}>
                              <IconTrash />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    </GuverySpace>
                  </Grid>
                </Grid>
              </div>
            );
          })}
          <Button
            className={styles.addOrder}
            fullWidth
            variant="outlined"
            color="primary"
            startIcon={<IconPlus size={18} />}
            onClick={handleNewDealOrder}>
            Agregar producto
          </Button>
        </GuverySpace>
      </div>
      <hr className={styles.divider} />
      <div className={styles.footer}>
        <GuverySpace>
          <div className={styles.priceItem}>
            <h6>$ {priceTotal}</h6>
            <span>Precio Total</span>
          </div>
          <div>
            <strong>/</strong>
          </div>
          <div className={styles.priceItem}>
            <h6>$ {comTotal}</h6>
            <span>Comisión Total</span>
          </div>
          <div>
            <strong>/</strong>
          </div>
          <div className={styles.priceItem}>
            <h6>{qtyTotal}</h6>
            <span>Cantidad Total</span>
          </div>
        </GuverySpace>
      </div>
    </GuveryPaper>
  );
};

export default DealOrders;
