import { createTheme } from '@material-ui/core';
import {unstable_createMuiStrictModeTheme} from "@material-ui/core";
// Config
import overrides from './overrides';
import palette from './palette';
import typography from './typography';

const fixedCreateTheme = process.env.NODE_ENV === 'production' ? createTheme : unstable_createMuiStrictModeTheme;

const theme = fixedCreateTheme({
  overrides,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1170,
      xl: 1920,
    },
  },
  palette,
  typography,
});

export default theme;
