import configService from './config';
import guveryFetchApi from './guveryFetchApi';
import * as METHODS from './methods';

const guveryApi = configService.apiGuvery;

const searchShoppers = async (search) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/expert_traveler/shoppers/${search}`,
    options: METHODS.GET(),
    message: {
      success: 'Se encontraron los compradores.',
      error: 'No se encontró ningún comprador.',
    },
  });

  return { ...response };
};

const createAutoDeal = async (arg) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/expert_traveler/deal/auto`,
    options: METHODS.POST({
      ...arg,
    }),
    message: {
      success: 'Acuerdo actualizado con éxito.',
      error: 'No se pudo actualizar el acuerdo.',
    },
  });

  return { ...response };
};

const expertTravelerService = {
  searchShoppers,
  createAutoDeal,
};

export default expertTravelerService;
