import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import GuveryRow from './GuveryRow';
import SkeletonRow from './SkeletonRow';

// @ts-ignore
const GuveryTableCell = withStyles(() => ({
  head: {
    maxWidth: '380px',
    backgroundColor: 'rgba(34, 51, 84, 0.02)',
    // borderTop: '1px solid #e8e8e8',
    fontWeight: 'var(--font-weight-semibold)',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

interface TableProps {
  columns: any[];
  dataSource: any[];
  loading: boolean;
  noContentText?: string;
}

const Table = (props: TableProps) => {
  const {
    loading = false,
    columns = [],
    dataSource = [],
    noContentText = 'No hay datos.',
  } = props;

  return (
    <>
      <TableContainer>
        <MuiTable style={{ minWidth: 950 }} aria-label="guvery table">
          <TableHead>
            <TableRow>
              {columns.map((item, index) => (
                <GuveryTableCell key={index}>{item.title}</GuveryTableCell>
              ))}
            </TableRow>
          </TableHead>
          {!loading ? (
            <TableBody>
              {dataSource.map((data, idx) => (
                <GuveryRow columns={columns} key={idx} rawData={data} />
              ))}
            </TableBody>
          ) : (
            <TableBody>
              {Array.from(new Array(10)).map((_data, idx) => (
                <SkeletonRow columnsCount={columns.length} key={idx} />
              ))}
            </TableBody>
          )}
        </MuiTable>
      </TableContainer>
      {dataSource.length <= 0 && !loading && (
        <div className="guv-table-no-content">
          <p>{noContentText}</p>
        </div>
      )}
    </>
  );
};

export default Table;
