import { Fragment, useState, useEffect, useRef } from 'react';
import {
  TextField,
  Tooltip,
  IconButton,
  CircularProgress,
  Avatar,
  InputAdornment,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { IconInfoCircle, IconUser, IconBrandWhatsapp } from '@tabler/icons';

import { expertTravelerService } from '#root/services';
import { GuverySpace } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import styles from './dealShopper.module.scss';

type ShopperType = {
  idu?: number;
  mask_fullname?: string;
  mask_whatsapp?: string;
  one_fullname?: string;
  pic?: string;
  picture_path?: string;
  url_traveler_public_profile?: string;
};

const DealShopper = (props) => {
  const { dealForm, setDealForm } = props;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<ShopperType | null>(dealForm?.shopper?.one_fullname);
  const [inputValue, setInputValue] = useState('');
  const [shoppers, setShoppers] = useState([]);
  const [userImage, setUserImage] = useState<string>(dealForm?.shopper?.picture_path);
  const [valueType, setValueType] = useState('one_fullname');
  const touchedRef = useRef(null);
  const inputRef = useRef(null);

  const hasShopper = dealForm?.shopper ? true : false;
  const hasError = touchedRef.current && !hasShopper;

  const handleTouch = (state) => {
    touchedRef.current = state;
    if (!state) {
      setShoppers([]);
    }
  };

  const handleValueType = (value) => {
    if (Number(value)) {
      setValueType('mask_whatsapp');
      return;
    }
    setValueType('one_fullname');
  };

  const handleAutocompleteChange = (event: any, newShopper) => {
    setValueType('one_fullname');
    setValue(newShopper?.one_fullname);
    setDealForm({ ...dealForm, shopper: newShopper });
    inputRef.current.blur();
  };

  const handleInputChange = (event: any, newInputValue) => {
    setInputValue(newInputValue);
  };

  const fetchSearchShoppers = async (active) => {
    setLoading(true);
    const res = await expertTravelerService.searchShoppers(inputValue);
    if (!res.error) {
      if (active) setShoppers(res.data.shoppers);
    }
    setLoading(false);
  };

  useEffect(() => {
    let active = true;
    handleValueType(inputValue);
    if (
      inputValue.length >= 4 &&
      touchedRef.current &&
      dealForm?.shopper?.one_fullname !== inputValue &&
      dealForm?.shopper?.mask_whatsapp !== inputValue
    ) {
      fetchSearchShoppers(active);
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    let active = true;
    if (dealForm?.shopper) {
      active && setUserImage(dealForm?.shopper?.picture_path);
      return;
    }
    active && setUserImage(null);
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    <GuveryPaper disableHover disableElevation className={styles.root}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <span className={styles.headerTitle}>Comprador</span>
        </div>
        <Tooltip
          arrow
          title="Mostrando a los compradores con quienes has tenido (o mantienes) un acuerdo pagado."
          placement="top">
          <IconButton size="small" color="secondary">
            <IconInfoCircle size={22} color="var(--color-text-alt-2)" />
          </IconButton>
        </Tooltip>
      </div>
      <hr className={styles.divider} />
      <div className={styles.body}>
        <div className={styles.hint}>
          <p className={styles.text}>
            Puedes buscar y seleccionar dentro de todos los compradores con quienes tienes o
            tuviste un acuerdo.
            <br />
            (Busca por su nombre, apellido o whatsapp).
          </p>
        </div>
        <br />
        <GuverySpace direction="vertical" size="small">
          <Autocomplete
            onFocus={() => handleTouch(true)}
            onBlur={() => handleTouch(false)}
            onClose={() => inputRef.current.blur()}
            id="shopper"
            loading={isLoading}
            loadingText="Cargando..."
            includeInputInList
            filterOptions={(x) => x}
            value={value}
            onChange={handleAutocompleteChange}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            options={shoppers}
            getOptionLabel={(shopper) => shopper[valueType]}
            getOptionSelected={(option, value) => option?.one_fullname === value?.one_fullname}
            renderOption={(option) => (
              <div className={styles.user}>
                <Avatar className={styles.avatar} src={option?.picture_path} alt="user">
                  <IconUser size={20} />
                </Avatar>
                <span className={styles.text}>
                  {`${option?.one_fullname} (${option?.mask_whatsapp})`}
                </span>
              </div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={inputRef}
                required
                label="Buscar y seleccionar"
                variant="outlined"
                error={hasError}
                helperText={hasError && 'Debes seleccionar a un comprador'}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <Fragment>
                      {dealForm?.shopper ? (
                        <InputAdornment position="start">
                          <Avatar className={styles.avatar} src={userImage} alt="user">
                            <IconUser size={20} />
                          </Avatar>
                        </InputAdornment>
                      ) : null}
                      {params.InputProps.startAdornment}
                    </Fragment>
                  ),
                  endAdornment: (
                    <Fragment>
                      {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
          />
          {dealForm?.shopper && (
            <>
              <br />
              <div className={styles.hint}>
                <p className={styles.text}>
                  * Para asegurarte que estás seleccionando al comprador correcto, puedes
                  validarlo con los últimos 3 dígitos de su whatsapp.
                </p>
              </div>
              <div className={styles.validate}>
                <span className={styles.title}>
                  <IconBrandWhatsapp size={20} />
                  <p>Validar Whatsapp:</p>
                </span>
                <h6 className={styles.number}>{dealForm?.shopper?.mask_whatsapp}</h6>
              </div>
            </>
          )}
        </GuverySpace>
      </div>
    </GuveryPaper>
  );
};

export default DealShopper;
