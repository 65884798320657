import SvgIcon from '@material-ui/core/SvgIcon';

const DolarSvg = () => (
  <>
    <path d="M302.03 259.32c-9.714-7.286-21.83-11.998-34.899-13.714v-60.941c18.955 3.499 33.391 15.635 33.391 29.596 0 6.147 4.984 11.13 11.13 11.13s11.13-4.983 11.13-11.13c0-14.738-7.369-28.381-20.753-38.419-9.714-7.286-21.83-11.997-34.899-13.714v-17.432c0-6.147-4.984-11.13-11.13-11.13-6.146 0-11.13 4.983-11.13 11.13v17.434c-13.069 1.716-25.186 6.428-34.899 13.714-13.383 10.036-20.753 23.68-20.753 38.418s7.369 28.381 20.753 38.419c9.714 7.286 21.83 11.998 34.899 13.714v60.941c-18.955-3.498-33.391-15.634-33.391-29.595 0-6.147-4.984-11.13-11.13-11.13s-11.13 4.983-11.13 11.13c0 14.738 7.369 28.382 20.753 38.419 9.714 7.286 21.83 11.997 34.899 13.714v17.431c0 6.147 4.984 11.13 11.13 11.13 6.146 0 11.13-4.983 11.13-11.13v-17.434c13.069-1.716 25.186-6.428 34.899-13.714 13.383-10.036 20.753-23.68 20.753-38.418s-7.37-28.381-20.753-38.419zm-57.16-15.464c-18.955-3.498-33.391-15.634-33.391-29.595 0-13.961 14.436-26.096 33.391-29.595v59.19zm22.26 83.478v-59.189c18.955 3.498 33.391 15.634 33.391 29.595s-14.435 26.096-33.391 29.594z"></path>
    <path d="M437.02 74.981C388.667 26.628 324.381 0 256 0S123.333 26.628 74.98 74.981C26.628 123.333 0 187.62 0 256s26.628 132.667 74.98 181.019C123.333 485.372 187.619 512 256 512s132.667-26.628 181.02-74.981C485.372 388.667 512 324.38 512 256s-26.628-132.667-74.98-181.019zM256 489.739C127.116 489.739 22.261 384.884 22.261 256S127.116 22.261 256 22.261 489.739 127.116 489.739 256 384.884 489.739 256 489.739z"></path>
    <path d="M403.709 137.729c-3.846-4.798-10.852-5.566-15.647-1.722-4.797 3.846-5.567 10.85-1.723 15.646 23.956 29.881 36.618 65.963 36.618 104.347 0 92.06-74.897 166.957-166.957 166.957S89.044 348.06 89.044 256 163.94 89.044 256 89.044c28.936 0 57.44 7.515 82.434 21.733 5.339 3.039 12.137 1.173 15.177-4.171 3.04-5.343 1.173-12.139-4.171-15.179C321.1 75.305 288.788 66.783 256 66.783 151.666 66.783 66.783 151.666 66.783 256S151.666 445.217 256 445.217 445.217 360.334 445.217 256c0-42.878-14.741-84.881-41.508-118.271z"></path>
    <path d="M382.208 118.216a11.553 11.553 0 00-.634-2.081 11.24 11.24 0 00-1.024-1.914 11.326 11.326 0 00-1.391-1.692 10.743 10.743 0 00-1.692-1.391 11.278 11.278 0 00-1.914-1.024c-.679-.278-1.38-.49-2.081-.634a11.031 11.031 0 00-4.352 0c-.712.145-1.415.356-2.081.634a10.86 10.86 0 00-1.924 1.024c-.613.412-1.181.868-1.693 1.391a11.35 11.35 0 00-2.404 3.606 11.47 11.47 0 00-.633 2.081 11.06 11.06 0 00-.223 2.182c0 .723.077 1.458.223 2.17.144.712.355 1.414.633 2.081.278.679.624 1.325 1.024 1.926.401.612.868 1.18 1.38 1.692.512.512 1.08.979 1.693 1.38.6.401 1.245.746 1.924 1.024.668.278 1.369.49 2.081.634a10.94 10.94 0 002.17.223c.722 0 1.458-.078 2.182-.223a11.829 11.829 0 002.081-.634 11.24 11.24 0 001.914-1.024c.612-.401 1.18-.868 1.692-1.38a10.948 10.948 0 002.415-3.618 11 11 0 00.845-4.251c0-.724-.067-1.458-.211-2.182z"></path>
  </>
);

const Dolar = (props) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
    <DolarSvg />
  </SvgIcon>
);

export default Dolar;
