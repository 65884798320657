import React, { useState, useEffect } from 'react';
import { useDialog, useAuth } from '#root/hooks';
import refund_label from '#root/models/RefundDTO';
import { GuveryLayout, GuveryPageHeader, GuveryDialog } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import { userService } from '#root/services';
import RefundDialogForm from './RefundDialogForm';
import { withStyles } from '@material-ui/core/styles';
import { changeCurrencySymbol } from '#root/utils';
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

const GuveryTableCell = withStyles((theme) => ({
  head: {
    maxWidth: '380px',
    backgroundColor: 'rgba(34, 51, 84, 0.02)',
    borderTop: '1px solid #e8e8e8',
    fontWeight: 'var(--font-weight-semibold)',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const RefundDialog = (props) => {
  const { open = false, onClose, refundOptions, amount, refundInProcess = false } = props;
  if (refundInProcess) {
    return (
      <GuveryDialog
        open={open}
        onClose={onClose}
        title="Solicitar Reembolso"
        closeText="Cerrar"
        widthAuto
        heightAuto>
        <div
          style={{ display: 'flex', flexDirection: 'column', gap: 12, alignItems: 'center' }}>
          <p className="guv-font-size-6 guv-regular" style={{ paddingInline: 24 }}>
            Tienes un reembolso en proceso.
            <br />
            <strong>
              Los reembolsos se completan entre{' '}
              {refundOptions?.processing_time?.min_days +
                ' - ' +
                refundOptions?.processing_time?.max_days}{' '}
              días útiles
            </strong>
            .<br />
            Si ha pasado más tiempo o quieres corregir la cuenta, contáctanos.
          </p>
          <div style={{ marginTop: 8 }}>
            <a
              className="guv-font-size-5 guv-no-decoration"
              href="https://api.whatsapp.com/send?phone=51960684330&text=Hola!"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'var(--color-status-success)' }}>
              +51 960684330
            </a>
          </div>
          <p style={{ marginTop: 8, fontSize: 12 }}>Lunes a Viernes de 9am a 6pm.</p>
        </div>
      </GuveryDialog>
    );
  }
  return (
    <GuveryDialog open={open} onClose={onClose} title="Solicitar Reembolso" closeText="Cerrar">
      <div className="dialog-content-retire">
        <RefundDialogForm refundOptions={refundOptions} amount={amount} onClose={onClose} />
      </div>
    </GuveryDialog>
  );
};

const SkeletonRow = (
  <TableRow>
    <TableCell>
      <Skeleton width="100%" />
    </TableCell>
    {Array.from(new Array(3)).map((data, index) => (
      <TableCell key={index}>
        <Skeleton />
      </TableCell>
    ))}
  </TableRow>
);

const Row = ({ row }) => {
  const paid_at_format = row.paid_at
    ? format(new Date(row.paid_at.replace(/ /g, 'T')), 'dd/MM/yyyy', { locale: es })
    : '';
  const rowStyle =
    row.current_status !== 'PAID' ? {} : { backgroundColor: 'rgba(0, 171, 85, 0.16)' };
  const rowStatus =
    row.current_status !== 'PAID'
      ? refund_label(row.current_status)
      : refund_label(row.current_status) + ': ' + paid_at_format;
  return (
    <TableRow hover style={rowStyle}>
      <TableCell>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ fontWeight: 800 }}>{'#' + row.id}</div>
          <div style={{ fontSize: 10 }}>
            {format(new Date(row.created_at.replace(/ /g, 'T')), "dd 'de' MMMM 'del' yyyy", {
              locale: es,
            })}
          </div>
        </div>
      </TableCell>
      <TableCell>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ fontWeight: 600 }}>{row.bank + ' - ' + row.currency}</div>
          <div style={{ fontSize: 12 }}>
            {row.bank_account_number + ' : ' + row.bank_account_holder}
          </div>
        </div>
      </TableCell>
      <TableCell>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ fontWeight: 800 }}>
            {changeCurrencySymbol('DOLARES')} {row.amount}
          </div>
          <div style={{ fontSize: 10 }}>{rowStatus}</div>
        </div>
      </TableCell>
    </TableRow>
  );
};

function UserWalletRefunds() {
  const refundDialog = useDialog();
  const { usuario } = useAuth();
  const [loading, setLoading] = useState(true);
  const [refunds, setRefunds] = useState([]);
  const [refundOptions, setRefundOptions] = useState({});

  const fetchData = async () => {
    setLoading(true);
    const res = await userService.refunds();
    if (res.error) return setLoading(false);
    const data = res.data;
    setRefunds(data.refunds);
    setRefundOptions(data.refund_options);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [refundDialog.open]);
  return (
    <GuveryLayout className="user-wallet">
      <GuveryPageHeader
        backButton
        title="Mis Reembolsos"
        extra={[
          <Button
            disabled={usuario.saldo_est !== 1 || usuario.saldo < 1}
            color="secondary"
            variant="outlined"
            onClick={refundDialog.openDialog}
            style={{ marginRight: 10 }}>
            Solicitar Reembolso
          </Button>,
        ]}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <GuveryPaper disableElevation disableHover>
            <div className="card-wallet">
              {usuario.saldo_est === 1 ? (
                <>
                  <h6>
                    <span>{usuario.saldo}</span> US$
                  </h6>
                  {usuario.saldo < 0 && usuario.saldo_evento === 'ENVIO_ACTUALIZADO' && (
                    <p className="balanceEvent">(envío actualizado)</p>
                  )}
                  {usuario.saldo < 0 && usuario.saldo_evento === 'ACUERDO_MODIFICADO' && (
                    <p className="balanceEvent">(pedido actualizado)</p>
                  )}
                  {usuario.saldo > 0 && (
                    <>
                      <p className="balanceMessage positive">
                        El saldo a tu favor <strong>jamás expira</strong> y estará disponible
                        para ti <strong>por siempre</strong>.
                      </p>
                      <p className="balanceSubMessage positive">
                        Puedes usarlo para pagar ó puedes solicitar un reembolso en cualquier
                        momento.
                      </p>
                    </>
                  )}
                </>
              ) : (
                <h6>En proceso...</h6>
              )}
            </div>
          </GuveryPaper>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <GuveryPaper
          disableHover
          disableElevation
          className="card-movements"
          style={{ marginTop: '1rem' }}>
          <div className="header">
            <h4>Reembolsos</h4>
          </div>
          <Table style={{ minWidth: 650 }} aria-label="wallet table">
            <TableHead>
              <TableRow>
                <GuveryTableCell>#ID / Fecha</GuveryTableCell>
                <GuveryTableCell>Banco / Cuenta - Titular</GuveryTableCell>
                <GuveryTableCell>Importe</GuveryTableCell>
              </TableRow>
            </TableHead>
            {!loading ? (
              <TableBody>
                {refunds.map((row, index) => (
                  <Row row={row} key={index} />
                ))}
              </TableBody>
            ) : (
              <TableBody>
                {Array.from(new Array(10)).map((data, index) => (
                  <React.Fragment key={index}>{SkeletonRow}</React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>
        </GuveryPaper>
      </Grid>
      <RefundDialog
        open={refundDialog.open}
        onClose={refundDialog.closeDialog}
        refundInProcess={refunds.length > 0 && refunds.find((refund) => refund.paid_at == null)}
        refundOptions={refundOptions}
        amount={parseFloat(usuario.saldo)}
      />
    </GuveryLayout>
  );
}

export default UserWalletRefunds;
