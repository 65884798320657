import { MouseEventHandler, ReactNode, CSSProperties } from 'react';
import { Button, Dialog as MuiDialog, DialogContent, DialogActions } from '@material-ui/core';
import clsx from 'clsx';

import DialogTitle from './DialogTitle';
import styles from './dialog.module.scss';

interface DialogProps {
  children?: ReactNode;
  open?: boolean;
  onClose?: MouseEventHandler<HTMLButtonElement>;
  onConfirm?: MouseEventHandler<HTMLButtonElement>;
  onConfirmDisable?: boolean;
  title?: string | ReactNode;
  disableActions?: boolean;
  closeText?: string;
  confirmText?: string;
  confirmIcon?: ReactNode;
  content?: boolean;
  style?: CSSProperties;
  className?: string;
  widthAuto?: boolean;
  heightAuto?: boolean;
}

const Dialog = (props: DialogProps) => {
  const {
    children,
    open = false,
    onClose,
    onConfirm,
    onConfirmDisable,
    title,
    disableActions = false,
    closeText = 'Cancelar',
    confirmText = 'Guardar',
    confirmIcon = null,
    content = true,
    style,
    className,
    widthAuto = false,
    heightAuto = false
  } = props;

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      className={clsx(styles.root, className && className)}
      aria-labelledby="form-dialog-title"
      style={style}
      PaperProps={{
        style: {
          width: (!widthAuto) ? 'calc(100% - 16px)' : 'auto',
          margin: '16px',
        },
      }}>
      {title && <DialogTitle onClose={onClose}>{title}</DialogTitle>}
      {content ? (
        <DialogContent dividers className={styles.content}>
          <div className={clsx(styles.wrap, !heightAuto && styles.minHeight)}>{children}</div>
        </DialogContent>
      ) : (
        children
      )}
      {!disableActions && (
        <DialogActions className={styles.actions}>
          <Button
            disableElevation
            onClick={onClose}
            variant={onConfirm ? 'text' : 'outlined'}
            color="secondary">
            {closeText}
          </Button>
          {onConfirm && (
            <Button
              disableElevation
              disabled={onConfirmDisable}
              onClick={onConfirm}
              variant="contained"
              color="primary"
              startIcon={confirmIcon ? confirmIcon : null}>
              {confirmText}
            </Button>
          )}
        </DialogActions>
      )}
    </MuiDialog>
  );
};

export default Dialog;
