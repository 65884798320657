import SvgIcon from '@material-ui/core/SvgIcon';

const CheckmarkCircle2Svg = () => (
  <>
    <g data-name="Layer 2">
      <g data-name="checkmark-circle">
        <rect width={24} height={24} opacity={0} />
        <path d="M9.71 11.29a1 1 0 0 0-1.42 1.42l3 3A1 1 0 0 0 12 16a1 1 0 0 0 .72-.34l7-8a1 1 0 0 0-1.5-1.32L12 13.54z" />
        <path d="M21 11a1 1 0 0 0-1 1 8 8 0 0 1-8 8A8 8 0 0 1 6.33 6.36 7.93 7.93 0 0 1 12 4a8.79 8.79 0 0 1 1.9.22 1 1 0 1 0 .47-1.94A10.54 10.54 0 0 0 12 2a10 10 0 0 0-7 17.09A9.93 9.93 0 0 0 12 22a10 10 0 0 0 10-10 1 1 0 0 0-1-1z" />
      </g>
    </g>
  </>
);

const CheckmarkCircle2 = (props) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <CheckmarkCircle2Svg />
  </SvgIcon>
);

export default CheckmarkCircle2;
