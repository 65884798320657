import { MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { IconMessageCircle } from '@tabler/icons';

import { replacePathParams } from '#root/utils';
import { USER_CHAT_ROOM } from '#root/constants/navigation';
import styles from './actions.module.scss';

interface ActionsProps {
  channelId?: string | number;
  isChannelClosed?: boolean;
  isChannelEmpty?: boolean;
  onClose: (e?: MouseEvent<HTMLElement>) => void;
}

const Actions = (props: ActionsProps) => {
  const { channelId, onClose, isChannelClosed, isChannelEmpty } = props;
  const history = useHistory();

  const handleGoChat = () => {
    if (channelId) {
      history.push(replacePathParams(USER_CHAT_ROOM, { id: channelId }));
      return;
    }
    onClose();
  };

  return (
    <div className={styles.root}>
      <div className={styles.accept}>
        {!isChannelClosed && (
        <Button
          fullWidth
          disableElevation
          variant="contained"
          color="primary"
          startIcon={<IconMessageCircle />}
          onClick={handleGoChat}>
          {(isChannelEmpty) ? "Iniciar conversación" : "Seguir conversando"}
        </Button>)}
        {isChannelClosed && (
        <Button
          fullWidth
          disableElevation
          variant="outlined"
          color="secondary"
          disabled
          onClick={handleGoChat}>
          Conversación finalizada
        </Button>)}
      </div>
      <Button
        className={styles.remove}
        disableElevation
        variant="text"
        color="secondary"
        onClick={onClose}>
        Cerrar
      </Button>
    </div>
  );
};

export default Actions;
