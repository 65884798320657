import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Badge, IconButton } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';

import { useResponsive, useAuth } from '#root/hooks';
import { ArrowCircleLeftIcon, ArrowCircleRightIcon, MoreVerticalIcon } from '#root/icons';
import * as VARIABLES from '#root/constants/variables';
import ConversationUserDTO from '#root/interfaces/ConversationUserDTO';
import styles from './headerDetail.module.scss';

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: '14%',
    bottom: '14%',
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: -1,
      left: -1,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

interface HeaderDetailProps {
  openList?: boolean;
  onListClose?: () => void;
  openDetail?: boolean;
  onDetailClose?: () => void;
  loading?: boolean;
  conversationUsers?: ConversationUserDTO[];
  isReceptorActive?: boolean;
}

const HeaderDetail = (props: HeaderDetailProps) => {
  const { openList, onListClose, onDetailClose, loading, conversationUsers, isReceptorActive } =
    props;
  const { usuario } = useAuth();
  const { isMobile } = useResponsive();
  const Icon = openList ? ArrowCircleLeftIcon : ArrowCircleRightIcon;

  const recordUser = conversationUsers.find((u) => u.user_id !== usuario.idu);
  const userFirstName = recordUser ? recordUser?.user?.first_name : 'Usuario';
  const userName =
    recordUser && `${recordUser?.user?.first_name} ${recordUser?.user?.last_name.charAt(0)}.`;

  return (
    <div className={styles.root}>
      <div className="guv-effect-grow ">
        <IconButton
          color="secondary"
          className={clsx(styles.icon, !openList && styles.active)}
          onClick={onListClose}>
          {isMobile ? <ArrowCircleLeftIcon /> : <Icon />}
        </IconButton>
      </div>
      <div className={styles.profile}>
        <div className={styles.avatar}>
          {!loading ? (
            <StyledBadge
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              variant={isReceptorActive ? 'dot' : 'standard'}>
              <Avatar
                alt={recordUser?.user.first_name}
                src={VARIABLES.IMAGE_PROFILE_PATH + recordUser?.user.profile_picture + '.png'}>
                {userFirstName.charAt(0)}
              </Avatar>
            </StyledBadge>
          ) : (
            <Skeleton className={styles.avatar} variant="circle" width={40} height={40} />
          )}
        </div>
        <div className={styles.info}>
          {!loading ? (
            <>
              <h6>{userName}</h6>
              <p>{recordUser?.role}</p>
            </>
          ) : (
            <>
              <Skeleton variant="text" height="100%" width="120px" />
              <Skeleton variant="text" height="100%" width="50%" />
            </>
          )}
        </div>
      </div>
      <div className={styles.space} />
      <div className="guv-effect-grow ">
        <IconButton color="primary" onClick={onDetailClose}>
          <MoreVerticalIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default HeaderDetail;
