import { useState, useEffect, useRef } from 'react';
import { Grid, Button } from '@material-ui/core';
import clsx from 'clsx';

import { userService } from '#root/services';
import { useNotification, useAuth, useGlobalNotification } from '#root/hooks';
import { GuveryTextField, ImageCropModal } from '#root/components';
import { GuveryLayout, GuveryPageHeader, GuveryTab, GuveryTabs } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import { CameraPlusIcon, PersonOutlineIcon, PlanenIcon } from '#root/icons';
import { CanguroGuveryRadius } from '#root/assets';
import EmailAlert from './EmailAlert';
import PhotoAlert from './PhotoAlert';
import Rating from './Rating';
import TravelerInformation from './TravelerInformation';
import * as VARIABLES from '#root/constants/variables';
import './userProfile.scss';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-guvery-tabpanel-${index}`}
      aria-labelledby={`scrollable-guvery-tab-${index}`}
      style={{ marginTop: 24 }}
      // className={styles.panel}
      {...other}>
      {value === index && <>{children}</>}
    </div>
  );
};

function UserProfile() {
  const { createMessage } = useNotification();
  const { global_is_viajero, usuario, setUsuario } = useAuth();
  const globalNotification = useGlobalNotification();
  const [value, setValue] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [userObj, setUserObj] = useState(usuario);
  const [avatar, setAvatar] = useState({ file: '', imagePreviewUrl: '' });
  const imageProfileRef = useRef();

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setAvatar({ file: '', imagePreviewUrl: '' });
    setModalOpen(false);
  };

  const handleUserInputChange = (e) => {
    setUserObj({
      ...userObj,
      [e.target.id]: e.target.value,
    });
  };

  const validateUserObj = () => {
    if (usuario.wsp) {
      if (userObj.nom === usuario.nom && userObj.ape === usuario.ape) {
        setBtnDisable(true);
        return;
      }
    }
    if (!userObj.nom || !userObj.ape || !userObj.wsp) {
      setBtnDisable(true);
      return;
    }
    setBtnDisable(false);
  };

  const handleAvatarChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setAvatar({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    if (file && file.type.match('image.*')) {
      reader.readAsDataURL(file);
      handleModalOpen();
    }
  };

  const handleUpdateBasicInfo = async (e) => {
    e.preventDefault();
    fetchUpdateBasicInfo();
  };

  const fetchUpdateBasicInfo = async () => {
    setBtnDisable(true);
    let body = {
      idu: userObj.idu,
      nom: userObj.nom,
      ape: userObj.ape,
    };
    if (!usuario.wsp) {
      body = {
        idu: userObj.idu,
        nom: userObj.nom,
        ape: userObj.ape,
        wsp: userObj.wsp,
      };
    }

    const res = await userService.updateData(body);
    // console.log(res);
    if (res.error) {
      createMessage.error(res.message);
      setBtnDisable(false);
      return;
    }
    createMessage.success(res.message);
    setUsuario({
      ...usuario,
      is_active: '1',
      nom: userObj.nom,
      ape: userObj.ape,
      wsp: userObj.wsp,
    });
    setBtnDisable(false);
  };

  const handleAvatarSuccess = (blobImage, croppedImageUrl) => {
    handleModalClose();
    const body = {
      idu: userObj.idu,
      img: blobImage,
    };
    fetchUpdateProfileImage(body, croppedImageUrl);
  };

  const fetchUpdateProfileImage = async (arg, croppedImageUrl) => {
    createMessage.info('...Subiendo imagen');
    const res = await userService.updateProfileImage(arg);
    if (!res.info.error) {
      // createMessage.success(res.info.message);
      globalNotification.alert(res.info.message, 'success');
      setUsuario({
        ...usuario,
        pic: res.data,
      });
    } else {
      globalNotification.alert(res.info.message, 'error');
    }
  };

  useEffect(() => {
    validateUserObj();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userObj]);

  return (
    <GuveryLayout className="user-profile">
      <GuveryPageHeader title="Mi Perfil" />
      {usuario.is_active !== 1 && usuario.wsp !== null && <EmailAlert />}
      {global_is_viajero && usuario.pic === null && <PhotoAlert />}
      <GuveryTabs
        value={value}
        onChange={handleTabChange}
        indicatorColor="primary"
        variant="scrollable"
        // scrollButtons={isDesktop ? 'off' : 'on'}
        aria-label="scrollable auto tabs guvery">
        <GuveryTab label="General" icon={<PersonOutlineIcon fontSize="small" />} />
        {global_is_viajero && (
          <GuveryTab label="Información Viajero" icon={<PlanenIcon fontSize="small" />} />
        )}
      </GuveryTabs>
      <TabPanel value={value} index={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <div className="stack-root">
              <GuveryPaper disableElevation disableHover>
                <div className="card-img">
                  <div className="avatar-dashed">
                    <label htmlFor="input-user-avatar">
                      <div className="avatar-wrap">
                        {usuario.pic ? (
                          <img
                            ref={imageProfileRef}
                            src={VARIABLES.IMAGE_PROFILE_PATH + usuario.pic + '.png'}
                            onError={() => (imageProfileRef.current.src = CanguroGuveryRadius)}
                            alt="avatar"
                          />
                        ) : (
                          <>
                            <img
                              className="avatar-upload"
                              ref={imageProfileRef}
                              src={CanguroGuveryRadius}
                              alt="avatar"
                            />
                            {usuario.is_active === 1 && (
                              <div className="placeholder">
                                <CameraPlusIcon />
                                <span>Actualizar foto</span>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </label>
                  </div>
                  {usuario.pic === null && usuario.is_active === 1 && (
                    <>
                      <div className="btn-action">
                        <Button
                          disableElevation
                          variant="contained"
                          color="primary"
                          type="submit"
                          component="label"
                          htmlFor="input-user-avatar"
                          disabled={usuario.pic ? true : false}
                          startIcon={<CameraPlusIcon />}>
                          Actualizar foto de perfil
                        </Button>
                      </div>
                      <span className="sub-text">
                        El formato debe ser JPEG, PNG y no puede superar los 2 MB.
                      </span>
                      <input
                        disabled={usuario.pic ? true : false}
                        className="guv-hidden"
                        id="input-user-avatar"
                        type="file"
                        accept=".jpg,.jpeg,.png,.gif"
                        onChange={handleAvatarChange}
                      />
                    </>
                  )}
                </div>
              </GuveryPaper>
              <Rating clientRating={usuario.calcom} travelerRating={usuario.calvia} />
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <GuveryPaper disableElevation disableHover className="card-form">
              <form onSubmit={handleUpdateBasicInfo}>
                <div className="form-wrap">
                  <div className="form-row">
                    <GuveryTextField
                      id="nom"
                      label="Nombre"
                      value={userObj.nom}
                      onChange={handleUserInputChange}
                    />
                  </div>
                  <div className="form-row">
                    <GuveryTextField
                      id="ape"
                      label="Apellidos"
                      value={userObj.ape}
                      onChange={handleUserInputChange}
                    />
                  </div>
                  <div className="form-row">
                    <GuveryTextField disabled id="mai" label="Email" value={userObj.mai} />
                  </div>
                  <div className={clsx('form-row', usuario.wsp === null && 'wsp-row')}>
                    <GuveryTextField
                      disabled={usuario.wsp !== null ? true : false}
                      id="wsp"
                      label="Whatsapp"
                      type="text"
                      value={userObj.wsp}
                      onChange={handleUserInputChange}
                    />
                  </div>
                </div>
                <div className="form-submit">
                  <Button
                    disableElevation
                    disabled={btnDisable}
                    variant="contained"
                    color="primary"
                    type="submit">
                    Guardar cambios
                  </Button>
                </div>
              </form>
            </GuveryPaper>
            <div className="footer">
              <span className="text-dark text-small">
                ¿Cambiar correo?{' '}
                <a
                  href="https://m.me/guveryoficial"
                  className="guv-no-decoration"
                  target="_blank"
                  rel="noopener noreferrer">
                  Aquí
                </a>
              </span>
              <span className="text-dark text-small">
                ¿Cambiar whatsapp?{' '}
                <a
                  href="https://m.me/guveryoficial"
                  className="guv-no-decoration"
                  target="_blank"
                  rel="noopener noreferrer">
                  Aquí
                </a>
              </span>
              <span className="text-dark text-small">
                ¿Tienes problemas?{' '}
                <a
                  href="https://m.me/guveryoficial"
                  className="guv-no-decoration"
                  target="_blank"
                  rel="noopener noreferrer">
                  Avísanos
                </a>
              </span>
            </div>
          </Grid>
        </Grid>
      </TabPanel>
      {global_is_viajero && (
        <TabPanel value={value} index={1}>
          <TravelerInformation userObj={userObj} />
        </TabPanel>
      )}
      <ImageCropModal
        open={modalOpen}
        onCancel={handleModalClose}
        onConfirm={handleAvatarSuccess}
        title="Actualizar imagen de perfil"
        src={avatar.imagePreviewUrl}
      />
      {/* {notification.state && <Notification onClose={closeMessage} {...notification} />} */}
    </GuveryLayout>
  );
}

export default UserProfile;
