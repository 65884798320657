import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { format, formatDistanceStrict } from 'date-fns';
import { es } from 'date-fns/locale';

import { ScooterIcon } from '#root/icons';
import styles from './arrivalTime.module.scss';

const formatTimeNoYear = (date: string | Date) => {
  let time: string | null;
  time = date ? format(new Date(date), "dd 'de' MMM", { locale: es }) : '---';

  return time;
};

interface ArrivalTimeProps {
  dateCreate: string | null;
  dateExpire: string | null;
}

const ArrivalTime = (props: ArrivalTimeProps) => {
  const { dateCreate, dateExpire } = props;
  const createDate = dateCreate
    ? new Date(dateCreate.replace(/ /g, 'T'))
    : new Date(2015, 0, 1);
  const expireDate = dateExpire
    ? new Date(dateExpire.replace(/ /g, 'T'))
    : new Date(2016, 0, 1);
  const distanceTotalDays = formatDistanceStrict(expireDate, createDate, {
    unit: 'day',
    locale: es,
  });
  const distanceCurrentDays = formatDistanceStrict(new Date(), createDate, {
    unit: 'day',
    locale: es,
  });
  const timeLeft = formatDistanceStrict(expireDate, new Date(), {
    locale: es,
  });
  const totalDaysInt = distanceTotalDays.split(' ')[0];
  const currentDaysInt = distanceCurrentDays.split(' ')[0];

  const normalise = (Number(currentDaysInt) * 100) / Number(totalDaysInt);

  return (
    <div className={styles.root}>
      <div className={styles.dates}>
        <span>{formatTimeNoYear(createDate)}</span>
        <span>{formatTimeNoYear(expireDate)}</span>
      </div>
      <LinearProgress variant="determinate" value={normalise} />
      <div className={styles.hint}>
        <span>Falta {timeLeft}</span>
      </div>
      <div className={styles.plane}>
        <ScooterIcon size={30} />
      </div>
    </div>
  );
};

export default ArrivalTime;
