import { IconClock } from '@tabler/icons';

import { GuveryPaper } from '#root/ui-components/cards';
import styles from './expire.module.scss';

const Expire = () => {
  return (
    <GuveryPaper disableHover disableElevation>
      <div className={styles.root}>
        <span className={styles.wrap}>
          <span className={styles.text}>
            <IconClock size={14} />
            Acuerdo vencido.
          </span>
          <span className={styles.hint}>
            Contacta al viajero y pregúntale si puede extender la fecha de vencimiento.
          </span>
        </span>
      </div>
    </GuveryPaper>
  );
};

export default Expire;
