import SvgIcon from '@material-ui/core/SvgIcon';

const MoreVerticalSvg = () => (
  <>
    <g data-name="Layer 2">
      <g data-name="more-vertical">
        <circle cx="12" cy="12" r="2"></circle>
        <circle cx="12" cy="5" r="2"></circle>
        <circle cx="12" cy="19" r="2"></circle>
      </g>
    </g>
  </>
);

const MoreVertical = (props) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <MoreVerticalSvg />
  </SvgIcon>
);

export default MoreVertical;
