import { Overrides } from '@material-ui/core/styles/overrides';

// import MuiAlertTitle from './MuiAlertTitle';
import MuiBadge from './MuiBadge';
import MuiFormLabel from './MuiFormLabel';
import MuiInputBase from './MuiInputBase';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiPopover from './MuiPopover';
import MuiSlider from './MuiSlider';
import MuiTooltip from './MuiTooltip';

// eslint-disable-next-line import/no-anonymous-default-export

const overrides: Overrides = {
  // MuiAlertTitle,
  MuiBadge,
  MuiFormLabel,
  MuiInputBase,
  MuiOutlinedInput,
  MuiPopover,
  MuiSlider,
  MuiTooltip,
};

export default overrides;
