import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import { roundDecimal } from '#root/utils';
import { GuveryPaper } from '#root/ui-components/cards';
import styles from './dealTable.module.scss';

const DealTable = (props) => {
  const { deal = {} } = props;

  return (
    <GuveryPaper disableHover disableElevation className={styles.card}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <span className={styles.headerTitle}>Detalle del acuerdo</span>
        </div>
      </div>
      <hr className={styles.divider} />
      <div className={styles.body}>
        <Table className={styles.table}>
          <TableHead className={styles.headerTable}>
            <TableRow>
              <TableCell>Producto</TableCell>
              <TableCell className="guv-ellipsis">Precio Real</TableCell>
              <TableCell>Viajero</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={styles.bodyTable}>
            {deal?.deal_orders?.map((order, index) => (
              <TableRow key={index}>
                <TableCell>
                  <span className={styles.name}>
                    <span>{`x ${order.can}`}</span>
                    <a
                      className={styles.link}
                      href={order.url}
                      target="_blank"
                      rel="noopener noreferrer">
                      <span>{order.url}</span>
                    </a>
                  </span>
                </TableCell>
                <TableCell>$ {roundDecimal(order.pre)}</TableCell>
                <TableCell>$ {roundDecimal(order.com)}</TableCell>
                <TableCell>$ {roundDecimal(order.pre + order.com)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <span>Guvery</span>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>$ {roundDecimal(deal.guvery_fee)}</TableCell>
            </TableRow>
            {deal.guvery_express_fee && (
              <TableRow>
                <TableCell>
                  <span>Express ⚡️</span>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  $ {roundDecimal(deal.guvery_express_fee)}
                </TableCell>
              </TableRow>
            )}
            {deal.dscto && (
              <TableRow>
                <TableCell>
                  <span>Descuento</span>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <span className="guv-text-error">- $ {roundDecimal(deal.dscto)}</span>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                <span className={styles.total}>Total</span>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <span className={styles.total}>$ {roundDecimal(deal.total)}</span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </GuveryPaper>
  );
};

export default DealTable;
