import { useEffect, useState } from 'react';
import { Input, TextField, Grid, FormControlLabel, Checkbox } from '@material-ui/core';

import { useAuth } from '#root/hooks';
import { GuveryDialog } from '#root/ui-components';

import { travelerService } from '#root/services';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import styles from './styles.module.scss';
import clsx from 'clsx';

const AddDeliverySetup = (props) => {
  const { open = false, onClose, onSave, deliverySetup = null } = props;
  const { usuario } = useAuth();
  const defaultTitle = "Agregar una nueva configuración";
  const [title, setTitle] = useState(defaultTitle);
  const [checkAddress, setCheckAddress] = useState(true);

  const formik = useFormik({
    initialValues: {
      id: '',
      checkAddress: true,
      label: 'Entrega en Mi Casa',
      custom_message:
        'Hola, gracias por escogerme para traer tu pedido. Ya está listo para entregar, por favor lee estas indicaciones para coordinar la entrega.',
      pickup_contact: usuario.one_fullname,
      phone_number: '',
      whatsapp_number: usuario.wsp,
      pickup_address: '',
      pickup_details:
        'Contáctame para coordinar el día y el horario por favor.',
    },
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      checkAddress: Yup.boolean(),
      label: Yup.string()
        .required('Requerido')
        .min(4, 'Al menos 4 caracteres')
        .max(100, 'Máximo 100 caracteres'),
      custom_message: Yup.string()
        .required('Requerido')
        .min(4, 'Al menos 4 caracteres')
        .max(1000, 'Máximo 1000 caracteres'),
      phone_number: Yup.string()
        .min(9, 'Al menos 9 dígitos')
        .max(20, 'Máximo 20 dígitos y/o símbolos')
        .nullable(),
      whatsapp_number: Yup.string()
        .required('Requerido')
        .min(9, 'Al menos 9 dígitos')
        .max(20, 'Máximo 20 dígitos y/o símbolos'),
      pickup_contact: Yup.string()
        .required('Requerido')
        .min(9, 'Al menos 9 caracteres')
        .max(100, 'Máximo 100 caracteres'),
      pickup_address: Yup.string()
        .notRequired()
        .nullable()
        .when("checkAddress", {
          is: true,
          then: Yup.string()
          .required("Ingresa un Punto de entrega o Dirección, o desmarca la casilla de arriba.")
          .min(20, 'Al menos 20 caracteres')
          .max(1000, 'Máximo 1000 caracteres'),
        }),
      pickup_details: Yup.string()
        .required('Requerido')
        .min(20, 'Al menos 20 caracteres')
        .max(1000, 'Máximo 1000 caracteres'),
    }),
    onSubmit: () => {
      handleSave();
    },
  });

  const resetForm = () => {
    formik.resetForm({
      values: formik.initialValues,
    });
  };

  const formikLiveFeedbackLength = {
    label: 100,
    custom_message: 1000,
    pickup_contact: 100,
    pickup_address: 1000,
    pickup_details: 1000,
  };

  const handleHelperText = (key) => {
    if (formik.errors[key]) {
      return formik.errors[key];
    }
    if (formikLiveFeedbackLength[key] && formik.values[key]) {
      return formik.values[key].length + '/' + formikLiveFeedbackLength[key];
    }
    return '';
  };

  const handleCheckboxChange = (event) => {
    if (!event.target.checked) {
      formik.values.pickup_details = "Contáctame para brindarte el lugar de entrega"
    } else {
      formik.values.pickup_details = "Contáctame para coordinar el día y el horario por favor"
    }
    setCheckAddress(event.target.checked);
    formik.handleChange(event);
  };

  const handleSave = async () => {
    formik.setSubmitting(true);
    const response = await travelerService.addDeliverySetup(formik.values);
    onSave();
    onClose();
  };

  useEffect(() => {
    if (!open) {
      resetForm();
      setCheckAddress(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (deliverySetup) {
      formik.setValues(deliverySetup);
      setTitle("Ver o Editar una configuración");
      if (!deliverySetup.pickup_address) {
        deliverySetup.pickup_address = '';
        setCheckAddress(false);
      }
    } else {
      setTitle(defaultTitle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliverySetup]);

  return (
    <GuveryDialog open={open} onClose={onClose} title={title} onConfirm={handleSave} onConfirmDisable={!(formik.isValid && formik.dirty) || formik.isSubmitting}>
      <Grid container spacing={3}>
        <p className={clsx(styles.helpText)} style={{ marginBottom: 20 }}>
          Esta configuración sólo será visible para el comprador que elijas, desde que marques el acuerdo <strong>Listo para Entregar</strong> hasta que se confirme la entrega.
        </p>
      </Grid>
      <form onSubmit={formik.handleSubmit}>
          <Input
          id='id'
          name='id'
          value={formik.values.id}
          type='hidden'
          />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="label"
              name="label"
              label="Etiqueta"
              type="text"
              autoComplete="off"
              value={formik.values.label}
              onChange={formik.handleChange}
              error={Boolean(formik.touched.label || formik.errors.label)}
              helperText={handleHelperText('label')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="pickup_contact"
              name="pickup_contact"
              label="Persona de contacto"
              type="text"
              autoComplete="off"
              value={formik.values.pickup_contact}
              onChange={formik.handleChange}
              error={Boolean(formik.touched.pickup_contact || formik.errors.pickup_contact)}
              helperText={handleHelperText('pickup_contact')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="custom_message"
              name="custom_message"
              label="Mensaje Personalizado"
              type="text"
              autoComplete="off"
              value={formik.values.custom_message}
              multiline={true}
              minRows={2}
              onChange={formik.handleChange}
              error={Boolean(formik.touched.custom_message || formik.errors.custom_message)}
              helperText={handleHelperText('custom_message')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="whatsapp_number"
              name="whatsapp_number"
              label="Whatsapp"
              type="text"
              autoComplete="off"
              value={formik.values.whatsapp_number}
              onChange={formik.handleChange}
              error={Boolean(formik.touched.whatsapp_number || formik.errors.whatsapp_number)}
              helperText={handleHelperText('whatsapp_number')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              id="phone_number"
              name="phone_number"
              label="Celular en Perú"
              type="text"
              autoComplete="off"
              value={formik.values.phone_number}
              onChange={formik.handleChange}
              error={Boolean(formik.touched.phone_number || formik.errors.phone_number)}
              helperText={handleHelperText('phone_number')}
            />
          </Grid>
          <Grid item xs={12}>
          <FormControlLabel
        control={
          <Checkbox
            id="checkAddress"
            name="checkAddress"
            checked={checkAddress}
            onChange={handleCheckboxChange}
            color="primary"
          />
        }
        label="Quiero agregar mi Punto de entrega o Dirección"
      />
          </Grid>
          {checkAddress && (
            <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="pickup_address"
              name="pickup_address"
              label="Punto de entrega ó Dirección"
              type="text"
              autoComplete="off"
              value={formik.values.pickup_address}
              multiline={true}
              minRows={2}
              onChange={formik.handleChange}
              error={Boolean(formik.touched.pickup_address || formik.errors.pickup_address)}
              helperText={handleHelperText('pickup_address')}
            />
          </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              id="pickup_details"
              name="pickup_details"
              label="Detalles adicionales"
              disabled={!checkAddress}
              type="text"
              autoComplete="off"
              value={formik.values.pickup_details}
              multiline={true}
              onChange={formik.handleChange}
              error={Boolean(formik.touched.pickup_details || formik.errors.pickup_details)}
              helperText={handleHelperText('pickup_details')}
            />
          </Grid>
        </Grid>
      </form>
    </GuveryDialog>
  );
};

export default AddDeliverySetup;
