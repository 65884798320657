import { CSSProperties, useEffect, useState } from 'react';
import { IconClock } from '@tabler/icons';
import clsx from 'clsx';
import styles from './expirationDays.module.scss';

interface ExpirationDaysProps {
  className?: string;
  style?: CSSProperties;
  disableIcon?: boolean;
  expirationDays?: number;
  objectPrefix?: string;
}

const ExpirationDays = (props: ExpirationDaysProps) => {
  const { className, style, disableIcon, expirationDays, objectPrefix = null } = props;
  const [dateText, setDateText] = useState('');
  const [state, setState] = useState({
    error_expired: true,
    error: false,
    warning: false,
    default: false,
  });

  const handleState = () => {
    const isErrorExpired = expirationDays < 0 ? true : false;
    const isError = expirationDays < 3 ? true : false;
    const isWarning = expirationDays >= 3 && expirationDays < 10 ? true : false;
    const isDefault = expirationDays >= 10 ? true : false;

    setState({
      error: isError,
      error_expired: isErrorExpired,
      warning: isWarning,
      default: isDefault,
    });
  };

  const handleDateText = () => {
    const prefix = objectPrefix === null ? '' : objectPrefix + ' ';
    const vence = (expired = false) => {
      if (expired) {
        if (prefix !== '') {
          return prefix + ' venció';
        } else {
          return 'Venció';
        }
      }
      if (prefix !== '') {
        return prefix + ' vence ';
      } else {
        return 'Vence ';
      }
    };
    if (expirationDays === undefined) {
      setDateText('');
      return;
    }
    if (expirationDays === 0) {
      setDateText(vence() + 'hoy');
      return;
    }
    if (expirationDays > 0) {
      const daysText = expirationDays === 1 ? ' día' : ' días';
      setDateText(vence() + 'en ' + expirationDays + daysText);
      return;
    }
    if (expirationDays < 0) {
      setDateText(vence(true));
      return;
    }
  };

  useEffect(() => {
    handleState();
    handleDateText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cn = clsx(
    styles.root,
    {
      [styles.error]: state.error,
      [styles.error_expired]: state.error_expired,
      [styles.warning]: state.warning,
      [styles.default]: state.default,
    },
    className,
  );

  return (
    <div className={cn} style={{ ...style }}>
          {!disableIcon && <IconClock size={14} />}
          <div className={styles.text}>{dateText}.</div>
        </div>
  );
};

export default ExpirationDays;
