import { useEffect, useState } from 'react';
import { useDialog } from '#root/hooks';
import DeliverySetupDialog from '#root/components/DeliverySetupDialog';
import { Button, Container, Grid } from '@material-ui/core';
import { GuveryPageHeader } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import { Skeleton } from '@material-ui/lab';
import { PlusIcon } from '#root/icons';
import {
  IconMapPins,
  IconUser,
  IconPhoneCall,
  IconBrandWhatsapp,
  IconMapPin,
} from '@tabler/icons';
import styles from './travelerDeliverySetup.module.scss';
import clsx from 'clsx';

import DeliverySetupDTO from '#root/models/DeliverySetupDTO';
import { travelerService } from '#root/services';
import AddDeliverySetup from './AddDeliverySetup';

const TravelerDeliverySetup = () => {
  const [deliverySetups, setDeliverySetups] = useState<DeliverySetupDTO[]>([]);
  const [loading, setLoading] = useState(true);
  const deliverySetupDialog = useDialog();
  const addDeliverySetupDialog = useDialog();

  const [deliverySetup, setDeliverySetup] = useState(null);

  function handleEditButton(item) {
    setDeliverySetup(item);
    addDeliverySetupDialog.openDialog();
  }
  const handleNewButton = () => {
    setDeliverySetup(null);
    addDeliverySetupDialog.openDialog();
  };

  const SetupCard = (props) => {
    const { item } = props;

    return (
      <GuveryPaper disableHover className={styles.itemCard}>
        <div className={clsx(styles.header)}>
          <IconMapPins />
          <span>{item.label}</span>
        </div>
        <div className={clsx(styles.content)}>
          <h6 className={clsx(styles.message)}>{item.custom_message}</h6>
          <hr />
          <div className={clsx(styles.body)}>
            <div className={clsx(styles.col)}>
              {item.pickup_contact && (
                <p>
                  <IconUser size={14} /> {item.pickup_contact}
                </p>
              )}
              {item.phone_number && (
                <p>
                  <IconPhoneCall size={14} /> {item.phone_number}
                </p>
              )}
              {item.whatsapp_number && (
                <p>
                  <IconBrandWhatsapp size={14} /> {item.whatsapp_number}
                </p>
              )}
            </div>
            <div className={clsx(styles.col)}>
              {item.pickup_address && (
                <p>
                  <IconMapPin size={14} /> {item.pickup_address}
                </p>
              )}
            </div>
          </div>
          <hr />
          <p className={clsx(styles.details)}>{item?.pickup_details}</p>
        </div>
        <div className={clsx(styles.actions)}>
          <Button variant="outlined" color="primary" component="button" onClick={() => handleEditButton(item)}>
            Ver / Editar
          </Button>
        </div>
      </GuveryPaper>
    );
  };

  const fetchItems = async () => {
    setLoading(true);
    const res = await travelerService.getDeliverySetups();
    if (!res.error) {
      setDeliverySetups(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buttonHeader = (
    <>
      <Button variant="outlined" color="primary" onClick={deliverySetupDialog.openDialog}>
        Cómo funciona / Consejos
      </Button>
      <DeliverySetupDialog
        open={deliverySetupDialog.open}
        onClose={deliverySetupDialog.closeDialog}
      />
    </>
  );

  const subtitle = `Predefine la información que usas para coordinar una entrega y envíasela a los compradores que decidas. Ellos solo lo podrán verlo al momento que su acuerdo esté Listo para Entregar.`;

  return (
    <div className={clsx(styles.root, 'guv-main-container')}>
      <Container maxWidth="lg">
        <GuveryPageHeader
          title="Configurar Entregas"
          extra={buttonHeader}
          subTitle={subtitle}
        />
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              fullWidth
              className={clsx(styles.addItemCard)}
              onClick={handleNewButton}>
              <PlusIcon />
              <span className="add-text">Nueva Configuración</span>
            </Button>
          </Grid>
          {!loading ? (
            <>
              {deliverySetups.map((item, index) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <SetupCard item={item} />
                </Grid>
              ))}
            </>
          ) : (
            <>
              {Array.from(new Array(2)).map((item, index) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <GuveryPaper
                    disableHover
                    disableElevation
                    className={clsx(styles.addItemCard)}>
                    <Skeleton variant="rect" height="100%" />
                  </GuveryPaper>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Container>
      <AddDeliverySetup
              open={addDeliverySetupDialog.open}
              onClose={addDeliverySetupDialog.closeDialog}
              onSave={fetchItems}
              deliverySetup={deliverySetup}
            />
    </div>
  );
};

export default TravelerDeliverySetup;
