import SvgIcon from '@material-ui/core/SvgIcon';

const NuevoSolSvg = () => (
  <>
    <g>
      <g>
        <path d="M466.978 110.962c-3.134-4.549-9.36-5.696-13.908-2.564-4.549 3.133-5.696 9.359-2.564 13.908C477.652 161.72 492 207.951 492 256c0 130.131-105.869 236-236 236S20 386.131 20 256 125.869 20 256 20c48.021 0 94.229 14.333 133.629 41.449 4.549 3.129 10.775 1.981 13.907-2.568 3.131-4.55 1.981-10.776-2.568-13.907C358.217 15.552 308.088 0 256 0 187.62 0 123.333 26.628 74.981 74.981 26.628 123.333 0 187.62 0 256s26.628 132.667 74.981 181.019C123.333 485.372 187.62 512 256 512s132.667-26.628 181.019-74.981C485.372 388.667 512 324.38 512 256c0-52.119-15.568-102.272-45.022-145.038z"></path>
        <path d="M334.701 56H177.299a10.001 10.001 0 00-7.071 2.929L58.929 170.228A10.001 10.001 0 0056 177.299V334.7c0 2.652 1.054 5.196 2.929 7.071L170.228 453.07a10.001 10.001 0 007.071 2.929H334.7c2.652 0 5.196-1.054 7.071-2.929L453.07 341.771a10.001 10.001 0 002.929-7.071V177.299c0-2.652-1.054-5.196-2.929-7.071L341.772 58.929A10.003 10.003 0 00334.701 56zM436 330.559L330.559 436H181.441L76 330.559V181.441L181.441 76h149.117L436 181.441z"></path>
        <path d="M221.415 247.926c-22.199-8.188-42.725-17.147-46.913-18.993-7.071-4.923-10.533-12.977-9.281-21.694 1.486-10.354 9.243-18.605 20.749-22.071 29.273-8.821 55.246 12.243 55.629 12.56 4.228 3.531 10.519 2.979 14.065-1.241 3.554-4.228 3.007-10.536-1.22-14.089-1.386-1.165-34.394-28.384-74.243-16.38-18.869 5.684-32.195 20.39-34.777 38.379-2.386 16.62 4.805 32.6 18.768 41.702.442.288.906.541 1.387.755.947.423 23.496 10.459 48.915 19.836 20.754 7.655 31.19 20.26 28.633 34.583-2.401 13.448-17.093 27.741-39.59 27.741-19.487 0-38.346-7.973-50.448-21.327-3.708-4.092-10.032-4.403-14.125-.694-4.092 3.708-4.403 10.032-.694 14.125 15.83 17.467 40.229 27.896 65.268 27.896 29.292 0 54.776-19.013 59.278-44.226 3.368-18.863-5.739-43.707-41.401-56.862z"></path>
        <path d="M296.589 348.307a9.976 9.976 0 003.693.71c3.976 0 7.736-2.387 9.295-6.307l66.036-166.03c2.041-5.132-.464-10.947-5.596-12.988-5.131-2.042-10.947.464-12.988 5.596l-66.036 166.03c-2.041 5.133.464 10.948 5.596 12.989z"></path>
        <path d="M361.5 329.01c-2.63 0-5.21 1.07-7.07 2.93a10.1 10.1 0 00-2.93 7.08c0 2.63 1.07 5.21 2.93 7.07s4.44 2.93 7.07 2.93a10.1 10.1 0 007.08-2.93c1.86-1.86 2.92-4.44 2.92-7.07 0-2.64-1.06-5.21-2.92-7.08a10.1 10.1 0 00-7.08-2.93z"></path>
        <path d="M429.95 92.05c2.63 0 5.21-1.07 7.07-2.93s2.93-4.44 2.93-7.07-1.07-5.21-2.93-7.07-4.44-2.93-7.07-2.93-5.21 1.07-7.07 2.93-2.93 4.44-2.93 7.07 1.07 5.21 2.93 7.07 4.44 2.93 7.07 2.93z"></path>
      </g>
    </g>
  </>
);

const NuevoSol = (props) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
    <NuevoSolSvg />
  </SvgIcon>
);

export default NuevoSol;
