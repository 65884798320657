// action - account
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const THEME_TYPE = '@customization/THEME_TYPE';
export const THEME_LOCALE = '@customization/THEME_LOCALE';

// action - notification
export const NOTIFICATION_OPEN = '@notification/NOTIFICATION_OPEN';
export const NOTIFICATION_CLOSED = '@notification/NOTIFICATION_CLOSED';
