import { GuveryDialog } from '#root/ui-components';
import { IconMapPins } from '@tabler/icons';
import styles from './styles.module.scss';

const CurrentDeliverySetupDialog = (props) => {
    const { open = false, onClose } = props;

  return (
    <GuveryDialog
      open={open}
      onClose={onClose}
      title={<p style={{display: 'flex', alignItems: 'center', gap: 3, fontSize: 20}}><IconMapPins /> Compartiendo una Configuración de Entrega</p>}
      closeText="Cerrar">
      <div className={styles.root}>
        <p className="guv-font-size-5 guv-regular guv-text-alt-2">
          Cuando compartes una <strong>Configuración de Entrega</strong>, el comprador puede ver la información que has predefinido y contactarte al número de teléfono o whatsapp que has proporcionado, a fin de concretar la entrega.
        </p>
        <p className="guv-font-size-5 guv-regular guv-text-alt-2">
          Una vez que alguno de los dos haya confirmado la entrega, se dejará de compartir la <strong>Configuración de Entrega</strong>.
        </p>
        <hr />
        <p className="guv-font-size-6 guv-regular guv-text-alt-2">
          <span>Si el comprador no te ha contactado, contáctalo.</span>
        </p>
        <p className="guv-font-size-6 guv-regular guv-text-alt-2">
          <span>Si el comprador te ha contactado, pero aún no se acerca a recoger su pedido (y han pasado al menos 5 días), comunícate con Guvery para más alternativas de entrega.</span>
        </p>
        <p className="guv-font-size-6 guv-regular guv-text-alt-2">
          <span>Si has tenido una mala experiencia con un comprador y quieres dejar de compartirle tu <strong>Configuración de Entrega</strong> comunícate con Guvery para poder hacerlo.</span>
        </p>
      </div>
    </GuveryDialog>
  );
}

export default CurrentDeliverySetupDialog;