import configService from './config';
import fetchApi from './fetchApi';
import guveryFetchApi from './guveryFetchApi';
import * as METHODS from './methods';

const baseUrl = configService.apiUrl;
const guveryApi = configService.apiGuvery;

const dashboard = async () => {
  const { response } = await guveryFetchApi({
    url: guveryApi + `/traveler/dashboard`,
    options: METHODS.GET(),
    message: {
      success: 'Dashboard obtenido con exito.',
      error: 'No se pudo obtener el dashboard.',
    },
  });

  return { ...response };
};

// const summary = async () => {
//   const { response } = await fetchApi({
//     url: baseUrl + `/viajero_resumen`,
//     options: METHODS.GET(),
//     dataType: {},
//     message: {
//       success: 'Resumen obtenido con exito.',
//       error: 'No se pudo obtener el resumen.',
//     },
//   });

//   return { ...response };
// };

const orders = async (offset = 0) => {
  const { response } = await guveryFetchApi({
    url: guveryApi + `/traveler/orders?offset=${offset}`,
    options: METHODS.GET(),
    message: {
      success: 'Pedidos obtenidos con exito.',
      error: 'No se puedo obtener los pedidos.',
    },
  });

  return { ...response };
};

const ordersSearch = async (search: string | number, offset = 0) => {
  const { response } = await guveryFetchApi({
    url: guveryApi + `/traveler/orders?search=${search}&offset=${offset}`,
    // url: guveryApi + `/traveler/orders/${search}`,
    options: METHODS.GET(),
    message: {
      success: 'Pedidos obtenidos con exito.',
      error: 'No se puedo obtener los pedidos.',
    },
  });

  return { ...response };
};

const orderById = async (orderId) => {
  const { response } = await guveryFetchApi({
    url: guveryApi + `/traveler/order/${orderId}`,
    options: METHODS.GET(),
    message: {
      success: 'Pedido obtenido con exito.',
      error: 'No se puedo obtene el pedidos.',
    },
    avoidOverwriteData: true,
  });

  return { ...response };
};

const createOffer = async (arg) => {
    const { response } = await guveryFetchApi({
    url: `${guveryApi}/traveler/order/${arg.order_id}/offer`,
    options: METHODS.POST({
      quantity: arg.quantity,
      real_price: arg.real_price,
      traveler_fee: arg.traveler_fee,
      delivery_date: arg.delivery_date,
      delivery_place: arg.delivery_places,
      packaging_type: arg.packaging_type,
      expiration_date: arg.expiration_date,
      traveler_message: arg.offer_message,
    }),
    message: {
      success: 'Oferta creada exitosamente.',
      error: 'No se pudo crear la oferta.',
    },
  });

  return { ...response };
};

const getOrderOfferOptions = async (orderId) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/traveler/order/${orderId}/offer_options`,
    options: METHODS.GET(),
    message: {
      success: 'Datos obtenidos con exito.',
      error: 'No se puedo obtener los datos.',
    },
  });

  return { ...response };
};

// const wallet = async () => {
//   const { response } = await fetchApi({
//     url: baseUrl + `/usuario_billetera`,
//     options: METHODS.GET(),
//     dataType: {},
//     message: {
//       success: 'Resumen obtenidos con exito.',
//       error: 'No se puedo obtener el resumen.',
//     },
//   });

//   return { ...response };
// };

const getDeliverySetups = async () => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/traveler/delivery_setups`,
    options: METHODS.GET(),
    message: {
      success: 'Datos obtenidos con exito.',
      error: 'No se puedo obtener los datos.',
    },
  });

  return { ...response };
};

const addDeliverySetup = async (values) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/traveler/delivery_setups`,
    options: METHODS.POST(values),
    message: {
      success: 'Datos obtenidos con exito.',
      error: 'No se puedo obtener los datos.',
    },
  });

  return { ...response };
};

const trainingTest = async (arg) => {
  const { response } = await fetchApi({
    url: baseUrl + '/post_traveler_profile',
    options: METHODS.POST({
      online_shopping: arg.online_shopping,
      amazon_account: arg.amazon_account,
      payment_methods: [...arg.payment_methods],
      payment_methods_others: arg.payment_methods_others,
      traveler_experience: [...arg.traveler_experience],
      traveler_experience_similars: arg.traveler_experience_similars,
      travel_frecuency: arg.travel_frecuency,
      travel_reasons: [...arg.travel_reasons],
      travel_reasons_others: arg.travel_reasons_others,
    }),
    dataType: {},
    message: {
      success: 'Datos guardados exitosamente.',
      error: 'No se puedo enviar los datos.',
    },
  });

  return { ...response };
};

const getTestResult = async () => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/traveler/training`,
    options: METHODS.GET(),
    message: {
      success: 'Datos obtenidos con exito.',
      error: 'No se puedo obtener los datos.',
    },
  });

  return { ...response };
};

const dealRating = async (arg) => {
  const { response } = await guveryFetchApi({
    url: `${guveryApi}/traveler/rating/deal`,
    options: METHODS.POST({
      deal_id: arg.deal_id,
      rating_id: arg.rating_id,
      score: arg.score,
      opinion: arg.opinion,
    }),
    message: {
      success: 'Acuerdo recibido',
      error: 'No se pudo completar la acción.',
    },
  });

  return { ...response };
};

const travelerService = {
  dashboard,
  // summary,
  orders,
  ordersSearch,
  orderById,
  createOffer,
  getOrderOfferOptions,
  // wallet,
  getDeliverySetups,
  addDeliverySetup,
  trainingTest,
  getTestResult,
  dealRating,
};

export default travelerService;
