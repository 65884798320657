/* eslint-disable no-mixed-operators */
import React, { ReactNode, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Button, IconButton, Menu, MenuItem, ListItemIcon, Tooltip } from '@material-ui/core';
import { IconMapPins, IconUsers } from '@tabler/icons';
import {
  IconEye,
  IconPencil,
  IconDotsVertical,
  IconQuestionMark,
  IconBorderAll,
  IconShoppingCart,
  IconChecks,
  IconClipboardCheck,
  IconCameraPlus,
  IconTruck,
  IconTrash,
  IconInfoCircle,
  IconStar,
  IconClock,
} from '@tabler/icons';
import clsx from 'clsx';

import DealDTO, { DealStatus } from '#root/models/DealDTO';
import { useDialog } from '#root/hooks';
import { dealService } from '#root/services';
import { roundDecimal, replacePathParams } from '#root/utils';
import {
  ExpirationDays,
  RatingDialog,
  DealDeliverySetupDrawer,
  DealInstructionsDrawer,
  CurrentDeliverySetupDialog,
} from '#root/components';
import { AlertDialog, GuveryObsToolTip, GuverySpace } from '#root/ui-components';
import { CanguroGuvery, OneLogoSm } from '#root/assets';
import {
  TRAVELER_DEAL_EDIT,
  TRAVELER_DEAL_DETAIL,
  TRAVELER_DEAL_DETAIL_RATING,
} from '#root/constants/navigation';
import PriceDialog from '../PriceDialog';
import RejectedDialog from '../RejectedDialog';
import PartnersDialog from '../PartnersDialog';
import DeliveredOfficeDialog from '../DeliveredOfficeDialog';
import ProvinceDialog from '../ProvinceDialog';
import styles from './summary.module.scss';
import { SparksIcon } from '#root/icons';

interface AlertProps {
  message?: string | ReactNode;
  title?: string;
  variant?: 'error' | 'info' | 'success' | 'warning';
  onConfirm?: () => void;
}

interface SummaryProps {
  deal: DealDTO;
  imOwner?: any;
  carriers?: any;
  partners?: any;
  handleLoading?: () => void;
  createMessage: any;
}

const Summary = (props: SummaryProps) => {
  const { deal, imOwner, carriers, partners = [], handleLoading, createMessage } = props;
  const history = useHistory();
  const priceDialog = useDialog();
  const alertDialog = useDialog();
  const rejectedDialog = useDialog();
  const partnersDialog = useDialog();
  const ratingDialog = useDialog();
  const provinceDialog = useDialog();
  const deliveredOfficeDialog = useDialog();
  const currentDeliverySetupDialog = useDialog();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [drawerInstructionsOpen, setDrawerInstructionsOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [alertProps, setAlertProps] = useState<AlertProps>({
    variant: 'info',
    title: 'Marcar el acuerdo como comprado',
    message: (
      <span>
        <span style={{ display: 'inline-block' }}>
          ¿Está seguro de que desea marcar este acuerdo como comprado?.
        </span>
        <span style={{ display: 'inline-block' }}>Esta acción no se puede deshacer.</span>
      </span>
    ),
    onConfirm: () => fetchPurchased(),
  });

  const imagePath = deal?.offer?.order?.img_path;
  const dealObs = deal?.offer?.order?.obs;
  const ordersList = deal?.deal_orders;
  const partnership = deal?.partnership_travelers;
  let partnerName = imOwner
    ? partnership?.partner_traveler?.mask_fullname
    : partnership?.expert_traveler?.mask_fullname;

  const isCreated = deal?.est_n === DealStatus.created;
  const isPaid = deal?.est_n === DealStatus.paid;
  const isBuyed = deal?.est_n === DealStatus.buyed;
  const isRecivedTraveler = deal?.est_n === DealStatus.recivedTraveler;
  const isArrived = deal?.est_n === DealStatus.arrived;
  const isOffice = deal?.offer?.order?.flag_envio ? true : false;
  const isOne = deal?.offer?.order?.flujo === 'ONE';

  const hasDeliverySetup = deal?.delivery_setup ? true : false;

  const allowRating = deal?.rating_to_shopper ? true : false;

  const unclosedChangelogs = deal?.unclosed_changelogs ?? null;
  const hasUnclosedChangelogs =
    unclosedChangelogs && Object.keys(deal?.unclosed_changelogs).length > 0 ? true : false;

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDeliverySetup = () => {
    if (isOffice) {
      handleOpenAlert('readyForDelivery');
      return;
    }
    setDrawerOpen(!drawerOpen);
  };

  const handlePriceDialogOpen = () => {
    priceDialog.openDialog();
  };

  const handleInstructionsDrawer = () => {
    setDrawerInstructionsOpen(!drawerInstructionsOpen);
  };

  const handleOpenAlert = (type, delivery_setup_id = null) => {
    handleMenuClose();
    if (type === 'purchased') {
      setAlertProps({
        variant: 'success',
        title: 'Marcar el acuerdo como comprado',
        message: (
          <span>
            <span style={{ display: 'inline-block' }}>
              ¿Está seguro de que desea marcar este acuerdo como comprado?.
            </span>
            <span style={{ display: 'inline-block' }}>Esta acción no se puede deshacer.</span>
          </span>
        ),
        onConfirm: () => fetchPurchased(),
      });
    }
    if (type === 'received') {
      setAlertProps({
        variant: 'success',
        title: 'Recibido en EE.UU.',
        message: (
          <span>
            <span style={{ display: 'inline-block' }}>
              ¿Estás seguro de que deseas confirmar que recibiste el acuerdo?
            </span>
            <span style={{ display: 'inline-block' }}>Esta acción no se puede deshacer.</span>
          </span>
        ),
        onConfirm: () => fetchRecivedTraveler(),
      });
    }
    if (type === 'readyForDelivery') {
      setAlertProps({
        variant: 'success',
        title: 'Listo para entregar.',
        message: (
          <span>
            <span style={{ display: 'inline-block' }}>
              ¿Estás seguro de que deseas marcar este acuerdo como 'Listo para entregar'?
            </span>
            <span style={{ display: 'inline-block' }}>Esta acción no se puede deshacer.</span>
          </span>
        ),
        onConfirm: () => fetchReadyTraveler(delivery_setup_id),
      });
    }
    if (type === 'delivered') {
      setAlertProps({
        variant: 'success',
        title: 'Acuerdo Entregado',
        message: (
          <span>
            <span style={{ display: 'inline-block' }}>
              ¿Estás seguro de que deseas marcar este acuerdo como <strong>Entregado</strong>?
            </span>
            <span style={{ display: 'inline-block' }}>Esta acción no se puede deshacer.</span>
          </span>
        ),
        onConfirm: () => fetchDelivered(),
      });
    }
    if (type === 'expired') {
      setAlertProps({
        variant: 'error',
        title: 'Eliminar Acuerdo',
        message: (
          <span>
            <span style={{ display: 'inline-block' }}>
              Los acuerdos que vencieron y no se pagaron, se pueden eliminar ó modificar para
              extender su vigencia.
            </span>
            <br />
            <br />
            <span style={{ display: 'inline-block' }}>
              <strong>¿Estás seguro de que deseas Eliminar este acuerdo?</strong>
            </span>
            <span style={{ display: 'inline-block' }}>Esta acción no se puede deshacer.</span>
          </span>
        ),
        onConfirm: () => fetchExpired(),
      });
    }
    alertDialog.openDialog();
  };

  const fetchPurchased = async () => {
    const res = await dealService.purchasedByTraveler({ deal_id: deal?.ida });
    if (!res.error) {
      createMessage.success('Acuerdo actualizado con éxito');
      handleLoading();
    } else {
      createMessage.error('No se pudo actualizar el acuerdo');
    }
    alertDialog.closeDialog();
  };

  const fetchCancel = async (reason, reasonOther, dealId) => {
    const body = {
      reason,
      reasonOther,
      dealId,
    };
    const res = await dealService.rejectedByTraveler(body);
    if (!res.error) {
      createMessage.success('Acuerdo anulado con éxito');
      handleLoading();
      return;
    }
    createMessage.error('Ocurrió un error al anular el acuerdo');
    rejectedDialog.closeDialog();
    handleMenuClose();
  };

  const fetchRecivedTraveler = async () => {
    const res = await dealService.receivedByTraveler({ deal_id: deal?.ida });
    if (!res.error) {
      createMessage.success('Acuerdo actualizado con éxito');
      handleLoading();
    } else {
      createMessage.error('No se pudo actualizar el acuerdo');
    }
    alertDialog.closeDialog();
  };

  const fetchReadyTraveler = async (delivery_setup_id) => {
    const res = await dealService.readyByTraveler({
      deal_id: deal?.ida,
      delivery_setup_id: delivery_setup_id,
    });
    if (!res.error) {
      createMessage.success('Acuerdo actualizado con éxito');
      handleLoading();
    } else {
      createMessage.error('No se pudo actualizar el acuerdo');
    }
    alertDialog.closeDialog();
  };

  const fetchDelivered = async () => {
    const res = await dealService.deliveredByTraveler({ deal_id: deal?.ida });
    if (!res.error) {
      createMessage.success('Acuerdo actualizado con éxito');
      history.push(replacePathParams(TRAVELER_DEAL_DETAIL_RATING, { ida: deal?.ida }));
    } else {
      createMessage.error('No se pudo actualizar el acuerdo');
    }
    alertDialog.closeDialog();
  };

  const fetchDeliveredCarrier = async (
    photo_1,
    photo_2 = null,
    carrier_id,
    message = null,
    deal_id,
  ) => {
    const body = {
      photo_1,
      photo_2,
      carrier_id,
      message,
      deal_id,
    };
    const res = await dealService.deliveredCarrierByTraveler(body);
    if (!res.error) {
      createMessage.success('Acuerdo actualizado con éxito');
      history.push(replacePathParams(TRAVELER_DEAL_DETAIL_RATING, { ida: deal?.ida }));
    } else {
      createMessage.error('No se pudo actualizar el acuerdo');
    }
    alertDialog.closeDialog();
  };

  const fetchExpired = async () => {
    const res = await dealService.expiredByTraveler({ dealId: deal?.ida });
    if (!res.error) {
      createMessage.success('Acuerdo actualizado con éxito');
      handleLoading();
    } else {
      createMessage.error('No se pudo actualizar el acuerdo');
    }
    alertDialog.closeDialog();
  };

  const fetchPartners = async (partnerId, dealId) => {
    createMessage.info('Enviando datos...');
    const body = {
      partnerId,
      dealId,
    };
    const res = await dealService.addPartnerByTraveler(body);
    if (!res.error) {
      createMessage.success('Acuerdo actualizado con éxito');
      handleLoading();
      return;
    }
    createMessage.error('No se pudo actualizar el acuerdo');
    partnersDialog.closeDialog();
    handleMenuClose();
  };

  const tools = (
    <div className={styles.tools}>
      <IconButton color="secondary" onClick={handleMenuClick} className={styles.toolsButton}>
        <IconDotsVertical size={22} />
      </IconButton>
      <Menu
        id="tools-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}>
        <MenuItem
          component={RouterLink}
          to={replacePathParams(TRAVELER_DEAL_DETAIL, { ida: deal?.ida })}>
          <ListItemIcon style={{ minWidth: 32, color: 'inherit' }}>
            <IconEye size={20} />
          </ListItemIcon>
          <h6>Ver Detalle</h6>
        </MenuItem>
        {!hasUnclosedChangelogs && (
          <div>
            {imOwner && partners?.length > 0 && !partnership && (
              <MenuItem onClick={partnersDialog.openDialog}>
                <ListItemIcon style={{ minWidth: 32, color: 'inherit' }}>
                  <IconUsers size={20} color="var(--color-status-info)" />
                </ListItemIcon>
                <h6>Asignar Socio</h6>
              </MenuItem>
            )}
            {(isBuyed || isArrived || isRecivedTraveler) && (
              <div>
                {!isOffice && (
                  <MenuItem onClick={() => handleOpenAlert('delivered')}>
                    <ListItemIcon style={{ minWidth: 32, color: 'inherit' }}>
                      <IconChecks size={20} color="var(--color-status-success)" />
                    </ListItemIcon>
                    <h6>Confirmar Entrega</h6>
                  </MenuItem>
                )}
                {isOffice && (
                  <MenuItem onClick={deliveredOfficeDialog.openDialog}>
                    <ListItemIcon style={{ minWidth: 32, color: 'inherit' }}>
                      <IconCameraPlus size={20} color="var(--color-status-success)" />
                    </ListItemIcon>
                    <h6>Entregado en oficina</h6>
                  </MenuItem>
                )}
              </div>
            )}
            {((imOwner && isPaid) || isBuyed || isRecivedTraveler || isArrived) && (
              <MenuItem onClick={rejectedDialog.openDialog}>
                <ListItemIcon style={{ minWidth: 32 }}>
                  <IconTrash size={20} color="var(--color-status-error)" />
                </ListItemIcon>
                <h6>Anular Acuerdo</h6>
              </MenuItem>
            )}
          </div>
        )}
      </Menu>
    </div>
  );

  return (
    <div className={styles.root}>
      <div className={clsx(styles.cover, !imagePath && styles.holder)}>
        {imagePath ? (
          <img src={imagePath} alt="producto" />
        ) : (
          <img src={CanguroGuvery} alt="producto" />
        )}
      </div>
      <div className={styles.body}>
        <div className={styles.info}>
          <div className={styles.header}>
            <RouterLink to={replacePathParams(TRAVELER_DEAL_DETAIL, { ida: deal?.ida })}>
              <h4 className={styles.title}>Acuerdo #{deal.ida}</h4>
            </RouterLink>
            {isCreated && (
              <Tooltip
                arrow
                placement="top"
                title="Debes esperar a que Guvery te confirme el pago y te autorice a comprar.">
                <div className={styles.alert}>
                  <IconInfoCircle size={20} />
                </div>
              </Tooltip>
            )}
            {isPaid && (
              <Tooltip arrow placement="top" title="Pendiente de compra.">
                <div className={styles.alert}>
                  <IconInfoCircle size={20} />
                </div>
              </Tooltip>
            )}
            {isBuyed && (
              <Tooltip arrow placement="top" title="Pendiente de recepción...">
                <div className={styles.alert}>
                  <IconInfoCircle size={20} />
                </div>
              </Tooltip>
            )}
            {isRecivedTraveler && (
              <Tooltip arrow placement="top" title="Pendiente por llegar a Perú...">
                <div className={styles.alert}>
                  <IconInfoCircle size={20} />
                </div>
              </Tooltip>
            )}
            {isArrived && (
              <Tooltip arrow placement="top" title="Pendiente de entrega...">
                <div className={styles.alert}>
                  <IconInfoCircle size={20} />
                </div>
              </Tooltip>
            )}
            {isOne && (
              <div className={clsx(styles.badge, styles.badgeOne)}>
                <img src={OneLogoSm} alt="One" />
              </div>
            )}
            {isOffice && (
              <div
                className={clsx(styles.badge, styles.office)}
                onClick={provinceDialog.openDialog}>
                <span className={styles.text}>Envío Nacional</span>
                <IconQuestionMark size={20} style={{ marginLeft: 2 }} />
              </div>
            )}
          </div>
          {ordersList.length === 1 ? (
            <>
              {ordersList.map((order, index) => (
                <div className={styles.product} key={index}>
                  <h5 className={styles.text}>
                    <span className={styles.amount}>{order.can} x</span>
                    <a
                      className={styles.link}
                      href={order.url}
                      target="_blank"
                      rel="noopener noreferrer">
                      {order.fixed_url}
                    </a>
                  </h5>
                </div>
              ))}
            </>
          ) : (
            <Tooltip arrow title="Detalle del acuerdo" placement="top">
              <div
                className={clsx(styles.product, styles.list)}
                onClick={handlePriceDialogOpen}>
                <h5 className={styles.text}>
                  <span>{ordersList?.length} Productos</span>
                </h5>
                <IconBorderAll size={20} />
              </div>
            </Tooltip>
          )}
          {dealObs && (
            <GuveryObsToolTip arrow title={dealObs} placement="bottom">
              <div className={styles.obs}>
                <p>{dealObs}</p>
              </div>
            </GuveryObsToolTip>
          )}
        </div>
        <div className={styles.bottom}>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: 16 }}>
            <div className={styles.pricing} onClick={handlePriceDialogOpen}>
              <h4 className={styles.text}>
                <span>
                  <span className={styles.currency}>$</span>
                  <span className={styles.priceNum}>{roundDecimal(deal?.pretot)}</span>
                </span>
                <span>+</span>
                <span>
                  <span className={styles.currency}>$</span>
                  <span className={styles.priceNum}>{roundDecimal(deal?.comtot)}</span>
                </span>
                {deal.guvery_match_fee && (
                  <span className={styles.matchFee}>
                    <span>
                      <SparksIcon inlineDefault />
                    </span>
                    <span className={styles.currency}>$</span>
                    <span>{Number(deal.guvery_match_fee)}</span>
                  </span>
                )}
              </h4>
              <Tooltip arrow title="Detalle del total" placement="top">
                <IconButton
                  size="small"
                  color="primary"
                  className={styles.btn}
                  style={{ marginLeft: 8 }}>
                  <IconQuestionMark size={18} />
                </IconButton>
              </Tooltip>
            </div>
            {/* Fecha de entrega */}
            <div className={styles.hintText}>
              {deal?.fixed_delivery_date && (
                <div className={styles.agreedDate}>
                  <span className={styles.textHelper}>Fecha pactada:</span>
                  <span className={styles.text}>{deal?.fixed_delivery_date}</span>
                </div>
              )}
            </div>
            {/* status label + fecha */}
            <div className={styles.hintText}>
              {deal?.current_status_date && (
                <div className={styles.actionAlert}>
                  <span className={styles.textHelper}>{deal?.status_label}</span>
                  <span className={styles.text}>el {deal?.current_status_date}</span>
                </div>
              )}
            </div>
          </div>
          <div className={styles.actionsWrap}>
            {partnership && (
              <div className={styles.partner}>
                <div className={styles.partnerWrap}>
                  <IconUsers size={16} />
                  &nbsp;&nbsp;
                  <p className={styles.partnerText}>
                    Asociado con <strong>{partnerName}</strong>
                  </p>
                </div>
              </div>
            )}
            {hasUnclosedChangelogs ? (
              <>
                <GuverySpace direction="vertical" size="middle">
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button
                      disableElevation
                      disabled
                      variant="contained"
                      color="primary"
                      startIcon={<IconClock />}>
                      Hay cambios en Proceso
                    </Button>
                    <p
                      style={{
                        fontSize: 'var(--font-size-7)',
                        color: 'var(--color-text-alt-2)',
                        marginTop: 6,
                      }}>
                      Mientras tanto, no puedes modificar el acuerdo
                    </p>
                  </div>
                </GuverySpace>
              </>
            ) : (
              <>
                <GuverySpace
                  size="middle"
                  className={styles.actions}
                  align="start"
                  style={{ justifyContent: 'flex-end' }}>
                  {isCreated && deal.status_label === 'vencido' && (
                    <Button
                      disableElevation
                      variant="outlined"
                      color="secondary"
                      startIcon={<IconTrash />}
                      onClick={() => handleOpenAlert('expired')}>
                      Eliminar
                    </Button>
                  )}
                  {isCreated && (
                    <div>
                      <Button
                        disableElevation
                        variant="contained"
                        color="secondary"
                        startIcon={<IconPencil />}
                        component={RouterLink}
                        to={replacePathParams(TRAVELER_DEAL_EDIT, { ida: deal?.ida })}>
                        Modificar
                      </Button>
                      <ExpirationDays
                        expirationDays={deal?.fixed_expiration_days}
                        style={{ marginTop: 4 }}
                      />
                    </div>
                  )}
                  {imOwner && isPaid && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <p className={styles.anchorActionSuggestionText}>
                        <a onClick={handleInstructionsDrawer}>
                          Instrucciones para comprar
                        </a>
                      </p>
                      <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        startIcon={<IconShoppingCart />}
                        onClick={handleInstructionsDrawer}>
                        Ya lo compré
                      </Button>
                      <DealInstructionsDrawer
                        open={drawerInstructionsOpen}
                        onClose={() => setDrawerInstructionsOpen(!drawerInstructionsOpen)}
                        handleLoading={handleOpenAlert}
                        deal={deal}
                        nextAction="purchased"
                      />
                    </div>
                  )}
                  {imOwner && isBuyed && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <p className={styles.anchorActionSuggestionText}>
                        <a onClick={handleInstructionsDrawer}>
                          ¿Qué hacer al recibir este pedido?
                        </a>
                      </p>
                      <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        startIcon={<IconTruck />}
                        onClick={handleInstructionsDrawer}>
                        Ya lo recibí
                      </Button>
                      <DealInstructionsDrawer
                        open={drawerInstructionsOpen}
                        onClose={() => setDrawerInstructionsOpen(!drawerInstructionsOpen)}
                        handleLoading={handleOpenAlert}
                        deal={deal}
                        nextAction="received"
                      />
                    </div>
                  )}
                  {imOwner && isRecivedTraveler && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}>
                      <div style={{ textAlign: 'end' }}>
                        <Button
                          disableElevation
                          variant="contained"
                          color="primary"
                          onClick={handleOpenDeliverySetup}
                          startIcon={<IconMapPins />}>
                          Listo para entregar
                        </Button>
                        <DealDeliverySetupDrawer
                          open={drawerOpen}
                          onClose={() => setDrawerOpen(!drawerOpen)}
                          handleLoading={handleOpenAlert}
                          deal={deal}
                        />
                      </div>
                    </div>
                  )}
                  {imOwner && isArrived && !isOffice && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                      }}>
                      {hasDeliverySetup && (
                        <p className={styles.anchorActionSuggestionText}>
                          <a onClick={currentDeliverySetupDialog.openDialog}>
                            Se está compartiendo: {deal.delivery_setup.label}
                          </a>
                        </p>
                      )}
                      <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        startIcon={<IconClipboardCheck />}
                        onClick={() => handleOpenAlert('delivered')}>
                        Confirmar Entrega
                      </Button>
                    </div>
                  )}
                  {imOwner && isArrived && isOffice && (
                    <div>
                      <p className={styles.anchorActionSuggestionText}>
                        <a onClick={provinceDialog.openDialog}>¿Cómo entregar en oficina?</a>
                      </p>
                      <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        startIcon={<IconCameraPlus />}
                        onClick={deliveredOfficeDialog.openDialog}>
                        Entregado en oficina
                      </Button>
                    </div>
                  )}
                  {imOwner && allowRating && !deal?.rating_to_shopper?.score && (
                    <Button
                      disableElevation
                      onClick={ratingDialog.openDialog}
                      variant="outlined"
                      color="primary"
                      startIcon={<IconStar />}>
                      Calificar
                    </Button>
                  )}
                  {imOwner && allowRating && deal?.rating_to_shopper?.score && (
                    <p className={styles.rating}>
                      <span className={styles.text}>Calificaste al comprador con</span>
                      <span className={styles.score}>
                        {deal?.rating_to_shopper?.score} <IconStar />
                      </span>
                    </p>
                  )}
                </GuverySpace>
              </>
            )}
          </div>
        </div>
        {tools}
      </div>
      <PriceDialog open={priceDialog.open} onClose={priceDialog.closeDialog} deal={deal} />
      <AlertDialog open={alertDialog.open} onClose={alertDialog.closeDialog} {...alertProps} />
      <RejectedDialog
        id={deal?.ida}
        open={rejectedDialog.open}
        onClose={rejectedDialog.closeDialog}
        onConfirm={fetchCancel}
      />
      {partners?.length > 0 && (
        <PartnersDialog
          id={deal?.ida}
          partners={partners}
          open={partnersDialog.open}
          onClose={partnersDialog.closeDialog}
          onConfirm={fetchPartners}
        />
      )}
      <DeliveredOfficeDialog
        id={deal?.ida}
        carriers={carriers}
        open={deliveredOfficeDialog.open}
        onClose={deliveredOfficeDialog.closeDialog}
        onConfirm={fetchDeliveredCarrier}
      />
      <RatingDialog
        id={deal?.ida}
        ratingId={deal?.rating_to_shopper?.id}
        open={ratingDialog.open}
        onClose={ratingDialog.closeDialog}
        ratingType="traveler"
        title="Estas calificando a tu comprador"
        date1={deal?.fecentcom}
        date2={deal?.fecentvia}
        hint="Esta es una calificación anónima. El comprador no será notificado ni podrá saber su calificación."
        userData={deal?.offer?.order?.user}
        handleLoading={handleLoading}
        createMessage={createMessage}
        confirmText={isArrived && 'Confirmar entrega y calificar'}
      />
      <ProvinceDialog open={provinceDialog.open} onClose={provinceDialog.closeDialog} />
      <CurrentDeliverySetupDialog
        open={currentDeliverySetupDialog.open}
        onClose={currentDeliverySetupDialog.closeDialog}
      />
    </div>
  );
};

export default Summary;
