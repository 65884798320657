import { useEffect } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { Button, Drawer, List } from '@material-ui/core';
import {
  IconShield,
  IconTruckDelivery,
  IconMessageCircle,
  IconWallet,
  IconTicket,
} from '@tabler/icons';

import { NavItem } from '#root/components';
import {
  PersonOutlineIcon,
  PinIcon,
  ShoppingBagIcon,
  PlanenIcon,
  ScooterIcon,
} from '#root/icons';
import { useResponsive, useAuth } from '#root/hooks';
import * as VARIABLES from '#root/constants/variables';
import * as ROUTES from '#root/constants/navigation';
import ItemModel from '#root/interfaces/Item';
import styles from './profileSidebar.module.scss';

const items: ItemModel[] = [
  {
    icon: PersonOutlineIcon,
    href: ROUTES.USER_PROFILE,
    title: 'Mi Perfil',
  },
  {
    icon: ShoppingBagIcon,
    href: ROUTES.USER_ORDERS,
    // external: true,
    // href: VARIABLES.URL_PHP_PAGE + 'comprador/mispedidos',
    title: 'Mis Pedidos',
  },
  {
    icon: ScooterIcon,
    href: ROUTES.USER_ORDERS_STORE,
    // external: true,
    // href: VARIABLES.URL_PHP_PAGE + 'comprador/mispedidos',
    title: 'Mis Pedidos Tienda',
  },
  {
    icon: IconMessageCircle,
    href: ROUTES.USER_CHAT,
    title: 'Mis Chats',
  },
  {
    icon: IconWallet,
    href: ROUTES.USER_WALLET,
    title: 'Mi Billetera',
  },
  {
    icon: PinIcon,
    // href: VARIABLES.URL_PHP_PAGE + 'usuario/perfil/direcciones',
    // external: true,
    href: ROUTES.USER_ADDRESS,
    title: 'Mis Direcciones',
  },
  {
    icon: IconTruckDelivery,
    // href: VARIABLES.URL_PHP_PAGE + 'usuario/perfil/envios',
    // external: true,
    href: ROUTES.USER_SHIPMENTS,
    title: 'Mis Envíos',
  },
  {
    icon: IconTicket,
    href: ROUTES.USER_COUPONS,
    title: 'Mis Cupones',
  },
  /*
  {
    icon: IconSettings,
    href: ROUTES.USER_TRAVELER_SETTINGS,
    title: 'Configuración Viajero',
    // done: true,
  },
  */
];

interface ProfileSidebarProps {
  onMobileClose?: any /* func */;
  openMobile?: boolean;
}

const ProfileSidebar = (props: ProfileSidebarProps) => {
  const { onMobileClose = () => {}, openMobile = false } = props;
  const { global_is_admin, global_is_viajero } = useAuth();
  const { isDesktop } = useResponsive();
  const location = useLocation();

  const content = (
    <div className={styles.content}>
      <div className={styles.list}>
        <List style={{ padding: 0 }}>
          {items.map((item) => (
            <NavItem
              done={item.done}
              external={item.external}
              href={item.href}
              icon={item.icon}
              key={item.title}
              title={item.title}
            />
          ))}
        </List>
      </div>
      <div className={styles.space} />
      <div className={styles.bottom}>
        {global_is_admin && (
          <Button
            color="secondary"
            variant="outlined"
            component={'a'}
            disableElevation
            fullWidth
            style={{ marginBottom: '16px' }}
            href={VARIABLES.URL_PHP_PAGE + 'admin'}
            startIcon={<IconShield size={18} strokeWidth={1.5} />}>
            Admin
          </Button>
        )}
        {global_is_viajero ? (
          <Button
            color="primary"
            variant="outlined"
            component={RouterLink}
            disableElevation
            fullWidth
            style={{ marginBottom: '16px' }}
            to={ROUTES.TRAVELER_DASHBOARD}
            startIcon={<PlanenIcon fontSize="small" />}>
            Viajero
          </Button>
        ) : (
          <Button
            color="primary"
            variant="outlined"
            component={RouterLink}
            disableElevation
            fullWidth
            style={{ marginBottom: '16px' }}
            to={ROUTES.USER_TRAVELER_SETTINGS}>
            Configuración Viajero
          </Button>
        )}
      </div>
    </div>
  );

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      {isDesktop ? (
        <Drawer
          anchor="left"
          open
          PaperProps={{
            elevation: 0,
            style: {
              background: 'var(--color-background-alt)',
              // borderRight: '1px solid rgba(135,153,160,0.25)',
              borderRight: 'none',
              width: 208,
              top: 64,
              height: '100%',
              zIndex: 1000,
            },
          }}
          variant="persistent">
          {content}
        </Drawer>
      ) : (
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            style: {
              width: 256,
              top: 57,
              background: 'var(--color-background-alt)',
            },
          }}
          style={{
            zIndex: 1200,
          }}
          variant="temporary">
          {content}
        </Drawer>
      )}
    </>
  );
};

export default ProfileSidebar;
