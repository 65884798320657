interface MessageModel {
  error?: string;
  success?: string;
}

interface FetchApiProps {
  url: string;
  options: RequestInit;
  dataType: any;
  message: MessageModel;
}

type IData = any;

export interface FetchApiResponse {
  info: {
    status: number | null;
    error: boolean;
    message: string | null;
  };
  data: IData;
}

const fetchApi = async (data: FetchApiProps) => {
  const { url, options, dataType, message } = data;

  const response: FetchApiResponse = {
    info: { status: null, error: false, message: null },
    data: {},
  };

  if (dataType) {
    response.data = dataType;
  }

  try {
    const res = await fetch(url, options);
    const resData = await res.json();

    response.info.status = res.status;

    if (res.ok) {
      response.info.message = message.success;
      if (resData.success === 'false') {
        response.info.error = true;
        response.info.message = message.error;
      }

      response.data = resData.data ?? resData;
    } else {
      response.info.error = true;
      response.info.message = message.error;
    }
  } catch (err) {
    response.info.error = true;
    response.info.message = `Error: ${err.message}`;
  }

  return { response };
};

export default fetchApi;
