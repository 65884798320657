import { useState, useEffect } from 'react';
import { Button, Container } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { travelerService } from '#root/services';
import { useAuth } from '#root/hooks';
import { TravelerTest } from '#root/components';
import { GuveryLayout } from '#root/ui-components';
import { WandIcon, AwardIcon, MedalIcon } from '#root/icons';
import styles from './travelerTraining.module.scss';

const PAGE_VIAJERO_NO_CAPACITADO = 0;
const PAGE_VIAJERO_QUESTIONS = 1;
const PAGE_VIAJERO_CAPACITADO = 2;

const manualLink = {
  href: 'https://bit.ly/ViajerosGuvery',
  target: '_blank',
  rel: 'noopener noreferrer',
};

const TravelerTraining = () => {
  const { usuario } = useAuth();
  const isQualifiedTraveler = usuario?.capable_traveler_at
    ? PAGE_VIAJERO_CAPACITADO
    : PAGE_VIAJERO_NO_CAPACITADO;
  const [pageState, setPageState] = useState(isQualifiedTraveler);
  const [testStep, setTestStep] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchTestResult = async () => {
    setLoading(true);
    const res = await travelerService.getTestResult();
    if (!res.error) {
      if (!res.data.traveler_profile) {
        setTestStep(0);
      } else {
        setTestStep(3);
      }
      setPageState(PAGE_VIAJERO_QUESTIONS);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isQualifiedTraveler === PAGE_VIAJERO_NO_CAPACITADO) {
      fetchTestResult();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GuveryLayout container={false}>
      <Container maxWidth="md">
        <div className={styles.title}>
          {pageState === PAGE_VIAJERO_QUESTIONS ? (
            <h1>Proceso de Capacitación</h1>
          ) : (
            <h1>Capacitación</h1>
          )}
        </div>
        {!loading ? (
          <>
            {pageState === PAGE_VIAJERO_NO_CAPACITADO && (
              <>
                <p className={styles.desc}>
                  Los viajeros son los principales aliados de Guvery para
                  <strong>
                    {' '}
                    ofrecer un servicio responsable y comprometido a la comunidad de
                    compradores.
                  </strong>
                  &nbsp; Por este motivo es muy importante que cada viajero sepa sus funciones y
                  compromisos dentro de la plataforma, y los cumpla con ética y transparencia.
                </p>
                <div className={styles.paper}>
                  <MedalIcon className={styles.icon} />
                  <h3 style={{ marginBottom: 0 }}>Proceso de capacitación</h3>
                  <p className={styles.text}>(Toma 3 minutos máximo)</p>
                  <ul className={styles.list}>
                    <li>Perfil de viajero.</li>
                    <li>Funciones y compromisos del viajero.</li>
                    <li>Preguntas frecuentes del viajero.</li>
                  </ul>
                  <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    className={styles.action}
                    startIcon={<AwardIcon />}
                    onClick={() => setPageState(PAGE_VIAJERO_QUESTIONS)}>
                    Iniciar la Capacitación
                  </Button>
                </div>
              </>
            )}
            {pageState === PAGE_VIAJERO_CAPACITADO && (
              <>
                <p className={styles.desc}>
                  Los viajeros son los principales aliados de Guvery para
                  <strong>
                    {' '}
                    ofrecer un servicio responsable y comprometido a la comunidad de
                    compradores.
                  </strong>
                  &nbsp; Por este motivo es muy importante que cada viajero sepa sus funciones y
                  compromisos dentro de la plataforma, y los cumpla con ética y transparencia.
                </p>
                <p className={styles.desc}>
                  <strong>Recuerda estos 3 compromisos:</strong>
                  <ul className={styles.list}>
                    <br/>
                    <li style={{marginLeft: '4px'}}>- Sé activo:</li>
                    <li style={{color: 'var(--color-dark-gray)', marginLeft: '12px'}}>Responde rápido a las notificaciones de Guvery.</li>
                    <br/>
                    <li style={{marginLeft: '4px'}}>- Sé transparente:</li>
                    <li style={{color: 'var(--color-dark-gray)', marginLeft: '12px'}}>Comunica oportunamente (al comprador y a Guvery) cualquier incidente o cambio sobre algún acuerdo.</li>
                    <br/>
                    <li style={{marginLeft: '4px'}}>- Sé honesto:</li>
                    <li style={{color: 'var(--color-dark-gray)', marginLeft: '12px'}}>Está prohibido negociar por fuera de la plataforma y/o aceptar pagos directos.</li>
                  </ul>
                </p>
                <div className={styles.paper}>
                  <div className={styles.header}>
                    <h4>Ya eres un viajero capacitado</h4>
                    <MedalIcon />
                  </div>
                  <p className={styles.text}>
                    Te recomendamos estar actualizado consultando el manual :)
                  </p>
                  <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    className={styles.action}
                    startIcon={<WandIcon />}
                    {...manualLink}>
                    Manual del viajero
                  </Button>
                </div>
              </>
            )}
            {pageState === PAGE_VIAJERO_QUESTIONS && (
              <div className={styles.paperQuestion}>
                <TravelerTest step={testStep} />
              </div>
            )}
          </>
        ) : (
          <div style={{ marginTop: 70 }}>
            <Skeleton variant="rect" width="100%" height={336} />
          </div>
        )}
      </Container>
    </GuveryLayout>
  );
};

export default TravelerTraining;
