import React from 'react';
import { Avatar, Tooltip } from '@material-ui/core';
import { IconStar } from '@tabler/icons';
import clsx from 'clsx';

import styles from './seller.module.scss';

const Seller = (props) => {
  const { seller } = props;
  return (
    <div className={styles.root}>
      <div className={styles.sellerSumary}>
        <Avatar className={styles.avatar} src={seller?.user?.picture_path}>
          {seller?.user?.one_firstname.charAt(0)}
        </Avatar>
        <div className={styles.sellerInfo}>
          <h6 className={clsx(styles.name, 'guv-ellipsis')}>
            {seller?.user?.mask_fullname} (vendedor)
          </h6>
          {seller?.user?.calvia && (
            <div className={styles.sellerScore}>
              <Tooltip arrow title="Puntaje del vendedor" placement="top">
                <IconStar size={15} />
              </Tooltip>
              <span>
                <strong>{seller?.user?.calvia}</strong> en Guvery en 40 meses.
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Seller;
