import { Avatar, Button, Grid, Tooltip } from '@material-ui/core';
import { IconStar, IconMessageCircle } from '@tabler/icons';

import { useDialog } from '#root/hooks';
import { ChatDrawer } from '#root/components';
import { GuveryPaper } from '#root/ui-components/cards';
import { CanguroGuvery } from '#root/assets';
import styles from './dealInfo.module.scss';

const Item = ({ title, response }) => (
  <div className={styles.itemText}>
    <h6>{title}</h6>
    <div className={styles.response}>
      <p>{response}</p>
    </div>
  </div>
);

const DealInfo = (props) => {
  const { offerInfo } = props;
  const chatDrawer = useDialog();

  const shopper = offerInfo?.order?.user;
  const order = offerInfo?.order;

  return (
    <div className={styles.root}>
      {/*
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <span className={styles.headerTitle}>Información de la oferta</span>
        </div>
      </div>
      <hr className={styles.divider} />
      */}
      <div className={styles.body}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <GuveryPaper disableHover disableElevation className={styles.offerInfo}>
              <div className={styles.product}>
                <div className={styles.cover}>
                  {order?.img_path ? (
                    <Avatar className={styles.image} src={order.img_path} alt="Oferta">
                      <img className={styles.placeholder} src={CanguroGuvery} alt="Pedido" />
                    </Avatar>
                  ) : (
                    <img className={styles.placeholder} src={CanguroGuvery} alt="Pedido" />
                  )}
                </div>
                <div className={styles.info}>
                  <div>
                    <p className={styles.offerId}>#{offerInfo?.ido}</p>
                    <a href={order?.url} target="_blank" rel="noopener noreferrer">
                      <p className={styles.link}>{order?.tit}</p>
                    </a>
                    {order?.obs && (
                      <p className={styles.observation}>
                        <Tooltip title={order?.obs} arrow placement="top">
                          <span>{order?.obs}</span>
                        </Tooltip>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </GuveryPaper>
          </Grid>
          <Grid item xs={12} md={4}>
            <GuveryPaper disableElevation disableHover>
              <div className={styles.traveler}>
                <div className={styles.travelerCover}>
                  <Avatar className={styles.avatar} src={shopper?.picture_path}>
                    {shopper?.one_firstname?.charAt(0)}
                  </Avatar>
                </div>
                <div className={styles.travelerText}>
                  <h4 className="guv-ellipsis">{shopper?.mask_fullname}</h4>
                  {shopper?.calvia && (
                    <Tooltip arrow title="Puntaje del viajero" placement="bottom">
                      <div className={styles.score}>
                        <IconStar />
                        <span>{shopper.calvia}</span>
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </GuveryPaper>
          </Grid>
          {offerInfo?.empaque && (
            <Grid item xs={12} md={4}>
              <GuveryPaper disableElevation disableHover className={styles.item}>
                <div className={styles.wrap}>
                  <Item title="Empaque" response={offerInfo?.empaque} />
                </div>
              </GuveryPaper>
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            <Button
              style={{ height: '100%' }}
              fullWidth
              disableElevation
              variant="outlined"
              color="secondary"
              onClick={chatDrawer.openDialog}
              startIcon={<IconMessageCircle />}>
              Vista previa del chat
            </Button>
          </Grid>
        </Grid>
      </div>
      {offerInfo?.ido && (
        <ChatDrawer
          extraTitle={`Pedido #${offerInfo?.order?.id_public}`}
          offerId={offerInfo?.ido}
          open={chatDrawer.open}
          onClose={chatDrawer.closeDialog}
        />
      )}
    </div>
  );
};

export default DealInfo;
