import { MouseEvent } from 'react';
import { Avatar, IconButton, Toolbar, Divider } from '@material-ui/core';
import { IconUser } from '@tabler/icons';

import { useResponsive, useAuth } from '#root/hooks';
import { ArrowBackIcon, CloseIcon } from '#root/icons';
import styles from './appbar.module.scss';

interface AppbarProps {
  conversationUsers: any;
  onClose: (e?: MouseEvent<HTMLElement>) => void;
  extraTitle?: string;
}

const Appbar = (props: AppbarProps) => {
  const { conversationUsers, onClose, extraTitle } = props;
  const { isMobile } = useResponsive();
  const { usuario } = useAuth();

  const recordUser = conversationUsers?.find((u) => u?.user_id !== usuario.idu);
  const userName = recordUser ? recordUser?.user?.mask_fullname : 'Usuario';
  const userImage = recordUser && recordUser?.user?.picture_path;
  const userType = recordUser ? recordUser?.role : '---';

  return (
    <div className={styles.root}>
      <Toolbar>
        {isMobile && (
          <IconButton onClick={onClose}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <div className={styles.content}>
          <div className={styles.wrap}>
            <Avatar src={userImage} alt="usuario">
              <IconUser />
            </Avatar>
            <div className={styles.info}>
              <h6 className={styles.name}>{userName}</h6>
              <p className={styles.type}>{userType}</p>
            </div>
          </div>
          <Divider orientation="vertical" flexItem />
          {extraTitle && (
            <div className={styles.title}>
              <span>{extraTitle}</span>
            </div>
          )}
        </div>
        {!isMobile && (
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Toolbar>
    </div>
  );
};

export default Appbar;
