import { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { Avatar, Chip, Badge, Button, Grid, Slide, useScrollTrigger } from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import { IconSearch, IconRefresh, IconCheck } from '@tabler/icons';
import { SparksIcon } from '#root/icons';

import { useDialog, useResponsive, useAuth } from '#root/hooks';
import { travelerService } from '#root/services';
import { CreateOfferDrawer } from '#root/components';
import { GuveryLayout, GuveryPageHeader } from '#root/ui-components';
import { GuveryPaper, GuveryEmpty } from '#root/ui-components/cards';
import { ShippingSearch } from '#root/assets';
import * as ROUTES from '#root/constants/navigation';
import OrderCard from './OrderCard';
import HowOfferDialog from './HowOfferDialog';
import MatchDialog from './MatchDialog';
import SearchDialog from './SearchDialog';
import styles from './travelerOrders.module.scss';

function ElevationScroll(props) {
  const { children, search } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 60,
  });

  return (
    <Slide appear={false} direction="down" in={trigger && search}>
      {children}
    </Slide>
  );
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

type SearchType = string | number;

function TravelerOrders(props) {
  let query = useQuery();
  let queryParamSearch: SearchType = query.get('search');
  const orderId = props.match.params.id;
  const { usuario } = useAuth();
  const { isMobile } = useResponsive();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const ITEMS_BY_PAGE = 7;
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [paginable, setPaginable] = useState(true);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [orderObj, setOrderObj] = useState(null);
  const [search, setSearch] = useState<SearchType>(queryParamSearch);
  const howOfferDialog = useDialog();
  const matchDialog = useDialog();
  const searchDialog = useDialog();
  const history = useHistory();

  const handleOpenTravelerOffer = (order) => {
    setOrderObj(order);
    setDrawerOpen(!drawerOpen);
  };

  const handleOrdersReset = () => {
    history.push({
      pathname: ROUTES.TRAVELER_ORDERS,
    });
    window.scrollTo(0, 0);
    setSearch('');
    if (offset === 0) {
      return fetchOrders();
    }
    setOffset(0);
  };

  const fetchOrders = async () => {
    if (offset > 0) setLoadingPagination(true);
    setLoading(true);
    setPaginable(true);
    if (search) {
      history.push({
        pathname: ROUTES.TRAVELER_ORDERS,
        search: `?search=${search}`,
      });
    } else {
      history.push({
        pathname: ROUTES.TRAVELER_ORDERS,
      });
    }
    const res = (!search) 
    ? await travelerService.orders(offset)
    : await travelerService.ordersSearch(search, offset);
    setLoadingPagination(false);
    setLoading(false);
    if (res.error) return;
    if (offset > 0) {
      setOrders(prevOrders => [...prevOrders, ...res.data]);
    } else {
      setOrders(res.data);
    }
    setHasMore(res.data.length > (ITEMS_BY_PAGE - 1));
  };

  const fetchOrderById = async (id) => {
    setLoading(true);
    setPaginable(false);
    setOrders([]);
    const res = await travelerService.orderById(id);
    if (!res.error) {
      if (res.data) {
        setOrders([res.data]);
      }
    }
    setLoading(false);
  };

  const removeOrder = (order) => {
    const idx = orders.indexOf(order);
    const newOrderArray = orders;
    if (idx > -1) {
      newOrderArray.splice(idx, 1);
    }
    setOrders(newOrderArray);
  };

  const observer = useRef() as any;
  const lastMapElementRef = useCallback(node => {
    if (loading) return;
    if (!paginable) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setOffset(offset => offset + ITEMS_BY_PAGE);
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore]);

  useEffect(() => {
    if (orderId) {
      fetchOrderById(orderId);
      return;
    }
    fetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, search]);

  const headerExtra = [
    <Fragment key="0">
      {!search && !orderId && (
        <Button
          disableFocusRipple
          disableElevation
          color="primary"
          variant="outlined"
          onClick={searchDialog.openDialog}
          endIcon={<IconSearch size={20} />}>
          Buscar
        </Button>
      )}
    </Fragment>,
    <Fragment key="1">
      {search && (
        <Chip
          avatar={
            <Avatar>
              <IconSearch size={16} />
            </Avatar>
          }
          label={search}
          onDelete={handleOrdersReset}
        />
      )}
    </Fragment>,
    <Fragment key="2">
      {orderId && (
        <Chip
          avatar={
            <Avatar>
              <IconSearch size={16} />
            </Avatar>
          }
          label={`Pedido: #${orderId}`}
          onDelete={handleOrdersReset}
        />
      )}
    </Fragment>,
    <Fragment key="3">
    {usuario.capable_traveler_at && (
      <Badge variant="standard" badgeContent={' '} color='error' overlap="rectangular">
      <Button color="secondary" variant="outlined" onClick={matchDialog.openDialog} startIcon={<SparksIcon inlineDefault />}>
        Match
      </Button>
    </Badge>
    )}
    </Fragment>,
    <Button key="4" color="secondary" variant="outlined" onClick={howOfferDialog.openDialog}>
      ¿Cómo ofertar?
    </Button>,
  ];

  const OrderList = ({ orders, disableOffer, handleOpenOffer }) => (
    <>
      {orders?.length < 1 ? (
        <Grid item xs={12}>
          <GuveryEmpty description="No se encontraron pedidos..." image={ShippingSearch}>
            {search && (
              <Button
                disableElevation
                color="secondary"
                variant="outlined"
                onClick={handleOrdersReset}>
                Ver todos
              </Button>
            )}
          </GuveryEmpty>
        </Grid>
      ) : (
        <>
          {orders.map((order, index) => {
            if (orders.length === index + 1) {
              return (
              <Grid item xs={12} key={index}>
              <OrderCard
                xref={lastMapElementRef}
                order={order}
                disableOffer={disableOffer}
                onActionClick={handleOpenOffer}
              />
            </Grid>)
            } else {
              return (
              <Grid item xs={12} key={index}>
              <OrderCard
                order={order}
                disableOffer={disableOffer}
                onActionClick={handleOpenOffer}
              />
            </Grid>)
            }
          })}
          
          {paginable && loadingPagination && (
            <Grid item xs={12}>
            <GuveryPaper disableBorder disableElevation disableHover className={styles.root}>
            <h3 className="guv-text-alt-2" style={{textAlign: 'center', marginBottom: 30}}>
              <IconRefresh size={18} />{'  '}Cargando...
            </h3>
            </GuveryPaper>
            </Grid>
          )}

          {paginable && !loadingPagination && !hasMore && (
            <Grid item xs={12}>
            <GuveryPaper disableBorder disableElevation disableHover className={styles.root}>
            <h3 className="guv-text-alt-2" style={{textAlign: 'center', marginBottom: 30}}>
              <IconCheck size={18} />{'  '}Revisaste todos los pedidos...
            </h3>
            </GuveryPaper>
            </Grid>
          )}

        </>
      )}
      {orderId && (
        <Grid item xs={12}>
          <Button
            fullWidth
            size="large"
            color="secondary"
            variant="outlined"
            onClick={handleOrdersReset}>
            Ver todos los pedidos
          </Button>
        </Grid>
      )}
    </>
  );

  const alert = (
    <div className={styles.alert}>
      <RouterLink to={ROUTES.TRAVELER_TRAINING} className="guv-no-decoration">
        <Alert
          severity="error"
          action={
            !isMobile && (
              <Button color="inherit" variant="outlined" size="small">
                Capacitarme
              </Button>
            )
          }>
          <h5>Capacitación Obligatoria!</h5>
          Sólo los viajeros capacitados pueden hacer ofertas.
        </Alert>
      </RouterLink>
    </div>
  );

  return (
    <GuveryLayout style={{ position: 'relative' }}>
      {!usuario.capable_traveler_at && alert}
      <ElevationScroll search={search}>
        <div className={styles.searchTopbar}>
          <div className={styles.wrap}>
            <div className={styles.flex}>
              <h4 className={styles.title}>Buscando:</h4>
              <Chip
                avatar={
                  <Avatar>
                    <IconSearch size={16} />
                  </Avatar>
                }
                label={search}
                onDelete={handleOrdersReset}
              />
            </div>
          </div>
        </div>
      </ElevationScroll>
      <GuveryPageHeader title="Pedidos" extra={headerExtra} />
      <Grid container spacing={3}>
        {(!loading || loadingPagination) ? (
          <>
          <OrderList
            orders={orders}
            disableOffer={usuario.capable_traveler_at ? false : true}
            handleOpenOffer={handleOpenTravelerOffer}
          />
          {loadingPagination ?? (
            <h1>Cargando...</h1>
          )}
          </>
        ) : (
          <>
            {Array.from(new Array(6)).map((_, index) => (
              <Grid item xs={12} key={index}>
                <GuveryPaper disableBorder disableHover>
                  <Skeleton variant="rect" height={240} width="100%" />
                </GuveryPaper>
              </Grid>
            ))}
          </>
        )}
      </Grid>
      {orderObj && (
        <CreateOfferDrawer
          order={orderObj}
          open={drawerOpen}
          onClose={() => setDrawerOpen(!drawerOpen)}
          removeOrder={removeOrder}
        />
      )}
      <MatchDialog open={matchDialog.open} onClose={matchDialog.closeDialog} />
      <HowOfferDialog open={howOfferDialog.open} onClose={howOfferDialog.closeDialog} />
      <SearchDialog
        open={searchDialog.open}
        onClose={searchDialog.closeDialog}
        setSearch={setSearch}
        setOffset={setOffset}
      />
    </GuveryLayout>
  );
}

export default TravelerOrders;
